import * as React from 'react';
import { ExposureLevel } from '../../types';
import { barWidth, dotSize } from './barConstants';
import styles from './BarDot.emotion';

interface Props {
  exposureLevel: ExposureLevel;
  TIVPct: string | number;
}

const BarDot: React.FC<Props> = ({ exposureLevel, TIVPct }) => {
  // adding pixels for the additional gaps between segments
  const additionalPixels = {
    [ExposureLevel.VeryLow]: 0,
    [ExposureLevel.Low]: 1,
    [ExposureLevel.Medium]: 2,
    [ExposureLevel.High]: 3,
    [ExposureLevel.VeryHigh]: 4,
  };

  const pixelPosition =
    (parseInt(TIVPct.toString()) / 100) * barWidth + additionalPixels[exposureLevel] - dotSize / 2;

  return <div data-testid="exposure-bar-dot" className={styles.dot(pixelPosition)} />;
};

export default BarDot;

import * as React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import HighIcon from '../../icons/HighIcon';
import LowIcon from '../../icons/LowIcon';
import MediumIcon from '../../icons/MediumIcon';
import VeryHighIcon from '../../icons/VeryHighIcon';
import VeryLowIcon from '../../icons/VeryLowIcon';
import { ExposureLevel } from '../../types';
import exposureLabels from '../../utils/exposureLabels';
import styles from './indexSummary.emotion';

interface Props {
  exposureLevel: ExposureLevel;
}

const IndexSummary: React.FC<Props> = ({ exposureLevel }) => {
  const exposureLabel = exposureLabels[exposureLevel];

  const indexIcon = {
    [ExposureLevel.VeryHigh]: <VeryHighIcon />,
    [ExposureLevel.High]: <HighIcon />,
    [ExposureLevel.Medium]: <MediumIcon />,
    [ExposureLevel.Low]: <LowIcon />,
    [ExposureLevel.VeryLow]: <VeryLowIcon />,
  };

  return (
    <div data-testid="exposure-index-summary" className={styles.container}>
      <div className={styles.iconContainer(exposureLevel)}>{indexIcon[exposureLevel]}</div>
      <div className={styles.titleContainer(exposureLevel)}>
        <EuiFlexGroup direction="column" gutterSize="s">
          <EuiFlexItem grow={false}>
            <EuiText>
              <h3 className={styles.title}>Your Exposure is</h3>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText>
              <h1 className={styles.title}>{exposureLabel}</h1>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </div>
  );
};

export default IndexSummary;

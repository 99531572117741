import { FC, Fragment, useEffect, useState } from 'react';
import { RuleType } from 'react-querybuilder';
import { Button, ButtonEmpty, EuiHorizontalRule } from 'ui';
import {
  ButtonFooter,
  ClearFiltersContainer,
} from '@app/components/FiltersModal/FiltersModal.emotion';
import { FilterQuery } from '../types';

interface Props {
  onApply: () => void;
  onClear: () => void;
  query?: FilterQuery;
  onClose: () => void;
}

const FilterModalActionButtons: FC<Props> = ({ onApply, onClear, query, onClose }) => {
  const [initialQuery, setInitialQuery] = useState<FilterQuery>();

  useEffect(() => {
    setInitialQuery(query);
  }, []);

  const isValidFilterQuery = () => {
    // FIX ME
    // @ts-ignore
    const defaultEmptyRule = query?.rules?.[0]?.field === 'default';
    // if a query has no field selected, the query is not valid
    if (query?.rules?.some((f: RuleType) => f?.field === null)) {
      return false;
    }
    // If there was no previous query and there is a query now
    if (!initialQuery && query && query.rules.length > 0 && !defaultEmptyRule) {
      return true;
    }

    // If the query has been updated
    if (!!initialQuery && !!query && query.rules.length > 0 && !defaultEmptyRule) {
      return true;
    }

    return false;
  };

  return (
    <>
      <ClearFiltersContainer justifyContent="flexEnd">
        <ButtonEmpty
          size="xs"
          color="primary"
          iconName="x"
          iconSide="left"
          label="Clear all filters"
          onClick={onClear}
        />
      </ClearFiltersContainer>
      <EuiHorizontalRule margin="xs" />
      <ButtonFooter justifyContent="flexEnd" alignItems="center">
        <ButtonEmpty size="m" onClick={onClose} label="Cancel" />
        <Button
          data-testid="filter-apply-button"
          disabled={!isValidFilterQuery()}
          size="m"
          color="primary"
          fill
          label="Apply filters"
          onClick={onApply}
        />
      </ButtonFooter>
    </>
  );
};

export default FilterModalActionButtons;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AonLogo = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var AonLogo = function () { return ((0, jsx_runtime_1.jsxs)("svg", __assign({ width: "95", height: "36", viewBox: "0 0 95 36", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M15.2738 0L0 35.1921H7.28163L9.80267 29.1412H22.7669L20.0438 22.9385H12.3855L18.1109 9.19256L28.9413 35.1921H36.2219L20.9491 0H15.2738Z", fill: "#EB0017" }), (0, jsx_runtime_1.jsx)("path", { d: "M88.2584 5V22.9027L73.8196 5H68V35.1626H74.6799V16.5577L89.6889 35.1626H94.9383V5H88.2584Z", fill: "#EB0017" }), (0, jsx_runtime_1.jsx)("path", { d: "M49.8149 4C41.0943 4 34 11.0943 34 19.8149C34 28.5355 41.0943 35.6308 49.8149 35.6308C58.5355 35.6308 65.6298 28.5365 65.6298 19.8159C65.6298 11.0953 58.5355 4 49.8149 4ZM49.8149 28.9509C44.777 28.9509 40.6788 24.8528 40.6788 19.8149C40.6788 14.777 44.777 10.6788 49.8149 10.6788C54.8528 10.6788 58.951 14.777 58.951 19.8149C58.951 24.8528 54.8528 28.9509 49.8149 28.9509Z", fill: "#EB0017" })] }))); };
exports.AonLogo = AonLogo;
exports.default = exports.AonLogo;

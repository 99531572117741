import * as React from 'react';
import { PropertyAttributeMetadata } from '@app/components/QueryBuilderWrapper/utils';
import { EJobType } from '@app/queries/organizations/types';
import { Lock } from '@app/queries/properties/PropertyQuery/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import InProgress from './InProgress';
import StartEdit from './StartEdit';
import { IGraphQLProperty } from '@app/queries/properties/generated_types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';

interface Props {
  filterable: Array<PropertyAttributeMetadata>;
  inProgressJob?: Lock;
  orgName: string;
  stream: IGraphQLStream;
  jobType: EJobType;
  refetch: () => void;
  attributes?: IGraphQLAttributeMetadata[];
  properties?: IGraphQLProperty[];
}

const EditDataFlyout: React.FC<Props> = ({
  filterable,
  inProgressJob,
  orgName,
  stream,
  jobType,
  refetch,
  properties,
  attributes,
}) => {
  if (inProgressJob) {
    return <InProgress inProgressJob={inProgressJob} stream={stream} refetch={refetch} />;
  }

  return (
    <StartEdit
      filterable={filterable}
      orgName={orgName}
      stream={stream}
      attributes={attributes}
      properties={properties}
      jobType={jobType}
      refetch={refetch}
    />
  );
};

export default EditDataFlyout;

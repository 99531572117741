import { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { useQuery } from '@apollo/client';
import AddEditOrgModal from '@onarchipelago/cx/Organizations/AddEditOrgModal';
import debounce from 'lodash/debounce';
import {
  Button,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiTitle,
  Spacer,
  Spinner,
  useToast,
} from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { useModalState } from '@app/contexts/ModalContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useGetOrganizationQuery } from '@app/graphql/queries/organization/__generated__/Organization.generated';
import { GET_ORGANIZATION_PAGE } from '@app/queries/organizations/getOrganizationsPage';
import { IGraphQLOrganization } from '@app/queries/organizations/types';
import { ManagedOrgInfo } from '@app/queries/streams/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { OrgItemContent } from './OrgItemContent';
import { FocusTrapContainer, OrgSwitcherBody } from './OrgSwitcherFlyout.emotion';

export const OrgSwitcherFlyout: FC<{ side?: string }> = ({ side = 'right' }) => {
  const infiniteLoaderRef = useRef(null);

  const { account } = useAuth();
  const { selectedOrganization } = useUserSession();
  const { closeFlyout } = useFlyout();
  const toast = useToast();
  const { showModal } = useModalState();
  const isAdmin = account?.permissions?.admin;

  if (!account) {
    return <div />;
  }

  const [filteredOrgList, setFilteredOrgList] = useState<ReactElement[]>([]);

  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState([]);

  const onChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const debounceSetFilters = useCallback(
    debounce((filters) => {
      setFilters(filters);
    }, 500),
    [],
  );

  const { data: selectedOrgData, loading: loadingSelectedOrgData } = useGetOrganizationQuery({
    variables: {
      id: selectedOrganization?.id,
      name: selectedOrganization?.name,
    },
  });

  const { data, loading, fetchMore } = useQuery(GET_ORGANIZATION_PAGE, {
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    skip: !isAdmin,
    variables: {
      cursor: null,
      filters,
      pageSize: 50,
      sortBy: [{ attributeName: 'name', order: 'ASCENDING' }],
    },
  });

  const loadMore = () =>
    fetchMore?.({
      updateQuery: (prev, { fetchMoreResult }: { fetchMoreResult: any; rest: any }) => {
        if (!prev || !fetchMoreResult) {
          return prev;
        }
        return {
          ...fetchMoreResult,
          organizationsPage: {
            ...fetchMoreResult?.organizationsPage,
            organizations: [
              ...(prev?.organizationsPage?.organizations || {}),
              ...(fetchMoreResult?.organizationsPage?.organizations || {}),
            ],
          },
        };
      },
      variables: {
        cursor: data?.organizationsPage?.pageInfo?.cursor,
      },
    });

  useEffect(() => {
    if (data?.organizationsPage) {
      // filters out the selected org to place at the front
      const list = (data?.organizationsPage?.organizations || [])
        .filter((org: IGraphQLOrganization) => org.name !== selectedOrganization?.name)
        .map((org: IGraphQLOrganization) => <OrgItemContent key={org.id} org={org} />);

      const selectedOrgMatchesSearch = searchValue
        ? selectedOrganization?.name?.toLowerCase()?.includes(searchValue.toLowerCase())
        : false;

      if (selectedOrgData?.organization && (!searchValue || selectedOrgMatchesSearch)) {
        const selectedOrgItemContent = (
          <OrgItemContent
            key={selectedOrgData?.organization?.id}
            org={selectedOrgData?.organization}
          />
        );
        list.unshift(selectedOrgItemContent);
      }

      setFilteredOrgList(list);
    }
  }, [data, selectedOrgData]);

  // handles filtering managed orgs
  useEffect(() => {
    if (!isAdmin) {
      const managedOrgs: ManagedOrgInfo[] = [...(account?.managedOrgs || [])];

      const managedOrgsList = managedOrgs.map(({ org }) => (
        <OrgItemContent key={org.id} org={org} />
      ));
      if (searchValue.length > 0) {
        const list = managedOrgs
          .filter((o) => o?.org?.name?.toLowerCase().includes(searchValue.toLowerCase()))
          .map(({ org }) => <OrgItemContent key={org.id} org={org} />);
        setFilteredOrgList(list);
      } else {
        setFilteredOrgList(managedOrgsList);
      }
    }
  }, [account, searchValue]);

  useEffect(() => {
    if (!searchValue) {
      setFilters([]);
    } else {
      const newFilters = [{ name: 'NAME', operator: 'CONTAINS', values: [searchValue] }];
      debounceSetFilters(newFilters);
    }
  }, [searchValue]);

  const itemCount = data?.organizationsPage?.pageInfo?.hasNextPage
    ? 1 + filteredOrgList.length
    : filteredOrgList.length;

  const isItemLoaded = (index: number) => !!filteredOrgList[index];

  const Row = ({ index, style }: any) =>
    isItemLoaded(index) ? (
      <div data-testid={`org-flyout-result-${index}`} style={{ ...style }}>
        {filteredOrgList[index]}
      </div>
    ) : (
      <div style={{ ...style, padding: '12px' /* $euiSizeM */, textAlign: 'center' }}>
        <Spinner size="m" /> Loading ...
      </div>
    );

  const nullLoader = () => null;
  const loadMoreItems = data?.loading ? nullLoader : loadMore;

  const noOrgsFound =
    (!loading && filteredOrgList.length < 1) ||
    (loading && searchValue && filteredOrgList.length < 1);

  return (
    <EuiFlyout
      ownFocus
      onClose={closeFlyout}
      aria-labelledby="flyoutTitle"
      size="s"
      className="orgSwitcher"
      // FIX ME
      // @ts-ignore
      side={side}
    >
      <FocusTrapContainer initialFocus="input[name=orgSwitcherSearch]">
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2>Client Accounts</h2>
          </EuiTitle>
          <Spacer />
          <EuiFlexGroup gutterSize="s">
            <EuiFlexItem>
              <EuiFieldSearch
                data-testid="org-search-field-flyout"
                placeholder="Search"
                value={searchValue}
                onChange={onChange}
                autoComplete="off"
                onKeyDown={(e: any) => {
                  // Down arrow key
                  if (e?.key === 'ArrowDown') {
                    e.stopPropagation();
                    e.preventDefault();

                    // Go to first item in the list
                    const item = document.querySelector('.orgSwitcherItem');
                    (item as HTMLElement)?.focus();
                  }
                }}
                isClearable={false}
                fullWidth
                aria-label="Search Accounts"
                name="orgSwitcherSearch"
                isLoading={!!(searchValue && loading)}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {isAdmin && (
                <Button
                  data-testid="add-organization-button"
                  fill
                  color="primary"
                  onClick={() => showModal(<AddEditOrgModal action="add" />)}
                  iconSide="right"
                  iconName="plus"
                  label="Add"
                />
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutHeader>
        <Spacer size="xs" />
        {(loading || loadingSelectedOrgData) && !searchValue && (
          <LoadingSpinnerV2 variant="xxl" label="Loading" dataTestId="loading-spinner" />
        )}
        {noOrgsFound && (
          <div style={{ padding: '24px 12px' /* $euiSizeL,M */, textAlign: 'center' }}>
            No accounts found
          </div>
        )}
        {filteredOrgList.length > 0 && (
          <OrgSwitcherBody data-testid="org-switcher-body">
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  ref={infiniteLoaderRef}
                  isItemLoaded={isItemLoaded}
                  itemCount={itemCount}
                  loadMoreItems={loadMoreItems}
                  threshold={25}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      className="List"
                      height={height}
                      itemCount={itemCount}
                      itemSize={48}
                      width={width}
                      onItemsRendered={onItemsRendered}
                      ref={ref}
                    >
                      {Row}
                    </FixedSizeList>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          </OrgSwitcherBody>
        )}
      </FocusTrapContainer>
    </EuiFlyout>
  );
};

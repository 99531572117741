import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import styled from '@emotion/styled';
import queryString from 'query-string';
import {
  Button,
  ButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormRowProps,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  EuiTitle,
  EuiToolTip,
  Icon,
  useEuiTheme,
  useToast,
} from 'ui';
import { FormGoogleAutocompleteInput } from '@app/components/Form/FormGoogleAutocompleteInput';
import { EditFormField } from '@app/components/PropertiesDataGrid/CellPopoverContent/EditCellPopoverContent/EditFormField';
import { usePropertiesDataGridContext } from '@app/components/PropertiesDataGrid/context/PropertiesDataGridContext';
import { ColumnKey } from '@app/components/PropertiesGrid/types';
import { propertiesModalPathname, useDecodedParams } from '@app/containers/App/Routes/utils';
import {
  formatEditError,
  getFinalNewPropertyValues,
} from '@app/cx/PropertyModal/PropertyModalHeader/utils';
import {
  getAttributeRow,
  UNEDITABLE_KEYS,
} from '@app/cx/Stream/AddPropertyFlyout/AdditionalAttributesTab/AdditionalAttributes';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { useApplyEditPropertyJobMutation } from '@app/graphql/jobs/mutations/__generated__/applyEditPropertyJob.generated';
import { AttributeLocks, JobStatus } from '@app/graphql/types';
import { useJobPoller } from '@app/hooks/useJobPoller';
import { TrackGroupSovManager, useTracker } from '@app/hooks/useTracker';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { CancelModal } from '../Stream/AddPropertyFlyout/CancelModal/CancelModal';
import {
  ADDRESS_AND_GEO_INPUT_KEYS,
  AttributeDataType,
  CUSTOM_ATTRIBUTES_KEY,
  STREET_ADDRESS_INPUT_KEY,
} from '../Stream/AddPropertyFlyout/constants';
import { useStreamContext } from '../Stream/StreamProvider';

const StyledFlyout = styled(EuiFlyout)`
  inline-size: 45vw !important;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const StyledFlyoutBody = styled(EuiFlyoutBody)`
  height: calc(100% - 145px);
`;

interface PropertyAttributesEditFlyoutProps {
  property: any;
  columnId: string;
  onClose: () => void;
  attributeLocks: AttributeLocks[];
}

const skippedFields = ['locationId', 'pictures', 'ownerAttributes'];

const PropertyAttributesEditFlyout: FC<PropertyAttributesEditFlyoutProps> = ({
  property,
  columnId,
  onClose,
  attributeLocks = [],
}) => {
  const { euiTheme } = useEuiTheme();
  const { stream, propertyAttributeMetadata } = useStreamContext();
  const toast = useToast();
  const jobsApolloClient = useJobsApolloClient();
  const params = useDecodedParams();
  const {
    location: { search },
  } = window;
  const qs = queryString.parse(search);
  const { refetch } = usePropertiesDataGridContext();
  const tracker = useTracker();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const columnField = useMemo(
    () =>
      propertyAttributeMetadata.find(
        (field) => !skippedFields.includes(field.name) && field.name === columnId,
      ),
    [propertyAttributeMetadata],
  );

  const propertyLock = useMemo(
    () => attributeLocks?.find((lock) => lock?.propertyArchipelagoID === property?.archipelagoId),
    [attributeLocks],
  );

  const customFields = useMemo(
    () => propertyAttributeMetadata.filter((field) => field.isCustom).map((field) => field.name),
    [propertyAttributeMetadata],
  );

  const otherEditableFields = useMemo(
    () =>
      propertyAttributeMetadata
        .filter((field) => field.name !== columnId && !skippedFields.includes(field.name))
        .map((field) => {
          const value = property[field.name] ?? property?.ownerAttributes?.[field.name];
          return {
            ...field,
            value: value,
          };
        }),
    [propertyAttributeMetadata, property],
  );

  const editableFields = useMemo(
    () =>
      columnField
        ? [
            {
              ...columnField,
              value: property[columnId] ?? property?.ownerAttributes?.[columnId],
            },
            ...otherEditableFields,
          ]
        : otherEditableFields,
    [columnField, property, otherEditableFields],
  );

  const formMethods = useForm({
    defaultValues: editableFields.reduce((acc, field) => {
      acc[field.name] = field.value;
      return acc;
    }, {}),
    mode: 'all',
  });

  // Focus the first input when the flyout opens
  useEffect(() => {
    setTimeout(() => {
      const firstInput = document.querySelector('#edit-property-form input');
      if (firstInput) {
        (firstInput as HTMLInputElement).focus();
      }
    }, 700);
  }, []);

  useEffect(() => {
    const trackerProps = {
      archipelago_id: property?.archipelagoId,
      attribute: columnId,
      event_surface: TrackGroupSovManager.event_surface,
      location_id: property?.locationId,
      location_name: property?.locationName,
      organization_name: stream?.orgName,
      stream_name: stream?.name,
      stream_slug: stream?.slug,
    };
    tracker.track(`${TrackGroupSovManager.prefix}: Open edit attributes flyout`, trackerProps);

    return () => {
      tracker.track(`${TrackGroupSovManager.prefix}: Close edit attributes flyout`, trackerProps);
    };
  }, [property]);

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    setValue,
  } = formMethods;

  const valuationCurrency = useWatch({
    control: formMethods.control,
    name: 'valuationCurrency' as never,
  }) as string;

  const onUpdateError = (err: ApolloError) =>
    toast({
      title: formatEditError(getErrorMessage(err), formMethods.getValues()),
      type: 'danger',
    });

  const onUpdateCompleted = () => {
    toast({ title: 'Property successfully updated' });
    refetch();
    onClose();
  };

  const onTimeout = () => {
    toast({
      title:
        'Edit property job is taking longer than expected. Please check the page later for your changes.',
      type: 'danger',
    });
  };

  const { startGetJobPoll, isPolling } = useJobPoller(onUpdateCompleted, {
    interval: 2000,
    onError: onUpdateError,
    onTimeout,
    retryCount: 30,
    targetStatus: [JobStatus.Complete],
  });

  const [applyEditPropertyJob, { loading: submitLoading }] = useApplyEditPropertyJobMutation({
    client: jobsApolloClient,
    onError: onUpdateError,
  });

  const onSubmit = async (formData: any) => {
    let changedFields = Object.entries(formData).reduce((acc, [key, value]) => {
      const originalValue = property[key] ?? property?.ownerAttributes?.[key];
      if (value !== originalValue) {
        acc[key] = value;
      }
      return acc;
    }, {});

    if (ADDRESS_AND_GEO_INPUT_KEYS.find((item) => changedFields[item])) {
      for (const key of ADDRESS_AND_GEO_INPUT_KEYS) {
        changedFields = {
          ...changedFields,
          [key]: formData[key],
        };
      }
    }

    const changedFieldsKeys = Object.keys(changedFields);

    let attributeNames = changedFieldsKeys.filter(
      (key) => !propertyAttributeMetadata.find(({ name }) => name === key)?.derived,
    );

    if (attributeNames.length === 0) {
      return;
    }

    const hasCustomAttributes = customFields.some((field) => attributeNames.includes(field));
    const newValues = getFinalNewPropertyValues(changedFields, customFields);

    if (hasCustomAttributes) {
      attributeNames = attributeNames.filter((field) => !customFields.includes(field as ColumnKey));
      attributeNames.push(CUSTOM_ATTRIBUTES_KEY);
    }

    try {
      const { data: newEditJob } = await applyEditPropertyJob({
        variables: {
          input: {
            attributeNames: attributeNames,
            customerProvidedGeocode: false,
            orgName: property.orgName,
            propertyArchipelagoID: property.archipelagoId,
            propertyData: newValues,
            streamSlug: stream.slug,
          },
        },
      });

      startGetJobPoll(newEditJob.applyEditPropertyJob.id);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const renderFieldInput = (field: any) => {
    if (field === undefined) {
      return;
    }

    const isUneditable = UNEDITABLE_KEYS.has(field?.name);
    const readOnly =
      isUneditable || field.derived || !!propertyLock?.attributeNames?.includes(field.name);

    const { dataType } = field;
    let inputField;
    if (field.name === STREET_ADDRESS_INPUT_KEY) {
      const name = STREET_ADDRESS_INPUT_KEY;
      inputField = (
        <FormGoogleAutocompleteInput
          // FIX ME
          // @ts-ignore
          onChange={(value) => setValue(name, value)}
          name={STREET_ADDRESS_INPUT_KEY}
          formRowProps={{ fullWidth: true } as EuiFormRowProps}
          onSelect={(values) => {
            Object.keys(values).forEach((item) => {
              // FIX ME
              // @ts-ignore
              setValue(item, values[item]);
            });
          }}
          inputProps={{
            fullWidth: true,
            predictionTypes: ['address'],
          }}
        />
      );
    } else {
      switch (dataType) {
        case 'currency':
        case 'currencyLocal':
          inputField = (
            <EditFormField
              name={field.name}
              currency={valuationCurrency}
              fullWidth={true}
              type={dataType}
              readOnly={readOnly}
            />
          );
          break;
        case 'hazardIcon':
          inputField = getAttributeRow(
            {
              ...field,
              dataType: AttributeDataType.Boolean,
              displayName: null,
              name: field.name,
              value: field.value === '1' || field.value === true,
            },
            propertyLock,
            property,
            {
              labelAction: null,
              readOnly: readOnly,
            },
          );
          break;
        default:
          inputField = getAttributeRow(
            {
              ...field,
              displayName: null,
              name: field.name,
              value:
                dataType === AttributeDataType.Boolean
                  ? field.value === '1' || field.value === true
                  : field.value,
            },
            propertyLock,
            property,
            {
              labelAction: null,
              readOnly: readOnly,
            },
          );
          break;
      }
    }

    let tooltipContent = 'Another user is already editing attribute';

    if (isUneditable) {
      tooltipContent = 'This attribute cannot be edited';
    } else if (field.derived) {
      tooltipContent = 'This attribute is derived and cannot be edited';
    }

    return (
      <>
        <EuiFlexGroup alignItems="center" gutterSize="s">
          <EuiText>{field.displayName}</EuiText>
          {readOnly && (
            <EuiToolTip title="Cannot Edit Attribute" content={tooltipContent}>
              <Icon color="primary" name="info" />
            </EuiToolTip>
          )}
        </EuiFlexGroup>
        <EuiSpacer size="xs" />
        {inputField}
      </>
    );
  };

  const closeFlyout = () => {
    if (isDirty) {
      setIsCancelModalOpen(true);
      return;
    }
    onClose();
  };

  return (
    <>
      <StyledFlyout onClose={closeFlyout} aria-labelledby="propertyFlyoutTitle">
        <FormProvider {...formMethods}>
          <EuiFlyoutHeader hasBorder>
            <EuiTitle size="s">
              <EuiText>
                Editing&nbsp;
                <EuiTextColor color={euiTheme.colors.primary}>
                  <Link
                    style={{ fontWeight: 600 }}
                    to={{
                      pathname: propertiesModalPathname(stream, params, property.id),
                      search: queryString.stringify({ ...qs }),
                    }}
                  >
                    {property?.locationName || property?.propertyName || property?.archipelagoId}
                  </Link>
                </EuiTextColor>
                &nbsp;Attributes
              </EuiText>
            </EuiTitle>
          </EuiFlyoutHeader>
          <StyledForm id="edit-property-form" onSubmit={handleSubmit(onSubmit)}>
            <StyledFlyoutBody>
              <EuiFlexGroup direction="column" gutterSize="m">
                {editableFields.map((field) => (
                  <div key={field.name}>{renderFieldInput(field)}</div>
                ))}
              </EuiFlexGroup>
            </StyledFlyoutBody>
            <EuiFlyoutFooter>
              <EuiFlexGroup justifyContent="flexEnd">
                <EuiFlexGroup justifyContent="flexEnd">
                  <EuiFlexItem grow={false}>
                    <ButtonEmpty
                      loading={submitLoading}
                      disabled={submitLoading || isPolling}
                      onClick={closeFlyout}
                      label="Cancel"
                    />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <Button
                      loading={submitLoading || isPolling}
                      disabled={submitLoading || isPolling || !isValid || !isDirty}
                      fill
                      type="submit"
                      label="Save"
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexGroup>
            </EuiFlyoutFooter>
          </StyledForm>
        </FormProvider>
      </StyledFlyout>
      {isCancelModalOpen && (
        <CancelModal
          isEditMode
          onCancel={() => setIsCancelModalOpen(false)}
          onConfirm={() => {
            onClose();
          }}
        />
      )}
    </>
  );
};

export default PropertyAttributesEditFlyout;

import { useContext, useMemo, useState } from 'react';
import * as React from 'react';
import { useMutation } from '@apollo/client';
import PropertyItem from '@onarchipelago/dice/EnrichmentProjects/PropertyItem';
import SelectPropertiesFlyout from '@onarchipelago/dice/EnrichmentProjects/SelectPropertiesFlyout';
import { EPropertySelectType } from '@onarchipelago/dice/EnrichmentProjects/types';
import {
  Button,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutFooter,
  EuiFocusTrap,
  useToast,
} from 'ui';
import { PropertyAttributeMetadata } from '@app/components/QueryBuilderWrapper/utils';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { ModalContext } from '@app/contexts/ModalContext';
import { IGraphQLProperty } from '@app/queries/properties/types';
// FIX ME
// @ts-ignore
import DELETE_OR_RESTORE_PROPERTIES from '../../queries/properties/deleteOrRestoreProperties.gql';
import DeletePropertyModal from './DeletePropertyModal/DeletePropertyModal';
import { MetadataMap } from './StreamProvider';

interface IProps {
  metadataMap: MetadataMap;
  orgID: string;
  orgName: string;
  filterable: Array<PropertyAttributeMetadata>;
  streamSlug: string;
}

const PermanentlyDeletePropertyFlyout: React.FC<IProps> = ({
  metadataMap,
  orgID,
  orgName,
  filterable,
  streamSlug,
}) => {
  const toast = useToast();
  const { closeFlyout } = useContext(FlyoutContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const [selectedProperties, setSelectedProperties] = useState<IGraphQLProperty[]>([]);
  const handleUpdateSelectedProperties = (updatedProperties: IGraphQLProperty[]) => {
    setSelectedProperties([...updatedProperties]);
  };

  const [deleteOrRestoreProperties, { loading }] = useMutation(DELETE_OR_RESTORE_PROPERTIES, {
    onCompleted: () => {
      toast({ title: 'Successfully deleted properties.', type: 'success' });
      closeModal();
      closeFlyout();
    },
    onError: () => {
      toast({ title: 'Could not delete properties', type: 'danger' });
    },
  });

  const handleStart = async (properties: IGraphQLProperty[]) => {
    await deleteOrRestoreProperties({
      variables: {
        input: {
          delete: true,
          propertyIds: properties.map((property) => property.archipelagoId),
        },
      },
    });
  };

  const propertyItem = (renderedRow: any) => (
    <PropertyItem
      multiple
      item={renderedRow.item}
      selectedProperties={selectedProperties}
      updateSelectedProperties={handleUpdateSelectedProperties}
    />
  );

  const formatTitle = (multiple) => `Delete ${multiple ? 'these properties' : 'this property'}`;
  return (
    <EuiFocusTrap>
      <EuiFlyout
        data-testid="m-m-dispose-flyout"
        ownFocus
        onClose={closeFlyout}
        aria-labelledby="flyoutTitle"
        size="s"
        style={{ maxWidth: '0px', minWidth: '600px' }}
      >
        <SelectPropertiesFlyout
          multiple
          title="Delete properties"
          orgName={orgName}
          selectedItems={selectedProperties}
          rowComponent={propertyItem}
          filterable={filterable}
          updateSelectedProperties={handleUpdateSelectedProperties}
          propertySelectType={EPropertySelectType.AllProperties}
        />
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="flexEnd">
            <EuiFlexItem grow={false}>
              <Button
                data-testid="m-m-permanently-delete-submit"
                onClick={() => {
                  showModal(
                    <DeletePropertyModal
                      metadataMap={metadataMap}
                      closeModal={closeModal}
                      selectedProperties={selectedProperties}
                      handleStart={handleStart}
                      loading={loading}
                      formatTitle={formatTitle}
                      confirmButtonLabel="Delete"
                      footNote="This will remove the property from the platform completely. It will not appear in the change analysis."
                    />,
                  );
                }}
                fill
                disabled={selectedProperties.length === 0}
                iconSide="right"
                loading={loading}
                label={loading ? 'Deleting' : 'Delete'}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </EuiFocusTrap>
  );
};

export default PermanentlyDeletePropertyFlyout;

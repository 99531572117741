"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateOptions = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Icon_1 = require("../../../neutrons/icon/Icon");
var updateOptions = function (_a) {
    var options = _a.options, setFinalOptions = _a.setFinalOptions;
    if (options.some(function (option) { return !!option.iconName; })) {
        var finalOptions = options.map(function (option) {
            if (option.iconName) {
                return __assign({ iconType: function () { return (0, jsx_runtime_1.jsx)(Icon_1.Icon, { name: option.iconName }); } }, option);
            }
            return option;
        });
        setFinalOptions(finalOptions);
    }
};
exports.updateOptions = updateOptions;

import { useContext, useState } from 'react';
import * as React from 'react';
import { useInterval } from 'react-use';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import moment from 'moment';
import { useToast } from 'ui';
import { useDecodedParams } from '@app/containers/App/Routes/utils';
import { integrationsApolloClient } from '@app/cx/Organizations/Tabs/IntegrationsTab/IntegrationsApolloClient';
import {
  ALL_PROPERTIES_OPTION,
  CUSTOM_SET_OF_PROPERTIES,
  E2VALUE,
  PROPERTIES_WITH_ERRORS_IN_PREVIOUS_RUN,
  RUN_STATUS_COMPLETED,
  RUN_STATUS_FAILED,
  RUN_STATUS_IN_PROGRESS,
  RUN_STATUS_INITIATED,
} from '@app/platform/Integrations/consts';
// FIX ME
// @ts-ignore
import GET_USAGE from '@app/queries/integrations/getUsage.gql';
// FIX ME
// @ts-ignore
import GET_INTEGRATION_SUMMARY from '@app/queries/integrations/integrationSummary.gql';
// FIX ME
// @ts-ignore
import START_INTEGRATION from '@app/queries/integrations/startIntegration.gql';
import {
  IIntegrationSummaryData,
  IIntegrationSummaryVariables,
  IStartIntegrationData,
  IStartIntegrationVariables,
  Run,
  ScopeType,
} from '@app/queries/integrations/types';
import { IGraphQLProperty } from '@app/queries/properties/generated_types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';

export interface IntegrationRunContext {
  // It's loading usage and history
  loading: boolean;
  runInProgress: boolean;
  /** the amount of credit the user has spend for this partner */
  usage: number;
  /** the credit limit the user can max spend */
  limit: number;
  /** the amount of credit the user has left to spend. equals {limit}-{usage}  */
  remainingLimit: number;
  /** Whether the API is enabled */
  enabled: boolean;
  lastRun?: Run;
  integrationSummary?: Array<Run>;
  showIntegrationRunError: boolean;
  showApiLimitError: boolean;
  showSkippingPropertiesWarning: boolean;
  startIntegration?: () => void;
  propertyAttributeMetadata: IGraphQLAttributeMetadata[];
  handleUpdateSelectedProperties?: (updatedProperties: IGraphQLProperty[]) => void;
  selectedProperties: IGraphQLProperty[];
  totalPropertiesInStream: number;
  selectedScope: string;
  setSelectedScope?: (selectedScope: string) => void;
  amountOfPropertiesSelected?: () => number;
}

export const IntegrationRunContext = React.createContext<IntegrationRunContext>({
  enabled: false,
  lastRun: undefined,
  limit: 0,
  loading: true,
  propertyAttributeMetadata: [],
  remainingLimit: 0,
  runInProgress: false,
  selectedProperties: [],
  selectedScope: '',
  showApiLimitError: false,
  showIntegrationRunError: false,
  showSkippingPropertiesWarning: false,
  totalPropertiesInStream: 0,
  usage: 0,
});

export const useIntegrationRunProvider = () => {
  const context = useContext(IntegrationRunContext);
  if (context === undefined) {
    throw new Error('useIntegrationRun must be used within a IntegrationRunProvider');
  }
  return context;
};

interface UsageInput {
  organizationID: string;
  partnerName: string;
}

interface IUsageVariables {
  input: UsageInput;
}

interface IUsageData {
  usage: {
    limit: number;
    usage: number;
    enabled: boolean;
  };
}

export const GET_STREAM = gql`
  query GetStream($slug: ID!, $snapshot: String) {
    streamV2(slug: $slug) {
      stream {
        id
        organizationId
        propertiesCount
      }
    }
    propertyAttributeMetadataV2(input: { streamSlug: $slug, snapshot: $snapshot }) {
      name
      displayName
      displayWidth
      parent
      filterable
      groupable
      dataType
      source
      maxThreshold
      decimals
      enumMetadata {
        valueId
        value
        valueDisplay
        position
        clusterValue
        hexColorCode
        externalMappings {
          externalCoding
          valueId
          value
        }
        softMappings {
          modelSchema
          secondaryModifier
          value
        }
      }
      hiddenInGrid
    }
  }
`;

export const IntegrationRunProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const toast = useToast();

  const [streamInfo, setStreamInfo] = useState<{
    orgId: string;
    streamId: string;
    totalPropertiesInStream: number;
  }>(undefined);

  const [propertyAttributeMetadata, setPropertyAttributeMetadata] = useState<
    IGraphQLAttributeMetadata[]
  >([]);

  const [streamUsage, setStreamUsage] = useState<{
    limit: number;
    usage: number;
    enabled: boolean;
  }>(undefined);

  const [integrationSummary, setIntegrationSummary] = useState<Array<Run>>([]);

  const [selectedScope, setSelectedScope] = useState(ALL_PROPERTIES_OPTION);
  const { streamSlug } = useDecodedParams<{
    streamSlug: string;
  }>();

  const [selectedProperties, setSelectedProperties] = useState<IGraphQLProperty[]>([]);
  const handleUpdateSelectedProperties = (updatedProperties: IGraphQLProperty[]) => {
    setSelectedProperties([...updatedProperties]);
  };

  const [getIntegrationSummary, { loading: l2 }] = useLazyQuery<
    IIntegrationSummaryData,
    IIntegrationSummaryVariables
  >(GET_INTEGRATION_SUMMARY, {
    client: integrationsApolloClient,
    onCompleted: (data) => {
      if (!data.integrationSummary || data.integrationSummary.length == 0) {
        return;
      }

      let runs = [...data.integrationSummary];
      runs = runs.sort((a: Run, b: Run) => moment(b.runDate).unix() - moment(a.runDate).unix());

      setIntegrationSummary(runs);
    },
    onError: (error) => {
      toast({
        title: `Could not load integration summary: ${getErrorMessage(error)}`,
        type: 'danger',
      });
    },
  });

  const [getUsage, { loading: l3 }] = useLazyQuery<IUsageData, IUsageVariables>(GET_USAGE, {
    client: integrationsApolloClient,
    onCompleted: (data) => {
      if (data?.usage) {
        setStreamUsage({
          enabled: data?.usage?.enabled || false,
          limit: data?.usage?.limit || 0,
          usage: data?.usage?.usage || 0,
        });
      }
    },
    onError: (error) => {
      toast({
        title: `Could not load integration usage: ${getErrorMessage(error)}`,
        type: 'danger',
      });
    },
  });

  const { loading: l } = useQuery<{
    streamV2: {
      stream: {
        id: string;
        organizationId: string;
        propertiesCount: number;
      };
    };
    propertyAttributeMetadataV2: Array<IGraphQLAttributeMetadata>;
  }>(GET_STREAM, {
    onCompleted: (data) => {
      if (data?.streamV2?.stream?.organizationId) {
        const orgId = data.streamV2.stream.organizationId;
        const streamId = data.streamV2.stream?.id;

        setStreamInfo({
          orgId: orgId,
          streamId: streamId,
          totalPropertiesInStream: data.streamV2.stream?.propertiesCount || 0,
        });

        getUsage({
          variables: {
            input: {
              organizationID: orgId,
              partnerName: E2VALUE,
            },
          },
        });

        getIntegrationSummary({
          variables: {
            input: {
              organizationID: orgId,
              partnerName: E2VALUE,
              streamID: streamId,
            },
          },
        });
      }
      if (data?.propertyAttributeMetadataV2) {
        setPropertyAttributeMetadata(data?.propertyAttributeMetadataV2);
      }
    },
    onError: (error) => toast({ title: getErrorMessage(error), type: 'danger' }),
    variables: {
      slug: streamSlug,
    },
  });

  const amountOfPropertiesSelected = (): number => {
    const lastRun = getLastRun();

    if (lastRun?.status === RUN_STATUS_INITIATED || lastRun?.status === RUN_STATUS_IN_PROGRESS) {
      return lastRun.propertyCount;
    }
    if (selectedScope === ALL_PROPERTIES_OPTION) {
      return streamInfo?.totalPropertiesInStream || 0;
    }
    if (
      selectedScope === PROPERTIES_WITH_ERRORS_IN_PREVIOUS_RUN &&
      lastRun?.status === RUN_STATUS_FAILED
    ) {
      return lastRun?.erroredProperties;
    }
    // if (selectedScope === CUSTOM_SET_OF_PROPERTIES) { // All selected! }
    return selectedProperties.length;
  };

  const [startIntegration, { loading: l4 }] = useMutation<
    IStartIntegrationData,
    IStartIntegrationVariables
  >(START_INTEGRATION, {
    client: integrationsApolloClient,
    onCompleted: () => {
      getIntegrationSummary({
        variables: {
          input: {
            organizationID: streamInfo?.orgId,
            partnerName: E2VALUE,
            streamID: streamInfo?.streamId,
          },
        },
      });
    },
    onError: (err) => {
      toast({ title: getErrorMessage(err), type: 'danger' });
      getIntegrationSummary({
        variables: {
          input: {
            organizationID: streamInfo?.orgId,
            partnerName: E2VALUE,
            streamID: streamInfo?.streamId,
          },
        },
      });
    },
  });

  const startIntegrationHandler = () => {
    const properties = [];
    let scope = ScopeType.AllProperties;

    switch (selectedScope) {
      case ALL_PROPERTIES_OPTION:
        scope = ScopeType.AllProperties;
        break;
      case CUSTOM_SET_OF_PROPERTIES:
        scope = ScopeType.SelectedProperties;
        for (let i = 0; i < selectedProperties.length; i++) {
          properties.push(selectedProperties[i].archipelagoId);
        }
        break;
      case PROPERTIES_WITH_ERRORS_IN_PREVIOUS_RUN:
        scope = ScopeType.ErroredProperties;
        break;
    }

    startIntegration({
      variables: {
        input: {
          organizationID: streamInfo?.orgId,
          partnerName: E2VALUE,
          properties,
          scope,
          streamID: streamInfo?.streamId,
        },
      },
    });
  };

  const getLastRun = (): Run | undefined =>
    integrationSummary.length > 0 ? integrationSummary[0] : undefined;

  // Poll the integration summary API every 10 seconds as long as a run is still initiated or in progress.
  useInterval(async () => {
    const lastRun = getLastRun();

    if (lastRun?.status === RUN_STATUS_INITIATED || lastRun?.status === RUN_STATUS_IN_PROGRESS) {
      getIntegrationSummary({
        variables: {
          input: {
            organizationID: streamInfo?.orgId,
            partnerName: E2VALUE,
            streamID: streamInfo?.streamId,
          },
        },
      });
    }
  }, 10000);

  return (
    <IntegrationRunContext.Provider
      value={{
        amountOfPropertiesSelected,
        enabled: streamUsage?.enabled || false,
        handleUpdateSelectedProperties,
        integrationSummary,
        lastRun: getLastRun(),
        limit: streamUsage?.limit || 0,
        loading: l || l2 || l3 || l4,
        propertyAttributeMetadata: propertyAttributeMetadata,
        remainingLimit: (streamUsage?.limit || 0) - (streamUsage?.usage || 0),
        runInProgress:
          getLastRun()?.status === RUN_STATUS_INITIATED ||
          getLastRun()?.status === RUN_STATUS_IN_PROGRESS,
        selectedProperties,
        selectedScope: selectedScope,
        setSelectedScope: (selectedScope: string) => {
          setSelectedScope(selectedScope);
        },
        showApiLimitError:
          streamUsage?.limit - streamUsage?.usage - amountOfPropertiesSelected() < 0,
        showIntegrationRunError: getLastRun()?.status === RUN_STATUS_FAILED,
        showSkippingPropertiesWarning:
          getLastRun()?.status !== RUN_STATUS_COMPLETED &&
          (getLastRun()?.skippedProperties || 0) > 0,
        startIntegration: startIntegrationHandler,
        totalPropertiesInStream: streamInfo?.totalPropertiesInStream || 0,
        usage: streamUsage?.usage || 0,
      }}
    >
      {children}
    </IntegrationRunContext.Provider>
  );
};

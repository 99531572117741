import { useEffect, useState } from 'react';
import * as React from 'react';
import { useQuery } from '@apollo/client';
import { EuiFlexGroup, useToast } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import AutoEnableButton from '@app/platform/AttributeMetadataEditor/AutoEnable/AutoEnableButton';
// FIX ME
// @ts-ignore
import GET_ORGANIZATION_ATTRIBUTE_METADATA from '@app/queries/propertyMetadata/getOrganizationAttributeMetadata.gql';
import {
  GetOrgAttributeMetadataVariables,
  OrgAttributeMetadataResult,
} from '@app/queries/propertyMetadata/types';
import { useAttributeMetadataEditorContext } from './context/attributeMetadataEditorContext';
import EditAttributeModal from './EditAttributeModal/EditAttributeModal';
import MetadataTable from './MetadataTable/MetadataTable';
import UploadDownload from './UploadDownload/UploadDownload';
import { PropertyMetadata, SelectOption } from './types';
import { getState } from './utils';

const AttributeMetadataEditor: React.FC = () => {
  const { organizationId, orgName, selectedAttribute, streamId, stream } =
    useAttributeMetadataEditorContext();
  const toast = useToast();
  const [propertyMetadata, setPropertyMetadata] = useState<Array<PropertyMetadata>>();
  const [parentOptions, setParentOptions] = useState<Array<SelectOption>>([]);

  const { data, loading, error } = useQuery<
    OrgAttributeMetadataResult,
    GetOrgAttributeMetadataVariables
  >(GET_ORGANIZATION_ATTRIBUTE_METADATA, {
    variables: {
      organizationId,
      streamId,
    },
  });

  useEffect(() => {
    if (error) {
      toast({ title: 'Could not get property metadata', type: 'danger' });
    } else if (data) {
      const finalParentOptions: Array<SelectOption> = [];
      data.organizationAttributeMetadata.forEach((datum) => {
        // only attributes that don't have a parent can be used as parent
        if (!datum?.metadata?.parent) {
          finalParentOptions.push({
            label: datum.metadata.displayName,
            value: datum.metadata.name,
          });
        }
      });
      setParentOptions(finalParentOptions);
      setPropertyMetadata(getState(data.organizationAttributeMetadata));
    }
  }, [data, error]);

  let organizationName = orgName;
  if (stream && organizationName === undefined) {
    organizationName = stream.orgName;
  }

  if (loading) {
    return <LoadingSpinnerV2 />;
  }

  if (propertyMetadata) {
    return (
      <>
        <EuiFlexGroup direction="row">
          <UploadDownload propertyMetadata={propertyMetadata} />
          <AutoEnableButton orgName={organizationName} streamSlug={stream?.slug} />
        </EuiFlexGroup>
        <MetadataTable
          propertyMetadata={propertyMetadata}
          // FIX ME
          // @ts-ignore
          stream={stream}
        />
        {selectedAttribute && <EditAttributeModal parentOptions={parentOptions} />}
      </>
    );
  }

  return <LoadingSpinnerV2 />;
};

export default AttributeMetadataEditor;

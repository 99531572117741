import { FC, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem } from 'ui';
import { ChartValues } from '@app/queries/streams/types';
import { TooltipContainer } from './Charts.emotion';
import { categoriesHeight } from './const';
import { LegendItem } from './types';
import {
  colorByName,
  createFillerBar,
  findTivAttributePct,
  findTivAttributeValue,
  formatPercent,
  formatTIV,
} from './utils';

interface BarProps {
  testid?: string;
  values: ChartValues;
  title?: string;
  growFull?: boolean;
  keyItems: LegendItem[];
  includeSecondBar?: boolean;
}

const HorizontalBar: FC<BarProps> = ({
  values,
  title,
  growFull = false,
  keyItems,
  includeSecondBar = true,
  testid: dataTestId,
}) => {
  const height = keyItems[0].label === 'Full' ? categoriesHeight[title || 'Other Category'] : 8;
  const [activeBar, setActiveBar] = useState('');
  const opacity = (name: string) =>
    activeBar === '' || (activeBar !== '' && activeBar === name) ? 1 : 0.25;

  const orderedFields = ['Full', 'Partial', 'No', 'Unknown', 'Blank'];
  const sortByQuality = (a: any, b: any) =>
    orderedFields.indexOf(a.label) - orderedFields.indexOf(b.label);
  const chartData = values.groupedBy ? values.groupedBy : [values];
  const barSegments = [...chartData].sort(sortByQuality);
  const fillData = createFillerBar(values);
  if (!values.groupedBy) {
    barSegments.push(fillData);
  }

  return (
    <EuiFlexGroup
      data-testid={dataTestId}
      gutterSize="none"
      style={{ alignItems: 'center', marginBottom: 6, marginTop: -2 }}
    >
      {barSegments.map((item, index) => {
        const last = index + 1 === barSegments.length;
        const flexGrow =
          !!last && growFull
            ? 1
            : formatPercent(findTivAttributePct(item.attributes, includeSecondBar));

        return (
          <EuiFlexItem
            data-testid={`${dataTestId}-${item.label}`}
            key={item.label}
            style={{
              flexGrow,
            }}
          >
            <TooltipContainer
              position="bottom"
              content={
                <p>
                  {findTivAttributePct(item.attributes, includeSecondBar) && (
                    <>
                      {`${formatPercent(
                        findTivAttributePct(item.attributes, includeSecondBar),
                      )}% of TIV`}
                      <br />
                    </>
                  )}
                  {findTivAttributeValue(item.attributes) && (
                    <>
                      {`${formatTIV(findTivAttributeValue(item.attributes))} TIV`}
                      <br />
                    </>
                  )}
                </p>
              }
              label={item.label}
            >
              <div
                style={{
                  backgroundColor: 'transparent',
                  height,
                }}
                onMouseEnter={() =>
                  setActiveBar(item.label && item.label !== 'None' ? item.label : '')
                }
                onMouseLeave={() => setActiveBar('')}
              >
                <div
                  style={{
                    backgroundColor: colorByName(
                      includeSecondBar ? 'Prev. bound' : item.label || '',
                      keyItems,
                    ),
                    height: item.label === 'None' ? 0 : height,
                    marginRight: 1,
                    opacity: opacity(item.label || ''),
                    position: 'relative',
                    top: item.label === 'None' ? (height - 1) / 2 : 0,
                    transition: 'all 0.6s ease-in-out' /* should match Elastic UI */,
                  }}
                />
              </div>
            </TooltipContainer>
          </EuiFlexItem>
        );
      })}
    </EuiFlexGroup>
  );
};

export default HorizontalBar;

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiStepsHorizontal,
  EuiStepStatus,
  EuiText,
} from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { STEP } from './constants';
import { ManageExportsContextProvider, useManageExportsContext } from './ManageExportsContext';
import { FlyoutBody } from './ManageExportsFlyout.emotion';
import { ManageExportsFlyoutButtons } from './ManageExportsFlyoutButtons';
import { ManageExportsOrder } from './ManageExportsOrder';
import { ManageExportsSelect } from './ManageExportsSelect';

const ManageExportsFlyoutWithoutContext = () => {
  const { closeFlyout } = useFlyout();
  const { step, setStep, transitionToOrderStep } = useManageExportsContext();

  const steps = [
    {
      'aria-label': 'Select step',
      onClick: () => setStep(STEP.SELECT),
      status: (step === STEP.SELECT ? 'current' : 'complete') as EuiStepStatus,
      step: 1,
      title: 'Select',
    },
    {
      'aria-label': 'Order step',
      onClick: transitionToOrderStep,
      status: (step === STEP.ORDER ? 'current' : 'incomplete') as EuiStepStatus,
      step: 2,
      title: 'Order',
    },
  ];

  return (
    <EuiFlyout aria-labelledby="manage exports flyout" size="s" onClose={closeFlyout}>
      <EuiFlyoutHeader>
        <EuiText>
          <h2>{step === STEP.SELECT ? 'Select attributes' : 'Order attributes'}</h2>
        </EuiText>
      </EuiFlyoutHeader>
      <FlyoutBody>
        <EuiFlexGroup direction="column" style={{ height: '100%' }}>
          <EuiFlexItem grow={0}>
            <EuiStepsHorizontal steps={steps} />
          </EuiFlexItem>
          <EuiFlexItem>
            {step === STEP.SELECT ? <ManageExportsSelect /> : <ManageExportsOrder />}
          </EuiFlexItem>
        </EuiFlexGroup>
      </FlyoutBody>
      <EuiFlyoutFooter>
        <ManageExportsFlyoutButtons />
      </EuiFlyoutFooter>
    </EuiFlyout>
  );
};

interface IManageExportsFlyout {
  orgId: string;
  streamId?: string;
  streamSlug?: string;
}

export const ManageExportsFlyout = ({ orgId, streamId, streamSlug }: IManageExportsFlyout) => {
  const { account } = useAuth();

  if (!account) {
    return null;
  }

  return (
    <ManageExportsContextProvider orgId={orgId} streamId={streamId} streamSlug={streamSlug}>
      <ManageExportsFlyoutWithoutContext />
    </ManageExportsContextProvider>
  );
};

import { createContext, useContext, useState } from 'react';
import * as React from 'react';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import useLocalStorage from '@app/hooks/useLocalStorage';

export interface PaginationProps {
  pageIndex: number;
  pageSize: number;
}
interface PaginationContext {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  setPagination: React.Dispatch<
    React.SetStateAction<{
      pageIndex: number;
      pageSize: any;
    }>
  >;
}

export const PaginationContext = createContext({} as PaginationContext);

export const PaginationProvider = ({ stream, children }) => {
  const { account } = useAuth();
  const [pageSize, setPageSize] = useLocalStorage(account, `pageSize|${stream?.id}.v1`, 20);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize,
  });

  return (
    <PaginationContext.Provider value={{ pageSize, pagination, setPageSize, setPagination }}>
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = () => useContext(PaginationContext);

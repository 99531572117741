import * as React from 'react';
import moment from 'moment';
import { useEuiTheme } from 'ui';
import { User } from './types';
import { Table } from './UserViewsExpandedRow.emotion';

interface Props {
  user: User;
}

const UserViewsExpandedRow: React.FC<Props> = ({ user }) => {
  const { country, lastLogin, organizationName } = user;

  const columns = [
    { field: 'organizationName', name: 'Organization' },
    { field: 'country', name: 'Country' },
    { field: 'lastLogin', name: 'Last Login' },
  ];

  const { euiTheme } = useEuiTheme();
  const { colors } = euiTheme;

  return (
    <Table
      columns={columns}
      color={colors.lightestShade}
      items={[{ country, lastLogin: moment(lastLogin).format('MMM DD, YYYY'), organizationName }]}
      tableLayout="auto"
    />
  );
};

export default UserViewsExpandedRow;

import { ReactourStep } from 'reactour';
import { css } from '@emotion/css';
import { Global } from '@emotion/react';
import TourPanel from './TourPanel';
import { IStep } from './types';

type GetSteps = (steps?: Array<IStep>, goToNext?: (next: number) => void) => Array<ReactourStep>;

export const getSteps: GetSteps = (steps, goToNext) =>
  (steps || []).reduce((arr, step, index) => {
    const parsedStep: ReactourStep =
      {
        content: () => (
          <>
            <Global
              styles={css`
                ${(step.selectors || [])
                  .map(
                    (selector) => `
              ${selector} {
                position: relative;
                z-index: 3;
              }
            `,
                  )
                  .join('\n')}
              `}
            />
            <TourPanel title={step.title} content={step.content} author={step.author} />
          </>
        ),
        position: step.position,
        selector: step.attach,
      } || ([] as ReactourStep[]);

    const interstitialStepAction = async () => {
      await step.action();
      goToNext?.(index * 2);
    };

    const interstitialStep: ReactourStep = {
      action: () => {
        interstitialStepAction();
      },
      content: () => (
        <TourPanel step={index} title={step.title} content={step.content} author={step.author} />
      ),
      selector: step.attach,
      style: {
        opacity: 0.0,
      },
    };

    return arr.concat(interstitialStep, parsedStep);
  }, [] as Array<ReactourStep>);

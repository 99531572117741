import { useMutation } from '@apollo/client';
import { EuiConfirmModal, useToast } from 'ui';
// FIX ME
// @ts-ignore
import DELETE_SNAPSHOT from '@app/queries/streams/snapshots/deleteSnapshot.gql';
// FIX ME
// @ts-ignore
import GET_STREAM_SNAPSHOT_CONFIG from '@app/queries/streams/snapshots/getSnapshotConfig.gql';

interface SnapshotDeleteModalProps {
  snapshotId: string;
  streamSlug: string;
  onClose: () => void;
}

const SnapshotDeleteModal = ({ snapshotId, streamSlug, onClose }: SnapshotDeleteModalProps) => {
  const [deleteSnapshot, { loading }] = useMutation(DELETE_SNAPSHOT, {
    onCompleted: () => {
      onClose();
      toast({ title: 'Snapshot deleted.', type: 'success' });
    },
    onError: () => toast({ title: 'Could not delete snapshot.', type: 'danger' }),
    refetchQueries: [
      {
        query: GET_STREAM_SNAPSHOT_CONFIG,
        variables: { streamSlug: streamSlug },
      },
    ],
    variables: {
      input: {
        snapshotId,
        updateReportingViews: true,
      },
    },
  });
  const toast = useToast();

  return (
    <EuiConfirmModal
      isLoading={loading}
      title="Delete snapshot?"
      onCancel={onClose}
      onConfirm={() => deleteSnapshot()}
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      buttonColor="danger"
      defaultFocusedButton="confirm"
      style={{ display: 'flex', zIndex: 10000000 }}
    >
      <p>This snapshot will be deleted for all streams (incl. My Properties).</p>
    </EuiConfirmModal>
  );
};

export default SnapshotDeleteModal;

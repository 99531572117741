import {
  Checkbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight,
  EuiSelectableOption,
  EuiText,
} from 'ui';
import { useManageExportsContext } from './ManageExportsContext';
import { Selectable } from './ManageExportsFlyout.emotion';
import { SelectableOptionDataType } from './types';

export const ManageExportsSelect = () => {
  const { selectableAttributeOptions, setSelectableAttributeOptions, isLoading } =
    useManageExportsContext();

  const renderSelectableOptions = (
    option: EuiSelectableOption<SelectableOptionDataType>,
    searchValue: string,
  ) => {
    const isGroupLabel = option.children?.length;
    const isChild = !!option.groupKey;

    if (isGroupLabel) {
      return (
        <EuiFlexGroup
          alignItems="center"
          justifyContent="spaceBetween"
          gutterSize="s"
          style={isChild ? { marginLeft: '20px' } : {}}
        >
          <EuiFlexItem>
            <EuiText>
              <h5>
                <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
              </h5>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <Checkbox id={option.key} checked={option.checked === 'on'} onChange={() => {}} />
          </EuiFlexItem>
        </EuiFlexGroup>
      );
    }
    return (
      <EuiFlexGroup
        alignItems="center"
        gutterSize="s"
        style={isChild ? { marginLeft: '20px' } : {}}
      >
        <EuiFlexItem grow={false}>
          <Checkbox id={option.key} checked={option.checked === 'on'} onChange={() => {}} />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  };

  const onChange = (
    options: Array<EuiSelectableOption<SelectableOptionDataType>>,
    event,
    changedOption: EuiSelectableOption<SelectableOptionDataType>,
  ) => {
    const isGroupLabel = changedOption.data?.children?.length;
    const groupKey = changedOption.data?.groupKey;
    if (isGroupLabel) {
      options.forEach((option) => {
        if (option.data?.groupKey === changedOption.key) {
          option.checked = changedOption.checked === 'on' ? 'on' : undefined;
        }
      });
    }
    if (groupKey) {
      const groupFullyChecked = options
        .filter((option) => option.data?.groupKey === changedOption.data?.groupKey)
        .every((option) => option.checked === 'on');

      options.find((option) => option.key === groupKey).checked = groupFullyChecked
        ? 'on'
        : undefined;
    }
    setSelectableAttributeOptions(options);
  };

  return (
    <EuiFlexGroup direction="column" gutterSize="s">
      <EuiFlexItem grow={0}>
        <EuiText>
          <h4>Attributes</h4>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem style={{ height: '100%' }}>
        <Selectable
          aria-label="Selectable example with custom list items"
          allowExclusions={false}
          searchProps={{
            placeholder: 'Search Attributes',
          }}
          isLoading={isLoading}
          options={selectableAttributeOptions}
          onChange={onChange}
          listProps={{
            isVirtualized: true,
            onFocusBadge: false,
            rowHeight: 40,
            showIcons: false,
          }}
          height="full"
          renderOption={renderSelectableOptions}
          searchable
        >
          {(list, search) => (
            <>
              {search}
              {list}
            </>
          )}
        </Selectable>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toggle = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var eui_1 = require("@elastic/eui");
var Toggle = function (_a) {
    var dataTestId = _a["data-testid"], checked = _a.checked, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.label, label = _c === void 0 ? null : _c, _d = _a.name, name = _d === void 0 ? null : _d, onChange = _a.onChange, _e = _a.size, size = _e === void 0 ? "l" : _e;
    return ((0, jsx_runtime_1.jsx)(eui_1.EuiSwitch, { "data-testid": dataTestId || "ui-atom-input-toggle", checked: checked, compressed: size === "s", disabled: disabled, label: label, name: name, onChange: onChange }));
};
exports.Toggle = Toggle;

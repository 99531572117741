import { Dispatch, SetStateAction, useContext } from 'react';
import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import { getMetadataByType } from '@onarchipelago/cx/Stream/utils';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { EJobType } from '@app/queries/organizations/types';
import { Lock } from '@app/queries/properties/PropertyQuery/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import EditDataFlyout from '../EditDataFlyout/EditDataFlyout';
import { PanelItem } from './types';

interface Props {
  inProgressJobs: Partial<Record<EJobType, Lock>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refetch: () => void;
}

export default ({ inProgressJobs, setOpen, refetch }: Props): PanelItem => {
  const { showFlyout } = useContext(FlyoutContext);
  const { stream, propertyAttributeMetadata } = useStreamContext();
  const filterable = getMetadataByType(propertyAttributeMetadata, 'filterable');

  const handleClick = () => {
    showFlyout(
      <EditDataFlyout
        filterable={filterable}
        orgName={stream?.orgName as string}
        stream={stream as IGraphQLStream}
        inProgressJob={inProgressJobs[EJobType.EditLosses]}
        jobType={EJobType.EditLosses}
        refetch={refetch}
      />,
    );

    setOpen(false);
  };

  return {
    'data-testid': 'edit-losses-button',
    iconName: 'pencil',
    label: inProgressJobs[EJobType.EditLosses] ? 'Continue editing' : 'Edit your loss data',
    onClick: handleClick,
  };
};

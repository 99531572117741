import { useContext } from 'react';
import * as React from 'react';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { Lock } from '@app/queries/properties/PropertyQuery/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import { Flyout } from '../EditDataFlyout/Flyout.emotion';
import AddJobPanel from './AddJobPanel';
import { AddJobType } from './types';

interface Props {
  orgName: string;
  inProgressJob?: Lock;
  stream: IGraphQLStream;
  jobType: AddJobType;
}
const AddDataFlyout: React.FC<Props> = ({ orgName, inProgressJob, stream, jobType }) => {
  const { closeFlyout } = useContext(FlyoutContext);

  return (
    <Flyout ownFocus onClose={closeFlyout} aria-labelledby="flyoutTitle" size="m">
      <AddJobPanel
        orgName={orgName}
        inProgressJob={inProgressJob}
        stream={stream}
        jobType={jobType}
      />
    </Flyout>
  );
};

export default AddDataFlyout;

import { FC, useContext } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiTextColor, EuiToolTip, Icon, Spacer } from 'ui';
import Currency from '@app/components/Currency/Currency';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import ErrorState from '../../components/ErrorState';
import ScoreIndicatorBar from '../../components/ScoreIndicatorBar';
import { getReadinessGrade } from '../../ReadinessReport/utils';
import { headingStyles } from '../../reports.emotion';
import { UnderwritingAssessmentReportContext } from '../underwritingAssessmentReportContext';
import OverviewItem from './OverviewItem';
import { NoData, NoDataFooter } from './OverviewItem.emotion';

const Overview: FC = () => {
  const { overviewData, overviewDataError, overviewDataIsLoading } = useContext(
    UnderwritingAssessmentReportContext,
  );

  if (overviewDataIsLoading) {
    return <LoadingSpinnerV2 styles={{ height: '184px' }} dataTestId="dqs-loading" />;
  }

  if (overviewDataError) {
    return <ErrorState />;
  }

  const snapshotScore = overviewData?.qualitySnapshotOverall?.score * 100 || '';

  // ToDo: remove the conversion when consuming the api
  const topPercentile = getReadinessGrade(parseInt(snapshotScore.toString(), 10));

  if (overviewData && !overviewDataIsLoading) {
    return (
      <EuiFlexItem grow={false}>
        <>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="row" alignItems="center" gutterSize="none">
              <EuiFlexItem grow={false}>
                <EuiText size="m">
                  <h3>Overview</h3>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <Spacer size="m" />
          <EuiFlexGroup direction="row" justifyContent="flexStart">
            <EuiFlexItem>
              <OverviewItem
                title={
                  <EuiFlexGroup direction="row" alignItems="center" gutterSize="xs">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        <h4 style={headingStyles}>Readiness Summary</h4>
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiToolTip
                        position="top"
                        content="The Readiness Summary level of the SoV is an aggregation of the data quality of the individual properties, taking into account their importance (TIV) and riskiness (exposure to hazards)."
                      >
                        <Icon color="primary" name="info" />
                      </EuiToolTip>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                }
                content={
                  snapshotScore > 0 ? (
                    <EuiText size="m">
                      <h2>{`Data Quality ${topPercentile}`}</h2>
                    </EuiText>
                  ) : (
                    <NoData>--</NoData>
                  )
                }
                footerContent={
                  snapshotScore > 0 ? (
                    <ScoreIndicatorBar percentile={topPercentile} compact tooltips />
                  ) : (
                    <div>
                      <NoDataFooter size="s">Not scored yet</NoDataFooter>
                    </div>
                  )
                }
                loading={overviewDataIsLoading}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <OverviewItem
                title={
                  <EuiText size="m">
                    <h4 style={headingStyles}>TIV</h4>
                  </EuiText>
                }
                content={
                  <EuiText size="m">
                    <h2>
                      <Currency value={overviewData?.qualitySnapshotOverall.tiv} />
                    </h2>
                  </EuiText>
                }
                loading={overviewDataIsLoading}
                percentChange={overviewData?.qualitySnapshotOverall.tivChangePct || 0}
                footNote="Since last bound"
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <OverviewItem
                title={
                  <EuiText size="m">
                    <h4 style={headingStyles}>Properties</h4>
                  </EuiText>
                }
                content={
                  <EuiText size="m">
                    <h2>{overviewData?.qualitySnapshotOverall.locationCount}</h2>
                  </EuiText>
                }
                loading={overviewDataIsLoading}
                percentChange={overviewData?.qualitySnapshotOverall.locationCountChangePct || 0}
                footNote="Since last bound"
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexItem grow={1}>
                <EuiFlexGroup direction="row" justifyContent="flexStart">
                  <EuiFlexItem grow={false}>
                    <EuiTextColor
                      color={
                        overviewData?.qualitySnapshotOverall.locationCountAdded
                          ? 'default'
                          : 'subdued'
                      }
                    >
                      <EuiText size="s">
                        <h4>
                          {overviewData?.qualitySnapshotOverall.locationCountAdded || 0} Added
                          properties
                        </h4>
                      </EuiText>
                    </EuiTextColor>
                    <EuiTextColor
                      color={
                        overviewData?.qualitySnapshotOverall.locationCountDisposed
                          ? 'default'
                          : 'subdued'
                      }
                    >
                      <EuiText size="s">
                        <h4>
                          {overviewData?.qualitySnapshotOverall.locationCountDisposed || 0} Disposed
                          properties
                        </h4>
                      </EuiText>
                    </EuiTextColor>
                  </EuiFlexItem>
                </EuiFlexGroup>
                <Spacer size="m" />
                <EuiFlexGroup direction="row" justifyContent="flexStart">
                  <EuiFlexItem grow={false}>
                    <EuiTextColor
                      color={
                        overviewData?.qualitySnapshotOverall.locationCountAdded ||
                        overviewData?.qualitySnapshotOverall.locationCountDisposed
                          ? 'default'
                          : 'subdued'
                      }
                    >
                      <EuiText size="s">
                        {overviewData?.qualitySnapshotOverall.locationCountAdded ||
                        overviewData?.qualitySnapshotOverall.locationCountDisposed
                          ? 'Since last bound'
                          : 'Prev. bind n/a'}
                      </EuiText>
                    </EuiTextColor>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      </EuiFlexItem>
    );
  }
  return <></>;
};

export default Overview;

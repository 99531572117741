"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var Property = function (_a) {
    var dataTestId = _a["data-testid"], _b = _a.color, color = _b === void 0 ? 'black' : _b, size = _a.size;
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ "data-testid": dataTestId, width: size, height: size, viewBox: "0 0 24 24", fill: "none" }, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M16 23H8V5.206C8 4.53985 8.55591 4 9.24189 4H14.7581C15.4441 4 16 4.53985 16 5.206V23Z", stroke: color, strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M8 23H1V10.9922L4.5 9L8 10.9922V23Z", stroke: color, strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M23 23H16V10.9922L19.5 9L23 10.9922V23Z", stroke: color, strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M10 4L10 1", stroke: color, strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M13.8477 3.93773V1.9668", stroke: color, strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M8.1543 9H11.402", stroke: color, strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M8.1543 14H11.402", stroke: color, strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M8.1543 19H11.402", stroke: color, strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] })));
};
exports.default = Property;

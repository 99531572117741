"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrencyField = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var eui_1 = require("@elastic/eui");
var utils_1 = require("../../../utils");
exports.CurrencyField = React.forwardRef(function (_a, ref) {
    var dataTestId = _a["data-testid"], disabled = _a.disabled, _b = _a.fullWidth, fullWidth = _b === void 0 ? false : _b, id = _a.id, name = _a.name, onBlur = _a.onBlur, onChange = _a.onChange, placeholder = _a.placeholder, prepend = _a.prepend, append = _a.append, _c = _a.readOnly, readOnly = _c === void 0 ? false : _c, value = _a.value, _d = _a.isInvalid, isInvalid = _d === void 0 ? false : _d;
    var handleChange = function (event) {
        var _a;
        var inputValues = (_a = event.target.value) === null || _a === void 0 ? void 0 : _a.split('.');
        if (inputValues[1] && inputValues[1].length > 2) {
            onChange((0, utils_1.toNumber)([inputValues[0], inputValues[1].substr(0, 2)].join('.')));
        }
        else {
            onChange((0, utils_1.toNumber)(event.target.value));
        }
    };
    return ((0, jsx_runtime_1.jsx)(eui_1.EuiFieldNumber, { "data-testid": dataTestId || 'ui-atom-input-currency-field', disabled: disabled, fullWidth: fullWidth, id: id, name: name, onBlur: onBlur, onChange: handleChange, placeholder: placeholder, prepend: prepend && (0, jsx_runtime_1.jsx)(eui_1.EuiText, { children: prepend }), append: append && (0, jsx_runtime_1.jsx)(eui_1.EuiText, { children: append }), readOnly: readOnly, step: "any", value: value, inputRef: ref, isInvalid: isInvalid }));
});

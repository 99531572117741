import * as React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, Icon, Spacer } from 'ui';
import { RiskSummaryChartData } from '@app/queries/streams/types';
import { currentAndPrevBound } from '../../../RiskTabs/Charts/const';
import HorizontalBarChart from '../../../RiskTabs/Charts/HorizontalBarChart';

interface Props {
  countryCharts: RiskSummaryChartData;
  stateCharts: RiskSummaryChartData;
}

const TivBarCharts: React.FC<Props> = ({ countryCharts, stateCharts }) => (
  <>
    <EuiFlexGroup direction="column" gutterSize="none">
      <EuiFlexItem key={`${countryCharts.name}`} grow={false}>
        <HorizontalBarChart
          bars={countryCharts.values}
          secondaryBars={countryCharts.values}
          showBarLabels
          showTIV
          title="Top Countries By TIV"
          chartType="GeographyAndHazards"
          keyItems={currentAndPrevBound}
          justifyContentLegend="flexStart"
        />
      </EuiFlexItem>
      <Spacer size="s" />
      <EuiFlexItem grow={false}>
        <EuiText size="xs">
          <h5>
            $ TIV
            <Icon name="arrowRight" size="xs" />
          </h5>
        </EuiText>
      </EuiFlexItem>
      <Spacer size="xl" />
      <EuiFlexItem key={`${stateCharts.name}`} grow={false}>
        <HorizontalBarChart
          bars={stateCharts.values}
          secondaryBars={stateCharts.values}
          showBarLabels
          showTIV
          showKey={false}
          title="Top States By TIV"
          chartType="GeographyAndHazards"
          keyItems={currentAndPrevBound}
        />
      </EuiFlexItem>
      <Spacer size="s" />
      <EuiFlexItem grow={false}>
        <EuiText size="xs">
          <h5>
            $ TIV
            <Icon name="arrowRight" size="xs" />
          </h5>
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  </>
);

export default TivBarCharts;

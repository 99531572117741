import { Dispatch, SetStateAction, useContext } from 'react';
import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import { IJob } from '@onarchipelago/dice/EnrichmentProjects/types';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { EJobType } from '@app/queries/organizations/types';
import { Lock } from '@app/queries/properties/PropertyQuery/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import AddDataFlyout from '../AddDataFlyout/AddDataFlyout';
import { PanelItem } from './types';

interface Props {
  inProgressJobs: Partial<Record<EJobType, Lock>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default ({ inProgressJobs, setOpen }: Props): PanelItem => {
  const { showFlyout } = useContext(FlyoutContext);
  const { stream } = useStreamContext();

  const handleClick = () => {
    showFlyout(
      <AddDataFlyout
        orgName={stream?.orgName as string}
        inProgressJob={inProgressJobs[EJobType.AddLosses]}
        stream={stream as IGraphQLStream}
        jobType={EJobType.AddLosses}
      />,
    );

    setOpen(false);
  };

  return {
    'data-testid': 'add-losses-button',
    iconName: 'plusCircle',
    label: 'Add more losses',
    onClick: handleClick,
  };
};

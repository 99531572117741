import { createContext, useContext, useReducer } from 'react';
import { Property } from '@app/graphql/types';

export interface SelectedRows {
  [key: string]: Property;
}
interface SelectionContext {
  selectedRows: SelectedRows;
  updateSelectedRows: ({
    action,
    rowIndex,
    id,
    property,
  }: {
    action: string;
    rowIndex?: number;
    id?: string;
    property?: Property;
  }) => void;
}

export const SelectionContext = createContext({} as SelectionContext);

export const SelectionProvider = ({ children, properties, pagination }) => {
  const [selectedRows, updateSelectedRows] = useReducer(
    (rowSelection: SelectedRows, { action, id, property }) => {
      if (action === 'add') {
        return { ...rowSelection, [id]: property } as SelectedRows;
      } if (action === 'delete') {
        const nextRowSelection = { ...rowSelection };
        delete nextRowSelection[id];
        return nextRowSelection as SelectedRows;
      } if (action === 'clear') {
        return {} as SelectedRows;
      } if (action === 'selectAll') {
        const nextRowSelection = { ...rowSelection };
        const startIndex = pagination.pageIndex * pagination.pageSize;
        let currentIndex = startIndex;
        properties.forEach((property) => {
          nextRowSelection[property.id] = property;
          currentIndex++;
        });

        return nextRowSelection as SelectedRows;
      }
      return rowSelection as SelectedRows;
    },
    {},
  );
  return (
    <SelectionContext.Provider value={{ selectedRows, updateSelectedRows }}>
      {children}
    </SelectionContext.Provider>
  );
};

export const useSelection = () => useContext(SelectionContext);

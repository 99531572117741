"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WTWLogo = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var WTWLogo = function () { return ((0, jsx_runtime_1.jsx)("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "95", height: "36", fill: "#7f35b2", viewBox: "0 0 155 50" }, { children: (0, jsx_runtime_1.jsx)("path", { d: "M155 9.83 139.8 49.1H127l-6.4-21.93-6.45 21.93h-12.79L90.12 19.81h-7V33c0 6.19 2.5 7.21 5.92 7.21a12.83 12.83 0 0 0 2.69-.34l3.5 9.12a34.8 34.8 0 0 1-8.33 1c-11.72 0-16.72-4.79-16.72-16V19.81h-5.34L53.51 49.1h-12.8l-6.39-21.93-6.47 21.93H15.06L0 9.83h14.51l7.57 23.73L29.3 9.83h10l7.29 23.73 7.53-23.73h16.06V2.77L83.12 0v9.83h17.69l7.56 23.73 7.22-23.73h10l7.29 23.73 7.5-23.73Z" }) }))); };
exports.WTWLogo = WTWLogo;
exports.default = exports.WTWLogo;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var ImageFileColor = function (_a) {
    var dataTestId = _a["data-testid"], size = _a.size;
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ "data-testid": dataTestId, width: size, height: size, viewBox: "0 0 24 24", fill: "none" }, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9L13 2Z", fill: "#DCAD05" }), (0, jsx_runtime_1.jsx)("path", { d: "M13 2V9H20", fill: "white", fillOpacity: "0.5" }), (0, jsx_runtime_1.jsx)("circle", { cx: "8.75", cy: "9.25", r: "2.25", fill: "white" }), (0, jsx_runtime_1.jsx)("mask", __assign({ id: "mask0_1183:36056", maskUnits: "userSpaceOnUse", x: "4", y: "2", width: "16", height: "20" }, { children: (0, jsx_runtime_1.jsx)("path", { d: "M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9L13 2Z", fill: "#FCDC6A" }) })), (0, jsx_runtime_1.jsxs)("g", __assign({ mask: "url(#mask0_1183:36056)" }, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M3 18.1911L4.9883 16.4527C7.24986 14.4755 10.6251 14.4755 12.8867 16.4527L19.875 22.5625", stroke: "white", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M15.375 17.25L17.1207 16.2434C18.3212 15.5512 19.7995 15.5512 21 16.2434V16.2434", stroke: "white", strokeWidth: "1.5", strokeLinecap: "square", strokeLinejoin: "round" })] }))] })));
};
exports.default = ImageFileColor;

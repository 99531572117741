import { FC, memo } from 'react';
import { ExplorerTitleContainer, ExplorerTitleText } from './ExplorerTitle.emotion';

interface IProps {
  title: string;
}

const ExplorerTitle: FC<IProps> = ({ title }) => (
  <ExplorerTitleContainer data-testid="explorer-title">
    <ExplorerTitleText>
      <p>{title}</p>
    </ExplorerTitleText>
  </ExplorerTitleContainer>
);

export default memo(ExplorerTitle);

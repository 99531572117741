import { useState } from 'react';
import { ButtonIcon, ContextMenu, EuiFlexGroup, EuiFlexItem, EuiText, EuiTextColor } from 'ui';
import { getFileURL } from '@app/utils/download';
import { EditUploadModal } from '../EditUploadModal/EditUploadModal';
import LoadingSpinnerV2 from '../LoadingSpinnerV2/LoadingSpinnerV2';
import { UnlinkDocumentModal } from '../UnlinkDocumentModal/UnlinkDocumentModal';
import { StyledHorizontalLineMarginTop } from './Row.emotion';

export const Row = ({ id, title, subtitle, loading, propertyID }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isEditUploadModalOpen, setIsEditUploadModalOpen] = useState(false);
  const [isUnlinkDocumentModalOpen, setIsUnlinkDocumentModalOpen] = useState(false);

  return (
    <EuiFlexItem>
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem>
          <EuiText size="s">
            <p>{title}</p>
          </EuiText>
          <EuiText size="xs">
            <EuiTextColor color="subdued">
              <p>{subtitle}</p>
            </EuiTextColor>
          </EuiText>
        </EuiFlexItem>
        {loading ? (
          <LoadingSpinnerV2 variant="l" styles={{ alignItems: 'flex-end' }} />
        ) : !propertyID ? null : (
          <ContextMenu
            anchorPosition="leftCenter"
            button={
              <ButtonIcon
                iconName="ellipsisHorizontal"
                size="m"
                color="text"
                onClick={() => {
                  setIsPopoverOpen(true);
                }}
              />
            }
            onClose={() => {
              setIsPopoverOpen(false);
            }}
            open={isPopoverOpen}
            panel={{
              items: [
                {
                  iconName: 'pencil',
                  label: 'Edit',
                  onClick: () => {
                    setIsEditUploadModalOpen(true);
                    setIsPopoverOpen(false);
                  },
                },
                {
                  iconName: 'download',
                  label: 'Download',
                  onClick: () => {
                    window.location.href = getFileURL(id);
                  },
                },
                {
                  iconName: 'x',
                  label: 'Unlink',
                  onClick: () => {
                    setIsUnlinkDocumentModalOpen(true);
                    setIsPopoverOpen(false);
                  },
                },
              ],
            }}
          />
        )}
      </EuiFlexGroup>
      <StyledHorizontalLineMarginTop margin="none" />
      {isEditUploadModalOpen && (
        <EditUploadModal
          id={id}
          documentName={title.split('.')[0]}
          documentType={subtitle.split(': ')[1]}
          onClose={() => setIsEditUploadModalOpen(false)}
        />
      )}
      {isUnlinkDocumentModalOpen && (
        <UnlinkDocumentModal
          id={id}
          propertyID={propertyID}
          onClose={() => {
            setIsUnlinkDocumentModalOpen(false);
          }}
        />
      )}
    </EuiFlexItem>
  );
};

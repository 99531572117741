import { Button, EuiToolTip } from 'ui';
import { useRetryJobMutation } from '@app/graphql/jobs/mutations/__generated__/retryJob.generated';
// FIX ME
// @ts-ignore
import GET_JOB from '@app/queries/organizations/getJob.gql';
import { useJobsApolloClient } from '../JobsApolloClient';

interface IRetryJobButton {
  jobID: string;
}

export const RetryJobButton = ({ jobID }: IRetryJobButton) => {
  const jobsApolloClient = useJobsApolloClient();
  const [retryJobMutation, { loading }] = useRetryJobMutation({
    client: jobsApolloClient,
    refetchQueries: [
      {
        query: GET_JOB,
        variables: {
          jobID,
        },
      },
    ],
    variables: {
      input: {
        jobID,
      },
    },
  });
  return (
    <EuiToolTip position="bottom" content="Reset job state to Ready For Review">
      <Button
        data-testid="retry-job-button"
        label="Retry"
        onClick={() => retryJobMutation()}
        loading={loading}
        size="s"
        fill
      />
    </EuiToolTip>
  );
};

import * as React from 'react';
import PropertyItem from '@onarchipelago/dice/EnrichmentProjects/PropertyItem';
import SelectPropertiesFlyout from '@onarchipelago/dice/EnrichmentProjects/SelectPropertiesFlyout';
import { EPropertySelectType } from '@onarchipelago/dice/EnrichmentProjects/types';
import { PropertyAttributeMetadata } from '@app/components/QueryBuilderWrapper/utils';
import { IGraphQLProperty } from '@app/queries/properties/types';

interface Props {
  filterable: Array<PropertyAttributeMetadata>;
  handleClose: () => void;
  handleUpdateSelectedProperties: (updatedProperties: IGraphQLProperty[]) => void;
  orgName: string;
  streamSlug?: string;
  selectedProperties: IGraphQLProperty[];
  propertySelectType: EPropertySelectType;
}

const PropertyPicker: React.FC<Props> = ({
  filterable,
  handleClose,
  handleUpdateSelectedProperties,
  orgName,
  streamSlug,
  selectedProperties,
  propertySelectType,
}) => {
  const propertyItem = (renderedRow: any) => (
    <PropertyItem
      multiple
      item={renderedRow.item}
      selectedProperties={selectedProperties}
      updateSelectedProperties={handleUpdateSelectedProperties}
    />
  );

  return (
    <SelectPropertiesFlyout
      filterable={filterable}
      hide={handleClose}
      multiple
      orgName={orgName}
      rowComponent={propertyItem}
      selectedItems={selectedProperties}
      updateSelectedProperties={handleUpdateSelectedProperties}
      propertySelectType={propertySelectType}
      streamSlug={streamSlug}
    />
  );
};

export default PropertyPicker;

import { formatDefault, formatMaxThreshold } from '../../../utils/format';
import { getEdits, getProvenance, ICell } from './Cell';

const SecondaryModifier = (props: ICell) => {
  // TODO - we need a custom rendering for secondaryModifier once it's specified. Right now it's
  // just rendering as text. Story:
  // https://app.clubhouse.io/onarchipelago/story/24959/implement-ui-behavior-for-secondary-modifiers

  const id = props?.column?.id as string;
  const value = props?.value;
  const options = props?.column?.formattingOptions;
  const formatted = options?.maxThreshold
    ? formatMaxThreshold(value, options?.maxThreshold)
    : formatDefault(value, '-');

  const provenance = getProvenance(props.prop, id);

  const tooltip = {
    description: undefined,
    edits: getEdits(props.prop, id),
    provenance,
    title: undefined,
  };

  const body = <span>{formatted}</span>;

  return { body, tooltip };
};

export default SecondaryModifier;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Radio = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var eui_1 = require("@elastic/eui");
var Radio = function (_a) {
    var dataTestId = _a["data-testid"], _b = _a["data-dd-privacy"], dataDdPrivacy = _b === void 0 ? "allow" : _b, checked = _a.checked, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.label, label = _d === void 0 ? null : _d, id = _a.id, onChange = _a.onChange;
    return ((0, jsx_runtime_1.jsx)(eui_1.EuiRadio, { "data-testid": dataTestId || "ui-atom-input-radio", "data-dd-privacy": dataDdPrivacy, id: (0, eui_1.useGeneratedHtmlId)({ prefix: id }), checked: checked, disabled: disabled, label: label, onChange: function (event) { return onChange(event.target.checked); } }));
};
exports.Radio = Radio;

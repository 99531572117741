import { useEffect } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Button, ButtonEmpty, EuiFlexItem, EuiHeader, useEuiTheme } from 'ui';
import { useFlyout } from '@app/contexts/FlyoutContext';
import {
  SubmissionsDashboardProvider,
  useSubmissionsDashboard,
} from '@app/cx/Dashboard/Submissions/Context';
import CreateSubmissionFlyout from '@app/cx/Dashboard/Submissions/CreateSubmissionFlyout';
import { useTracker } from '@app/hooks/useTracker';
import { StyledGroup, StyledItem } from './Alert.emotion';

export const SubmissionFlyout = ({ orgName }) => {
  const ctx = useSubmissionsDashboard();
  const history = useHistory();
  const refetch = () => {
    history.push({
      pathname: orgName ? encodeURI(`/organizations/${orgName}/accounts`) : '/accounts',
    });
  };
  return <CreateSubmissionFlyout ctx={ctx} refetch={refetch} enterpriseOrg={orgName} />;
};

interface IProps {
  hideAlert: () => void;
  message: string;
  shouldShowAlert: boolean;
  enterpriseOrgName?: string;
}

export const Alert: React.FC<IProps> = ({
  hideAlert,
  message,
  shouldShowAlert,
  enterpriseOrgName,
}) => {
  const tracker = useTracker();

  const { showFlyout } = useFlyout();
  const theme = useEuiTheme();

  useEffect(() => {
    if (shouldShowAlert) {
      tracker.track('In-App Alert V1: Viewed');
    }
  }, [shouldShowAlert]);

  if (!shouldShowAlert) {
    return null;
  }

  const dismissAlert = () => {
    tracker.track('In-App Alert V1: Dismissed');
    hideAlert();
  };

  return (
    <SubmissionsDashboardProvider>
      <EuiHeader position="fixed" style={{ padding: 0 }}>
        <StyledGroup color={theme.euiTheme.colors.primary} alignItems="center" gutterSize="s">
          <StyledItem grow={3}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 16 16"
                role="img"
                fill="white"
                aria-hidden="true"
              >
                <path d="M4.934 3.063a1.5 1.5 0 0 1 .547.321l.112.115 6.07 6.915a1.5 1.5 0 0 1-.646 2.41l-.142.04-9.031 2.097A1.5 1.5 0 0 1 .037 13.19l.043-.159L3.04 4.02a1.5 1.5 0 0 1 1.893-.957ZM4.027 4.25l-.036.083-2.961 9.011a.5.5 0 0 0 .498.656l.09-.013 2.937-.681-1.399-1.508a.5.5 0 0 1 .666-.74l.067.06 1.788 1.927 2.634-.611-3.198-3.601a.5.5 0 0 1 .682-.726l.066.062 3.559 4.007 1.229-.284a.5.5 0 0 0 .15-.063l.067-.049a.5.5 0 0 0 .099-.632l-.053-.073-6.07-6.916a.5.5 0 0 0-.138-.11l-.082-.035-.088-.02a.5.5 0 0 0-.507.256Zm11.66 5.039a2.5 2.5 0 0 1-.975 3.399.5.5 0 0 1-.485-.875 1.5 1.5 0 0 0-1.454-2.624.5.5 0 0 1-.485-.875 2.5 2.5 0 0 1 3.399.975Zm-5.03-6.206a.5.5 0 0 1 .338.544l-.02.088-.677 2.035 2.068-.721a.5.5 0 0 1 .6.225l.036.082a.5.5 0 0 1-.225.6l-.082.037L9.67 7.028a.5.5 0 0 1-.659-.55l.02-.08.995-3a.5.5 0 0 1 .632-.316ZM14.5 4a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1ZM7.862.403a2.5 2.5 0 0 1 .735 3.459.5.5 0 0 1-.839-.545 1.5 1.5 0 1 0-2.516-1.634.5.5 0 0 1-.839-.545A2.5 2.5 0 0 1 7.862.403ZM13.5 2a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1Zm-3-1a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1Zm4-1a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1Z"></path>
              </svg>
            </div>
            <div>{message.replace('{Enterprise}', enterpriseOrgName)}</div>
            <Button
              size="s"
              fontWeight={'bold'}
              label="Add all your SOVs here"
              onClick={() => {
                tracker.track('In-App Alert V1: Create Account Clicked');
                showFlyout(<SubmissionFlyout orgName={enterpriseOrgName} />);
              }}
            />
          </StyledItem>
          <EuiFlexItem grow={false} style={{ marginRight: '12px' }}>
            <ButtonEmpty color="ghost" label="" iconName={'x'} onClick={dismissAlert} />
          </EuiFlexItem>
        </StyledGroup>
      </EuiHeader>
    </SubmissionsDashboardProvider>
  );
};

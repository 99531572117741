import { Button, EuiFlexGroup } from 'ui';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { STEP } from './constants';
import { useManageExportsContext } from './ManageExportsContext';

export const ManageExportsFlyoutButtons = () => {
  const { closeFlyout } = useFlyout();
  const { step, setStep, isLoading, isSaving, transitionToOrderStep, saveExportConfig } =
    useManageExportsContext();

  const backButtonOnClick = () => {
    if (step === STEP.SELECT) {
      closeFlyout();
    } else {
      setStep(STEP.SELECT);
    }
  };
  const saveButtonOnClick = () => {
    if (step === STEP.SELECT) {
      transitionToOrderStep();
    } else {
      saveExportConfig();
    }
  };
  const backButtonLabel = step === STEP.SELECT ? 'Cancel' : 'Back';
  const saveButtonLabel = step === STEP.SELECT ? 'Next' : 'Save';

  return (
    <EuiFlexGroup justifyContent="flexEnd" gutterSize="s">
      <Button label={backButtonLabel} minWidth="70px" onClick={backButtonOnClick} />
      <Button
        label={saveButtonLabel}
        minWidth="70px"
        disabled={isLoading}
        loading={isSaving}
        onClick={saveButtonOnClick}
        fill
      />
    </EuiFlexGroup>
  );
};

import { Dispatch, SetStateAction, useContext } from 'react';
import DisposePropertyFlyout from '@onarchipelago/cx/Stream/DisposePropertyFlyout';
import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import { getMetadataByType } from '@onarchipelago/cx/Stream/utils';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { PanelItem } from './types';

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default ({ setOpen }: Props): PanelItem => {
  const { showFlyout } = useContext(FlyoutContext);
  const { stream, propertyAttributeMetadata, metadataMap } = useStreamContext();
  const filterable = getMetadataByType(propertyAttributeMetadata, 'filterable');

  const handleClick = () => {
    showFlyout(
      <DisposePropertyFlyout
        metadataMap={metadataMap}
        orgID={stream?.organizationId as string}
        orgName={stream?.orgName as string}
        filterable={filterable}
        streamSlug={stream?.slug || ''}
      />,
    );

    setOpen(false);
  };

  return {
    'data-testid': 'dispose-properties-button',
    iconName: 'trash',
    label: 'Dispose',
    onClick: handleClick,
  };
};

import { FC, ReactNode, useEffect, useState } from 'react';
import moment from 'moment';
import { useToast } from 'ui';
import { VERSION } from '../../config';

export const THROTTLE_CHECK_IN_MINUTES = 1;
export const UPDATE_CHECK_INTERVAL = 1000;
export const getLatestVerion = async () =>
  fetch('/VERSION')
    .then((res) => res.text())
    .then((sha) => {
      if (sha.length > 40) {
        return VERSION;
      }

      return sha;
    });

export const UpdateProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const toast = useToast();
  const [lastChecked, setLastChecked] = useState(() => moment().toISOString());
  const [alreadyNotified, setAlreadyNotified] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      if (moment().diff(moment(lastChecked), 'minutes') >= THROTTLE_CHECK_IN_MINUTES) {
        getLatestVerion().then((sha) => {
          setLastChecked(() => moment().toISOString());
          if (sha.trim() !== VERSION && !alreadyNotified) {
            toast({
              label: 'Reload',
              onClick: () => window.location.reload(),
              sticky: true,
              title: 'There is a new version of the app available!',
            });
            setAlreadyNotified(true);
          }
        });
      }
    }, UPDATE_CHECK_INTERVAL);

    return () => clearTimeout(timer);
  }, [lastChecked, alreadyNotified]);

  return <>{children}</>;
};

import { useState } from 'react';
import * as React from 'react';
import {
  Checkbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiModalBody,
  EuiText,
  EuiTextColor,
  Select,
} from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { useOrgModalContext } from '../../context/OrgModal.context';
import ConfirmSettingsChangeModal, { ModalContent } from './ConfirmSettingsChangeModal';
import { getLossStartYearOptions } from './utils';

const currentYear = new Date().getFullYear();

const lossStartYearOptions = getLossStartYearOptions(currentYear, 10);

interface ConfirmModalContent {
  title: string;
  message: string;
}

const PageConfigurationTab: React.FC = () => {
  const { values, onValueChange, disabled, org } = useOrgModalContext();
  const [confirmModalField, setConfirmModalField] = useState<ModalContent | null>(null);

  const renderCheckbox = (id: string, label: string, dataTestId: string) => (
    <Checkbox
      id={id}
      label={label}
      data-testid={dataTestId}
      checked={values[id]}
      onChange={onValueChange(id)}
    />
  );

  const renderConfirmCheckbox = (
    id: string,
    label: string,
    dataTestId: string,
    checkContent: ConfirmModalContent,
    uncheckContent: ConfirmModalContent,
  ) => (
    <Checkbox
      id={id}
      label={label}
      data-testid={dataTestId}
      checked={values[id]}
      onChange={() =>
        setConfirmModalField(
          values[id]
            ? {
                field: id,
                value: false,
                ...uncheckContent,
              }
            : {
                field: id,
                value: true,
                ...checkContent,
              },
        )
      }
    />
  );

  return (
    <EuiModalBody>
      <EuiFormRow label="SOV Upload" fullWidth>
        <>
          {renderCheckbox(
            'enableUploadPortal',
            'Redirect users to upload portal',
            'enable-upload-portal-checkbox',
          )}
          {renderCheckbox(
            'enableSendEmailOnSOVUpload',
            'Send email on SOV upload',
            'enable-email-on-sov',
          )}
          {renderCheckbox(
            'enableRedirectOnSOVUpload',
            'Redirect on SOV upload',
            'enable-redirect-on-sov',
          )}
        </>
      </EuiFormRow>
      <EuiFormRow label="Channel accounts" fullWidth>
        <>
          {renderConfirmCheckbox(
            'enableAccountDashboard',
            'Enable Accounts List',
            'enable-account-dashboard-checkbox',

            {
              message: 'This will enable the submission dashboard.',
              title: 'Confirm Enable Submissions Dashboard',
            },
            {
              message:
                'This will disable the submission dashboard without removing any submission data. All external users with this as their primary account will lose access to the submission streams.',
              title: 'Confirm Disable Submissions Dashboard',
            },
          )}
          {renderCheckbox(
            'enableRiskManagerAssessment',
            'Enable Risk Assessment Report',
            'enable-underwriting-assessment-report',
          )}
          {org?.enableRiskManagerAssessment && values.enableRiskManagerAssessment && (
            <div style={{ marginLeft: '24px' }}>
              {renderCheckbox(
                'enableCarrierAssessment',
                'Include Carrier Metrics',
                'include-carrier-metrics',
              )}
            </div>
          )}
          {renderConfirmCheckbox(
            'enableUnderwriterEdit',
            'Grant RM on Underlying Customer Accounts within Accounts Page',
            'enable-underwriter-edit-checkbox',
            {
              message:
                'A RM role will allow users to edit data, manage documents, and see RM-specific reports, including content from carriers, etc. It may be appropriate to grant this to Brokers working on behalf of the risk owner, but do not enable it for Carriers or other accounts.',
              title: 'Confirm Enable Grant RM Permissions on Submission Orgs',
            },
            {
              message:
                'Disabling the Grant RM Permissions on Submission Accounts setting will replace the RM role with the StreamViewer role for carrier account users.',
              title: 'Confirm Disable Grant RM Permissions on Submission Accounts',
            },
          )}
          {renderCheckbox(
            'enableRapidExport',
            'Enable RAPID Export',
            'enable-rapid-export-checkbox',
          )}
        </>
      </EuiFormRow>
      <EuiFormRow label="Valuations" fullWidth>
        <>
          {renderCheckbox('enableSubValues', 'Enable Subvalues', 'enable-sub-values-checkbox')}
          {org?.enableSubValues === true && values.enableSubValues === false && (
            <EuiText
              size="s"
              style={{
                backgroundColor: 'rgb(255, 249, 232) ',
                borderRadius: '4px',
                marginBottom: '4px',
                padding: '6px',
              }}
            >
              <EuiTextColor color="warning">
                Enrichment job must be run to repopulate and distribute the valuations amongst
                subvalues!
              </EuiTextColor>
            </EuiText>
          )}
          {renderCheckbox(
            'enableCurrencyConversion',
            'Enable Currency Conversion',
            'enable-currency-conversion-checkbox',
          )}
        </>
      </EuiFormRow>
      <EuiFormRow label="Losses">
        <>
          {renderCheckbox('enableLosses', 'Enable Losses', 'enable-losses')}
          <EuiFormRow
            label={
              <EuiFlexGroup gutterSize="s">
                <EuiFlexItem grow={false}>Losses Start Year</EuiFlexItem>
              </EuiFlexGroup>
            }
            fullWidth
          >
            <Select
              options={lossStartYearOptions}
              onChange={onValueChange('lossStartYear')}
              value={String(values.lossStartYear)}
              data-testid="loss-start-year"
              disabled={disabled}
              fullWidth
            />
          </EuiFormRow>
        </>
      </EuiFormRow>
      <EuiFormRow label="Other" fullWidth>
        <>
          {renderCheckbox(
            'excludeFromReporting',
            'Exclude from Reporting',
            'exclude-from-reporting-checkbox',
          )}
          {renderCheckbox(
            'enableStreamDashboard',
            `Enable ${STREAMS_LABEL} Dashboard`,
            'enable-stream-dashboard-checkbox',
          )}
          {renderCheckbox(
            'showNonEnrichmentDocs',
            'Show non-enrichment documents',
            'show-non-enrichment-docs',
          )}
        </>
      </EuiFormRow>
      <EuiFormRow label="Apps" fullWidth>
        <>
          {renderCheckbox(
            'enableSOVManager',
            'Enable SOV Manager App',
            'enable-sov-manager-checkbox',
          )}
        </>
      </EuiFormRow>
      {!!confirmModalField && (
        <ConfirmSettingsChangeModal
          content={confirmModalField}
          onClose={() => setConfirmModalField(null)}
        />
      )}
    </EuiModalBody>
  );
};

export default PageConfigurationTab;

import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { colors } from 'ui';
import { IGraphQLChart, IGraphQLRawJSON } from '@app/queries/explorers/types';
import { formatNumber } from '@app/utils/format';
import { Explorer } from '../Explorer.emotion';

export const formatDataQualityArrayByKey = (payload: Array<any> = []) =>
  payload.reduce((accumulator, current) => {
    accumulator[current.query_name] = current;

    return accumulator;
  }, {});

const getOptions = (queryData: any, title?: string) => ({
    chart: {
      height: 285,
      style: {
        marginLeft: '0 auto',
      },
      width: 288,
    },
    colorAxis: {
      maxColor: '#2F80ED',
      min: 0,
      minColor: '#E9F4FB',
    },
    credits: {
      enabled: false,
    },
    exporting: {
      chartOptions: {
        legend: {
          enabled: true,
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        groupPadding: 0.2,
      } as any,
    },
    series: [
      {
        borderWidth: 0,
        color: colors.dataViz.archipelagoGreen3,
        data: queryData.total_loss_by_cause.data.map((v) => [v.label, v.value / 1e6]),
        name: 'Gross total loss ($M)',
        type: 'bar',
      } as Highcharts.SeriesBarOptions,
    ],
    title: {
      text: title ? title : null,
    },

    tooltip: {
      formatter() {
        //eslint-disable-line
        return `${this.point.name}<br/><b>$${formatNumber(this.y)}M</b>`;
      },
    },
    xAxis: {
      type: 'category' as Highcharts.AxisTypeValue,
    },
    yAxis: {
      endOnTick: true,
      labels: {
        step: 1,
        style: {
          fontSize: '10px',
          fontWeight: 'bold',
          margin: '0px',
          opacity: '1 !important' as any,
          padding: '0px',
          whiteSpace: 'nowrap',
        },
      },
      min: 0,
      showLastLabel: true,
      title: {
        align: 'high' as any,
        style: {
          fontSize: '10px',
          fontWeight: 'bold',
        },
        text: 'Gross total loss ($M)→',
      },
    },
  });

interface Props {
  explorer: IGraphQLChart;
  title?: string;
}

const TotalLossByCause: React.FC<Props> = ({ explorer, title }) => {
  const data = JSON.parse((explorer as IGraphQLRawJSON).json);
  const queryData = formatDataQualityArrayByKey(data);

  return (
    <Explorer>
      <HighchartsReact highcharts={Highcharts} options={getOptions(queryData, title)} />
    </Explorer>
  );
};

export default TotalLossByCause;

import { FC, ReactElement, useEffect, useState } from 'react';
import { IGraphQLStream } from '@app/queries/streams/types';
import Tour from './Tour';
import { IStep } from './types';

interface IProps {
  stream?: IGraphQLStream;
  step?: number;
  steps: Array<IStep>;
  children: (props: {
    handleClick: () => void;
    open: boolean;
    step: undefined | number;
  }) => ReactElement;
  handleStepChange?: (step: number) => void;
  handleClose?: () => void;
  handleOpen?: (step: number) => void;
}

const TourButton: FC<IProps> = ({
  stream,
  step,
  steps,
  children,
  handleStepChange,
  handleClose,
  handleOpen,
}) => {
  const [localStep, setLocalStep] = useState<undefined | number>();
  const [open, setOpen] = useState(
    step !== undefined && step !== null && step >= 1 && step <= steps.length,
  );

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(step !== undefined && step !== null && step >= 0 && step < steps.length);
  }, [step]);

  return (
    <>
      {children({
        handleClick,
        open,
        step: localStep,
      })}
      <Tour
        stream={stream}
        step={step}
        steps={steps}
        open={open}
        handleOpen={handleOpen}
        handleClose={() => {
          setOpen(false);
          if (handleClose) {
            handleClose();
          }
        }}
        handleStepChange={(changedStep) => {
          setLocalStep(changedStep);
          if (handleStepChange) {
            handleStepChange(changedStep);
          }
        }}
      />
    </>
  );
};

export default TourButton;

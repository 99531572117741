import { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import {
  CarrierAssessment,
  CarrierLossYear,
} from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';
import {
  CarrierPropertyClaimInput,
  CarrierPropertyClaims,
  GET_CARRIER_PROPERTY_CLAIMS,
} from '@onarchipelago/cx/Reports/queries/getCarrierPropertyClaims';
import { Direction, EuiBasicTable, Pagination } from 'ui';
import { formatCurrency } from '@app/utils/format';

interface Props {
  carrierAssessment: CarrierAssessment;
  carrierLossYear: CarrierLossYear;
}

interface CarrierPropertyResponse {
  carrierPropertyClaims: CarrierPropertyClaims;
}

const sortTypeMatch = {
  asc: 'ASCENDING',
  desc: 'DESCENDING',
};

const ClosedClaimsTable: FC<Props> = ({ carrierAssessment, carrierLossYear }) => {
  const { stream } = useReportsContext();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(9);
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortField, setSortField] = useState('lossOccurrence');

  const { data, loading } = useQuery<CarrierPropertyResponse, CarrierPropertyClaimInput>(
    GET_CARRIER_PROPERTY_CLAIMS,
    {
      skip: !carrierLossYear?.uwYear && !carrierAssessment,
      variables: {
        input: {
          filter: {
            limit: pageSize,
            offset: pageIndex,
            sortBy: {
              attributeName: sortField,
              order: sortTypeMatch[sortDirection],
            },
            year: carrierLossYear?.uwYear,
          },
          reportName: 'ZurichUwAR',
          streamId: stream?.streamId,
        },
      },
    },
  );

  const onTableChange = (criteria) => {
    setPageIndex(criteria.page?.index);
    setPageSize(criteria.page?.size);
    setSortField(criteria.sort?.field);
    setSortDirection(criteria.sort?.direction);
  };

  const sortable = true;

  const columns = [
    {
      field: 'fileNumber',
      name: 'Claim File Number',
      sortable,
    },
    {
      field: 'lossOccurrence',
      name: 'Occurrence Date',
      sortable,
    },
    {
      field: 'firstNotification',
      name: 'Report Date',
      sortable,
    },

    {
      field: 'perilType',
      name: 'Peril Type',
      sortable,
    },
    {
      field: 'causeOfLoss',
      name: 'Cause of Loss',
      sortable,
    },
    {
      field: 'address',
      name: 'Address',
      sortable,
    },
    {
      field: 'city',
      name: 'City',
      sortable,
    },
    {
      field: 'state',
      name: 'State/Province',
      sortable,
    },
    {
      field: 'country',
      name: 'Country',
      sortable,
    },
    {
      field: 'paidIndemnity',
      name: 'Paid Indemnity',
      render: (name, item) => (
        <>
          {formatCurrency(item.paidIndemnity, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
          })}
        </>
      ),
      sortable,
    },
  ];

  const pagination: Pagination = {
    // FIX ME
    // @ts-ignore
    hidePerPageOptions: true,
    pageIndex,
    pageSize,
    pageSizeOptions: [pageSize],
    totalItemCount: data?.carrierPropertyClaims?.claimCnt || 0,
  };

  const sorting = {
    enableAllColumns: true,
    sort: {
      direction: sortDirection as Direction,
      field: sortField,
    },
  };

  return (
    <EuiBasicTable
      data-testid="loss-history-closed-claims-table"
      items={data?.carrierPropertyClaims?.claims || []}
      columns={columns}
      loading={loading}
      sorting={sorting}
      pagination={pagination}
      onChange={onTableChange}
    />
  );
};

export default ClosedClaimsTable;

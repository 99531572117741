import { getAsSchema } from '@app/containers/Property/utils';
import { formatDefault } from '@app/utils/format';
import { getEdits, getProvenance, ICell } from './Cell';
import Couplet from './Couplet';

const showBoth = (id: string) => !['constructionType', 'occupancyType'].includes(id);

const SchemaWithType = (props: ICell, type: 'construction' | 'occupancy') => {
  const id = props?.column?.id;
  const value = props.value;
  const provenance = getProvenance(props.prop, id);
  const schema = getAsSchema(value, type);

  const tooltip = {
    description: schema,
    edits: getEdits(props.prop),
    provenance,
    title: undefined,
  };

  const body = showBoth(id) ? (
    <Couplet hero={schema || ''} sidekick={value} />
  ) : (
    <p>{formatDefault(schema, '-')}</p>
  );

  return { body, tooltip };
};

export const SchemaConstruction = (props: ICell) => SchemaWithType(props, 'construction');

export const SchemaOccupancy = (props: ICell) => SchemaWithType(props, 'occupancy');

export default SchemaWithType;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextField = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var eui_1 = require("@elastic/eui");
var Icon_1 = require("../../../neutrons/icon/Icon");
exports.TextField = (0, react_1.forwardRef)(function (_a, ref) {
    var dataTestId = _a["data-testid"], append = _a.append, disabled = _a.disabled, _b = _a.fullWidth, fullWidth = _b === void 0 ? false : _b, id = _a.id, iconName = _a.iconName, _c = _a.invalid, invalid = _c === void 0 ? false : _c, _d = _a.loading, loading = _d === void 0 ? false : _d, name = _a.name, onBlur = _a.onBlur, onChange = _a.onChange, placeholder = _a.placeholder, prepend = _a.prepend, _e = _a.readOnly, readOnly = _e === void 0 ? false : _e, value = _a.value;
    var _f = __read((0, react_1.useState)({}), 2), additionalProps = _f[0], setAdditionalProps = _f[1];
    (0, react_1.useEffect)(function () {
        if (iconName) {
            var finalProps_1 = {
                icon: function () { return (0, jsx_runtime_1.jsx)(Icon_1.Icon, { name: iconName }); },
            };
            setAdditionalProps(function (prevState) { return (__assign(__assign({}, prevState), finalProps_1)); });
        }
    }, []);
    return ((0, jsx_runtime_1.jsx)(eui_1.EuiFieldText, __assign({ "data-testid": dataTestId || 'ui-atom-input-text-field', append: append, disabled: disabled, fullWidth: fullWidth, id: id, inputRef: ref, isInvalid: invalid, isLoading: loading, name: name, onBlur: onBlur, onChange: onChange, placeholder: placeholder, prepend: prepend, readOnly: readOnly, value: value }, additionalProps)));
});

import { Grade } from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';
import { formatPercentage } from '@app/utils/format';
import { TableData } from '../types';

interface Item {
  filterByGrade: Grade;
}

export const renderPct = (value: number) => (
  <b style={{ whiteSpace: 'nowrap', width: '50px' }}>{formatPercentage(value)}</b>
);

export interface OverallTableData extends TableData<Item> {
  overallGrade: Grade;
  totalRow: Item;
}

import { Children, FC, ReactElement } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import styles from './DocumentsModalContents.emotion';

interface Props {
  children: ReactElement | Array<ReactElement>;
  name: string;
}

const DocumentsGroup: FC<Props> = ({ children, name }) => (
  <EuiFlexGroup data-testid="documents-modal-documents-group" direction="column">
    <EuiFlexItem grow={false}>
      <EuiText>
        <h3>{name}</h3>
      </EuiText>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiFlexGroup className={styles.documentsContainer}>
        {Children.map(children, (child) => (
          <EuiFlexItem grow={false}>{child}</EuiFlexItem>
        ))}
      </EuiFlexGroup>
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default DocumentsGroup;

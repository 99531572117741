import { FC, useContext } from 'react';
import { EuiFlexItem, EuiHeaderSectionItem } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { ManagedOrgInfo } from '@app/queries/streams/types';
import { StyledButton } from './OrgSwitcherButton.emotion';

interface Props {
  isMyJobPage?: boolean;
  onClick: () => void;
  isLarge?: boolean;
}

export const OrgSwitcherButtonTestId = 'org-switcher-button';

export const isSwitchableOrg = (o?: ManagedOrgInfo) =>
  !!(
    o?.editDocuments ||
    o?.editProjects ||
    o?.editProperties ||
    o?.editSubmissions ||
    o?.manageUsers
  );

export const OrgSwitcherButton: FC<Props> = ({ isMyJobPage = false, onClick, isLarge = false }) => {
  const { account } = useAuth();
  const { selectedOrganization } = useContext(UserSessionContext);
  const isAdmin = account?.permissions?.admin;

  // Never display for "My Jobs"
  if (isMyJobPage) {
    return null;
  }

  const switcherOrgs = account?.managedOrgs?.some(isSwitchableOrg) && account?.managedOrgs;

  return (
    <>
      {(isAdmin || switcherOrgs?.length) && (
        <EuiHeaderSectionItem>
          <EuiFlexItem grow={false} style={{ marginRight: '12px' }}>
            <StyledButton
              data-testid={OrgSwitcherButtonTestId}
              color="text"
              disabled={!isAdmin && switcherOrgs?.length === 1}
              label={selectedOrganization?.name}
              onClick={onClick}
              shape="oval"
              size={isLarge ? 'm' : 's'}
              iconName={isLarge ? 'chevronDown' : undefined}
              iconSide={isLarge ? 'right' : undefined}
              isLarge={isLarge}
            />
          </EuiFlexItem>
        </EuiHeaderSectionItem>
      )}
    </>
  );
};

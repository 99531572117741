import * as React from 'react';
import { ProvenanceDocument } from '@app/components/PropertiesGrid/PropertiesGridTooltip/types';
import { Label, SourceLinkView } from './style';

interface IProps {
  type?: string;
  handleClick: (doc: ProvenanceDocument) => void;
  url: string;
}
const ExternalLink: React.FC<IProps> = ({ type, url, handleClick }) => (
  <SourceLinkView
    target="_blank"
    rel="noopener noreferrer"
    href={url}
    onClick={(e) => {
      e.stopPropagation();
      handleClick({
        filename: url,
        httpURL: url,
      });
    }}
  >
    <Label>
      <div>
        <span>{type ? `View ${type}` : 'View website'}</span>
      </div>
    </Label>
  </SourceLinkView>
);
export default ExternalLink;

"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UIProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var eui_1 = require("@elastic/eui");
var react_1 = require("@emotion/react");
var Toast_context_1 = require("../atoms/display/toast/Toast.context");
var overrides_1 = require("../themes/overrides/overrides");
var customGlobalStyles = (0, react_1.css)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  html,\n  body {\n    background-color: #fff;\n    margin: 0;\n    padding: 0;\n    min-height: 100%;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n  }\n  body {\n    height: 100vh;\n  }\n\n  ul,\n  li {\n    margin: 0;\n    padding: 0;\n    list-style: none;\n  }\n\n  * {\n    box-sizing: border-box;\n    font-variant-ligatures: none;\n  }\n\n  #root {\n    height: 100%;\n    display: block;\n    overflow: hidden;\n  }\n  .euiOverlayMask {\n    background: ", " !important;\n  }\n  *:focus {\n    &:focus-visible {\n      outline-style: none;\n    }\n  }\n"], ["\n  html,\n  body {\n    background-color: #fff;\n    margin: 0;\n    padding: 0;\n    min-height: 100%;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n  }\n  body {\n    height: 100vh;\n  }\n\n  ul,\n  li {\n    margin: 0;\n    padding: 0;\n    list-style: none;\n  }\n\n  * {\n    box-sizing: border-box;\n    font-variant-ligatures: none;\n  }\n\n  #root {\n    height: 100%;\n    display: block;\n    overflow: hidden;\n  }\n  .euiOverlayMask {\n    background: ", " !important;\n  }\n  *:focus {\n    &:focus-visible {\n      outline-style: none;\n    }\n  }\n"])), (0, eui_1.transparentize)('white', 0.8));
var UIProvider = function (_a) {
    var children = _a.children;
    return ((0, jsx_runtime_1.jsxs)(eui_1.EuiProvider, __assign({ modify: overrides_1.overrides }, { children: [(0, jsx_runtime_1.jsx)(react_1.Global, { styles: customGlobalStyles }), (0, jsx_runtime_1.jsx)(Toast_context_1.ToastProvider, { children: children })] })));
};
exports.UIProvider = UIProvider;
var templateObject_1;

import styled from '@emotion/styled';
import { isZone } from '../utils';
import { getEdits, getProvenance, ICell } from './Cell';

interface ISpanProps {
  active: boolean;
}

export enum ARCHIPELAGO_ZONE_ID {
  ARCHIPELAGO_FLOOD_ZONE = 'archipelagoFloodZone',
  ARCHIPELAGO_SEISMIC_ZONE = 'archipelagoSeismicZone',
  ARCHIPELAGO_WIND_ZONE = 'archipelagoWindZone',
}

const ARCHIPELAGO_ZONE_IDS: string[] = [
  ARCHIPELAGO_ZONE_ID.ARCHIPELAGO_SEISMIC_ZONE,
  ARCHIPELAGO_ZONE_ID.ARCHIPELAGO_WIND_ZONE,
];

const Span = styled.span<ISpanProps>`
  ${({ active }) =>
    active
      ? ''
      : `
    color: #828282;
  `}
`;

const getDefaultValue = (id: string) => {
  if (id === 'floodZone' || id === ARCHIPELAGO_ZONE_ID.ARCHIPELAGO_FLOOD_ZONE) {
    return '-';
  }

  if (['seismicZone', 'windZone', ...ARCHIPELAGO_ZONE_IDS].includes(id)) {
    return 'Out of Zone';
  }

  return 'No';
};

const getActive = (id: string, hazardValue: string | undefined) => {
  if (id === 'floodZone' || ARCHIPELAGO_ZONE_IDS.includes(id)) {
    return !!hazardValue;
  }

  return isZone(hazardValue);
};

const HazardText = (props: ICell) => {
  const id = props?.column.id as string;
  const active = getActive(id, props?.prop?.[id]);
  const value = active ? props?.prop?.[id] : getDefaultValue(id);
  const provenance = getProvenance(props.prop, id);

  const tooltip = {
    description: undefined,
    edits: getEdits(props.prop, id),
    provenance,
    title: undefined,
  };

  const body = <Span active={active}>{value}</Span>;

  return { body, tooltip };
};

export default HazardText;

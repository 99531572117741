import { FC } from 'react';
import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import { formatCurrency as _formatCurrency, Val } from '@app/utils/format';

interface IProps {
  value: Val;
  currency?: string | null;
  displayPositiveSign?: boolean;
}

const Currency: FC<IProps> = ({ value, currency = 'USD', displayPositiveSign }) => {
  const { formatStreamCurrency } = useStreamContext();
  const formatCurrency = formatStreamCurrency || _formatCurrency;

  if (value === null) {
    return <span>-</span>;
  }

  return (
    <span
      data-testid="currency"
      title={formatCurrency(value, {
        currency: currency || 'USD',
        displayWithoutUnits: true,
      })}
    >
      {formatCurrency(value, { currency: currency || 'USD', displayPositiveSign })}
    </span>
  );
};

export default Currency;

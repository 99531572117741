import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { IGraphQLChart, IGraphQLRawJSON } from '@app/queries/explorers/types';
import { Explorer } from '../Explorer.emotion';
import { NoChartContainer } from './AalScatter';

export const formatDataQualityArrayByKey = (payload: Array<any> = []) =>
  payload.reduce((accumulator, current) => {
    accumulator[current.query_name] = current;

    return accumulator;
  }, {});

const getOptions = (data: any) => ({
  chart: {
    height: 370,
    style: {
      marginLeft: '0 auto',
    },
    type: 'column',
    width: 600,
  },
  colorAxis: {
    maxColor: '#2F80ED',
    min: 0,
    minColor: '#E9F4FB',
  },
  colors: ['#14b2e2', '#01c18d', '#e54d42', '#f18600', '#ab56a3', '#f4d521', '#94a1ae'],
  credits: {
    enabled: false,
  },
  exporting: {
    chartOptions: {
      legend: {
        enabled: true,
      },
    },
  },

  legend: {
    enabled: true,
  },

  plotOptions: {
    series: {
      groupPadding: 0.2,
    } as any,
  },

  series: [
    {
      data: data.map((item: any) => item.properties),
      name: 'Properties Affected',
    },
    {
      data: data.map((item: any) => item.attributes),
      name: 'Attributes Added',
    },
  ],

  title: {
    text: null,
  },
  xAxis: {
    categories: data.map((item) => item.provenanceType),
    crosshair: true,
    title: {
      text: 'Addition Type',
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Properties Added',
    },
  },
});

interface Props {
  explorer: IGraphQLChart;
  title?: string;
}

const ProvenanceSummary: React.FC<Props> = ({ explorer }) => {
  const data = JSON.parse((explorer as IGraphQLRawJSON).json);

  if (!data) {
    return <NoChartContainer />;
  }

  return (
    <Explorer>
      <HighchartsReact highcharts={Highcharts} options={getOptions(data)} />
    </Explorer>
  );
};

export default ProvenanceSummary;

import * as React from 'react';
import { Badge, ButtonEmpty, EuiText, Spacer, useToast } from 'ui';
import { useSetAttributeMetadataAutoMutation } from '@app/graphql/mutations/attributeMetadata/__generated__/setAttributeMetadataAuto.generated';
import { ApplyMode } from '@app/graphql/types';
import AutoEnableConfirmModal from '@app/platform/AttributeMetadataEditor/AutoEnable/AutoEnableConfirmModal';
import { getErrorMessage } from '@app/utils/getErrorMessage';

interface Props {
  orgName: string;
  streamSlug: string;
}

const AutoEnableButton: React.FC<Props> = ({ orgName, streamSlug }) => {
  let applyChanges = false;
  let applyMode: ApplyMode = ApplyMode.All;
  const toast = useToast();

  const [showConfirm, setShowConfirm] = React.useState(false);
  const [setAttributeMetadataAutoMutation, { data, loading, error }] =
    useSetAttributeMetadataAutoMutation({
      onCompleted: (data) => {
        if (!applyChanges && !data?.setAttributeMetadataAuto?.updated) {
          setShowConfirm(true);
        } else {
          toast({ title: 'Stream metadata updated' });
        }
      },
      onError: (err) => {
        toast({ title: getErrorMessage(err), type: 'danger' });
      },

      variables: {
        input: {
          applyChanges: applyChanges,
          applyMode: applyMode,
          organizationName: orgName,
          streamSlug: streamSlug,
        },
      },
    });

  const handleClick = () => {
    if (applyChanges) {
      setShowConfirm(false);
    }
    setAttributeMetadataAutoMutation({
      variables: {
        input: {
          applyChanges,
          applyMode: applyMode,
          organizationName: orgName,
          streamSlug: streamSlug,
        },
      },
    });
  };

  const formatData = (): JSX.Element => (
      <>
        {data?.setAttributeMetadataAuto?.enabledAttributes?.length === 0 && (
          <EuiText>
            <strong>No attributes will be enabled.</strong>
          </EuiText>
        )}
        {data?.setAttributeMetadataAuto?.enabledAttributes?.length > 0 && (
          <>
            <EuiText>
              <strong>The following attributes will be enabled:</strong>
            </EuiText>
            {data?.setAttributeMetadataAuto?.enabledAttributes?.map((attribute) => (
              <Badge label={attribute.metadata.displayName}></Badge>
            ))}
          </>
        )}
        <Spacer size={'s'} />
        {data?.setAttributeMetadataAuto?.disabledAttributes?.length === 0 && (
          <EuiText>
            <strong>No attributes will be disabled.</strong>
          </EuiText>
        )}
        {data?.setAttributeMetadataAuto?.disabledAttributes?.length > 0 && (
          <>
            <EuiText>
              <strong>The following attributes will be disabled:</strong>
            </EuiText>
            {data?.setAttributeMetadataAuto?.disabledAttributes?.map((attribute) => (
              <Badge label={attribute.metadata.displayName}></Badge>
            ))}
          </>
        )}
      </>
    );

  return (
    <>
      {showConfirm && (
        <AutoEnableConfirmModal
          isOpen={showConfirm}
          confirmBody={formatData()}
          onSubmit={(mode: ApplyMode) => {
            applyChanges = true;
            applyMode = mode;
            handleClick();
          }}
          submitLoading={loading}
          onClose={() => {
            setShowConfirm(false);
          }}
        />
      )}
      <ButtonEmpty
        data-testid="attribute-metadata-download-button"
        label={'Enable all columns with data in SOV'}
        iconName={'edit'}
        loading={loading}
        onClick={handleClick}
      ></ButtonEmpty>
    </>
  );
};

export default AutoEnableButton;

import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface BarParams {
  color: string;
  value: number;
  opacity?: number;
}

export interface Props {
  bars: Array<BarParams>;
  withLegend: boolean;
}
const SingleBarChart: React.FC<Props> = ({ bars, withLegend }) => {
  const options: Highcharts.Options = {
    chart: {
      height: 50,
      type: 'bar',
      width: 100,
    },

    credits: {
      enabled: false,
    },

    legend: {
      enabled: false,
    },

    plotOptions: {
      bar: {
        pointWidth: 10,
      },

      series: {
        stacking: 'normal',
      },
    },

    series: bars.map((o) => ({
      color: o.color,
      data: [o.value],
      opacity: o.opacity,
      type: 'bar',
    })),

    title: {
      text: '',
    },

    tooltip: {
      enabled: withLegend,
    },

    xAxis: {
      categories: [''],

      labels: {
        enabled: false,
      },

      max: 0.1,

      title: {
        text: null,
      },
    },

    yAxis: {
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },

      min: 0,

      title: {
        text: '',
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default SingleBarChart;

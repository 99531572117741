import { FC, useEffect, useState } from 'react';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import { SnapshotOption } from '@onarchipelago/cx/Reports/contexts/reportsContext/types';
import { EuiFlexGroup, EuiFlexItem, Select } from 'ui';
import { FiltersText } from './Filters.emotion';

const Filters: FC = () => {
  const {
    afterSnapshot,
    afterSnapshotOptions,
    currentSnapshot,
    loading,
    setAfterSnapshot,
    setCurrentSnapshot,
    snapshotOptions,
  } = useReportsContext().snapshots;

  const [finalBeforeSnapshotOptions, setFinalBeforeSnapshotOptions] = useState<SnapshotOption[]>();
  const [finalAfterSnapshotOptions, setFinalAfterSnapshotOptions] = useState<SnapshotOption[]>();

  useEffect(() => {
    const beforeOptions = snapshotOptions.filter(
      (snapshotOption) => snapshotOption.value !== afterSnapshot?.snapshotName,
    );
    const afterOptions = afterSnapshotOptions.filter(
      (snapshotOption) => snapshotOption.value !== currentSnapshot?.snapshotName,
    );

    setFinalAfterSnapshotOptions(afterOptions);
    setFinalBeforeSnapshotOptions(beforeOptions);
  }, [afterSnapshot, afterSnapshotOptions, currentSnapshot, snapshotOptions]);

  return (
    <EuiFlexGroup responsive={false} gutterSize="s">
      <EuiFlexItem grow={false}>
        <FiltersText>Compare before:</FiltersText>
      </EuiFlexItem>
      <EuiFlexItem grow={false} style={{ width: '160px' }}>
        <Select
          loading={loading}
          onChange={(value) => setCurrentSnapshot(value)}
          options={finalBeforeSnapshotOptions || []}
          value={currentSnapshot?.snapshotName || ''}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <FiltersText>and after:</FiltersText>
      </EuiFlexItem>
      <EuiFlexItem grow={false} style={{ width: '160px' }}>
        <Select
          loading={loading}
          onChange={(value) => setAfterSnapshot(value)}
          options={finalAfterSnapshotOptions || []}
          value={afterSnapshot?.snapshotName || ''}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Filters;

import { useEffect, useState } from 'react';
import * as React from 'react';
import keyBy from 'lodash/keyBy';
import moment from 'moment';
import {
  CallOut,
  Checkbox,
  EuiAccordion,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiRadioGroup,
  EuiTextArea,
  FilePicker,
  IntegerField,
  Select,
} from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { getIndustryEnumData } from '@app/cx/utils/utils';
import { IGraphQLStream } from '@app/queries/streams/types';
import { dateField, getFormLabel, selectField, textField } from './FormFields';
import { TeamMembersDropdown } from './TeamMembersDropdown';
import { FormValues, RegionTeamsObject, SubmissionAttributeMetadata } from './types';
import UnderwriterDropdown from './UnderwriterDropdown';
import { getBusinessTypes, isFieldInvalid, touch } from './utils';
import { SubmissionFormMeta, validate, Validator } from './validator';

const reasonsForAdjustment = [
  'SOV complexity',
  'Internal - Bug',
  'Internal - Capacity',
  'Internal - QA',
  'External - Query for Underwriter',
  'External - Reprioritization Request',
];
const dateFields = ['effectiveDate', 'expiryDate', 'quoteDueDate'];
const selectFields = ['submissionType', 'accountOrgType', 'accountStatus', 'gusl'];
const comboFields = ['underwriter', 'underwriterTechnician', 'teamMemberEmails'];

const declinedStatus = 'Initial Triage - Declined';

const adminFields = [
  'sla',
  'adjustedSLA',
  'reasonForAdjustment',
  'internalNotes',
  'qaIssuesExternalCount',
];

interface Props {
  formData: Array<SubmissionAttributeMetadata>;
  values: FormValues;
  setValues: (v: FormValues) => void;
  meta: SubmissionFormMeta;
  setMeta: (m: SubmissionFormMeta) => void;
  saving: boolean;
  streams?: Array<IGraphQLStream>;
  validator: Validator;
  regionTeamsData?: RegionTeamsObject[];
}

const CondensedSubmissionForm: React.FC<Props> = ({
  formData = [],
  values,
  setValues,
  meta,
  setMeta,
  saving,
  validator,
  regionTeamsData = [],
}) => {
  const checkFields = ['uwFeedbackRequired'];
  const dynamicFields = new Set(formData?.map((d) => d?.name));
  const attributeMeta = keyBy(formData, 'name');
  const statusField = formData.find((f) => f.name === 'accountStatus');
  const accountOrgTypeField = formData.find((f) => f.name === 'accountOrgType');
  const nameToValue = (field: string, e: any) => {
    if (
      [
        'underwriter',
        'customerStreamSlug',
        'documents',
        ...dateFields,
        ...selectFields,
        ...comboFields,
      ].includes(field)
    ) {
      return e;
    }
    if (['region', 'team'].includes(field)) {
      return e?.target?.value;
    }

    if (checkFields.includes(field)) {
      return e.target.checked;
    }
    return e?.target?.value;
  };

  const [orgEnumData, setOrgEnumData] = useState([]);
  const fetchData = () => getIndustryEnumData().then((data) => setOrgEnumData(data));

  useEffect(() => {
    fetchData();
  }, []);

  const regionOptions =
    regionTeamsData.length > 0 &&
    regionTeamsData?.map((i) => ({
      label: i.name,
      value: i.name,
    }));

  const selectedRegion = regionTeamsData?.findIndex((r) => r.name === values.region);

  const teamOptions =
    regionTeamsData.length > 0 &&
    regionTeamsData[selectedRegion]?.teams?.map((i) => ({
      label: i,
      value: i,
    }));

  useEffect(() => {
    if (values.effectiveDate) {
      onValueChange('expiryDate')(moment(values.effectiveDate).add(1, 'year'));
    }
  }, [values.effectiveDate]);

  // value update utils
  const setFieldError = (fieldName: string, error: string, forceTouch = false) =>
    setMeta({
      ...meta,
      [fieldName]: {
        ...meta[fieldName],
        error,
        touched: forceTouch ? true : meta[fieldName]?.touched,
      },
    });

  const onNumberChange = (field: string) => (value: number) => {
    const newValues = { ...values, [field]: value };
    setValues(newValues);

    const v = validate(validator, field, value);
    setFieldError(field, v);
  };

  const onValueChange = (field: string) => (e: any) => {
    const newValue = nameToValue(field, e);
    const newValues = { ...values, [field]: newValue };
    setValues(newValues);

    const v = validate(validator, field, newValue);
    setFieldError(field, v);
  };

  const onCreateOption = (field: string, multiple: boolean) => (searchValue: string) => {
    const finalSearchValue = searchValue.trim();

    if (!finalSearchValue) {
      return;
    }

    const newOption = {
      id: finalSearchValue,
      label: finalSearchValue,
    };

    onValueChange(field)(multiple ? [...(values?.[field] || []), newOption] : [newOption]);
  };

  const businessTypes = getBusinessTypes(values.industry, orgEnumData);
  if (formData.length < 1) {
    return <LoadingSpinnerV2 />;
  }

  return (
    <EuiForm>
      {!!accountOrgTypeField && (
        <EuiFormRow label={getFormLabel(accountOrgTypeField.displayName, false)}>
          <Select
            options={accountOrgTypeField.enumValues.map(({ value, displayName }) => ({
              label: displayName,
              value,
            }))}
            value={values.accountOrgType}
            onChange={onValueChange('accountOrgType')}
            disabled={saving}
          />
        </EuiFormRow>
      )}
      {textField(
        'name',
        'Account Name',
        meta,
        attributeMeta,
        setMeta,
        values,
        onValueChange,
        saving,
        true,
      )}
      {selectField(
        'industry',
        'Industry',
        orgEnumData?.map((key: any) => ({
          label: key.value,
          value: key.value,
        })),
        'submission-select-industry',
        meta,
        attributeMeta,
        values,
        onValueChange,
        isFieldInvalid,
        true,
      )}
      {selectField(
        'businessType',
        'Business Type',
        businessTypes?.map((bizType: string) => ({
          label: bizType,
          value: bizType,
        })),
        'submission-select-business-type',
        meta,
        attributeMeta,
        values,
        onValueChange,
        isFieldInvalid,
        true,
      )}
      {dateField(
        'effectiveDate',
        'Renewal Effective Date',
        values,
        onValueChange,
        saving,
        attributeMeta,
      )}
      {dateField('expiryDate', 'Expiration Date', values, onValueChange, saving, attributeMeta)}
      <EuiFormRow>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiAccordion
              id="additionalSettings"
              buttonContent="Show Additional Fields"
              paddingSize="m"
            >
              {dynamicFields.has('broker') &&
                textField(
                  'broker',
                  'Broker Company',
                  meta,
                  attributeMeta,
                  setMeta,
                  values,
                  onValueChange,
                  saving,
                  false,
                )}
              {dynamicFields.has('brokerEmail') &&
                textField(
                  'brokerEmail',
                  'Primary Producer or Broker Email',
                  meta,
                  attributeMeta,
                  setMeta,
                  values,
                  onValueChange,
                  saving,
                  false,
                )}
              {dynamicFields.has('region') &&
                regionTeamsData.length > 0 &&
                regionOptions &&
                selectField(
                  'region',
                  'Region',
                  regionOptions,
                  'submission-select-region',
                  meta,
                  attributeMeta,
                  values,
                  onValueChange,
                  isFieldInvalid,
                  false,
                )}
              {dynamicFields.has('team') &&
                regionTeamsData.length > 0 &&
                teamOptions &&
                selectField(
                  'team',
                  'Team',
                  teamOptions,
                  'submission-select-team',
                  meta,
                  attributeMeta,
                  values,
                  onValueChange,
                  isFieldInvalid,
                  false,
                )}
              {dynamicFields.has('underwriter') && (
                <UnderwriterDropdown
                  label="Lead Underwriter"
                  onCreateOption={onCreateOption('underwriter', false)}
                  onValueChange={onValueChange('underwriter')}
                  selectedOptions={values.underwriter}
                  meta={meta.underwriter}
                  touch={touch('underwriter', meta, setMeta)}
                  required={false}
                />
              )}
              {dynamicFields.has('underwriterTechnician') && (
                <UnderwriterDropdown
                  label="Underwriting Technician"
                  onCreateOption={onCreateOption('underwriterTechnician', false)}
                  onValueChange={onValueChange('underwriterTechnician')}
                  selectedOptions={values.underwriterTechnician}
                  meta={meta.underwriterTechnician}
                  touch={touch('underwriterTechnician', meta, setMeta)}
                  required={false}
                />
              )}
              {dynamicFields.has('teamMemberEmails') && (
                <TeamMembersDropdown
                  label="Team Member Emails"
                  onCreateOption={onCreateOption('teamMemberEmails', true)}
                  setSelectedOptions={onValueChange('teamMemberEmails')}
                  selectedValues={values.teamMemberEmails}
                  meta={meta.teamMemberEmails}
                  touch={touch('teamMemberEmails', meta, setMeta)}
                  // Rationale: https://app.shortcut.com/onarchipelago/story/160369/bug-account-fields-are-not-editable-in-edit-flyout
                  // Fix: https://app.shortcut.com/onarchipelago/story/157572/team-member-emails-should-be-editable
                  required={false}
                />
              )}
              {dynamicFields.has('clientId') &&
                textField(
                  'clientId',
                  'Internal Client ID',
                  meta,
                  attributeMeta,
                  setMeta,
                  values,
                  onValueChange,
                  saving,
                  false,
                )}
              {dynamicFields.has('insuranceRequestType') && (
                <EuiFormRow label={getFormLabel('GU / S&L', false)}>
                  <EuiRadioGroup
                    options={[
                      { id: 'Ground Up', label: 'Ground Up' },
                      { id: 'Shared & Layered', label: 'Shared & Layered' },
                    ]}
                    idSelected={values.gusl}
                    disabled={saving}
                    onChange={onValueChange('gusl')}
                    name="GU / S&L"
                  />
                </EuiFormRow>
              )}
              {dynamicFields.has('quoteDueDate') &&
                dateField(
                  'quoteDueDate',
                  'Quote Date',
                  values,
                  onValueChange,
                  saving,
                  attributeMeta,
                  false,
                )}
              {dynamicFields.has('comment') && (
                <EuiFormRow label="Comments">
                  <EuiTextArea value={values.comment} onChange={onValueChange('comment')} />
                </EuiFormRow>
              )}
              {adminFields.some((field) => dynamicFields.has(field)) && (
                <CallOut title="Archipelago Admin Settings" type="warning">
                  {dynamicFields.has('sla') && (
                    <EuiFormRow label={getFormLabel('Original SLA (Number of Days)', false)}>
                      <IntegerField
                        data-testid="sla-days-input"
                        value={values.sla}
                        onChange={onNumberChange('sla')}
                      />
                    </EuiFormRow>
                  )}
                  {dynamicFields.has('adjustedSLA') && (
                    <EuiFormRow label={getFormLabel('Adjusted SLA', false)}>
                      <IntegerField
                        data-testid="adjusted-sla-input"
                        value={values.adjustedSLA}
                        onChange={onNumberChange('adjustedSLA')}
                      />
                    </EuiFormRow>
                  )}
                  {dynamicFields.has('reasonForAdjustment') &&
                    selectField(
                      'reasonForAdjustment',
                      'Reason for adjustment',
                      reasonsForAdjustment.map((v) => ({ label: v, text: v, value: v })),
                      'submission-select-reason-for-adjustment',
                      meta,
                      attributeMeta,
                      values,
                      onValueChange,
                      isFieldInvalid,
                      false,
                    )}
                  {dynamicFields.has('qaIssuesExternalCount') && (
                    <EuiFormRow label={getFormLabel('Number of External QA Issues', false)}>
                      <IntegerField
                        data-testid="number-qaissues-input"
                        value={values.qaIssuesExternalCount}
                        onChange={onNumberChange('qaIssuesExternalCount')}
                      />
                    </EuiFormRow>
                  )}
                  {dynamicFields.has('internalNotes') && (
                    <EuiFormRow
                      label={getFormLabel(attributeMeta.internalNotes?.displayName, false)}
                    >
                      <EuiTextArea
                        data-testid="internal-notes-input"
                        value={values.internalNotes}
                        onChange={onValueChange('internalNotes')}
                      />
                    </EuiFormRow>
                  )}
                </CallOut>
              )}
              {dynamicFields.has('assumptions') && (
                <EuiFormRow label={getFormLabel('Assumptions', false)}>
                  <EuiTextArea
                    data-testid="assumptions-input"
                    value={values.assumptions}
                    onChange={onValueChange('assumptions')}
                  />
                </EuiFormRow>
              )}
              {dynamicFields.has('accountStatus') &&
                statusField?.enumValues.some((f) => f.value === 'InitialTriageDeclined') && (
                  <EuiFormRow>
                    <Checkbox
                      id="initial-triage-declined"
                      label="Initial Triage - Declined"
                      checked={values.accountStatus === declinedStatus}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          accountStatus: e.target.checked ? declinedStatus : null,
                        })
                      }
                    />
                  </EuiFormRow>
                )}
              {dynamicFields.has('uwFeedbackRequired') && (
                <EuiFormRow>
                  <Checkbox
                    id="uw-feedback-required"
                    label="UW feedback required"
                    checked={!!values.uwFeedbackRequired}
                    onChange={(e) => setValues({ ...values, uwFeedbackRequired: e.target.checked })}
                  />
                </EuiFormRow>
              )}
              {dynamicFields.has('archipelagoTransformations') && (
                <EuiFormRow>
                  <Checkbox
                    id="archipelago-transformations"
                    label="Archipelago Transformations"
                    checked={!!values.archipelagoTransformations}
                    onChange={(e) =>
                      setValues({ ...values, archipelagoTransformations: e.target.checked })
                    }
                  />
                </EuiFormRow>
              )}
            </EuiAccordion>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      <EuiFormRow
        label={getFormLabel('Files', true)}
        isInvalid={isFieldInvalid('documents', meta)}
        error={meta.documents?.error}
      >
        <FilePicker
          prompt="Drag and drop your files, including SOVs and other documents. Additional documents can be uploaded later"
          data-testid="submission-file-picker"
          files={values?.documents || []}
          multiple
          onChange={(files: Array<File>) => {
            const nonZeroFiles = files.filter((f) => f.size > 0);
            if (!nonZeroFiles || nonZeroFiles.length === 0) {
              onValueChange('documents')(undefined);
              return;
            }
            onValueChange('documents')(nonZeroFiles);
          }}
        />
      </EuiFormRow>
    </EuiForm>
  );
};

export default CondensedSubmissionForm;

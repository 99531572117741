import { memo } from 'react';
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { IGraphQLChart, IGraphQLRawJSON } from '@app/queries/explorers/types';
import { Explorer } from '../Explorer.emotion';
import { chartDataIsEqual } from './utils';

interface IProps {
  explorer: IGraphQLChart;
  title?: string;
}

export const formatDataQualityArrayByKey = (payload: Array<any> = []) =>
  payload.reduce((accumulator, current) => {
    accumulator[current.query_name] = current;

    return accumulator;
  }, {});

const getOptions = (queryData: any, title?: string) => ({
  chart: {
    height: 285,
    style: {
      margin: '0 auto',
    },
    width: 265,
  },
  colorAxis: {
    maxColor: '#2F80ED',
    min: 0,
    minColor: '#E9F4FB',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    chartOptions: {
      legend: {
        enabled: true,
      },
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      pointWidth: 10,
    },
    series: {
      groupPadding: 8,
      pointPadding: 0,
    } as any,
  },
  series: [
    {
      borderWidth: 0,
      color: '#2D9CDB',
      data: [
        [
          'At least 1<br/>document',
          queryData.quality_documents_at_least_one.data[0]?.at_least_one_doc_pct,
        ],
        ['PCA', queryData.quality_documents_by_type.data[0].pca_pct],
        ['Design Docs', queryData.quality_documents_by_type.data[0].design_pct],
        ['Roof<br/>Inspection/<br/>Warranty', queryData.quality_documents_by_type.data[0].roof_pct],
        ['Seismic<br/>Inspection', queryData.quality_documents_by_type.data[0].seismic_pct],
      ],
      name: '% of TIV',
      type: 'bar',
    } as Highcharts.SeriesBarOptions,
  ],
  title: {
    text: title ? title : null,
  },

  tooltip: {
    formatter() {
      //eslint-disable-line
      return `<b>% of TIV: </b>${this.y}%`;
    },
  },
  xAxis: {
    type: 'category' as Highcharts.AxisTypeValue,
  },
  yAxis: {
    endOnTick: true,
    labels: {
      step: 1,
      style: {
        color: '#828282',
        fontSize: '10px',
        fontWeight: 'bold',
        margin: '0px',
        opacity: '1 !important' as any,
        padding: '0px',
        whiteSpace: 'nowrap',
      },
    },
    max: 100,
    min: 0,
    showLastLabel: true,
    tickPositions: [0, 20, 40, 60, 80, 100],
    title: {
      align: 'high' as any,
      style: {
        color: '#DBDBDB',
        fontSize: '10px',
        fontWeight: 'bold',
      },
      text: '% of TIV',
    },
  },
});

const QualityDocuments: React.FC<IProps> = ({ explorer, title }) => {
  const data = JSON.parse((explorer as IGraphQLRawJSON).json);
  const queryData = formatDataQualityArrayByKey(data);

  return (
    <Explorer>
      <HighchartsReact highcharts={Highcharts} options={getOptions(queryData, title)} />
    </Explorer>
  );
};

export default memo(QualityDocuments, chartDataIsEqual);

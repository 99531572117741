import styled from '@emotion/styled';
import { formatNumber, getNumberFormatOptions } from '../../../utils/format';
import { ColumnFormattingOptions } from '../types';
import { getEdits, getProvenance, ICell } from './Cell';

const FormattedNumber = styled.div(
  (props: ColumnFormattingOptions) => `
  label: Number;
  text-align: ${props.textAlign || 'right'};
  font-variant-numeric: tabular-nums;
`,
);

const Number = (props: ICell, format: boolean = true) => {
  const id = props.column && (props.column.id as string);

  const value = props.value as number;

  const decimals = props.column.formattingOptions?.decimals;
  const formatted = format
    ? formatNumber(value, undefined, getNumberFormatOptions(decimals))
    : value;
  const provenance = getProvenance(props.prop, id);

  const tooltip = {
    description: undefined,
    edits: getEdits(props.prop, id),
    provenance,
    title: undefined,
  };

  const body = (
    <FormattedNumber textAlign={props.column.formattingOptions.textAlign}>
      <span>{formatted}</span>
    </FormattedNumber>
  );

  return { body, tooltip };
};

export const RawNumber = (props: ICell) => Number(props, false);

export default Number;

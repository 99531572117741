import { ChangeEvent, FC, useRef } from 'react';
import { Button } from 'ui';
import { IAccountState } from '@app/containers/AuthProvider/types';
import styles from './UploadButton.emotion';

interface Props {
  account: IAccountState;
  disabled: boolean;
  upload: (file: File) => Promise<void>;
  uploading: boolean;
  marketVisibleToggle?: boolean;
}

const UploadButton: FC<Props> = ({
  account,
  disabled,
  upload,
  uploading,
  marketVisibleToggle = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      upload(e.target.files[0]);
    }
  };

  const handleSelect = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  if (account.permissions.admin && marketVisibleToggle) {
    return null;
  }

  if (!account.permissions.admin) {
    return null;
  }

  return (
    <div data-testid="documents-modal-upload-button" className={styles.container}>
      <input ref={inputRef} onChange={onFileSelect} type="file" />
      <Button
        disabled={disabled || inputRef.current === null || uploading}
        label="Upload"
        loading={uploading}
        onClick={handleSelect}
      />
    </div>
  );
};

export default UploadButton;

import { useState } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router';
import { useDebounce } from 'react-use';
import moment from 'moment';
import queryString from 'query-string';
import {
  Badge,
  Criteria,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  Spacer,
  Spinner,
} from 'ui';
import { useGetSupportTicketsPageQuery } from '@app/graphql/queries/support/__generated__/getSupportTicketsPage.generated';
import {
  BinaryOperator,
  OrgInfo,
  SortOrder,
  SupportTicket,
  TicketStatus,
} from '@app/graphql/types';
import { useQueryState } from '@app/hooks/useQueryState';
import { useTracker } from '@app/hooks/useTracker';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { useChatContext } from '../Chat/ChatProvider';
import EmptyMessage from '../EmptyMessage/EmptyMessage';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import TicketFlyout from './SupportTicket/TicketFlyout';

interface Props {
  includeClosedTickets?: boolean;
}

export const TicketsTable: React.FC<Props> = ({ includeClosedTickets }) => {
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  // eslint-disable-next-line
    const [_qs, setQueryState] = useQueryState();

  const location = useLocation();
  const ticketId: string = (queryString.parse(location.search)?.ticketId as string) || '';
  const tracker = useTracker();

  const [input, setInput] = useState('');
  const [tickets, setTickets] = useState([]);

  const { data, loading, error, refetch } = useGetSupportTicketsPageQuery({
    onCompleted: (data) => {
      onFilterData(data?.supportTicketsPage?.tickets || []);
    },
    variables: {
      input: {
        cursor: '',
        filter: [
          {
            name: 'customFields.status',
            operator: includeClosedTickets ? BinaryOperator.In : BinaryOperator.NotIn,
            values: [TicketStatus.Closed, TicketStatus.Resolved],
          },
        ],
        includeClosedTickets: null,
        sort: [{ attributeName: 'ts', order: SortOrder.Descending }],
      },
    },
  });

  const { roomSubscriptionsMap } = useChatContext();

  const columns: Array<EuiBasicTableColumn<SupportTicket>> = [
    {
      field: 'title',
      name: 'Title',
      render: (title: string, ticket) => {
        const subscription = roomSubscriptionsMap?.[ticket.chatRoomID];
        return (
          <EuiFlexGroup alignItems="center" justifyContent="flexStart">
            <EuiFlexItem>{title}</EuiFlexItem>
            <EuiFlexItem grow={false}>
              <Badge
                color="primary"
                label={`${subscription?.unread}`}
                hidden={!subscription?.unread}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      },
    },
    {
      field: 'org',
      name: 'Account',
      render: (org: OrgInfo) => org?.name || 'None',
    },
    {
      field: 'authorEmail',
      name: 'Author',
    },
    {
      field: 'assigneeEmails',
      name: 'Assigned to',
      render: (v) => v?.join(', ') || 'No one',
    },
    {
      field: 'status',
      name: 'Status',
    },
    {
      field: 'createdAt',
      name: 'Created on',
      render: (v) => moment(v).format('YYYY-MM-DD HH:MM'),
    },
  ];

  // Manually handle pagination of data
  const findTickets = (items: Array<SupportTicket>, pageIndex: number, pageSize: number) => {
    let pageOfItems;

    if (!pageIndex && !pageSize) {
      pageOfItems = items;
    } else {
      const startIndex = pageIndex * pageSize;
      pageOfItems = items.slice(startIndex, Math.min(startIndex + pageSize, items.length));
    }

    return {
      pageOfItems,
      totalItemCount: items.length,
    };
  };

  const { pageOfItems, totalItemCount } = findTickets(
    // FIX ME
    // @ts-ignore
    tickets || [],
    pageIndex,
    pageSize,
  );

  const onTableChange = ({ page }: Criteria<SupportTicket>) => {
    if (page) {
      const { index: pageIndex, size: pageSize } = page;
      setPageIndex(pageIndex);
      setPageSize(pageSize);
    }
  };

  const onFilterData = (items) => {
    const filteredTickets = items.filter((ticket) =>
      [ticket.title, ticket?.org?.name || ''].join('').toLowerCase().includes(input.toLowerCase()),
    );
    setTickets(filteredTickets);
  };

  const [,] = useDebounce(() => onFilterData(data?.supportTicketsPage?.tickets || []), 250, [
    input,
    data,
  ]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorMessage message={getErrorMessage(error)} />;
  }

  if (!data?.supportTicketsPage?.tickets?.length) {
    return (
      <EmptyMessage
        header="No tickets found"
        subText='Click on "Open Ticket" button to get started'
      />
    );
  }

  return (
    <>
      <EuiFieldSearch
        data-testid="support-search"
        placeholder="Search tickets (Title, Account)"
        value={input}
        onChange={(e) => setInput(e.target.value || '')}
        isClearable
      />
      <Spacer />
      <EuiBasicTable
        style={{ overflowY: 'auto', paddingBottom: '48px' }}
        columns={columns}
        pagination={{
          pageIndex,
          pageSize,
          totalItemCount,
        }}
        loading={loading}
        onChange={onTableChange}
        items={pageOfItems}
        rowProps={(row) => ({
          onClick: () => {
            tracker.track('Support Page: Ticket Clicked', { chatRoomID: row.chatRoomID });
            setQueryState({ ticketId: row.chatRoomID });
          },
          style: {
            fontWeight: roomSubscriptionsMap?.[row.chatRoomID]?.unread ? 'bold' : 'unset',
          },
        })}
      />
      {!!ticketId && (
        <TicketFlyout
          refetch={refetch}
          ticketId={ticketId}
          onClose={() => setQueryState({ ticketId: '' })}
        />
      )}
    </>
  );
};

import * as React from 'react';
import { Checkbox, EuiFlexItem, EuiFormRow, EuiText, EuiTextArea, Select,TextField } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { GroupedMetadata, HandleChange, SelectOption } from '../types';
import styles from './EditAttributeModal.emotion';

interface Props {
  attributeValue: GroupedMetadata;
  handleChange: HandleChange;
}

const EditAttributeModalStandard: React.FC<Props> = ({ attributeValue, handleChange }) => (
  <>
    <EuiFlexItem data-testid="edit-attribute-modal-standard-title" grow={false}>
      <EuiText className={styles.sectionHeader}>
        <h4>Standard</h4>
      </EuiText>
    </EuiFlexItem>
    <EuiFlexItem data-testid="edit-attribute-modal-standard-include-in-streams" grow={false}>
      <EuiFormRow label={`Include in ${STREAMS_LABEL}`}>
        <Checkbox
          id="include-in-streams"
          checked={!!attributeValue.includeInStreams}
          onChange={(event) => handleChange('includeInStreams', event.target.checked)}
        />
      </EuiFormRow>
    </EuiFlexItem>
    <EuiFlexItem data-testid="edit-attribute-modal-standard-display-name" grow={false}>
      <EuiFormRow label="Display Name">
        <TextField
          fullWidth
          onChange={(event) => handleChange('displayName', event.target.value)}
          value={attributeValue.displayName}
        />
      </EuiFormRow>
    </EuiFlexItem>
    <EuiFlexItem data-testid="edit-attribute-modal-standard-property-source-text" grow={false}>
      <EuiFormRow label="Property Source Text">
        <EuiTextArea
          fullWidth
          onChange={(event) => handleChange('source', event.target.value)}
          resize="none"
          value={attributeValue.source}
        />
      </EuiFormRow>
    </EuiFlexItem>
    <DataTypeSelect attributeValue={attributeValue} handleChange={handleChange} />
  </>
);

const DataTypeSelect: React.FC<{
  attributeValue: GroupedMetadata;
  handleChange: HandleChange;
}> = ({ attributeValue, handleChange }) => {
  const editableDataType =
    attributeValue?.name?.startsWith('ownerText') ||
    attributeValue?.name?.startsWith('ownerDecimal');

  if (!editableDataType) {
    return <></>;
  }

  let options: Array<SelectOption> = [];

  if (attributeValue?.name?.startsWith('ownerText')) {
    options = [
      {
        label: 'Default',
        value: 'default',
      },
      {
        label: 'Date',
        value: 'date',
      },
      {
        label: 'Description',
        value: 'description',
      },
      {
        label: 'Two Lines',
        value: 'twoLines',
      },
    ];
  } else if (attributeValue?.name?.startsWith('ownerDecimal')) {
    options = [
      {
        label: 'Default',
        value: 'default',
      },
      {
        label: 'Boolean',
        value: 'boolean',
      },
      {
        label: 'Currency',
        value: 'currency',
      },
      {
        label: 'Currency (Local)',
        value: 'currencyLocal',
      },
      {
        label: 'Integer',
        value: 'integer',
      },
      {
        label: 'Measurement Area',
        value: 'measurementArea',
      },
      {
        label: 'Measurement Distance (Small)',
        value: 'measurementDistanceSmall',
      },
      {
        label: 'Measurement Distance (Large)',
        value: 'measurementDistanceLarge',
      },
      {
        label: 'Number',
        value: 'number',
      },
      {
        label: 'Percentage',
        value: 'percentage',
      },
      {
        label: 'Year',
        value: 'year',
      },
    ];
  }

  return (
    <EuiFlexItem data-testid="edit-attribute-modal-grid-column-datatype" grow={false}>
      <EuiFormRow label="Data type">
        <Select
          fullWidth
          options={options}
          onClear={() => handleChange('dataType', null)}
          onChange={(value) => handleChange('dataType', value)}
          value={attributeValue.dataType}
        />
      </EuiFormRow>
    </EuiFlexItem>
  );
};

export default EditAttributeModalStandard;

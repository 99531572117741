"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var Construction = function (_a) {
    var dataTestId = _a["data-testid"], _b = _a.color, color = _b === void 0 ? 'black' : _b, size = _a.size;
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ "data-testid": dataTestId, width: size, height: size, viewBox: "0 0 24 24", fill: "none" }, { children: [(0, jsx_runtime_1.jsx)("rect", { x: "1", y: "1", width: "22", height: "22", rx: "3", stroke: color, strokeWidth: "2" }), (0, jsx_runtime_1.jsx)("path", { d: "M1 8H23", stroke: color, strokeWidth: "2" }), (0, jsx_runtime_1.jsx)("path", { d: "M1 15H23", stroke: color, strokeWidth: "2" }), (0, jsx_runtime_1.jsx)("path", { d: "M12 1V8", stroke: color, strokeWidth: "2" }), (0, jsx_runtime_1.jsx)("path", { d: "M12 15V22", stroke: color, strokeWidth: "2" }), (0, jsx_runtime_1.jsx)("path", { d: "M7 7V14", stroke: color, strokeWidth: "2" }), (0, jsx_runtime_1.jsx)("path", { d: "M17 7V14", stroke: color, strokeWidth: "2" })] })));
};
exports.default = Construction;

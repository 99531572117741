import { FC, useContext } from 'react';
import UploadPortalContent from '@onarchipelago/cx/UploadPortal/UploadPortalContent';
import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiFocusTrap,
  EuiTitle,
  Spacer,
  useEuiTheme,
} from 'ui';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { UploadPortalFlyout } from '@app/cx/UploadPortal/UploadPortal.emotion';

interface IProps {
  organizationName?: string;
  organizationId?: string;
  submissionName?: string;
  submissionId?: string;
  onlyShowNew?: boolean;
}

const UploadFlyout: FC<IProps> = ({
  organizationName,
  organizationId,
  submissionName,
  submissionId,
  onlyShowNew = false,
}) => {
  const { euiTheme } = useEuiTheme();
  const { animation } = euiTheme;

  const { closeFlyout } = useContext(FlyoutContext);

  let title = 'Upload Documents';
  if (organizationName) {
    title = `Upload Documents to ${organizationName}`;
  } else if (submissionName) {
    title = `Upload Documents - ${submissionName}`;
  }

  return (
    <EuiFocusTrap>
      <UploadPortalFlyout
        animationSpeed={animation.fast}
        animationSpeedResistance={animation.resistance}
        ownFocus
        onClose={closeFlyout}
        aria-labelledby="flyoutTitle"
        size="l"
      >
        <EuiFlyoutHeader hasBorder>
          <EuiTitle size="m">
            <h2>{title}</h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <Spacer size="xs" />
        <EuiFlyoutBody>
          <UploadPortalContent
            organizationName={organizationName}
            organizationId={organizationId}
            submissionId={submissionId}
            onlyShowNew={onlyShowNew}
            isFlyout
            eventPrefix="Upload Flyout"
          />
        </EuiFlyoutBody>
      </UploadPortalFlyout>
    </EuiFocusTrap>
  );
};
export default UploadFlyout;

import { FC, ReactElement } from 'react';
import TrackerContext, { value } from './TrackerContext';

interface IProps {
  children: ReactElement;
}

const TrackerWrapper: FC<IProps> = ({ children }) => (
  <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>
);

export default TrackerWrapper;

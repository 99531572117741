import { memo } from 'react';
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { IGraphQLChart, IGraphQLTIVChart, IGraphQLTIVCharts } from '@app/queries/explorers/types';
import { formatGeoCode } from '../../../../../components/PropertiesGrid/GridCell/Geocode';
import { Explorer } from '../Explorer.emotion';
import { buildTIVChartsData, chartDataIsEqual } from './utils';

interface IProps {
  explorer: IGraphQLChart;
  title?: string;
}

const getGeocodeColor = (name: string) => {
  const colors = {
    Address: '#27AE60',
    County: '#BAEFCD',
    'Postal Code': '#6FCF97',
    'Roof Centroid': '#219653',
    State: '#E0F5E9',
    Street: '#37D279',
  };

  return colors[name] || '#6FCF97';
};

const getStandardColor = (name: string) => {
  const colors = {
    complete: '#27AE60',
    no: '#E0E0E0',
    'out of zone': '#6FCF97',
    unknown: '#E0E0E0',
    yes: '#219653',
  };

  return colors[name.toLowerCase()] || '#6FCF97';
};

const getStandardIndex = (name: string) => {
  const index = {
    complete: 1,
    no: 1,
    'out of zone': 2,
    unknown: 0,
    yes: 0,
  };

  return index[name.toLowerCase()] || 0;
};

const formatPercentage = (value = 0) => {
  const percentage = (value * 100).toFixed(2);

  return parseFloat(percentage);
};

const getSeries = (
  chartList: any = [],
  barIndex: number,
  key: string,
  tooltipTitle: string,
  nameFormatter: (name: string) => string = (s) => s,
  colorFormatter: (name: string) => string,
  indexFormatter: (name: string) => number = () => 0,
  includeUnknown: boolean = true,
): Highcharts.SeriesBarOptions[] => {
  const data = chartList?.[key]?.values;
  if (!data || data.length === 0) {
    return [
      {
        enableMouseTracking: false,
        id: 'empty',
        type: 'bar',
      },
    ];
  }

  const res = [...data]
    .sort((a: any, b: any) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    })
    .map((item: any) => ({
      borderWidth: 0,
      color: colorFormatter(nameFormatter(item.label)),
      data: [
        {
          name: nameFormatter(item.label),
          x: barIndex,
          y: formatPercentage(item.pct),
        },
      ],
      id: key,
      index: indexFormatter(item.label),
      name: nameFormatter(item.label),
      tooltip: {
        headerFormat: `<span style="font-size: 10px">${tooltipTitle}</span><br/>`,
      },
      type: 'bar' as any,
    }))
    .filter((item: any) => includeUnknown || item.name !== 'Unknown');
  return res;
};

type ChartMap = {
  [index: string]: IGraphQLTIVChart;
};
const getSeriesForKey = (chartMap: ChartMap, barIndex: number, key: string, tooltipTitle: string) =>
  getSeries(chartMap, barIndex, key, tooltipTitle, undefined, getStandardColor, getStandardIndex);

const DataQuality: React.FC<IProps> = ({ explorer, title }) => {
  const queryData = buildTIVChartsData(explorer as IGraphQLTIVCharts);
  const options = {
    chart: {
      height: '90%',
      style: {
        margin: '0 auto',
      },
      width: 265,
    },
    colorAxis: {
      maxColor: '#2F80ED',
      min: 0,
      minColor: '#E9F4FB',
    },
    credits: {
      enabled: false,
    },
    exporting: {
      chartOptions: {
        legend: {
          enabled: true,
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        pointWidth: 10,
        stacking: 'percent' as Highcharts.OptionsStackingValue,
        tooltip: {
          distance: 20,
          followPointer: true,
        },
      },
      series: {
        states: {
          hover: {
            brightness: 0,
          },
          normal: {
            animation: {
              duration: 1,
              easing: 'linear',
            },
          },
        },
      },
    },
    series: [
      ...getSeries(
        queryData,
        0,
        'geo_code_type',
        'Geocode',
        formatGeoCode,
        getGeocodeColor,
        undefined,
        false,
      ),
      ...getSeriesForKey(queryData, 1, 'occupancy_type', 'Occupancy'),
      ...getSeriesForKey(queryData, 2, 'construction_type', 'Construction Type'),
      ...getSeriesForKey(queryData, 3, 'year_built', 'Year built'),
      ...getSeriesForKey(queryData, 4, 'nr_of_stories', '# of stories'),
    ],
    title: {
      text: title ? title : null,
    },

    tooltip: {
      pointFormat: '<span>{series.name}</span>: <b>{point.y}% of TIV</b><br/>',
      shared: true,
    },
    xAxis: {
      categories: [
        'Geocode',
        'Occupancy',
        'Constr.',
        'Year built',
        '# of stories',
        'EQ mods.',
        'Wind mods.',
      ],
    },
    yAxis: {
      tickPositions: [0, 50, 100],
      title: {
        text: undefined,
      },
    },
  };

  return (
    <Explorer>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Explorer>
  );
};

export default memo(DataQuality, chartDataIsEqual);

import * as React from 'react';
import styled from '@emotion/styled';
import { EuiText, Icon } from 'ui';

const Container = styled.div`
  width: 100%;
  display: flex;

  > div {
    max-width: 350px;
    margin: auto;
    text-align: center;
  }
`;

interface Props {
  testId?: string;
  title?: string;
  message?: string;
}

const ErrorMessage: React.FC<Props> = ({
  testId = 'error-message',
  title = 'An error occurred',
  message,
}) => (
  <Container data-testid={testId}>
    <div>
      <Icon color="danger" name="alertTriangle" size="l" />
      <EuiText>
        <h2>{title}</h2>
      </EuiText>
      <EuiText>{message}</EuiText>
    </div>
  </Container>
);

export default ErrorMessage;

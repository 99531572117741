import { FC } from 'react';
import { EuiFlexGroup, EuiFlexGroupProps, EuiFlexItem, EuiText } from 'ui';
import { LegendItem } from './types';

interface LegendKeyProps {
  items: LegendItem[];
  direction?: EuiFlexGroupProps['direction'];
  justifyContent?: EuiFlexGroupProps['justifyContent'];
  gutterSize?: EuiFlexGroupProps['gutterSize'];
}

const LegendKey: FC<LegendKeyProps> = ({
  items,
  direction = 'row',
  justifyContent = 'spaceBetween',
  gutterSize = 'none',
}) => {
  const Swatch: FC<{ color: string; score: number }> = ({ color, score }) => {
    const width = 9;
    const height = score === 0 ? 1 : width;
    return <div style={{ backgroundColor: color, height, marginRight: 5, width }} />;
  };

  return (
    <EuiFlexGroup gutterSize={gutterSize} direction={direction} justifyContent={justifyContent}>
      {items.map((item) => (
        <EuiFlexItem key={item.score} grow={false}>
          <EuiFlexGroup gutterSize="none" alignItems="center">
            <EuiFlexItem grow={false}>
              <Swatch color={item.color} score={item.score} />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText size="xs">
                <span style={{ whiteSpace: 'nowrap' }}>
                  <small>{item.name}</small>
                </span>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  );
};

export default LegendKey;

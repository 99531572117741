import { memo, useMemo } from 'react';
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { IGraphQLTIVCharts } from '@app/queries/explorers/types';
import { Explorer } from '../Explorer.emotion';
import { getBarMetadata } from './barsMetadata';
import { IExplorerProps } from './types';
import { buildTIVChartsData, chartDataIsEqual, getSeriesForKey } from './utils';

const TIVBarsChart: React.FC<IExplorerProps> = ({
  explorer,
  currentSnapshotLabel,
  previousSnapshotLabel,
  changeAnalysis,
  title,
}) => {
  const meta = getBarMetadata(explorer.name);
  const queryData = buildTIVChartsData(explorer as IGraphQLTIVCharts);

  const previousSnapshotTitle = useMemo(() => {
    if (!previousSnapshotLabel) {
      return 'Prev. bind N/A';
    }

    if (changeAnalysis) {
      return previousSnapshotLabel;
    }

    return 'Prev. bind';
  }, [previousSnapshotLabel, !!changeAnalysis]);

  const series = [
    ...getSeriesForKey(queryData.this_year, meta, 0),
    ...getSeriesForKey(queryData.last_year, meta, 1),
  ];

  const options = {
    chart: {
      height: 150,
      style: {
        margin: '0 auto',
      },
      width: 300,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      chartOptions: {
        legend: {
          enabled: true,
        },
      },
    },

    legend: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        grouping: false,
        pointWidth: 10,
        stacking: 'percent' as Highcharts.OptionsStackingValue,
      },
      series: {
        groupPadding: 0,
        pointPadding: 0,
      } as any,
    },

    series,
    title: {
      text: title ? title : null,
    },

    tooltip: {
      formatter() {
        //eslint-disable-line
        const { points, x: title } = this; //eslint-disable-line
        const pLength = points.length;
        let tooltipContent = pLength ? `${title}<br/>` : '';
        let index;

        for (index = 0; index < pLength; index += 1) {
          const isCodedSeries = points[index].series.name === 'Coded';

          const seriesColor =
            points[index].series.name === 'Unknown' ? '#828282' : points[index].series.color;

          const seriesName = isCodedSeries ? 'Known' : points[index].series.name;

          const valToDisplay = isCodedSeries ? points[index].point.ySummed : points[index].y;

          tooltipContent += `<span style="font-weight: bold; color:${seriesColor}">${seriesName}: </span> <b>${valToDisplay}% of TIV</b><br/>`;
        }

        return tooltipContent;
      },
      shared: true,
    },
    xAxis: {
      categories: [currentSnapshotLabel || 'This year', previousSnapshotTitle],
    },
    yAxis: {
      endOnTick: true,
      labels: {
        step: 1,
        style: {
          color: '#828282',
          fontSize: '10px',
          fontWeight: 'bold',
          margin: '0px',
          opacity: '1 !important' as any,
          padding: '0px',
          whiteSpace: 'nowrap',
        },
      },
      max: 100,
      min: 0,
      reversedStacks: false,
      showLastLabel: true,
      tickPositions: [0, 20, 40, 60, 80, 100],
      title: {
        align: 'high' as any,
        style: {
          color: '#DBDBDB',
          fontSize: '10px',
          fontWeight: 'bold',
        },
        text: '% of TIV',
      },
    },
  };

  return (
    <Explorer>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Explorer>
  );
};

export default memo(TIVBarsChart, chartDataIsEqual);

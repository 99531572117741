import { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiToolTip, Icon, Spacer } from 'ui';
import { getReadinessGrade, getReadinessGradeMessage } from '../ReadinessReport/utils';
import styles from './ScoreIndicatorBar.emotion';

interface ScoreIndicatorBarProps {
  percentile: string;
  compact?: boolean;
  tooltips?: boolean;
}

// TODO:
// * [ ] Move to styles to Emotion once we formally decide to move.
// * [ ] Refactor. I feel we can simplify this component.
const ScoreIndicatorBar: FC<ScoreIndicatorBarProps> = ({ percentile, compact, tooltips }) => {
  const spacerSize = 'm';

  const barGrow = null;

  const barStyleBase = {
    border: '1px solid #FFF',
    height: 8,
    width: compact ? 32 : 112,
  };

  const barStyleLevel1 = {
    ...barStyleBase,
    backgroundColor: percentile === 'Level 1' ? '#AEE1D8' : '#E0E6EB',
  };

  const barStyleLevel2 = {
    ...barStyleBase,
    backgroundColor: percentile === 'Level 2' ? '#53C4AF' : '#E0E6EB',
  };

  const barStyleLevel3 = {
    ...barStyleBase,
    backgroundColor: percentile === 'Level 3' ? '#008169' : '#E0E6EB',
  };

  return (
    <EuiFlexGroup gutterSize="none" style={{ alignItems: 'flex-start' }}>
      {tooltips ? (
        <EuiToolTip
          content={
            <div>
              <b>{`Data Quality ${getReadinessGrade(20)}`}</b>: {getReadinessGradeMessage(20)}
            </div>
          }
        >
          <EuiFlexItem className={styles.iconContainer} grow={barGrow}>
            {percentile === 'Level 1' && !compact ? (
              <Icon color="black" fill="black" name="play" />
            ) : (
              <Spacer size={spacerSize} />
            )}
            <div style={barStyleLevel1} />
          </EuiFlexItem>
        </EuiToolTip>
      ) : (
        <EuiFlexItem className={styles.iconContainer} grow={barGrow}>
          {percentile === 'Level 1' && !compact ? (
            <Icon color="black" fill="black" name="play" />
          ) : (
            <Spacer size={spacerSize} />
          )}
          <div style={barStyleLevel1} />
        </EuiFlexItem>
      )}
      {tooltips ? (
        <EuiToolTip
          content={
            <div>
              <b>{`Data Quality ${getReadinessGrade(5)}`}</b>: {getReadinessGradeMessage(55)}
            </div>
          }
        >
          <EuiFlexItem className={styles.iconContainer} grow={barGrow}>
            {percentile === 'Level 2' && !compact ? (
              <Icon color="black" fill="black" name="play" />
            ) : (
              <Spacer size={spacerSize} />
            )}
            <div style={barStyleLevel2} />
          </EuiFlexItem>
        </EuiToolTip>
      ) : (
        <EuiFlexItem className={styles.iconContainer} grow={barGrow}>
          {percentile === 'Level 2' && !compact ? (
            <Icon color="black" fill="black" name="play" />
          ) : (
            <Spacer size={spacerSize} />
          )}
          <div style={barStyleLevel2} />
        </EuiFlexItem>
      )}
      {tooltips ? (
        <EuiToolTip
          content={
            <div>
              <b>{`Data Quality ${getReadinessGrade(80)}`}</b>: {getReadinessGradeMessage(80)}
            </div>
          }
        >
          <EuiFlexItem className={styles.iconContainer} grow={barGrow}>
            {percentile === 'Level 3' && !compact ? (
              <Icon color="black" fill="black" name="play" />
            ) : (
              <Spacer size={spacerSize} />
            )}
            <div style={barStyleLevel3} />
          </EuiFlexItem>
        </EuiToolTip>
      ) : (
        <EuiFlexItem className={styles.iconContainer} grow={barGrow}>
          {percentile === 'Level 3' && !compact ? (
            <Icon color="black" fill="black" name="play" />
          ) : (
            <Spacer size={spacerSize} />
          )}
          <div style={barStyleLevel3} />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
};

export default ScoreIndicatorBar;

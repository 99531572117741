import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { RenewalFlyout } from '@app/cx/Properties/RenewalFlyout/RenewalFlyout';

const RenewSOVPanel = () => {
  const { stream } = useStreamContext();
  const { showFlyout } = useFlyout();
  return {
    'data-testid': 'renew-SOV-button',
    iconName: 'refresh',
    label: 'Renew SOV',
    onClick: () => {
      showFlyout(<RenewalFlyout directStream={stream} source="click" />);
    },
  };
};

export default RenewSOVPanel;

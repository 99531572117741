import * as React from 'react';
import { OperatorSelectorProps } from 'react-querybuilder';
import { Select } from 'ui';
import { Filters } from '../types';
import { OperatorSelectContainer } from './OperatorSelector.emotion';

interface Props extends OperatorSelectorProps {
  filters: Filters;
}

const OperatorSelector: React.FC<Props> = ({ field, filters, handleOnChange, value }) => {
  const filter = filters[field || ''];

  const options = filter?.operators.map((operator) => ({
    label: operator.label,
    value: operator.name,
  }));

  return (
    <OperatorSelectContainer data-testid="query-builder-wrapper-operator-selector">
      <Select options={options} onChange={(value) => handleOnChange(value)} value={value} />
    </OperatorSelectContainer>
  );
};

export default OperatorSelector;

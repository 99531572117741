import { useContext, useState } from 'react';
import * as React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import {
  Button,
  ButtonEmpty,
  CustomSelect,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  TextField,
  useToast,
} from 'ui';
import validator from 'validator';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { ModalContext } from '@app/contexts/ModalContext';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { IGraphQLOrganization } from '@app/queries/organizations/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
// FIX ME
// @ts-ignore
import GET_ROLE_ASSIGNMENTS from '../RoleChecker/getRoleAssignments.gql';
import { RoleAssignmentsData, RoleAssignmentsVariables } from '../RoleChecker/types';
import ConfirmUserOrgUpdateModal from './ConfirmUserOrgUpdateModal';

export interface IForm {
  email: string;
  name: string;
  title: string;
  phoneNumber: string;
  companyName: string;
  intent: string;
}

export const REGISTER_USER = gql`
  mutation registerUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      userId
    }
  }
`;

const dateFields: string[] = [];
const selectFields: string[] = ['intent'];
const checkFields: string[] = [];

const nameToValue = (field: string, e: any) => {
  if (selectFields.includes(field)) {return e;}
  if (checkFields.includes(field)) {return e.target.checked;}
  if (dateFields.includes(field)) {return e;}
  return e.target.value;
};

const isValid = (formValues: IForm) =>
  !!(formValues.email && formValues.intent && formValues.companyName && formValues.name);

interface IProps {
  org?: IGraphQLOrganization;
}

const PreRegisterOrgModal: React.FC<IProps> = ({ org }) => {
  const { account } = useAuth();
  const { selectedOrganization } = useContext(UserSessionContext);
  const organizationName = selectedOrganization?.name || account?.docUploadOrgs?.[0]?.name;
  const toast = useToast();
  const { showModal, closeModal } = useContext(ModalContext);

  if (!account || !organizationName) {
    return null;
  }

  const intentOptions = [
    'Buy insurance',
    'Provide information about a property',
    'Broker insurance',
    'Provide insurance',
  ];

  const defaultValues: IForm = {
    companyName: '',
    email: '',
    intent: '',
    name: '',
    phoneNumber: '',
    title: '',
  };

  const [registerUser, { loading }] = useMutation(REGISTER_USER, {
    onCompleted: () => {
      toast({ title: 'Successfully registered user', type: 'success' });
      closeModal();
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const [getRoleData, { loading: isLoading }] = useLazyQuery<
    RoleAssignmentsData,
    RoleAssignmentsVariables
  >(GET_ROLE_ASSIGNMENTS, {
    onCompleted: (data) => handleUserRegistration(data),
    onError: (error) => toast({ title: getErrorMessage(error), type: 'danger' }),
  });

  const [showSaveButton, setShowSaveButton] = useState<boolean>(isValid(defaultValues));

  const [values, setValues] = useState<IForm>(defaultValues);

  const onValueChange = (field: string) => (e: any) => {
    const newValue = nameToValue(field, e);
    const newValues = { ...values, [field]: newValue };
    setValues(newValues);
    setShowSaveButton(isValid(newValues));
  };

  const handleUserRegistration = (data: RoleAssignmentsData) => {
    if (!validator.isEmail(values.email)) {
      toast({ title: ` ${values.email} is not a valid email`, type: 'danger' });
      return;
    }
    if (data?.roleAssignmentsV2?.assignments?.length === 0) {
      registerUser({
        variables: {
          input: {
            connection: 'PASSWORDLESS',
            password: null,
            ...values,
            orgName: org?.name || null,
            sendEmail: false,
            streamSlugs: [],
          },
        },
      });
    }
    if (data?.roleAssignmentsV2?.assignments?.length > 0) {
      data?.roleAssignmentsV2?.primaryOrgName === org.name
        ? toast({ title: `User is already a member of ${org.name}`, type: 'danger' })
        : showModal(
            <ConfirmUserOrgUpdateModal values={values} org={org} roleAssignmentData={data} />,
          );
    }
  };

  const onSave = () => {
    getRoleData({
      variables: {
        principalId: values.email,
      },
    });
  };

  const onIntentChange = (selectedOptions: any) => {
    // We should only get back either 0 or 1 options.
    const newOption = selectedOptions.length > 0 ? selectedOptions?.[0]?.label : '';
    onValueChange('intent')(newOption);
  };

  const onCreateIntentOption = (searchValue = '') => {
    const normalizedSearchValue = searchValue?.trim()?.toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = [
      {
        label: searchValue,
      },
    ];

    // Select the option.
    onIntentChange(newOption);
  };

  return (
    <>
      <EuiModal onClose={closeModal} initialFocus="[name=email]">
        <EuiModalHeader>
          <EuiModalHeaderTitle>Pre-register User</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiForm>
            <EuiFormRow label="Email">
              <TextField
                name="email"
                value={values.email}
                onChange={onValueChange('email')}
                disabled={loading}
              />
            </EuiFormRow>
            <EuiFormRow label="Full Name">
              <TextField
                name="name"
                value={values.name}
                onChange={onValueChange('name')}
                disabled={loading}
              />
            </EuiFormRow>
            <EuiFormRow label="Title">
              <TextField
                name="title"
                value={values.title}
                onChange={onValueChange('title')}
                disabled={loading}
              />
            </EuiFormRow>
            <EuiFormRow label="Phone Number">
              <TextField
                name="phoneNumber"
                value={values.phoneNumber}
                onChange={onValueChange('phoneNumber')}
                disabled={loading}
              />
            </EuiFormRow>
            <EuiFormRow label="Company Name">
              <TextField
                name="companyName"
                value={values.companyName}
                onChange={onValueChange('companyName')}
                disabled={loading}
              />
            </EuiFormRow>
            <EuiFormRow
              label="They're looking to..."
              helpText="Select an option from the list or type in a custom response."
            >
              <CustomSelect
                placeholder="Select a single option"
                options={intentOptions.map((label) => ({ label, value: label }))}
                initialSelected={
                  values.intent ? [{ label: values.intent, value: values.intent }] : []
                }
                onChange={onIntentChange}
                onCreateOption={onCreateIntentOption}
                disabled={loading}
              />
            </EuiFormRow>
          </EuiForm>
        </EuiModalBody>
        <EuiModalFooter>
          <ButtonEmpty onClick={closeModal} label="Cancel" />
          <Button
            onClick={onSave}
            fill
            disabled={!showSaveButton || loading || isLoading}
            iconSide="right"
            loading={loading}
            label={loading ? 'Saving' : 'Register User'}
          />
        </EuiModalFooter>
      </EuiModal>
    </>
  );
};

export default PreRegisterOrgModal;

import { useState } from 'react';
import * as React from 'react';
import styled from '@emotion/styled';
import { SizeBy } from '@app/components/PropertiesMap/types';
import ClusterTooltip from '@app/cx/Stream/Reef/ReefMapV2/ClusterTooltip';
import { MAX, MIN } from '@app/cx/Stream/Reef/ReefMapV2/getStyle';
import MarkerDiv from '@app/cx/Stream/Reef/ReefMapV2/MarkerDiv';
import { ClusterRange, GraphqlPropertyCluster } from '@app/queries/properties/types';
import squash from '@app/utils/squash';
import { useStreamContext } from '../../StreamProvider';
import { formatStreamCurrency as formatter } from '../../utils';
import { getClusterValue } from './utils';

const Container = styled.div`
  label: ClusterMarker;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Marker = styled(MarkerDiv)`
  label: Cluster;
  border-radius: 500px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12),
    0px 0px 2px rgba(0, 0, 0, 0.14);
  background-color: white;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  font-weight: normal;
`;

export const Tooltip = styled.div<{
  hovering?: boolean;
}>`
  label: Tooltip;

  ${({ hovering }) =>
    hovering
      ? `
    opacity: 1;
    display: block;
  `
      : `
    opacity: 0;
    display: none;
  `}
  position: absolute;
  bottom: -30px;
  left: 0;
  min-height: 24px;
  min-width: 77px;
  background-color: rgba(255, 255, 255, 0.9);
  color: rgba(52, 55, 65, 1);
  font-size: 8px;
  text-align: center;
  padding: 2px 4px;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 6px;
  z-index: 2;
`;

const SIZE_RANGE: [number, number] = [45, 60];

const range: [number, number] = [0, 1];
const asHsl = (hsl: [number, number, number]) => `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;

const getStyle = (val: number, sizeRange: [number, number]) => {
  if (val < range[0] || val > range[1]) {
    throw new Error(
      `getStyle should return a value between ${range[0]} and ${range[1]}. Returned value is ${val}`,
    );
  }

  const backgroundColor = asHsl([
    squash(val, range, [MIN.color[0], MAX.color[0]]),
    squash(val, range, [MIN.color[1], MAX.color[1]]),
    squash(val, range, [MIN.color[2], MAX.color[2]]),
  ]);
  const size = squash(val, range, sizeRange);
  const fontSize = squash(val, range, [MIN.fontSize, MAX.fontSize]);
  const fontWeight = sizeRange[0] > 1 ? ('bold' as 'bold') : ('normal' as 'normal');

  return {
    backgroundColor,
    color: 'black',
    fontSize,
    fontWeight,
    height: size,
    marginLeft: size / -2,
    marginTop: size / -2,
    width: size,
  };
};

const lossAttributes: Array<string> = ['lossCountTotal', 'totalGrossLoss'];

interface Props {
  cluster: GraphqlPropertyCluster;
  range: ClusterRange;
  id: number;
}

const PropertyCluster: React.FC<Props> = ({ cluster, range, id }) => {
  const { min, max, attribute } = range || {};
  const { formatStreamCurrency } = useStreamContext();
  const [hovering, setHovering] = useState<boolean>(false);
  const clusterValues = {
    lossCountTotal: cluster.lossCountTotal,
    num: cluster.propertyCount,
    tiv: cluster.totalInsuredValueDisplay,
    totalGrossLoss: cluster.totalGrossLoss,
  };
  const clusterValue = getClusterValue(
    cluster,
    // TODO - Rename cluster field attribute in api
    attribute === 'tiv' ? 'totalInsuredValueDisplay' : attribute,
  );

  const clusterSize = min && min !== max ? (clusterValue - min) / (max - min) : 1;

  let formattedValue = `${clusterValue}`;
  // TODO: Fix string with a property of `SizeBy`
  if ([SizeBy.TIV, 'grossTotalLoss'].includes(attribute as SizeBy)) {
    const formatFunc = formatStreamCurrency ? formatStreamCurrency : formatter;
    formattedValue = formatFunc(clusterValue);
  }

  return (
    <Container>
      <Marker
        data-testid={`map-cluster-${id}`}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={getStyle(Math.min(Math.max(0, clusterSize), 1), SIZE_RANGE)}
      >
        {formattedValue}
      </Marker>
      <ClusterTooltip hovering={hovering} clusterValues={clusterValues} />
    </Container>
  );
};

export default PropertyCluster;

import * as React from 'react';
import { EuiText } from 'ui';
import Bar from '@app/cx/ExposureIndex/charts/Bar/Bar';
import { TableRow } from '../../../types';
import styles from './BenchmarksTable.emotion';

interface Props {
  row: TableRow;
  showSegmentBackgrounds: boolean;
}

const BenchmarksTableRow: React.FC<Props> = ({ row, showSegmentBackgrounds }) => (
    <div data-testid="exposure-benchmarks-table-row" className={styles.rowContainer}>
      <div className={styles.exposureContainer}>
        <EuiText>
          <p>{row.label}</p>
        </EuiText>
      </div>
      <div className={styles.spacer} />
      <div className={styles.tivContainer}>
        <EuiText>
          <p>{row.tiv}%</p>
        </EuiText>
      </div>
      <div className={styles.spacer} />
      <div className={styles.chartContainer}>
        <Bar row={row} TIVPct={row.tiv} showSegmentBackgrounds={showSegmentBackgrounds} />
      </div>
      <div className={styles.spacer} />
    </div>
  );
export default BenchmarksTableRow;

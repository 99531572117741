import { useState } from 'react';
import { useToast } from 'ui';
import { usePropertyV2LazyQuery } from '@app/graphql/queries/property/__generated__/propertyV2.generated';
import { useRemoveDocumentPropertyMutation } from '@app/graphql/queries/removeDocumentProperty/__generated__/removeDocumentProperty.generated';
import { ModalWithButtons } from '../ModalWithButtons/ModalWithButtons';

export const UnlinkDocumentModal = ({ id, onClose, propertyID }) => {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [removeDocumentProperty] = useRemoveDocumentPropertyMutation();

  const [getProperty] = usePropertyV2LazyQuery({
    variables: {
      propertyID,
    },
  });

  const submitHandler = async () => {
    setIsSubmitting(true);
    try {
      await removeDocumentProperty({
        variables: {
          input: { documentID: id, propertyID },
        },
      });
      getProperty();
      toast({ title: 'Document unlinked successfully' });
    } catch (error) {
      toast({ title: 'Failed to unlink document', type: 'danger' });
    }
    setIsSubmitting(false);
  };

  return (
    <ModalWithButtons
      header="Are you sure you want to unlink this document?"
      buttonActionLabel="Unlink"
      onClose={onClose}
      onClick={() => {
        submitHandler();
        onClose();
      }}
      buttonActionVariant="primary"
      isLoading={isSubmitting}
    />
  );
};

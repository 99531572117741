import { generatePath } from 'react-router';
import { EuiSpacer, Icon } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useNavContext } from '../SideNavContext';
import { HomeLink } from './Home';
import { NavItem } from './NavItem';
import { UserLink } from './Settings';
import { LibraryLink } from './SovManager';

export const CasualtyAppNav = () => {
  const { selectedOrganization } = useUserSession();
  const { permissions } = useNavContext();

  const casualtyLink = generatePath('/organizations/:organizationName/casualty', {
    organizationName: selectedOrganization?.name,
  });

  const supportLink = generatePath('/organizations/:organizationName/support', {
    organizationName: selectedOrganization?.name,
  });

  return (
    <>
      <HomeLink />
      <EuiSpacer size="m" />
      {permissions.casualty.canViewUsers && <UserLink />}
      <EuiSpacer size="m" />
      <NavItem icon={<Icon name="hexagon" />} link={casualtyLink} label="Casualty" />
      <EuiSpacer size="m" />
      {permissions.casualty.canViewLibrary && <LibraryLink />}
      <EuiSpacer size="m" />
      <NavItem icon={<Icon name="help" />} link={supportLink} label="Support" />
    </>
  );
};

import * as React from 'react';
import { CallOut, EuiText } from 'ui';

interface Props {
  hoursRemaining: number;
}

const TimeRemainingCallout: React.FC<Props> = ({ hoursRemaining }) => (
  <CallOut type="reminder" title="Reminder">
    <EuiText>
      You have{' '}
      <b>
        {hoursRemaining} hour{hoursRemaining === 1 ? '' : 's'}
      </b>{' '}
      left to submit your edits.
    </EuiText>
  </CallOut>
);

export default TimeRemainingCallout;

import * as React from 'react';
import {
  Button,
  ButtonEmpty,
  EuiDatePicker,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  TextField,
  Toggle,
} from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { useAddStreamModal } from './useAddStreamModal';

interface Props {
  // If there's already a 'My Properties' stream then this boolean will be set to true.
  hasMyPropertiesStream: boolean;
  onCloseModal?: () => void;
}

const AddStreamModal: React.FC<Props> = ({ hasMyPropertiesStream, onCloseModal }) => {
  const {
    closeModal,
    triggerValueChange,
    dontRender,
    handleSave,
    isSaving,
    onValueChange,
    showSaveButton,
    values,
  } = useAddStreamModal();

  if (dontRender) {
    return null;
  }

  return (
    <EuiModal
      data-testid="add-stream-modal"
      onClose={onCloseModal || closeModal}
      initialFocus="[name=name]"
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>Add {STREAMS_LABEL}</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm>
          <EuiFormRow label={`${STREAMS_LABEL} Name`}>
            <TextField
              data-testid="add-stream-modal-name-field"
              name="name"
              value={values.name}
              onChange={onValueChange('name')}
              disabled={isSaving || values?.isMyProperties}
            />
          </EuiFormRow>
          <EuiFormRow label="Broker">
            <TextField
              data-testid="add-stream-modal-broker-field"
              name="broker"
              value={values.broker}
              onChange={onValueChange('broker')}
              disabled={isSaving}
            />
          </EuiFormRow>
          <EuiFormRow label="REIT">
            <TextField
              data-testid="add-stream-modal-reit-field"
              name="reit"
              value={values.reit}
              onChange={onValueChange('reit')}
              disabled={isSaving}
            />
          </EuiFormRow>
          <EuiFormRow label="Status">
            <TextField
              data-testid="add-stream-modal-status-field"
              name="status"
              value={values.status}
              onChange={onValueChange('status')}
              disabled={isSaving}
            />
          </EuiFormRow>
          <EuiFormRow label="Effective Date">
            <EuiDatePicker
              name="effectiveDate"
              selected={values.effectiveDate}
              onChange={onValueChange('effectiveDate')}
              disabled={isSaving}
            />
          </EuiFormRow>
          <EuiFormRow label="Expiry Date">
            <EuiDatePicker
              name="expiryDate"
              selected={values.expiryDate}
              onChange={onValueChange('expiryDate')}
              disabled={isSaving}
            />
          </EuiFormRow>
          <EuiFormRow label="Is 'My Properties'">
            <>
              {hasMyPropertiesStream && (
                <EuiText
                  data-testid="add-stream-modal-has-my-properties-text"
                  size="s"
                  color="subdued"
                >
                  <p className="euiTextColor--subdued">
                    {`This field is disabled because there's already a 'My Properties'
                    ${STREAMS_LABEL}`}
                  </p>
                </EuiText>
              )}
              <Toggle
                data-testid="add-stream-modal-has-my-properties-toggle"
                disabled={hasMyPropertiesStream}
                name="isMyProperties"
                label="'My Properties'"
                checked={values.isMyProperties}
                onChange={() => {
                  triggerValueChange({
                    isMyProperties: !values.isMyProperties,
                    name: !values.isMyProperties ? 'My Properties' : '',
                  });
                }}
              />
            </>
          </EuiFormRow>
          <EuiFormRow label="Stock Throughput">
            <>
              {values.isMyProperties && (
                <EuiText
                  data-testid="add-stream-modal-stock-throughput-text"
                  size="s"
                  color="subdued"
                >
                  <p className="euiTextColor--subdued">
                    The “Stock Throughput” flag cannot be checked when My Properties is enabled on a
                    stream and is only available for Placement streams.
                  </p>
                </EuiText>
              )}
              <Toggle
                data-testid="add-stream-modal-stock-throughput-toggle"
                disabled={isSaving || values.isMyProperties}
                name="stockThroughputEnabled"
                label="'Stock Throughput'"
                checked={values.stockThroughputEnabled}
                onChange={onValueChange('stockThroughputEnabled')}
              />
            </>
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <ButtonEmpty onClick={onCloseModal || closeModal} label="Cancel" />
        <Button
          data-testid="add-stream-modal-save-button"
          onClick={() => {
            handleSave(values);
            onCloseModal();
          }}
          fill
          disabled={!showSaveButton}
          loading={isSaving}
          iconSide="right"
          label={isSaving ? 'Saving' : 'Save'}
        />
      </EuiModalFooter>
    </EuiModal>
  );
};

export default AddStreamModal;

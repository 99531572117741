import { FC } from 'react';
import * as React from 'react';
import { EuiAccordion, EuiDescriptionList, EuiFlexItem, EuiHorizontalRule,EuiText } from 'ui';
import { StyledDescription, StyledPanel } from './AccordionTable.emotion';

interface AccordionTableProps {
  id: string;
  title: string;
  tables: Array<{ label: string; value: string }>;
  children?: React.ReactNode;
}

export const AccordionTable: FC<AccordionTableProps> = ({ id, title, tables, children }) => (
    <EuiFlexItem key={id}>
      <EuiAccordion
        initialIsOpen
        id="dispose-properties"
        buttonContent={
          <EuiText size="s">
            <b>{title}</b>
          </EuiText>
        }
      >
        <StyledPanel paddingSize="xs" color="subdued">
          <EuiDescriptionList type="column" compressed>
            {tables.map((table) => (
                <>
                  <StyledDescription>{table.label}</StyledDescription>
                  <StyledDescription>{table.value}</StyledDescription>
                  <EuiHorizontalRule margin="none" />
                </>
              ))}
            {children}
          </EuiDescriptionList>
        </StyledPanel>
      </EuiAccordion>
    </EuiFlexItem>
  );

import { FC, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { Button, useToast } from 'ui';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { useGetSovJobWorksheetMutation } from '@app/graphql/jobs/mutations/__generated__/getSOVJobWorksheet.generated';

interface IProps {
  jobID: string;
}

const GetSovWorksheetButton: FC<IProps> = ({ jobID }) => {
  const toast = useToast();
  const jobsApolloClient = useJobsApolloClient();

  const [getSovJob, { data, loading }] = useGetSovJobWorksheetMutation({
    client: jobsApolloClient,
    onCompleted: (data) => {
      window.location.href = data?.getSOVJobWorksheet?.httpURL;
    },
    onError: (error: ApolloError) => {
      toast({ title: `Could not download worksheet: ${error.message}`, type: 'danger' });
    },
    variables: {
      input: {
        jobID: jobID,
      },
    },
  });

  const handleOnClick = () => {
    getSovJob();
  };

  const GetSovWorksheetButtonButton = (
    <Button
      data-testid="sov-job-worksheet"
      size="s"
      onClick={handleOnClick}
      fill
      loading={loading}
      label="Get worksheet"
    />
  );

  return GetSovWorksheetButtonButton;
};

export default GetSovWorksheetButton;

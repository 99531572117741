import { FC, ReactNode, useContext } from 'react';
import { EuiOverlayMask } from 'ui';
import { ModalContext, ModalProvider } from '@app/contexts/ModalContext';

const Modal: FC<{ children: ReactNode }> = ({ children }) => {
  const { isModalOpen, modalContent } = useContext(ModalContext);
  const modal = <EuiOverlayMask>{modalContent}</EuiOverlayMask>;

  return (
    <>
      {children}
      {isModalOpen && modal}
    </>
  );
};

const ModalWithContext: FC<{ children: ReactNode }> = ({ children }) => (
  <ModalProvider>
    <Modal>{children}</Modal>
  </ModalProvider>
);

export default ModalWithContext;

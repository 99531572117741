import moment from 'moment';
import { AccountUsers, Role } from '@app/graphql/types';

export const displayRoleName = (role: string): string => {
  switch (role) {
    case Role.EnterpriseAdmin:
      return 'Enterprise Owner';
    case Role.RiskManager:
      return 'Admin';
    case Role.PropertyEditor:
      return 'Contributor';
    case Role.StreamViewer:
      return 'Reviewer';
    default:
      return role.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
};

export const internalRoleName = (role: string): string => {
  switch (role) {
    case Role.EnterpriseAdmin:
      return 'EnterpriseOwner';
    case Role.RiskManager:
      return 'Admin';
    case Role.PropertyEditor:
      return 'Contributor';
    case Role.StreamViewer:
      return 'Reviewer';
    default:
      return role.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
};

const getSOVs = (user: AccountUsers) => {
  const streams = [];
  for (const role of user?.roles || []) {
    if (role.role === Role.StreamViewer) {
      streams.push(role.stream?.name);
    }
  }
  if (streams.length === 0) {
    return '-';
  }

  return `"${streams.join(',')}"`;
};

const getProperties = (user: AccountUsers) => {
  const properties = [];
  user.roles
    .filter((role) => role.role === Role.PropertyEditor)
    .forEach((role) => {
      role.properties.forEach((property) => {
        properties.push(property.archipelagoId);
      });
    });
  if (properties.length === 0) {
    return '-';
  }
  return `"${properties.join(',')}"`;
};

const getClientAccounts = (user: AccountUsers) => {
  const accounts = [];
  for (const role of user?.roles || []) {
    accounts.push(role.accountName);
  }
  return `"${accounts.join(',')}"`;
};

const getUserName = (user: AccountUsers) => {
  let name = '-';
  if (user?.profile?.familyName === 'temporarily_unavailable') {
    name = `${user?.profile?.givenName}`;
  } else if (user?.profile?.givenName || user?.profile?.familyName) {
    name = `${user?.profile?.givenName} ${user?.profile?.familyName}`;
  }
  return name;
};

export const generateCSV = (users: any, isEnterprise: boolean) => {
  if (!users?.length) {
    return '';
  }

  let headers = ['Name', 'Email', 'Role', 'Client Accounts', 'SOV', 'Property', 'Last Login'];
  let rows = [];
  if (isEnterprise) {
    headers = headers.filter((header) => !['SOV', 'Property'].includes(header));
    rows = [
      headers,
      ...users.map((user) => [
        getUserName(user),
        user.profile.email,
        `"${[...new Set(user?.roles?.map((role) => displayRoleName(role.role)))].join(',')}"`,
        getClientAccounts(user),
        user.profile.lastLogin ? moment(user?.profile?.lastLogin).format('MMM DD, YYYY') : '-',
      ]),
    ];
  } else {
    headers = headers.filter((header) => header !== 'Client Accounts');
    rows = [
      headers,
      ...users.map((user) => [
        getUserName(user),
        user.profile.email,
        `"${[...new Set(user?.roles?.map((role) => displayRoleName(role.role)))].join(',')}"`,
        getSOVs(user),
        getProperties(user),
        user.profile.lastLogin ? moment(user?.profile?.lastLogin).format('MMM DD, YYYY') : '-',
      ]),
    ];
  }

  let csvContent = '';

  rows.forEach(function (rowArray) {
    const row = rowArray.join(',');
    csvContent += row + '\r\n';
  });

  const blob = new Blob([csvContent], { type: 'octet/stream' });
  const url = window.URL.createObjectURL(blob);

  return url;
};

import { generatePath } from 'react-router';
import { EuiCollapsibleNavGroup, EuiFlexGroup, EuiSpacer, Icon } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useNavContext } from '../SideNavContext';
import { NavItem } from './NavItem';

export const InternalSettings = () => {
  const { account } = useAuth();
  const showSettings = account?.permissions?.admin;

  const { selectedOrganization } = useUserSession();

  const { isCollapsed } = useNavContext();

  if (!showSettings || !selectedOrganization) {
    return null;
  }

  const smartDocsLink = generatePath('/organizations/:organizationName/documents', {
    organizationName: selectedOrganization.name,
  });

  const projectsLink = generatePath('/organizations/:organizationName/projects', {
    organizationName: selectedOrganization.name,
  });

  return (
    <>
      <EuiSpacer size="m" />
      <EuiCollapsibleNavGroup
        background="light"
        data-testid="internal-settings"
        title={isCollapsed ? 'A' : 'INTERNAL'}
        iconSize="s"
        isCollapsible={true}
        initialIsOpen={false}
      >
        <EuiFlexGroup gutterSize="l" direction="column">
          <NavItem
            testId="my-jobs-menu-item"
            icon={<Icon name="briefcase" />}
            link="/jobs"
            label="My Jobs"
          />
          <NavItem icon={<Icon name="help" />} link="/support" label="Support" />
          <NavItem
            icon={<Icon name="search" />}
            link={projectsLink}
            label="Projects"
            testId="projects"
          />
          <NavItem
            testId="smartfolders-menu-item"
            icon={<Icon name="folder" />}
            link={smartDocsLink}
            label="Smart Docs"
          />
          <NavItem
            testId="organizations-menu-item"
            icon={<Icon name="globe" />}
            link="/organizations"
            label="Client Accounts"
          />
          <NavItem
            testId="system-menu-item"
            icon={<Icon name="gear" />}
            link="/system"
            label="System Settings"
          />
        </EuiFlexGroup>
      </EuiCollapsibleNavGroup>
    </>
  );
};

import { useState } from 'react';
import * as React from 'react';
import {
  Checkbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiToolTip,
  Icon,
  MultiSelect,
  TextField,
} from 'ui';
import { Option } from '@app/platform/AccountUsers/types';
import { useOrgModalContext } from '../../context/OrgModal.context';

const RbacTab: React.FC = () => {
  const {
    disabled,
    values,
    ssoConfigName,
    setSSOConfigName,
    marketAccessDomainOptions,
    setMarketAccessDomainOptions,
  } = useOrgModalContext();

  const [isDomainInvalid, setDomainInvalid] = useState(false);

  const isValidDomain = (value: string) => {
    // Check for valid domain
    const domain = /^([A-Za-z0-9-]{1,63}\.)+[A-Za-z]{2,6}$/;
    return value.match(domain) !== null;
  };

  // This event occurs when the user types in the MultiSelect.
  const onCreateOption = (searchValue, flattenedOptions = []) => {
    if (!searchValue) {
      return;
    }

    const normalizedSearchValue = searchValue?.trim()?.toLowerCase();

    if (!normalizedSearchValue) {
      return false;
    }

    // Select the option.
    // setSelected((prevSelected) => [...prevSelected, newOption]);
    setMarketAccessDomainOptions([...marketAccessDomainOptions, normalizedSearchValue]);
  };

  const selectedOptions: Option[] = marketAccessDomainOptions?.map((domain) => ({
    label: domain,
    value: domain,
  }));

  // This event occurs when the X is clicked in the MultiSelect.
  const onSelectedChange = (selectedOptions: Array<Option>) => {
    setMarketAccessDomainOptions(selectedOptions.map((option) => option.value));
  };

  const hasInvalidDomains = (emails: Option[]): boolean => {
    for (let i = 0; i < emails?.length; i++) {
      const valid = isValidDomain(emails[i].label);
      if (!valid) {
        return true;
      }
    }
    return false;
  };

  const labelWithHelpText = (title: string, tooltip: string) => (
    <EuiFlexGroup gutterSize="s">
      <EuiFlexItem grow={false}>{title}</EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiToolTip content={tooltip} position="right">
          <Icon name="help" size="m" />
        </EuiToolTip>
      </EuiFlexItem>
    </EuiFlexGroup>
  );

  return (
    <>
      <EuiForm>
        <EuiFormRow
          fullWidth
          label={labelWithHelpText(
            'SSO Config Name',
            "This is the name of the SAML connection in Auth0 that is used to configure SSO for customers. By configuring this the platform automatically grants the user roles to the customer's organization which gives access to organization's stream data.",
          )}
        >
          <TextField
            data-testid="sso-config-name-input"
            name="ssoConfigName"
            value={ssoConfigName || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSSOConfigName(e.target.value);
            }}
            disabled={disabled}
            fullWidth
          />
        </EuiFormRow>
        <EuiFormRow
          fullWidth
          label={labelWithHelpText(
            'Market Access Domains',
            "Setting for Enterprise Orgs to automatically configure additional Market Access domains. The values in this setting will be used as an extra way of populating the underlying org's stream market access domain allow list. The other way being the default allowed domains list.",
          )}
          isInvalid={isDomainInvalid}
          error={isDomainInvalid ? 'Must be a valid domain name' : undefined}
          helpText="Press enter to add domain"
        >
          {/* @ts-ignore */}
          <MultiSelect
            placeholder="No domains assigned"
            delimiter=","
            initialSelected={selectedOptions}
            onChange={onSelectedChange}
            onCreateOption={onCreateOption}
            noSuggestions
            invalid={hasInvalidDomains(selectedOptions)}
            fullWidth
            disabled={disabled || values.orgLevel !== 'Enterprise'}
          />
        </EuiFormRow>
      </EuiForm>
    </>
  );
};

export default RbacTab;

import { generatePath } from 'react-router';
import { EuiFlexItem, Icon } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { FEATURE_VALUE_COLLECTION } from '@app/platform/SystemSettings/Flags/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import { useNavContext } from '../SideNavContext';
import { NavGroup } from './NavGroup';
import { NavItem } from './NavItem';

export const Precheck = () => {
  const { selectedOrganization } = useUserSession();
  const { permissions } = useNavContext();

  if (!permissions.canViewPrecheck || !selectedOrganization) {
    return null;
  }

  const collectionsEnabled = isFeatureEnabled(
    selectedOrganization?.enabledFeatures,
    FEATURE_VALUE_COLLECTION,
  );

  const precheckLink = generatePath('/organizations/:organizationName/precheck', {
    organizationName: selectedOrganization?.name,
  });

  const collectionLink = generatePath('/organizations/:organizationName/collection', {
    organizationName: selectedOrganization?.name,
  });

  return (
    <NavGroup title="PRECHECK">
      <EuiFlexItem grow={1}>
        <NavItem
          testId="header-precheck-tab"
          icon={<Icon name="checkCircle" />}
          link={precheckLink}
          label="Recommendations"
        />
      </EuiFlexItem>
      {collectionsEnabled && (
        <EuiFlexItem grow={1}>
          <NavItem
            testId="header-collection-tab"
            icon={<Icon name="inbox" />}
            link={collectionLink}
            label="Collections"
          />
        </EuiFlexItem>
      )}
    </NavGroup>
  );
};

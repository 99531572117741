import { FC } from 'react';
import { Button, ButtonEmpty, EuiFlexGroup, EuiFlexItem } from 'ui';
import { useCasualtyData } from '../context/context';

export const Buttons: FC<{
  loading: boolean;
  next?: () => void;
  prev?: () => void;
  save?: () => void;
  options?: {
    disableNext?: boolean;
  };
  cancel?: () => void;
}> = ({ next, prev, save, loading, options, cancel }) => {
  const { viewOnly } = useCasualtyData();
  return (
    <>
      <EuiFlexGroup
        data-testid="casualty-dashboard-buttons"
        direction="row"
        gutterSize="s"
        alignItems="flexEnd"
        justifyContent="flexEnd"
        responsive={false}
      >
        <EuiFlexItem grow={false}>
          <ButtonEmpty onClick={cancel} label="Cancel" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {prev && <Button onClick={prev} fill label={'Previous'} loading={loading} />}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {next && (
            <Button
              onClick={next}
              fill
              label={'Next'}
              loading={loading}
              disabled={options?.disableNext}
            />
          )}
          {save && (
            <Button onClick={save} fill label={'Submit'} loading={loading} disabled={viewOnly} />
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

import { useState } from 'react';
import * as React from 'react';
import { ExpandedRows, User } from './types';
import UserViewsColumns from './UserViewsColumns';
import UserViewsExpandedRow from './UserViewsExpandedRow';
import { Table } from './UserViewsTable.emotion';

interface Props {
  items: User[];
}

const UserViewsTable: React.FC<Props> = ({ items }) => {
  const [expandedRows, setExpandedRows] = useState<ExpandedRows>({});

  const handleExpand = (id: string) => {
    const finalExpandedRows = { ...expandedRows };

    if (Object.keys(finalExpandedRows).includes(id)) {
      delete finalExpandedRows[id];
    } else {
      const user = items.filter((item) => item.id === id)[0];

      finalExpandedRows[id] = <UserViewsExpandedRow user={user} />;
    }

    setExpandedRows(finalExpandedRows);
  };

  return (
    <Table
      columns={UserViewsColumns(expandedRows, handleExpand)}
      isExpandable
      itemId="id"
      itemIdToExpandedRowMap={expandedRows}
      items={items}
    />
  );
};

export default UserViewsTable;

import { useState } from 'react';
import * as React from 'react';
import { ButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiText, Icon } from 'ui';
import { useExposureIndexContext } from '@app/cx/ExposureIndex/context/exposureIndexContext';
import { ExposureGroups } from '@app/cx/ExposureIndex/context/types';
import Line from '@app/cx/Reports/ReadinessReport/Line';
import styles, { TableGrid } from './BenchmarksTable.emotion';
import BenchmarksTableHeader from './BenchmarksTableHeader';
import BenchmarksTableRow from './BenchmarksTableRow';

const BenchmarksTable: React.FC = () => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const {
    exposure: { mainExposureRows, rowGroups },
  } = useExposureIndexContext();

  const buttonLabel = showAll ? 'Hide perils and metrics' : 'See more perils and metrics';
  const buttonIconName = showAll ? 'chevronUp' : 'chevronDown';

  return (
    <EuiFlexGroup data-testid="exposure-benchmarks-table" direction="column" gutterSize="xs">
      <EuiFlexItem grow={false}>
        <BenchmarksTableHeader />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiHorizontalRule margin="xs" />
      </EuiFlexItem>
      {showAll ? (
        <>
          {[ExposureGroups.Seismic, ExposureGroups.Wind, ExposureGroups.Other]?.map(
            (group, index) => (
              <>
                {index > 0 && <Line orientation="horizontal" />}
                <TableGrid>
                  {rowGroups[group]?.map((row, id) => (
                    <EuiFlexItem key={`benchmarks-table-row-${id}`} grow={false}>
                      <BenchmarksTableRow row={row} showSegmentBackgrounds={showAll} />
                    </EuiFlexItem>
                  ))}
                </TableGrid>
              </>
            ),
          )}
        </>
      ) : (
        mainExposureRows?.map((row, id) => (
          <TableGrid>
            <EuiFlexItem key={`benchmarks-table-row-${id}`} grow={false}>
              <BenchmarksTableRow row={row} showSegmentBackgrounds={showAll} />
            </EuiFlexItem>
          </TableGrid>
        ))
      )}
      <EuiFlexItem grow={false}>
        <div className={styles.tivKey}>
          <EuiText size="s">
            <b>% TIV</b>
          </EuiText>
          <Icon name="arrowRight" />
        </div>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <div>
          <ButtonEmpty
            label={buttonLabel}
            iconName={buttonIconName}
            iconSide="right"
            onClick={() => setShowAll((prevState) => !prevState)}
          />
        </div>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default BenchmarksTable;

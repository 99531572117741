"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YearField = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var eui_1 = require("@elastic/eui");
var utils_1 = require("../../../utils");
var YearField = function (_a) {
    var dataTestId = _a["data-testid"], disabled = _a.disabled, _b = _a.fullWidth, fullWidth = _b === void 0 ? false : _b, onChange = _a.onChange, placeholder = _a.placeholder, value = _a.value;
    var handleChange = function (event) {
        if (event.target.value.length <= 4) {
            onChange((0, utils_1.toNumber)(event.target.value));
        }
    };
    return ((0, jsx_runtime_1.jsx)(eui_1.EuiFieldNumber, { "data-testid": dataTestId || 'ui-atom-input-year-field', disabled: disabled, fullWidth: fullWidth, max: 9999, min: 1000, onChange: handleChange, placeholder: placeholder, value: value }));
};
exports.YearField = YearField;

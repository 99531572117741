import { useContext, useState } from 'react';
import * as React from 'react';
import { useQuery } from '@apollo/client';
import { CustomSelect, EuiFormRow } from 'ui';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { getFormLabel } from './FormFields';
import { GET_USERS_BY_ORGANIZATION } from './Queries';
import { UserOption } from './types';
import { FieldMeta } from './validator';

interface Props {
  label: string;
  disabled?: boolean;
  onCreateOption: (searchValue: string) => void;
  onValueChange: (e: any) => void;
  selectedOptions: UserOption[];
  meta?: FieldMeta;
  required?: boolean;
  touch: () => void;
}

interface User {
  email: string;
  familyName: string;
  givenName: string;
}

interface UsersByOrgData {
  usersByOrganization: User[];
}

interface UsersByOrgVars {
  orgName: string;
}

const UnderwriterDropdown: React.FC<Props> = ({
  label,
  disabled = false,
  onCreateOption,
  onValueChange,
  touch,
  selectedOptions,
  meta,
  required = false,
}) => {
  const { selectedOrganization } = useContext(UserSessionContext);
  const [underwriterOptions, setUnderwriterOptions] = useState<UserOption[]>([]);

  const getFinalName = (user: User): { id: string; label: string } => {
    const { email, familyName, givenName } = user;
    if (familyName && givenName) {
      return {
        id: email || `${givenName}-${familyName}`,
        label: `${givenName} ${familyName}`,
      };
    }

    if (!familyName && givenName) {
      return {
        id: email || `${givenName}`,
        label: `${givenName}`,
      };
    }

    if (familyName && !givenName) {
      return {
        id: email || `${familyName}`,
        label: `${familyName}`,
      };
    }

    return {
      id: `${email}`,
      label: `${email}`,
    };
  };

  const onCompleted = (data: UsersByOrgData): void => {
    const options = data.usersByOrganization
      .map((underwriter, index) => {
        const finalName = getFinalName(underwriter);
        return {
          key: `${index}${finalName.id}`,
          label: finalName.label,
          value: finalName.id,
        };
      })
      .sort((a: any, b: any) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
    setUnderwriterOptions(options);
  };

  if (selectedOrganization?.name) {
    useQuery<UsersByOrgData, UsersByOrgVars>(GET_USERS_BY_ORGANIZATION, {
      onCompleted,
      variables: {
        orgName: selectedOrganization.name,
      },
    });
  }

  const { error, touched } = meta || {};
  const isInvalid = !!error && !!touched;
  return (
    <EuiFormRow label={getFormLabel(label, required)} isInvalid={isInvalid} error={error}>
      <CustomSelect
        data-testid={`submission-dropdown-${label.split(' ').join('-')}`}
        disabled={disabled}
        onChange={onValueChange}
        onCreateOption={onCreateOption}
        options={underwriterOptions}
        initialSelected={selectedOptions}
        invalid={isInvalid}
        onBlur={touch}
      />
    </EuiFormRow>
  );
};

export default UnderwriterDropdown;

import { FC } from 'react';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import { ReportProps } from '@onarchipelago/cx/Reports/types';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText } from 'ui';
import { reportPageStyle } from '../reports.emotion';
import { ReportsHeader } from '../ReportsHeader/ReportsHeader';
import CoreAttributesBeforeAfterContainer from './CoreAttributesBeforeAfter/CoreAttributesBeforeAfterContainer';
import ExportButton from './ExportButton/ExportButton';
import Filters from './Filters/Filters';
import MostImproved from './MostImproved/MostImproved';
import NextProperties from './NextProperties/NextProperties';
import OccupancyConstructionImprovements from './OccupancyConstructionImprovements/OccupancyConstructionImprovements';
import SecondaryAttributesContainer from './SecondaryAttributes/SecondaryAttributesContainer';
import Summary from './Summary/Summary';

const EnrichmentReport: FC<ReportProps> = ({ visible }) => {
  if (!visible) {
    return null;
  }

  const { snapshotOptions } = useReportsContext().snapshots;

  if (snapshotOptions.length < 2) {
    return (
      <EuiFlexGroup
        direction="column"
        responsive={false}
        style={reportPageStyle}
        data-testid="enrichment-report"
      >
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" justifyContent="spaceBetween">
            <ReportsHeader header="Enrichment Report" />
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Filters />
        </EuiFlexItem>
        <EuiFlexItem data-testid="enrichment-report-not-enough-snapshots">
          <EuiFlexGroup alignItems="center" direction="column" justifyContent="center">
            <EuiFlexItem grow={false}>
              <EuiText>
                <h2>There are not enough snapshots scored yet.</h2>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText color="subdued">
                <p>You must have at least two scored snapshots in order to view this report.</p>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  return (
    <EuiFlexGroup
      direction="column"
      responsive={false}
      style={reportPageStyle}
      data-testid="enrichment-report"
    >
      <EuiFlexItem grow={false}>
        <EuiFlexGroup justifyContent="spaceBetween">
          <ReportsHeader header="Enrichment Report" />
          <EuiFlexItem grow={false}>
            <ExportButton />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <Filters />
      </EuiFlexItem>
      <EuiFlexItem data-testid="enrichment-report-chart-section-1" grow={false}>
        <EuiPanel hasShadow={false} paddingSize="l" style={{ display: 'flex' }}>
          <Summary />
        </EuiPanel>
      </EuiFlexItem>
      <EuiFlexItem data-testid="enrichment-report-chart-section-2">
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={2}>
            <EuiFlexGroup direction="column">
              <EuiFlexItem>
                <EuiPanel hasShadow={false} paddingSize="l" style={{ display: 'flex' }}>
                  <CoreAttributesBeforeAfterContainer />
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiPanel hasShadow={false} paddingSize="l" style={{ display: 'flex' }}>
                  <OccupancyConstructionImprovements />
                </EuiPanel>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel hasShadow={false} paddingSize="l" style={{ display: 'flex' }}>
              <SecondaryAttributesContainer />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem data-testid="enrichment-report-chart-section-3">
        <EuiFlexGroup direction="row">
          <EuiFlexItem grow={2}>
            <EuiFlexGroup direction="column">
              <EuiFlexItem>
                <EuiPanel hasShadow={false} paddingSize="l" style={{ display: 'flex' }}>
                  <MostImproved />
                </EuiPanel>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel hasShadow={false} paddingSize="l" style={{ display: 'flex' }}>
              <NextProperties />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default EnrichmentReport;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var Integration = function (_a) {
    var dataTestId = _a["data-testid"], _b = _a.color, color = _b === void 0 ? '#28343E' : _b, size = _a.size;
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ "data-testid": dataTestId, width: size, height: size, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [(0, jsx_runtime_1.jsx)("circle", { cx: "20.1052", cy: "19.9472", r: "2.89474", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M10.7085 4.89453H17.2103C18.809 4.89453 20.105 6.19055 20.105 7.78927V17.0524", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M13.0243 7.21032L10.7085 4.89453", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M13.0243 2.57886L10.7085 4.89465", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("circle", { cx: "3.89474", cy: "4.89474", r: "2.89474", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M13.2915 19.9474H6.78976C5.19104 19.9474 3.89502 18.6514 3.89502 17.0527V7.78955", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M10.9751 17.6318L13.2909 19.9476", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M10.9751 22.2633L13.2909 19.9475", stroke: color, strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" })] })));
};
exports.default = Integration;

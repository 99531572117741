import { ReactElement } from 'react';
import * as React from 'react';
import { ButtonIcon, EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import styles from './AttributeNameColumn.emotion';

interface Props {
  isGrandchild?: boolean;
  item: any;
  itemIdToExpandedRowMap: Record<string, ReactElement>;
  toggleDetails: (item: any) => void;
}

const AttributeNameColumn: React.FC<Props> = ({
  isGrandchild,
  item,
  itemIdToExpandedRowMap,
  toggleDetails,
}) => (
  <EuiFlexGroup
    data-testid="attribute-name-column"
    className={isGrandchild ? styles.grandchildContainer : undefined}
    alignItems="center"
    gutterSize="s"
  >
    <EuiFlexItem grow={false}>
      {item.children?.length ? (
        <ButtonIcon
          iconName={itemIdToExpandedRowMap[item.id] ? 'chevronDown' : 'chevronRight'}
          onClick={() => toggleDetails(item)}
        />
      ) : (
        <div className={styles.emptyDiv} />
      )}
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiText>
        <p>{item.name}</p>
      </EuiText>
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default AttributeNameColumn;

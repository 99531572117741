import { generatePath } from 'react-router';
import { Icon } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { NavItem } from './NavItem';

export const HomeLink = () => {
  const { selectedOrganization } = useUserSession();
  const homeLink = selectedOrganization?.name
    ? generatePath('/organizations/:organizationName/home', {
        organizationName: selectedOrganization?.name || '',
      })
    : '/';

  return (
    <NavItem testId="header-home-tab" icon={<Icon name="home" />} link={homeLink} label="Home" />
  );
};

export const Home = () => <HomeLink />;

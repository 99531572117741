import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import GroupedRow from '@app/components/GroupedRow/GroupedRow';
import { IColumn } from '@app/components/GroupedRow/types';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { IGetPropertyGroupsData } from '@app/queries/streams/PropertyGroupsQuery/types';
import { getTotalGroup } from '@app/queries/streams/PropertyGroupsQuery/utils';

const Container = styled.div`
  label: TotalBarContainer;
  border: 1px solid #e0e0e0;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 64px;
  overflow-y: hidden;
  height: 88px;
  display: flex;
  background-color: white;
  position: relative;
  overflow-x: hidden;

  :hover {
    overflow-x: auto;
  }
`;

interface Props {
  changesSince?: string;
  groupData: IGetPropertyGroupsData;
  loading: boolean;
}

const TotalBar: FC<Props> = ({ changesSince, groupData, loading }) => {
  const { stream } = useStreamContext();
  const [columns, setColumns] = useState<Array<IColumn>>();

  useEffect(() => {
    if (groupData) {
      const { explorers } = getTotalGroup(groupData, stream.snapshots, !!changesSince);
      setColumns(explorers);
    }
  }, [groupData]);

  if (loading || !columns) {
    return (
      <Container data-testid="total-bar">
        <LoadingSpinnerV2 />
      </Container>
    );
  }

  return (
    <Container data-testid="total-bar">
      <GroupedRow label="Current Properties" columns={columns} isToolbar />
    </Container>
  );
};

export default TotalBar;

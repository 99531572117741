import { FC } from 'react';
import gql from 'graphql-tag';
import { IPropertiesGalleryProperty } from '@app/components/PropertiesGallery/types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import EmptyStateMessage from '../EmptyStateMessage/EmptyStateMessage';
import PropertiesPageQuery from '../PropertiesPageQuery/PropertiesPageQuery';
import ReefGalleryBody from './ReefGalleryBody';

const GET_PROPERTIES_FOR_GALLERY = gql`
  query GetProperties($groupID: ID!, $cursor: String!, $pageSize: Int!) {
    propertiesPage(groupID: $groupID, cursor: $cursor, pageSize: $pageSize, imageSize: MEDIUM) {
      properties {
        id
        locationName
        totalInsuredValue
        totalInsuredValueDisplay
        pictures {
          filename
          httpURLs {
            medium
          }
        }
      }
      pageInfo {
        hasNextPage
        cursor
        totalCount
      }
    }
  }
`;

interface Props {
  stream: IGraphQLStream;
  propertyAttributeMetadata: Array<IGraphQLAttributeMetadata>;
  groupID?: string;
  totalProperties: number;
}

const ReefGallery: FC<Props> = ({
  stream,
  propertyAttributeMetadata,
  groupID = '',
  totalProperties,
}) => (
  <PropertiesPageQuery<IPropertiesGalleryProperty>
    query={GET_PROPERTIES_FOR_GALLERY}
    pageSize={500}
    groupID={groupID}
  >
    {({ properties, fetchMore }) => {
      const onLoadMore = fetchMore
        ? () =>
            fetchMore({
              groupID,
              query: GET_PROPERTIES_FOR_GALLERY,
            })
        : undefined;

      if (!properties.length) {
        return <EmptyStateMessage />;
      }

      return (
        <ReefGalleryBody
          stream={stream}
          properties={properties}
          totalProperties={totalProperties}
          onLoadMore={onLoadMore}
          propertyAttributeMetadata={propertyAttributeMetadata}
        />
      );
    }}
  </PropertiesPageQuery>
);

export default ReefGallery;

import { FC, useContext } from 'react';
import moment from 'moment';
import {
  Checkbox,
  EuiDatePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiText,
  EuiTextArea,
  Select,
  Spacer,
  TextField,
} from 'ui';
import InputWithFormattedValue from '@app/components/InputWithFormattedValue/InputWithFormattedValue';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { Casualty, ClientProspect, LineOfBusiness, PolicyEffectiveDates } from '@app/graphql/types';
import { useCasualtyData } from '../context/context';
import { AllPolicyEffectiveYearID, dateFormat, getDate } from '../utils';

interface StepOneProps {
  // HACK: to lookup the enterprise account id later.
  casualtyList: Array<Casualty>;
}

export const StepOne: FC<StepOneProps> = ({ casualtyList }) => {
  const { selectedOrganization } = useContext(UserSessionContext);

  // This is a little hack to get the enterprise account name. We lookup the enterprise account name
  // from the underlying account in the casualty list from the "currently opened" account.
  const c = casualtyList.find((c) => c.accountOrgName === selectedOrganization.name);
  const accountName = c?.orgName || selectedOrganization.name;

  const { casualty, setCasualty, viewOnly } = useCasualtyData();

  const enableLineOfBusiness = (lineOfBusiness: LineOfBusiness, desiredCheckedState: boolean) => {
    let c = casualty;
    let linesOfBusiness = casualty?.requestedLinesOfBusiness || [];
    if (desiredCheckedState) {
      linesOfBusiness.push({
        limit: 0,
        lineOfBusiness: lineOfBusiness,
        renewalOrRfpEffectiveDate: moment.utc().toDate(),
        retention: 0,
      });
    } else {
      linesOfBusiness = linesOfBusiness.filter((l) => l.lineOfBusiness !== lineOfBusiness);
    }
    c = {
      ...c,
      requestedLinesOfBusiness: linesOfBusiness,
    };
    setPolicyEffectiveDates(lineOfBusiness, c, moment.utc());
  };

  const setCurrencyValue = (lineOfBusiness: LineOfBusiness, key: string, value: number) => {
    if (value < 0) {
      return;
    }

    setCasualty({
      ...casualty,
      requestedLinesOfBusiness: casualty?.requestedLinesOfBusiness?.map((l) => {
        if (l.lineOfBusiness === lineOfBusiness) {
          return {
            ...l,
            [key]: +value,
          };
        }
        return l;
      }),
    });
  };

  // When we change the renewalDate we also need to update ALL the policyEffectiveDates
  const setPolicyEffectiveDates = (
    lineOfBusiness: LineOfBusiness,
    c: Casualty,
    e: moment.Moment,
  ) => {
    let dates: Array<PolicyEffectiveDates> = [];
    console.log(c?.policyEffectiveDates);
    if (c?.policyEffectiveDates?.length === undefined) {
      AllPolicyEffectiveYearID.forEach((label) => {
        dates.push({
          [lineOfBusiness.toLowerCase()]: getDate(label, e.toDate()),
          policyEffectiveYear: label,
        });
      });
    } else {
      dates = c?.policyEffectiveDates?.map((d) => ({
        ...d,
        [lineOfBusiness.toLowerCase()]: getDate(d.policyEffectiveYear, e.toDate()),
      }));
    }

    setCasualty({
      ...c,
      policyEffectiveDates: dates,
      requestedLinesOfBusiness: c?.requestedLinesOfBusiness?.map((l) => {
        if (l.lineOfBusiness === lineOfBusiness) {
          return {
            ...l,
            renewalOrRfpEffectiveDate: e.toDate(),
          };
        }
        return l;
      }),
    });
  };

  const wc = casualty?.requestedLinesOfBusiness?.find(
    (l) => l.lineOfBusiness === LineOfBusiness.Wc,
  );
  const al = casualty?.requestedLinesOfBusiness?.find(
    (l) => l.lineOfBusiness === LineOfBusiness.Al,
  );
  const gl = casualty?.requestedLinesOfBusiness?.find(
    (l) => l.lineOfBusiness === LineOfBusiness.Gl,
  );

  const size = 's';

  return (
    <>
      <EuiText>
        <h4>Analysis Request Details</h4>
      </EuiText>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow label="Account Name">
            <TextField value={viewOnly ? casualty.orgName : accountName} readOnly={true} />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Analysis Date">
            <EuiDatePicker
              selected={casualty?.analysisDate}
              onChange={(e) => {
                setCasualty({
                  ...casualty,
                  analysisDate: e,
                });
              }}
              dateFormat={dateFormat}
              readOnly={viewOnly}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem></EuiFlexItem>
      </EuiFlexGroup>
      <Spacer size={size} />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow label="Client Name *">
            <TextField
              value={casualty?.clientName}
              onChange={(e) => {
                setCasualty({
                  ...casualty,
                  clientName: e.target.value,
                });
              }}
              readOnly={viewOnly}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Client/Prospect">
            {viewOnly ? (
              <TextField value={casualty?.clientProspect} readOnly={viewOnly} />
            ) : (
              <Select
                onChange={(value) => {
                  setCasualty({
                    ...casualty,
                    clientProspect: ClientProspect[value],
                  });
                }}
                options={Object.values(ClientProspect).map((v) => ({ label: v, value: v }))}
                value={casualty?.clientProspect}
              />
            )}
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          {' '}
          <EuiFormRow label="Type of business">
            <TextField
              value={casualty?.typeOfBusiness}
              onChange={(e) => {
                setCasualty({
                  ...casualty,
                  typeOfBusiness: e.target.value,
                });
              }}
              readOnly={viewOnly}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <Spacer size={size} />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow label="SIC Code">
            <TextField
              value={casualty?.sicCode}
              onChange={(e) => {
                setCasualty({
                  ...casualty,
                  sicCode: e.target.value,
                });
              }}
              readOnly={viewOnly}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="NAICS Code">
            <TextField
              value={casualty?.naicsCode}
              onChange={(e) => {
                setCasualty({
                  ...casualty,
                  naicsCode: e.target.value,
                });
              }}
              readOnly={viewOnly}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem></EuiFlexItem>
      </EuiFlexGroup>
      <Spacer size={size} />
      <EuiText>
        <h4>Contact information</h4>
      </EuiText>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow label="Primary Contact">
            <TextField
              value={casualty?.primaryContactEmail}
              onChange={(e) => {
                setCasualty({
                  ...casualty,
                  primaryContactEmail: e.target.value,
                });
              }}
              readOnly={viewOnly}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Office">
            <TextField
              value={casualty?.office}
              onChange={(e) => {
                setCasualty({
                  ...casualty,
                  office: e.target.value,
                });
              }}
              readOnly={viewOnly}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Region">
            <TextField
              value={casualty?.region}
              onChange={(e) => {
                setCasualty({
                  ...casualty,
                  region: e.target.value,
                });
              }}
              readOnly={viewOnly}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Secondary Contact">
            <TextField
              value={casualty?.secondaryContactEmail}
              onChange={(e) => {
                setCasualty({
                  ...casualty,
                  secondaryContactEmail: e.target.value,
                });
              }}
              readOnly={viewOnly}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <Spacer size={size} />
      <EuiText>
        <h4>Requested Lines of Business </h4>
      </EuiText>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow label="&nbsp;">
            <Checkbox
              id="wc"
              label="Worker's Compensation"
              checked={!!wc}
              onChange={(e) => {
                enableLineOfBusiness(LineOfBusiness.Wc, e.target.checked);
              }}
              disabled={viewOnly}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label={`Retention`}>
            <InputWithFormattedValue
              value={wc?.retention}
              onChange={(e: number) => {
                setCurrencyValue(LineOfBusiness.Wc, 'retention', e);
              }}
              disabled={!wc}
              prepend="$"
              readOnly={wc ? viewOnly : undefined}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label={`Limit`}>
            <InputWithFormattedValue
              value={wc?.limit}
              onChange={(e: number) => {
                setCurrencyValue(LineOfBusiness.Wc, 'limit', e);
              }}
              disabled={!wc}
              prepend="$"
              readOnly={wc ? viewOnly : undefined}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Renewal Date">
            <EuiDatePicker
              selected={
                wc?.renewalOrRfpEffectiveDate ? moment.utc(wc?.renewalOrRfpEffectiveDate) : null
              }
              onChange={(e) => {
                setPolicyEffectiveDates(LineOfBusiness.Wc, casualty, e);
              }}
              disabled={!wc}
              readOnly={wc ? viewOnly : undefined}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <Spacer size={size} />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow label="&nbsp;">
            <Checkbox
              id="al"
              label="Auto Liability"
              checked={!!al}
              onChange={(e) => {
                enableLineOfBusiness(LineOfBusiness.Al, e.target.checked);
              }}
              disabled={viewOnly}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label={`Retention`}>
            <InputWithFormattedValue
              value={al?.retention}
              onChange={(e: number) => {
                setCurrencyValue(LineOfBusiness.Al, 'retention', e);
              }}
              disabled={!al}
              prepend="$"
              readOnly={al ? viewOnly : undefined}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label={`Limit`}>
            <InputWithFormattedValue
              value={al?.limit}
              onChange={(e: number) => {
                setCurrencyValue(LineOfBusiness.Al, 'limit', e);
              }}
              disabled={!al}
              prepend="$"
              readOnly={al ? viewOnly : undefined}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Renewal Date">
            <EuiDatePicker
              selected={
                al?.renewalOrRfpEffectiveDate ? moment.utc(al?.renewalOrRfpEffectiveDate) : null
              }
              onChange={(e) => {
                setPolicyEffectiveDates(LineOfBusiness.Al, casualty, e);
              }}
              disabled={!al}
              readOnly={al ? viewOnly : undefined}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <Spacer size={size} />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFormRow label="&nbsp;">
            <Checkbox
              id="el"
              label="General Liability"
              checked={!!gl}
              onChange={(e) => {
                enableLineOfBusiness(LineOfBusiness.Gl, e.target.checked);
              }}
              disabled={viewOnly}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label={`Retention`}>
            <InputWithFormattedValue
              value={gl?.retention}
              onChange={(e: number) => {
                setCurrencyValue(LineOfBusiness.Gl, 'retention', e);
              }}
              disabled={!gl}
              prepend="$"
              readOnly={gl ? viewOnly : undefined}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label={`Limit`}>
            <InputWithFormattedValue
              value={gl?.limit}
              onChange={(e: number) => {
                setCurrencyValue(LineOfBusiness.Gl, 'limit', e);
              }}
              disabled={!gl}
              prepend="$"
              readOnly={gl ? viewOnly : undefined}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFormRow label="Renewal Date">
            <EuiDatePicker
              selected={
                gl?.renewalOrRfpEffectiveDate ? moment.utc(gl?.renewalOrRfpEffectiveDate) : null
              }
              onChange={(e) => {
                setPolicyEffectiveDates(LineOfBusiness.Gl, casualty, e);
              }}
              disabled={!gl}
              readOnly={gl ? viewOnly : undefined}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <Spacer size={size} />
      <EuiText>
        <h4>Client Net Worth</h4>
      </EuiText>
      <EuiFlexGroup>
        <EuiFlexItem grow={1}>
          <EuiFormRow label="Client Net Worth">
            <InputWithFormattedValue
              value={casualty?.clientNetWorth}
              onChange={(e: number) => {
                setCasualty({
                  ...casualty,
                  clientNetWorth: +e,
                });
              }}
              prepend="$"
              readOnly={viewOnly}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={2}></EuiFlexItem>
      </EuiFlexGroup>
      <Spacer size={size} />
      <EuiText>
        <h4>Analysis notes</h4>
      </EuiText>
      <EuiFlexGroup>
        <EuiFlexItem grow={2}>
          <EuiFormRow label="Analysis notes" fullWidth>
            <EuiTextArea
              value={casualty?.analysisNotes}
              onChange={(e) => {
                setCasualty({
                  ...casualty,
                  analysisNotes: e.target.value,
                });
              }}
              readOnly={viewOnly}
              fullWidth
            />
          </EuiFormRow>{' '}
        </EuiFlexItem>
        <EuiFlexItem grow={1}></EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

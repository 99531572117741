import { FC, ReactNode } from 'react';
import { EuiFlexItem, EuiStat, EuiText, EuiTextColor, Icon } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { formatNumber } from '@app/utils/format';
import ErrorState from '../../components/ErrorState';
import {
  Arrows,
  ContentContainer,
  FooterContainer,
  FooterContent,
  TimestampRow,
} from './OverviewItem.emotion';

export interface OverviewItemProps {
  loading?: boolean;
  error?: boolean;
  title: ReactNode | string;
  content: ReactNode;
  percentChange?: number;
  isToday?: boolean;
  footerContent?: ReactNode;
  footNote?: string;
  children?: ReactNode;
}

// TODO:
// * [ ] Refactor component with a general name. Stating what it does, instead
//  of what it is. This way, we can reuse it with any context and know what's
//  the component usability. For example, I'm going to use this component in
//  an area outside of overview and it's confusing to have a component named
//  `OverViewItem` when I'm gong to use it for exposed TIV areas.
// * [ ] Rename `title` and `content` to match `EuiStat` props.
const OverviewItem: FC<OverviewItemProps> = ({
  loading = false,
  error = false,
  title,
  content,
  percentChange,
  isToday,
  footerContent,
  footNote,
}) => {
  if (loading) {
    return <LoadingSpinnerV2 />;
  }

  if (error) {
    return <ErrorState />;
  }

  const Footer = () => {
    if (footerContent) {
      return <>{footerContent}</>;
    }

    if (isToday) {
      return (
        <TimestampRow>
          <EuiText size="s">As of today</EuiText>
        </TimestampRow>
      );
    }

    if (percentChange > 0 || percentChange < 0) {
      return (
        <TimestampRow>
          <EuiText size="s">
            <Arrows color={percentChange && percentChange > 0 ? 'success' : 'danger'}>
              {percentChange > 0 ? <Icon name="arrowUpRight" /> : <Icon name="arrowDownRight" />}
              {formatNumber(percentChange, null, {
                maximumFractionDigits: 2,
              })}
              %
            </Arrows>{' '}
            {footNote}
          </EuiText>
        </TimestampRow>
      );
    }
    return (
      <TimestampRow>
        <EuiText size="s">
          <EuiTextColor color="subdued">Prev. bind n/a</EuiTextColor>{' '}
        </EuiText>
      </TimestampRow>
    );
  };

  return (
    <EuiFlexItem grow={1}>
      <ContentContainer direction="row" justifyContent="flexStart">
        <EuiFlexItem>
          <EuiStat title={content} description={title} titleSize="xs" />
        </EuiFlexItem>
      </ContentContainer>
      <FooterContainer direction="row" justifyContent="flexStart">
        <FooterContent>
          <EuiText>
            <Footer />
          </EuiText>
        </FooterContent>
      </FooterContainer>
    </EuiFlexItem>
  );
};

export default OverviewItem;

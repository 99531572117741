import { FC, ReactElement } from 'react';

interface IProps {
  href: string;
  children: ReactElement | string | null | undefined | boolean;
  handleOpen?: (url: string) => void;
}

const MarkdownLink: FC<IProps> = ({ children, href, handleOpen }) => (
  <a
    href={href}
    rel="noopener noreferrer"
    target="_blank"
    onClick={(e) => {
      e.stopPropagation();
      if (handleOpen) {
        handleOpen(href);
      }
    }}
  >
    {children}
  </a>
);

export default MarkdownLink;

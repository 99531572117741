import { useContext, useState } from 'react';
import * as React from 'react';
import { ApolloError } from '@apollo/client';
import PropertyItem from '@onarchipelago/dice/EnrichmentProjects/PropertyItem';
import SelectPropertiesFlyout from '@onarchipelago/dice/EnrichmentProjects/SelectPropertiesFlyout';
import { EPropertySelectType } from '@onarchipelago/dice/EnrichmentProjects/types';
import {
  Button,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutFooter,
  EuiFocusTrap,
  useToast,
} from 'ui';
import { PropertyAttributeMetadata } from '@app/components/QueryBuilderWrapper/utils';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { ModalContext } from '@app/contexts/ModalContext';
import { IGraphQLProperty } from '@app/queries/properties/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import DeletePropertyModal from './DeletePropertyModal/DeletePropertyModal';
import { MetadataMap } from './StreamProvider';
import useDisposeProperties from './useDisposeProperties';

interface IProps {
  metadataMap: MetadataMap;
  orgID: string;
  orgName: string;
  filterable: Array<PropertyAttributeMetadata>;
  streamSlug: string;
}

const DisposePropertyFlyout: React.FC<IProps> = ({
  metadataMap,
  orgName,
  filterable,
  streamSlug,
}) => {
  const { closeFlyout } = useContext(FlyoutContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const toast = useToast();
  const [selectedProperties, setSelectedProperties] = useState<IGraphQLProperty[]>([]);
  const handleUpdateSelectedProperties = (updatedProperties: IGraphQLProperty[]) => {
    setSelectedProperties([...updatedProperties]);
  };

  const { loading, start } = useDisposeProperties({
    onAPIError: (err: ApolloError) => toast({ title: getErrorMessage(err), type: 'danger' }),
    onJobError: () => toast({ title: 'Could not dispose of properties', type: 'danger' }),
    onSuccess: () => {
      toast({
        title: 'Successfully disposed of properties, changes should be visible in 15-20 minutes',
        type: 'success',
      });
      closeModal();
      closeFlyout();
    },
    onTimeout: () =>
      toast({
        title:
          'We are still disposing of the properties you selected, please come back later to see your changes or try again',
      }),
    orgName,
    propertyIDs: selectedProperties.map((p) => p.archipelagoId || ''),
    streamSlug,
  });

  const propertyItem = (renderedRow: any) => (
    <PropertyItem
      multiple
      item={renderedRow.item}
      selectedProperties={selectedProperties}
      updateSelectedProperties={handleUpdateSelectedProperties}
    />
  );

  return (
    <EuiFocusTrap>
      <EuiFlyout
        data-testid="m-m-dispose-flyout"
        ownFocus
        onClose={closeFlyout}
        aria-labelledby="flyoutTitle"
        style={{ maxWidth: '0px', minWidth: '600px' }}
      >
        <SelectPropertiesFlyout
          multiple
          title="Dispose of properties"
          orgName={orgName}
          selectedItems={selectedProperties}
          rowComponent={propertyItem}
          filterable={filterable}
          updateSelectedProperties={handleUpdateSelectedProperties}
          propertySelectType={EPropertySelectType.AllProperties}
        />
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="flexEnd">
            <EuiFlexItem grow={false}>
              <Button
                data-testid="m-m-dispose-submit"
                onClick={() =>
                  showModal(
                    <DeletePropertyModal
                      confirmButtonLabel="Dispose"
                      metadataMap={metadataMap}
                      closeModal={closeModal}
                      selectedProperties={selectedProperties}
                      handleStart={start}
                      loading={loading}
                      formatTitle={(multiple) =>
                        `Dispose of ${multiple ? 'these properties' : 'this property'}`
                      }
                      footNote="If you change your mind later, you can always recover it; just ask your Account Manager for assistance."
                    />,
                  )
                }
                fill
                disabled={selectedProperties.length === 0}
                iconSide="right"
                loading={loading}
                label={loading ? 'Disposing' : 'Dispose'}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </EuiFocusTrap>
  );
};

export default DisposePropertyFlyout;

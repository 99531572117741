import { FormCurrencyInput } from '@app/components/Form/FormCurrencyInput';
import { FormTextInput } from '@app/components/Form/FormTextInput';
import { ColumnType } from '@app/components/PropertiesGrid/types';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { EDIT_CELL_INPUT_KEY } from '../constants';

interface IEditFormFieldProps {
  type: ColumnType;
  name?: string;
  readOnly?: boolean;
  fullWidth?: boolean;
  currency?: string;
}

export const EditFormField = ({
  type,
  readOnly = false,
  name = EDIT_CELL_INPUT_KEY,
  fullWidth = false,
  currency = '',
}: IEditFormFieldProps) => {
  const { stream } = useStreamContext();
  switch (type) {
    case 'currency':
    case 'currencyLocal':
      return (
        <FormCurrencyInput
          name={name}
          inputProps={{
            disabled: readOnly,
            fullWidth,
            prepend: <b>{currency || stream?.displayCurrency}</b>,
          }}
        />
      );

    default:
      return <FormTextInput inputProps={{ fullWidth, readOnly }} name={name} />;
  }
};

import { FC, useEffect } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import IndexBenchmarksCard from '@app/cx/ExposureIndex/cards/IndexBenchmarksCard/IndexBenchmarksCard';
import IndexSummaryCard from '@app/cx/ExposureIndex/cards/IndexSummaryCard/IndexSummaryCard';
import SnapshotSelect from '@app/cx/ExposureIndex/components/SnapshotSelect';
import { ExposureIndexContextProvider } from '@app/cx/ExposureIndex/context/exposureIndexContext';
import { useTracker } from '@app/hooks/useTracker';
import { flexColumnStyle, reportPageStyle } from '../reports.emotion';
import { ReportsHeader } from '../ReportsHeader/ReportsHeader';
import { ReportProps } from '../types';

const ExposureSummaryReport: FC<ReportProps> = ({ visible }) => {
  if (!visible) {
    return null;
  }

  const tracker = useTracker();

  useEffect(() => {
    tracker.track('Open Report', {
      report_name: 'Exposure Summary',
    });
  }, []);

  return (
    <ExposureIndexContextProvider>
      <EuiFlexGroup
        direction="column"
        responsive={false}
        style={reportPageStyle}
        data-testid="report-exposure-summary"
      >
        <EuiFlexItem grow={false}>
          <ReportsHeader header="Exposure Summary" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <SnapshotSelect />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row">
            <EuiFlexItem grow={2}>
              <IndexSummaryCard
                // FIX ME
                // @ts-ignore
                segmentPercents={{
                  High: 10,
                  Low: 10,
                  Medium: 15,
                  VeryHigh: 37.5,
                  VeryLow: 37.5,
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup direction="row">
            <EuiFlexItem grow={2}>
              <EuiFlexGroup direction="column">
                <EuiFlexItem style={{ flex: '1 1 auto' }}>
                  <div style={flexColumnStyle}>
                    <IndexBenchmarksCard />
                  </div>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </ExposureIndexContextProvider>
  );
};

export default ExposureSummaryReport;

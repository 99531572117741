import { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import { NoDataText } from './NoData.emotion';

interface Props {
  message: string;
}

const NoData: FC<Props> = ({ message }) => (
  <EuiFlexGroup>
    <EuiFlexItem>
      <NoDataText>
        <p data-testid="no-data-message">{message}</p>
      </NoDataText>
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default NoData;

import { useHistory, useLocation } from 'react-router';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiLink,
  EuiText,
  HorizontalAlignment,
  Icon,
  Spacer,
} from 'ui';
import Currency from '@app/components/Currency/Currency';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { formatPercentage } from '@app/utils/format';
import { buttonLabelStyles } from '../PropertyDistributionHistogram/PropertyDistributionHistogram.emotion';
import { useValuationSummaryReportContext } from '../ValuationSummaryReportContext';
import { titleContainerStyles,titleStyle } from './table.emotion';
import TableSort from './TableSort';

export const ValuationSummaryTable = () => {
  const { loading, tableData, streamSlug, propertyValTableIsLoading } =
    useValuationSummaryReportContext();
  const history = useHistory();
  const { search, pathname } = useLocation();

  const columns = [
    {
      name: 'Building Name',
      render: (item: any) => (
          <EuiLink onClick={() => history.push(`${pathname}/properties/${item.id}`)}>
            {item.locationName}
          </EuiLink>
        ),
    },
    {
      name: 'Street Address',
      render: (item: any) => item.streetAddress as string,
    },
    {
      name: 'State',
      render: (item: any) => item.usStateCode as string,
    },
    {
      name: 'Occupancy',
      render: (item: any) => item.occupancyType as string,
    },
    {
      align: 'right' as HorizontalAlignment,
      name: '% of Total RCV',
      render: (item: any) => formatPercentage(item.brcPercentOfTotal, false),
      sortable: (item: any) => item.brcPercentOfTotal,
    },
    {
      align: 'right' as HorizontalAlignment,
      name: '$ RCV',
      render: (item: any) => <Currency value={item?.brc} />,
    },
    {
      align: 'right' as HorizontalAlignment,
      name: '% Difference',
      render: (item: any) => formatPercentage(item.brcPercentDiff, false),
    },
    {
      align: 'right' as HorizontalAlignment,
      name: '$ Difference',
      render: (item: any) => <Currency value={item?.brcPercentValueDifference} />,
    },
  ];

  return (
    <>
      <EuiFlexGroup direction="column">
        <EuiFlexGroup
          style={titleContainerStyles}
          alignItems="center"
          direction="row"
          justifyContent="spaceBetween"
        >
          <EuiFlexItem grow={false}>
            <EuiText style={titleStyle}>Properties with the largest value differences</EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <TableSort labelStyles={buttonLabelStyles} />
          </EuiFlexItem>
        </EuiFlexGroup>
        <Spacer />
        {loading || propertyValTableIsLoading ? (
          <LoadingSpinnerV2
            dataTestId="valuation-summary-table-loading-spinner"
            styles={{ height: '581px' }}
          />
        ) : (
          <>
            <EuiFlexItem grow={false}>
              <EuiInMemoryTable
                data-testid="submission-table"
                columns={columns}
                items={tableData?.streamPropertyValuations?.properties || []}
                itemId="id"
                hasActions={false}
                loading={loading}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false} style={{ alignItems: 'end' }}>
              <EuiText size="s">
                <EuiLink
                  onClick={() => {
                    history.push({ pathname: encodeUrl`/streams/${streamSlug}`, search });
                  }}
                >
                  View all properties <Icon name="arrowRight" size="xs" />
                </EuiLink>
              </EuiText>
            </EuiFlexItem>
          </>
        )}
      </EuiFlexGroup>
    </>
  );
};

import { useState } from 'react';
import * as React from 'react';
import { useQuery } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import { HazardExposureType } from '@onarchipelago/cx/Reports/contexts/reportsContext/types';
import {
  AttributeScoresData,
  AttributeScoresVariables,
  GET_ATTRIBUTE_SCORES,
} from '@onarchipelago/cx/Reports/queries/getAttributeScores';
import getAttributes from '@onarchipelago/cx/Reports/utils/getAttributes';
import getCharts from '@onarchipelago/cx/Reports/utils/getCharts';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import ErrorState from '../../components/ErrorState';
import SecondaryAttributesComponent from './SecondaryAttributesComponent';

const SecondaryAttributesContainer: React.FC = () => {
  const { snapshots, stream } = useReportsContext();
  const { streamSlug } = stream;
  const { afterSnapshot, currentSnapshot, hazardOptions } = snapshots;
  const [currentHazard, setCurrentHazard] = useState<HazardExposureType>('Earthquake');

  const handleChange = (value: HazardExposureType) => setCurrentHazard(value);

  const {
    data: currentSnapshotData,
    loading: currentSnapshotLoading,
    error: currentSnapshotError,
  } = useQuery<AttributeScoresData, AttributeScoresVariables>(GET_ATTRIBUTE_SCORES, {
    variables: {
      input: {
        hazard: currentHazard,
        snapshotName: currentSnapshot?.snapshotName || '',
        streamSlug,
      },
    },
  });

  const {
    data: afterSnapshotData,
    loading: afterSnapshotLoading,
    error: afterSnapshotError,
  } = useQuery<AttributeScoresData, AttributeScoresVariables>(GET_ATTRIBUTE_SCORES, {
    variables: {
      input: {
        hazard: currentHazard,
        snapshotName: afterSnapshot?.snapshotName || '',
        streamSlug,
      },
    },
  });

  if (
    (currentSnapshotLoading || afterSnapshotLoading) &&
    !currentSnapshotError &&
    !afterSnapshotError
  ) {
    return <LoadingSpinnerV2 styles={{ height: '1016px' }} />;
  }

  if (
    !currentSnapshotLoading &&
    !afterSnapshotLoading &&
    (currentSnapshotError || afterSnapshotError)
  ) {
    return <ErrorState />;
  }

  if (
    currentSnapshotData &&
    afterSnapshotData &&
    hazardOptions &&
    !currentSnapshotLoading &&
    !afterSnapshotLoading &&
    !currentSnapshotError &&
    !afterSnapshotError
  ) {
    const attributes = getAttributes(currentHazard);

    const finalHazardOptions = hazardOptions
      .filter((hazardOption) => hazardOption.value !== 'All')
      .map((hazardOption) => ({
        label: `${hazardOption.value} Attributes`,
        value: hazardOption.value,
      }));

    return (
      <SecondaryAttributesComponent
        charts={getCharts(currentSnapshotData, afterSnapshotData, attributes)}
        currentHazard={currentHazard}
        handleChange={handleChange}
        hazardOptions={finalHazardOptions}
      />
    );
  }

  return null;
};

export default SecondaryAttributesContainer;

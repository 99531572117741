import { ReactElement, useState } from 'react';
import * as React from 'react';
import { EuiBasicTable } from 'ui';
import { useAttributeMetadataEditorContext } from '../context/attributeMetadataEditorContext';
import { PropertyMetadata } from '../types';
import { groupMetadata } from '../utils';
import getMetadataTableItems from './getMetadataTableItems';
import styles from './MetadataTable.emotion';
import MetadataTableChildren from './MetadataTableChildren';
import metadataTableColumns from './metadataTableColumns';
import useUpdateBoolean from './useUpdateBoolean';

interface Props {
  propertyMetadata: Array<PropertyMetadata>;
}

const MetadataTable: React.FC<Props> = ({ propertyMetadata }) => {
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState<
    Record<string, ReactElement>
  >({});

  const { setSelectedAttribute } = useAttributeMetadataEditorContext();

  const toggleDetails = (item: any) => {
    if (itemIdToExpandedRowMap[item.id]) {
      setItemIdToExpandedRowMap((prevState) => {
        const finalState = { ...prevState };
        delete finalState[item.id];

        return finalState;
      });
    } else {
      const parentAttributeName = item.name.split(' ')[0];
      const parentAttribute = propertyMetadata.filter(
        (datum) => datum.name === parentAttributeName,
      )[0];

      setItemIdToExpandedRowMap((prevState) => ({
        ...prevState,
        [item.id]: (
          <MetadataTableChildren
            data={item?.children}
            parentAttribute={parentAttribute}
            propertyMetadata={propertyMetadata}
          />
        ),
      }));
    }
  };

  const updateBoolean = useUpdateBoolean();

  const columns = metadataTableColumns({
    handleEdit: (attribute) => setSelectedAttribute({ attribute }),
    handleUpdate: updateBoolean,
    itemIdToExpandedRowMap,
    toggleDetails,
  });

  const items = getMetadataTableItems(groupMetadata(propertyMetadata)).filter((item) => item.name);

  return (
    <EuiBasicTable
      data-testid="attribute-metadata-table"
      className={styles.table}
      items={items}
      itemID="id"
      itemIdToExpandedRowMap={itemIdToExpandedRowMap}
      columns={columns}
    />
  );
};

export default MetadataTable;

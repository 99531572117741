import { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText, EuiTextColor, Spacer } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import ErrorState from '@app/cx/Reports/components/ErrorState';
import Line from '@app/cx/Reports/ReadinessReport/Line';
import ExposureBar from '../../charts/ExposureBar/ExposureBar';
import { useExposureIndexContext } from '../../context/exposureIndexContext';
import { ExposureLevel } from '../../types';
import exposureLabels from '../../utils/exposureLabels';
import IndexSummary from './IndexSummary';
import styles from './IndexSummaryCard.emotion';
import IndexSummaryKey from './IndexSummaryKey';
import { getNonStandardExposureLabels } from './utils';

const IndexSummaryCard: FC = () => {
  const { exposure, snapshots } = useExposureIndexContext();

  const portfolioN = exposure.portfolioN;
  const loading = exposure.loading;
  const error = exposure.hasError;

  const exposureLevel = exposure?.exposureGrade || ExposureLevel.Low;

  if (!loading && error) {
    return <ErrorState />;
  }

  if ((!snapshots.loading && snapshots.snapshotOptions.length === 0) || exposure.noData) {
    return <p>No data available</p>;
  }

  // for very low and very high, the message should be 'the lowest/highest' instead of 'a (level)' risk
  const nonStandardLabels = [ExposureLevel.VeryHigh, ExposureLevel.VeryLow];

  return (
    <EuiPanel data-testid="index-summary-card" paddingSize="l" className={styles.panel}>
      <EuiFlexGroup>
        {loading && !error ? (
          <LoadingSpinnerV2 styles={{ height: '240px' }} />
        ) : (
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="column">
              <EuiFlexItem grow={false}>
                <IndexSummary exposureLevel={exposureLevel} />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>
                  <EuiTextColor color="subdued">
                    <p>
                      {`Using exposure information to estimate risk, this portfolio exhibits ${
                        nonStandardLabels.includes(exposureLevel)
                          ? getNonStandardExposureLabels(exposureLevel)
                          : `a ${  exposureLabels[exposureLevel].toLowerCase()}`
                      } level of risk
                compared to others in the same industry.`}
                    </p>
                  </EuiTextColor>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <Line />
        </EuiFlexItem>
        {loading && !error ? (
          <LoadingSpinnerV2 styles={{ height: '240px' }} />
        ) : (
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="column" justifyContent="spaceBetween" gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiText>
                  <EuiTextColor color="subdued">
                    <p>
                      {`This is your exposure level relative to ${portfolioN} comparable portfolios on Archipelago as
                of today.`}
                    </p>
                  </EuiTextColor>
                </EuiText>
              </EuiFlexItem>
              <Spacer size="xl" />
              <EuiFlexItem grow={false}>
                <EuiFlexGroup direction="column" gutterSize="m">
                  <EuiFlexItem grow={false}>
                    <ExposureBar />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <IndexSummaryKey />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </EuiPanel>
  );
};

export default IndexSummaryCard;

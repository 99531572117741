import { CSSProperties, FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, Spacer, Spinner } from 'ui';
import { string } from 'yup';

interface Props {
  label?: string;
  variant?: string;
  dataTestId?: string;
  styles?: CSSProperties;
  classNames?: string;
}

const LoadingSpinnerV2: FC<Props> = ({
  dataTestId,
  label,
  variant = 'xl',
  styles,
  classNames,
  ...restProps
}) => (
  <EuiFlexGroup
    data-testid={dataTestId}
    direction="column"
    gutterSize="s"
    justifyContent="center"
    alignItems="center"
    className={classNames}
    style={{ height: '100%', ...styles }}
    {...restProps}
  >
    <EuiFlexItem
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Spinner size={variant} />
      {label && (
        <>
          <Spacer size="s" />
          <EuiText>
            <h3>{label}</h3>
          </EuiText>
          <Spacer size="xl" />
        </>
      )}
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default LoadingSpinnerV2;

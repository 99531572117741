import * as React from 'react';
import styled from '@emotion/styled';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { formatCurrency } from '@app/utils/format';

const Tooltip = styled.div<{
  hovering?: boolean;
}>`
  label: Tooltip;

  ${({ hovering }) =>
    hovering
      ? `
      opacity: 1;
      display: block;
    `
      : `
      opacity: 0;
      display: none;
    `}
  position: absolute;
  bottom: -30px;
  left: 0;
  min-height: 24px;
  min-width: 77px;
  background-color: rgba(255, 255, 255, 0.9);
  color: rgba(52, 55, 65, 1);
  font-size: 8px;
  text-align: center;
  padding: 2px 4px;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 6px;
  z-index: 2;
`;

const lossAttributes: Array<string> = ['lossCountTotal', 'totalGrossLoss'];

interface Props {
  hovering: boolean;

  clusterValues: {
    num: number;
    tiv: number;
    lossCountTotal: number;
    totalGrossLoss: number;
  };
}
const ClusterTooltip: React.FC<Props> = ({ hovering, clusterValues }) => {
  const { metadataMap, permissions } = useStreamContext();
  return (
    <Tooltip hovering={hovering}>
      <div>Properties: {clusterValues.num}</div>
      <div>TIV: {formatCurrency(clusterValues.tiv)}</div>
      {permissions?.canViewLosses &&
        lossAttributes.map((attributeName) => {
          const attribute = metadataMap[attributeName];
          if (!attribute) {
            return null;
          }

          const { name, displayName, dataType } = attribute;
          return (
            <div>
              {displayName}:{' '}
              {dataType === 'currency'
                ? formatCurrency(clusterValues[name], { currency: 'USD' })
                : clusterValues[name]}
            </div>
          );
        })}
    </Tooltip>
  );
};

export default ClusterTooltip;

import * as React from 'react';
import { EuiText, Icon } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import styles from './BenchmarksTable.emotion';

const BenchmarksTableHeader: React.FC = () => (
  <div data-testid="exposure-benchmarks-table-header" className={styles.rowContainer}>
    <div className={styles.exposureContainer}>
      <EuiText size="s">
        <b>Exposure</b>
      </EuiText>
    </div>
    <div className={styles.spacer} />
    <div className={styles.tivContainer}>
      <EuiText size="s">
        <b>% TIV</b>
      </EuiText>
    </div>
    <div className={styles.spacer} />
    <div className={styles.comparisonContainer}>
      <EuiText size="s">
        <b>Comparison with other {STREAMS_LABEL}s</b>
      </EuiText>
    </div>
    <div className={styles.spacer} />
  </div>
);

export default BenchmarksTableHeader;

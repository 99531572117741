import { FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsChart from 'highcharts-react-official';

interface Props {
  options: Highcharts.Options;
}

const Histogram: FC<Props> = ({ options }) => (
  <HighchartsChart highcharts={Highcharts} options={options} />
);

export default Histogram;

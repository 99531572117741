import { createContext, ReactNode, useContext, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import { GetQualityData } from '@onarchipelago/cx/Reports/queries/getQuality';
import useGetQualitySnapshotOverall from '../contexts/useGetQualitySnapshotOverall';
import { RiskAttribute } from './InsurerInsights/tabs/GradingDetails/types';

export interface OverviewField {
  value: number | string;
  percentChange?: number;
}
export interface OverviewData {
  TIV: OverviewField;
  percentile: OverviewField;
  placementGrade: OverviewField;
  properties: OverviewField;
}

export interface IUnderwritingAssessmentReportContext {
  overviewData?: GetQualityData;
  overviewDataIsLoading: boolean;
  overviewDataError?: ApolloError;
  insurerInsightsTab?: 'grading-overview' | 'grading-details' | 'loss-history-details';
  gradingDetailsSection?: RiskAttribute;
  gradingDetailsFilter?: string;
  setInsurerInsightsTab?: (tabName: string) => void;
  setGradingSection?: (value: RiskAttribute) => void;
  setGradingFilter?: (value: string) => void;
}

export const UnderwritingAssessmentReportContext = createContext(
  {} as IUnderwritingAssessmentReportContext,
);

export const UnderwritingAssessmentReportProvider = ({ children }: { children: ReactNode }) => {
  const { snapshots, stream } = useReportsContext();
  const { streamSlug } = stream;
  const { currentSnapshot } = snapshots;
  const snapshotName = currentSnapshot?.snapshotName || '';

  const [insurerTab, setInsurerTab] = useState<
    'grading-overview' | 'grading-details' | 'loss-history-details'
  >('grading-overview');

  const [gradingDetailsSection, setGradingDetailsSection] = useState<RiskAttribute>();
  const [gradingDetailsFilter, setGradingDetailsFilter] = useState<string>('');

  const setGradingSection = (value: RiskAttribute) => setGradingDetailsSection(value);
  const setGradingFilter = (value: string) => setGradingDetailsFilter(value);

  const setInsurerTabName = (
    tabName: 'grading-overview' | 'grading-details' | 'loss-history-details',
  ) => setInsurerTab(tabName);

  const qualitySnapshotData = useGetQualitySnapshotOverall(
    streamSlug,
    snapshotName,
    insurerTab,
    gradingDetailsSection,
    gradingDetailsFilter,
    setInsurerTabName,
    setGradingSection,
    setGradingFilter,
  );

  return (
    <UnderwritingAssessmentReportContext.Provider value={qualitySnapshotData}>
      {children}
    </UnderwritingAssessmentReportContext.Provider>
  );
};

// ----- USE CONTEXT HOOK ----- //

export const useUnderwritingAssessmentReportContext = (): IUnderwritingAssessmentReportContext =>
  useContext(UnderwritingAssessmentReportContext);

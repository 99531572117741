import { useState } from 'react';
import * as React from 'react';
import { Checkbox, EuiFormRow, IntegerField } from 'ui';
import ErrorMessage from '@app/components/ErrorMessage/ErrorMessage';
import { useOrgModalContext } from '../../context/OrgModal.context';

const IntegrationsTab: React.FC<{
  organizationId: string;
  organizationName: string;
}> = () => {
  const { integrationsSettings, setIntegrationsSettings, disabled } = useOrgModalContext();
  const [touched, setTouched] = useState(false);

  return (
    <>
      <EuiFormRow label="Integrations" fullWidth>
        <Checkbox
          id="enableE2Value"
          label="Enable E2Value"
          data-testid="enable-e2value-checkbox"
          checked={integrationsSettings.enabled}
          onChange={(value) => {
            setIntegrationsSettings({
              ...integrationsSettings,
              enabled: !integrationsSettings.enabled,
            });
            setTouched(true);
          }}
          disabled={disabled}
        />
      </EuiFormRow>
      <EuiFormRow
        fullWidth
        label="Property limit"
        helpText="This limit is on a per-vendor basis, and is cumulative for the account."
      >
        <IntegerField
          data-testid="property-limit-integer-field"
          fullWidth
          onChange={(value) => {
            setIntegrationsSettings({
              ...integrationsSettings,
              limit: value,
            });
            setTouched(true);
          }}
          value={integrationsSettings.limit}
          readOnly={disabled}
        />
      </EuiFormRow>
    </>
  );
};
export default IntegrationsTab;

import { FC } from 'react';
import { ValueEditorProps } from 'react-querybuilder';
import { Filters } from '../types';
import { isEmptyOperator, isNotEmptyOperator } from '../utils';
import InputOrSelect from './InputOrSelect';
import { ValueEditorContainer } from './ValueEditor.emotion';

interface Props extends ValueEditorProps {
  filters: Filters;
}

const ValueEditor: FC<Props> = ({ field, filters, handleOnChange, operator, value }) => {
  if (!field) {return null;}
  // sometimes operator is set to a different value then that is possible for this field.
  const filter = filters[field];
  const has = filter?.operators.some((element) => element.name === operator);
  let operatorOverride = operator;

  if (!has) {
    // if the operator passed here is not in our administration we default to the first one.
    if (filter?.operators.length > 0) {
      operatorOverride = filter.defaultOperator.name;
    }
  }

  // Gets the value from [object] and returns the value.
  const onInputChange = (value: string) => {
    if (handleOnChange) {
      handleOnChange(value);
    }
  };

  // Don't render the Value Box when the operator == isEmpty or isNotEmpty
  if (operatorOverride === isEmptyOperator.name || operatorOverride === isNotEmptyOperator.name) {
    return null;
  }

  // Render the value box or dropdown
  return (
    <ValueEditorContainer data-testid="query-builder-wrapper-value-editor">
      <InputOrSelect
        filter={filter}
        handleOnChange={onInputChange}
        operator={operator}
        value={value}
      />
    </ValueEditorContainer>
  );
};

export default ValueEditor;

"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomSelect = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var eui_1 = require("@elastic/eui");
var CustomSelect = function (_a) {
    var dataTestId = _a["data-testid"], _b = _a.compressed, compressed = _b === void 0 ? false : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.fullWidth, fullWidth = _d === void 0 ? false : _d, initialSelected = _a.initialSelected, _e = _a.invalid, invalid = _e === void 0 ? false : _e, _f = _a.isClearable, isClearable = _f === void 0 ? true : _f, onBlur = _a.onBlur, onChange = _a.onChange, onCreateOption = _a.onCreateOption, options = _a.options, placeholder = _a.placeholder, _g = _a.async, async = _g === void 0 ? false : _g, onSearchChange = _a.onSearchChange, _h = _a.isLoading, isLoading = _h === void 0 ? false : _h;
    var _j = __read((0, react_1.useState)(initialSelected || []), 2), selectedOptions = _j[0], setSelectedOptions = _j[1];
    var handleChange = function (chosenOptions) {
        setSelectedOptions(chosenOptions);
        if (onChange) {
            onChange(chosenOptions);
        }
    };
    var handleCreateOption = function (value, options) {
        var createdOption = onCreateOption(value, options);
        if (createdOption !== false) {
            setSelectedOptions([{ label: value, value: value }]);
        }
    };
    return ((0, jsx_runtime_1.jsx)(eui_1.EuiComboBox, { "data-testid": dataTestId || 'ui-atom-input-custom-select', fullWidth: fullWidth, isClearable: isClearable, isDisabled: disabled, isInvalid: invalid, onBlur: onBlur, onChange: handleChange, onCreateOption: onCreateOption ? handleCreateOption : undefined, options: options, placeholder: placeholder, selectedOptions: selectedOptions, singleSelection: { asPlainText: true }, async: async, isLoading: isLoading, onSearchChange: onSearchChange, compressed: compressed }));
};
exports.CustomSelect = CustomSelect;

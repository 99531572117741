import { FC, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useToast } from 'ui';
// FIX ME
// @ts-ignore
import GET_CURRENT_USER from '@app/queries/user/currentUser.gql';
import NotFound from '../NotFound';
import { IRenderer } from '../types';
import useGetProtectedRoutePermissions from './hooks/useGetProtectedRoutePermissions';
import { useRedirectFromPrecheck } from './hooks/useRedirectFromPrecheck';
import useRedirectToUploadPortal from './hooks/useRedirectToUploadPortal';
import useUpdateOrganization from './hooks/useUpdateOrganization';
import renderRoute from './utils/renderRoute';

interface Props extends IRenderer {
  exact?: boolean;
  match?: any;
  path: string | string[];
  portal?: boolean;
  prevPath?: string;
  requiredPermissions?: boolean;
  strict?: boolean;
}

const ProtectedRoute: FC<Props> = ({
  component,
  exact,
  path,
  portal,
  render,
  requiredPermissions,
  strict,
}) => {
  const permissions = useGetProtectedRoutePermissions();
  const { isAdmin, isDM, isNonAdminWithOrgs } = permissions;
  const toast = useToast();

  const [projectsList, setProjectsList] = useState(undefined);

  const [getCurrentUser, { loading }] = useLazyQuery(GET_CURRENT_USER, {
    onCompleted: (res) => {
      const managedOrgsList = {};

      res.currentUser.managedOrgs?.forEach((managedOrg) => {
        if (managedOrg.editProjects) {
          managedOrgsList[managedOrg.org.name] = managedOrg.org.name;
        }
      });

      setProjectsList(managedOrgsList);
    },
    onError: (error) =>
      toast({ title: `Could not load user profile: ${error?.message}`, type: 'danger' }),
  });

  // keep brokers and collaborators from precheck orgs on precheck app
  useRedirectFromPrecheck();

  if (requiredPermissions === false) {
    return <NotFound />;
  }

  useEffect(() => {
    if (isAdmin || isDM || isNonAdminWithOrgs) {
      getCurrentUser();
    }
  }, [path]);

  // If the user has the correct permissions, the cached organization may be updated, if needed, or the user might be
  // redirected to the home page.

  useUpdateOrganization({ data: projectsList, loading, permissions });

  // Redirects the user to the upload portal if necessary
  useRedirectToUploadPortal({ isAdmin, path });

  return (
    <Route
      exact={exact}
      strict={strict}
      path={path}
      render={(props) => renderRoute({ component, portal, props, render })}
    />
  );
};

export default ProtectedRoute;

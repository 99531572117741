import * as React from 'react';
import { EuiForm, EuiFormRow } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useUpdateSupportTicketMutation } from '@app/graphql/mutations/support/__generated__/updateSupportTicket.generated';
import { GET_SUPPORT_TICKET } from '@app/graphql/queries/support/getSupportTicket';
import {
  SupportTicket,
  TicketPriority,
  TicketStatus,
  UpdateSupportTicketInput,
} from '@app/graphql/types';
import { UserSelect } from '../UserSelect';

interface Props {
  ticket: SupportTicket;
  onCancel: () => void;
  refetch: () => void;
}

const EditTicket: React.FC<Props> = ({ ticket, onCancel, refetch }) => {
  const { account } = useAuth();
  const { chatRoomID, priority, status, assigneeEmails, followerEmails } = ticket;

  const [updateTicket] = useUpdateSupportTicketMutation({
    onCompleted: () => {
      refetch();
      onCancel();
    },
    refetchQueries: [
      { query: GET_SUPPORT_TICKET, variables: { input: { id: ticket.chatRoomID } } },
    ],
  });

  const disableEdits = !account?.permissions?.admin;
  const baseTicketInput: UpdateSupportTicketInput = {
    assigneeEmails,
    chatRoomID,
    followerEmails,
    priority: priority || TicketPriority.None,
    status: status || TicketStatus.Open,
  };

  return (
    <EuiForm>
      <EuiFormRow fullWidth label="Assigned to">
        <UserSelect
          initialSelected={ticket.assigneeEmails || []}
          fullWidth
          disabled={disableEdits}
          internalUsersOnly
          onChange={(emails) =>
            updateTicket({
              variables: {
                input: {
                  ...baseTicketInput,
                  assigneeEmails: emails,
                },
              },
            })
          }
        />
      </EuiFormRow>
      <EuiFormRow fullWidth label="Followers">
        <UserSelect
          initialSelected={ticket.followerEmails}
          fullWidth
          disabled={disableEdits}
          allowUnknowns
          onChange={(emails) =>
            updateTicket({
              variables: {
                input: {
                  ...baseTicketInput,
                  followerEmails: emails,
                },
              },
            })
          }
        />
      </EuiFormRow>
    </EuiForm>
  );
};

export default EditTicket;

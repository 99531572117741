import { FC, useMemo } from 'react';
import * as React from 'react';
import QueryBuilder, { ActionWithRulesProps, Field, RuleGroupType } from 'react-querybuilder';
import { ClassNames } from '@emotion/react';
import AddRuleAction from './components/AddRuleAction';
import FieldSelector from './components/FieldSelector';
import OperatorSelector from './components/OperatorSelector';
import RemoveRuleAction from './components/RemoveRuleAction';
import ValueEditor from './components/ValueEditor';
import {
  addRuleButtonStyles,
  combinatorSelectStyles,
  fieldsSelectStyles,
  operatorsSelectStyles,
  queryBuilderStyles,
  removeGroupButtonStyles,
  removeRuleButtonStyles,
  ruleGroupStyles,
  ruleStyles,
  valueInputStyles,
} from './QueryBuilderWrapper.emotion';
import translations from './translations';
import { Filters } from './types';
import {
  beginsWithOperator,
  containsOperator,
  endsWithOperator,
  equalOperator,
  greaterOperator,
  greaterOrEqualOperator,
  isEmptyOperator,
  isNotEmptyOperator,
  lessOperator,
  lessOrEqualOperator,
  notBeginsWithOperator,
  notContainsOperator,
  notEndsWithOperator,
  notEqualOperator,
  setZeroIndexOperator,
} from './utils';

interface Props {
  filters: Filters;
  onQueryChange: (ruleGroup: RuleGroupType) => void;
  query?: RuleGroupType;
}

const QueryBuilderWrapper: FC<Props> = ({ filters, onQueryChange, query }) => {
  const fields: Array<Field> = Object.values(filters).map((row) => ({
    label: row.label,
    name: row.name,
    operators: setZeroIndexOperator(row.name, filters, row.operators),
  }));

  // addGroupActionComponent - this hides the "New group" button and disables rule nesting.
  const addGroupActionComponent: React.FC<ActionWithRulesProps> = () => null;

  const withoutFieldSelector = useMemo(
    () => ({
      addGroupAction: addGroupActionComponent,
      addRuleAction: AddRuleAction,
      fieldSelector: FieldSelector,
      operatorSelector: (props) => <OperatorSelector filters={filters} {...props} />,
      removeRuleAction: RemoveRuleAction,
      valueEditor: (props) => <ValueEditor filters={filters} {...props} />,
    }),
    [],
  );

  return (
    <ClassNames>
      {({ css }) => (
        <QueryBuilder
          fields={fields}
          operators={[
            beginsWithOperator,
            containsOperator,
            endsWithOperator,
            equalOperator,
            greaterOperator,
            greaterOrEqualOperator,
            isEmptyOperator,
            isNotEmptyOperator,
            lessOperator,
            lessOrEqualOperator,
            notBeginsWithOperator,
            notContainsOperator,
            notEndsWithOperator,
            notEqualOperator,
          ]}
          onQueryChange={onQueryChange}
          query={query}
          controlElements={withoutFieldSelector}
          controlClassnames={{
            addRule: css(addRuleButtonStyles),
            combinators: css(combinatorSelectStyles),
            fields: css(fieldsSelectStyles),
            operators: css(operatorsSelectStyles),
            queryBuilder: css(queryBuilderStyles),
            removeGroup: css(removeGroupButtonStyles),
            removeRule: css(removeRuleButtonStyles),
            rule: css(ruleStyles),
            ruleGroup: css(ruleGroupStyles),
            value: css(valueInputStyles),
          }}
          translations={translations}
        />
      )}
    </ClassNames>
  );
};

export default QueryBuilderWrapper;

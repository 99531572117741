import { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import TourButton from '@app/components/Tour/TourButton';
import { IGraphQLStream, ITour } from '@app/queries/streams/types';
import { IPageState } from '../types';
import { VALID_KEYS_TO_OVERRULE_HIGHLIGHTS } from '../utils';
import StreamTourButton from './StreamTourButton';
import { prepareSteps } from './utils';

interface IProps {
  pageState: IPageState;
  setPageState: (props: Partial<IPageState>) => void;
  tour: ITour;
  setHighlights: (highlights: null | number) => void;
  handleOpenTour: (step: number) => void;
  hasSeenTour: boolean;
  stream?: IGraphQLStream;
}

const getStep = (highlights: string | null, shouldDefaultTourOpen: boolean) => {
  if (highlights !== null && highlights !== '') {
    return Number(highlights) - 1;
  }

  if (shouldDefaultTourOpen) {
    return 0;
  }

  return undefined;
};

const checkIfHasOverrideQueryParams = (search: string) => {
  const params = new URLSearchParams(search);

  return VALID_KEYS_TO_OVERRULE_HIGHLIGHTS.reduce((foundParam, key) => {
    if (foundParam) {
      return foundParam;
    }

    return !!params.get(key);
  }, false);
};

const checkIsBaseStreamPage = (path: string, slug?: string) => {
  const parts = path.split('/').filter((p) => p !== '');
  return parts.length === 2 && parts[0] === 'streams' && parts[1] === slug;
};

const StreamTour: FC<IProps> = ({
  pageState,
  setPageState,
  tour: { steps, author },
  setHighlights,
  handleOpenTour,
  hasSeenTour,
  stream,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [isValidTourURL] = useState(
    checkIsBaseStreamPage(location.pathname, stream?.slug) &&
      !checkIfHasOverrideQueryParams(location.search),
  );

  const shouldDefaultTourOpen = isValidTourURL && !hasSeenTour;
  return (
    <TourButton
      stream={stream}
      step={getStep(pageState.highlights, shouldDefaultTourOpen)}
      steps={prepareSteps(
        steps,
        {
          pageState,
          setPageState,
        },
        history,
      )}
      handleClose={() => setHighlights(null)}
      handleOpen={handleOpenTour}
    >
      {({ handleClick, open, step }) => (
        <StreamTourButton
          author={author}
          handleClick={handleClick}
          pulse={!hasSeenTour}
          open={open}
          step={step}
        />
      )}
    </TourButton>
  );
};

export default StreamTour;

import * as React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';

interface EmptyMessageProps {
  header?: string;
  subText: string;
}

const EmptyMessage: React.FC<EmptyMessageProps> = ({ header, subText }) => (
  <EuiFlexGroup alignItems="center" direction="column" justifyContent="center">
    <EuiFlexItem grow={false}>
      <EuiText>
        <h2>{header}</h2>
      </EuiText>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiText color="subdued">
        <p>{subText}</p>
      </EuiText>
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default EmptyMessage;

import { FC, MouseEvent, useState } from 'react';
import { usePageState } from '@onarchipelago/cx/Stream/utils';
import { EuiCard, EuiToolTip, Spinner } from 'ui';
import DocumentIcon from '@app/components/DocumentIcon/DocumentIcon';
import { getSnapshotLabel } from '@app/cx/Stream/Aside/Explorers/utils';
import { TrackStreamPageDocumentModal, useTracker } from '@app/hooks/useTracker';
import { IGraphQLStream } from '@app/queries/streams/types';
import { formatDate } from '@app/utils/format';
import { ExportCode } from '../Stream/types';
import styles from './Document.emotion';
import { IDocumentModalStream } from './types';

interface Props {
  extension: string;
  handleClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  href?: string;
  isExportFile: boolean;
  name: string;
  stream: IDocumentModalStream;
  testId?: string;
  time?: string;
  tourId?: string;
  useDefaultSnapshot?: boolean;
  exportCode?: ExportCode;
  marketVisibleToggle: boolean;
  marketVisibleSnapshots: IGraphQLStream['snapshots'];
}

const Document: FC<Props> = ({
  extension,
  handleClick,
  href,
  isExportFile,
  name,
  stream,
  testId,
  time,
  tourId,
  useDefaultSnapshot = false,
  exportCode,
  marketVisibleToggle,
  marketVisibleSnapshots,
}) => {
  const [{ currentSnapshot }] = usePageState();
  const firstSnapshot = stream?.snapshots?.length > 0 ? stream?.snapshots[0].name : '';
  let snapshotToUse;

  if (marketVisibleToggle) {
    snapshotToUse = marketVisibleSnapshots[0].name;
  } else if (useDefaultSnapshot) {
    snapshotToUse = stream.defaultSnapshot;
  } else {
    snapshotToUse = currentSnapshot || stream.defaultSnapshot || firstSnapshot;
  }

  const label = getSnapshotLabel(stream.snapshots, snapshotToUse);
  const [isLoading, setIsLoading] = useState(false);
  const tracker = useTracker();

  const getDocumentTitle = (): JSX.Element => {
    if (isLoading) {
      return (
        <>
          {name} <Spinner size="m" />
        </>
      );
    }

    return <>{name}</>;
  };

  const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (isLoading) {
      return; // prevent multiple exports
    }

    if (exportCode !== undefined) {
      tracker.track(`${TrackStreamPageDocumentModal.prefix}: ${name}`, {
        download_type: 'stream_single',
        event_surface: TrackStreamPageDocumentModal.event_surface,
        exportCode,
        stream_id: stream.id,
        stream_name: stream.name,
        stream_slug: stream.slug,
      });
    }

    (async () => {
      setIsLoading(true);
      if (handleClick) {
        await handleClick(event);
      }
      setIsLoading(false);
    })();
  };

  const getDescription = () => {
    if (isExportFile && label) {
      return `As of: ${label}`;
    }

    if (!isExportFile && time) {
      return `Updated ${formatDate(time)}`;
    }

    return null;
  };

  const documentTitle = getDocumentTitle();

  const titleWithTooltip = (
    <EuiToolTip content={documentTitle}>
      <p>{documentTitle}</p>
    </EuiToolTip>
  );

  return (
    <EuiCard
      data-testid={testId ? `document-${testId}` : undefined}
      data-tourid={tourId ? `document-${tourId}` : undefined}
      className={styles.card}
      description={getDescription()}
      href={href}
      icon={<DocumentIcon title={name} extension={extension} />}
      isDisabled={isLoading}
      layout="horizontal"
      onClick={onClick}
      rel="noopener noreferrer"
      target="_blank"
      title={titleWithTooltip}
    />
  );
};

export default Document;

import { FC, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { getColumnCount, getRowCount } from '@app/components/Gallery/Gallery';
import { ISize } from '@app/components/Gallery/types';
import PropertiesGallery, {
  PROPERTY_GALLERY_CELL_HEIGHT,
  PROPERTY_GALLERY_CELL_WIDTH,
} from '@app/components/PropertiesGallery/PropertiesGallery';
import { IPropertiesGalleryProperty } from '@app/components/PropertiesGallery/types';
import useResizeObserver from '@app/hooks/useResizeObserver';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import ScrollContainer from '../ScrollContainer';
import { pad } from '../utils';

type OnLoadMore = () => void | any;

interface IProps {
  stream: IGraphQLStream;
  properties: Array<IPropertiesGalleryProperty>;
  totalProperties: number;
  propertyAttributeMetadata: Array<IGraphQLAttributeMetadata>;
  onLoadMore: undefined | OnLoadMore;
}

const Container = styled.div`
  label: ReefGalleryBody;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`;

const PADDING = 200;

const getThreshold = (properties: Array<IPropertiesGalleryProperty>, size: ISize): number => {
  const columnCount = getColumnCount(size.width, PROPERTY_GALLERY_CELL_WIDTH);
  const rowCount = getRowCount(properties.length, columnCount);
  return rowCount * PROPERTY_GALLERY_CELL_HEIGHT - size.height - PADDING;
};

const ReefGalleryBody: FC<IProps> = ({
  stream,
  propertyAttributeMetadata,
  properties,
  totalProperties,
  onLoadMore,
}) => {
  const [size, setSize] = useState<ISize>();
  const [height, setHeight] = useState(800);
  const [threshold, setThreshold] = useState<number>();
  const ref = useRef<HTMLDivElement>(null);

  if (totalProperties === null) {
    throw new Error(`Null property count for stream ${stream.id}`);
  }

  useEffect(() => {
    if (size) {
      setThreshold(getThreshold(properties, size));
    }
  }, [size, properties.length]);

  const getHeight = () => {
    if (ref.current) {
      setHeight(Math.floor(ref.current.getBoundingClientRect().height));
    }
  };
  useResizeObserver<HTMLDivElement>(ref.current, getHeight);

  const paddedProperties = useMemo(
    () => pad(properties, totalProperties),
    [properties.length, totalProperties],
  );

  return (
    <Container data-testid="properties-gallery-container" ref={ref}>
      <ScrollContainer
        loadMore={onLoadMore}
        threshold={threshold}
        totalRows={totalProperties}
        rows={properties.length}
      >
        {({ handleScroll }) => (
          <PropertiesGallery
            handleScroll={(e) =>
              handleScroll({
                left: e.scrollLeft,
                top: e.scrollTop,
              })
            }
            height={height}
            stream={stream}
            propertyAttributeMetadata={propertyAttributeMetadata}
            properties={paddedProperties}
            onResize={setSize}
          />
        )}
      </ScrollContainer>
    </Container>
  );
};

export default ReefGalleryBody;

import * as React from 'react';
import { CallOut, EuiText } from 'ui';

const LotsOfEditsCallout: React.FC = () => (
  <CallOut title="Your edits may block others" type="warning">
    <EuiText>
      You’re editing a lot of data. While you’re editing, no one else can work on it, so please
      submit your edits as quickly as possible.
    </EuiText>
  </CallOut>
);

export default LotsOfEditsCallout;

import * as React from 'react';
import { useQuery } from '@apollo/client';
import { Button, useToast } from 'ui';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useCreatePropertyRecommendationsMutation } from '@app/graphql/mutations/propertyRecommendations/__generated__/createPropertyRecommendations.generated';
import { usePrecheckClient } from '@app/precheck/hooks/usePrecheckClient';
import { PRECHECK_SUMMARY } from '@app/precheck/hooks/usePrecheckUser';
import { getErrorMessage } from '@app/utils/getErrorMessage';

interface Props {
  streamSlug: string;
}

export const PrecheckRecommendationsButton: React.FC<Props> = ({ streamSlug }) => {
  const client = usePrecheckClient();
  const { selectedOrganization } = useUserSession();
  const toast = useToast();

  const {
    data,
    loading: summaryLoading,
    startPolling,
    stopPolling,
    refetch,
  } = useQuery(PRECHECK_SUMMARY, {
    client: client,
    variables: {
      input: {
        orgName: selectedOrganization.name,
        streamSlug,
      },
    },
  });

  const [createRecommendations, { loading }] = useCreatePropertyRecommendationsMutation({
    client,
    onCompleted: () => {
      refetch();
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  const inProgress = data?.preCheckRecommendationSummary.mlRecommendationsStatus === 'IN_PROGRESS';

  const handleCreateClick = async () => {
    await createRecommendations({
      variables: {
        // FIX ME
        // @ts-ignore
        input: {
          streamSlug,
        },
      },
    });
  };

  if (inProgress) {
    startPolling(1000);
  } else {
    stopPolling();
  }

  return (
    <Button
      disabled={loading || summaryLoading || inProgress}
      loading={loading || summaryLoading || inProgress}
      size="m"
      label="Regenerate Recommendations"
      onClick={handleCreateClick}
    />
  );
};

import { formatDefault } from '@app/utils/format';
import { getEdits, getProvenance, ICell } from './Cell';
import { formatGeoCode } from './Geocode';

const Text = (props: ICell) => {
  const id = props.column && (props.column.id as string);
  const value = props.value;
  const formatted =
    id === 'geoCodeType' ? formatGeoCode(value || 'Unknown') : formatDefault(value, '-');
  const provenance = getProvenance(props.prop, id);

  const tooltip = {
    description: undefined,
    edits: getEdits(props.prop, id),
    provenance,
    title: undefined,
  };

  const body = <span>{formatted}</span>;

  return { body, tooltip };
};

export default Text;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var PdfFile = function (_a) {
    var dataTestId = _a["data-testid"], _b = _a.color, color = _b === void 0 ? 'black' : _b, size = _a.size;
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ "data-testid": dataTestId, width: size, height: size, viewBox: "0 0 32 40", fill: "none" }, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M26.085 38.99H5.915c-2.711 0-4.91-2.205-4.91-4.926V5.936c0-2.721 2.199-4.927 4.91-4.927h12.93c.928 0 1.814.382 2.453 1.055l8.764 9.246c.598.631.932 1.47.932 2.342v20.414c0 2.72-2.197 4.925-4.91 4.925z", stroke: color, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "10", strokeWidth: "2" }), (0, jsx_runtime_1.jsx)("path", { d: "M20.571 1.743v7.354c0 1.56 1.26 2.824 2.814 2.824h6.878", stroke: color, strokeLinecap: "round", strokeLinejoin: "round", strokeMiterlimit: "10", strokeWidth: "2" }), (0, jsx_runtime_1.jsx)("path", { d: "M19.441 28.572l-.884-2.913h-4.446l-.884 2.913H10.44l4.305-12.288h3.161l4.321 12.288zm-1.501-5.09c-.818-2.64-1.28-4.132-1.385-4.478-.1-.346-.172-.62-.217-.82-.183.714-.709 2.48-1.577 5.298z", fill: color })] })));
};
exports.default = PdfFile;

import { FC } from 'react';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import { EuiFlexGroup, EuiFlexItem, EuiText, Select } from 'ui';

const Filters: FC = () => {
  const { currentSnapshot, loading, setCurrentSnapshot, snapshotOptions } =
    useReportsContext().snapshots;

  return (
    <EuiFlexGroup responsive={false} gutterSize="s">
      <EuiFlexItem grow={false}>
        <EuiText style={{ lineHeight: '40px' }}>View report for</EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <Select
          options={snapshotOptions}
          value={currentSnapshot?.snapshotName || ''}
          onChange={(value) => setCurrentSnapshot(value)}
          loading={loading}
          data-testid="ua-report-filter-select"
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Filters;

import * as React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import { HeaderTitle } from '@app/dice/JobDetails/JobDetails.emotion';
import processingGraphic from './processing.jpeg';
import { ProcessingImage, StyledFlexItem, StyledProcessingPage } from './SovProcessingPage.emotion';

interface SovProcessingPageProps {
  streamName: string;
}

const SovProcessingPage: React.FC<SovProcessingPageProps> = ({ streamName }) => (
  <StyledProcessingPage>
    <EuiFlexGroup alignItems="center" direction="column">
      <EuiFlexItem grow={false}>
        <EuiText size="m" data-testid="job-details-title">
          <HeaderTitle>{streamName} Is Processing</HeaderTitle>
        </EuiText>
      </EuiFlexItem>
      <StyledFlexItem grow={false}>
        <ProcessingImage src={processingGraphic} alt="Description" className="image" />
      </StyledFlexItem>
      <StyledFlexItem grow={false}>
        <EuiText>
          An email will be sent to the account admins as soon as it’s ready to view.
        </EuiText>
        <EuiText textAlign="center">
          <a href="/support">Questions? Send us a support ticket</a>
        </EuiText>
      </StyledFlexItem>
    </EuiFlexGroup>
  </StyledProcessingPage>
);
export default SovProcessingPage;

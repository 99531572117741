import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const handlePDFExport = (input, pdfName) => {
  input.scrollTop = 0;

  const linkContainer = input.querySelector('[data-hide-print="true"]');
  if (linkContainer) {
    linkContainer.classList.add('hide-for-print');
  }

  html2canvas(input, {
    scrollX: 0,
    scrollY: -window.scrollY,
    windowHeight: input.scrollHeight,
    windowWidth: input.scrollWidth,
  })
    .then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'pt', 'a4');
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      const pageHeight = pdf.internal.pageSize.getHeight();
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      // add additional pages if needed
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save(`${pdfName}.pdf`);
    })
    .catch((error) => {
      console.log('error', error);
    });

  if (linkContainer) {
    linkContainer.classList.remove('hide-for-print');
  }
};

import * as React from 'react';
import { IGraphQLStream } from '@app/queries/streams/types';
import { AttributeMetadataEditorContextProvider } from './context/attributeMetadataEditorContext';
import AttributeMetadataEditor from './AttributeMetadataEditor';

interface Props {
  organizationId: string;
  orgName?: string;
  streamId?: string;
  stream?: IGraphQLStream;
}

const AttributeMetadataEditorContainer: React.FC<Props> = ({
  organizationId,
  orgName,
  streamId,
  stream,
}) => (
  <AttributeMetadataEditorContextProvider
    organizationId={organizationId}
    orgName={orgName}
    streamId={streamId}
    stream={stream}
  >
    <AttributeMetadataEditor />
  </AttributeMetadataEditorContextProvider>
);

export default AttributeMetadataEditorContainer;

import { FC } from 'react';
import styled from '@emotion/styled';
import { IAuthor } from './types';

const Container = styled.div`
  label: TourPanelAuthorContainer;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div`
  label: TourPanelAuthorText;
`;

const Name = styled.label`
  label: TourPanelAuthorName;
  font-size: 16px;
  font-weight: 600;
`;

const Role = styled.div`
  label: TourPanelAuthorRole;
  font-size: 12px;
  line-height: 150%;
`;

const IMG_SIZE = 48;
const Img = styled.img`
  label: TourPanelAuthorImg;
  width: ${IMG_SIZE}px;
  height: ${IMG_SIZE}px;
  margin-right: 16px;
  border-radius: ${IMG_SIZE}px;
`;

const Company = styled.span`
  label: TourPanelAuthorCompany;
  font-weight: 600;
`;

interface IProps {
  author: IAuthor;
}

const TourPanelAuthor: FC<IProps> = ({ author: { name, role, avatar, company } }) => (
  <Container>
    {avatar && <Img src={avatar} alt={name} title={name} />}
    <Text>
      <Name>{name}</Name>
      <Role>
        {role}
        {company && (
          <>
            , <Company>{company}</Company>
          </>
        )}
      </Role>
    </Text>
  </Container>
);

export default TourPanelAuthor;

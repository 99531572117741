import * as React from 'react';
import { useMutation } from '@apollo/client';
import { Button, ButtonIcon, EuiFlexGroup, EuiFlexItem, EuiText, EuiToolTip, useToast } from 'ui';
// FIX ME
// @ts-ignore
import CALCULATE_STREAM_EXPOSURE_INDEX from '@app/queries/exposure/calculateStreamExposureIndex.gql';
// FIX ME
// @ts-ignore
import DELETE_STREAM_EXPOSURE_INDEX from '@app/queries/exposure/deleteStreamExposureIndex.gql';
// FIX ME
// @ts-ignore
import GET_STREAM_SNAPSHOT_CONFIG from '@app/queries/streams/snapshots/getSnapshotConfig.gql';
import {
  isIndexCalculationStatusPending,
  SnapshotInfo,
} from '@app/queries/streams/snapshots/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';

interface Props {
  streamSlug: string;
  snapshotInfo: SnapshotInfo;
}
const ExposureIndexButton: React.FC<Props> = ({ streamSlug, snapshotInfo }) => {
  const toast = useToast();
  const [calculateStreamExposureIndex, { loading }] = useMutation<
    CalculateStreamExposureIndexData,
    CalculateStreamExposureIndexVariables
  >(CALCULATE_STREAM_EXPOSURE_INDEX, {
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    refetchQueries: [{ query: GET_STREAM_SNAPSHOT_CONFIG, variables: { streamSlug: streamSlug } }],
    variables: {
      input: {
        snapshotName: snapshotInfo.snapshot.name,
        streamSlug: streamSlug,
      },
    },
  });

  const [deleteStreamExposureIndex, { loading: deleting }] = useMutation<
    DeleteStreamExposureIndexData,
    DeleteStreamExposureIndexVariables
  >(DELETE_STREAM_EXPOSURE_INDEX, {
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    refetchQueries: [{ query: GET_STREAM_SNAPSHOT_CONFIG, variables: { streamSlug: streamSlug } }],
    variables: {
      input: {
        snapshotName: snapshotInfo.snapshot.name,
        streamSlug: streamSlug,
      },
    },
  });

  if (snapshotInfo?.exposureStatus?.status === 'COMPLETED') {
    const { index, indexGrade } = snapshotInfo.exposureStatus;
    return (
      <EuiFlexGroup direction="row" alignItems="center">
        <EuiFlexItem>
          <EuiToolTip title="Exposure Index" content={index}>
            <EuiText size="xs">
              <p>
                <small>
                  {index} ({indexGrade})
                </small>
              </p>
            </EuiText>
          </EuiToolTip>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiToolTip content="Clear Exposure Index">
            <ButtonIcon
              iconName="trash"
              aria-label="Clear Index"
              color="danger"
              data-testid="delete-exposure-index"
              border
              onClick={() => deleteStreamExposureIndex()}
              loading={deleting}
              disabled={deleting}
            />
          </EuiToolTip>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  const creating = loading || isIndexCalculationStatusPending(snapshotInfo.exposureStatus?.status);
  return (
    <EuiFlexGroup direction="row" alignItems="center">
      <Button
        disabled={creating}
        loading={creating}
        size="s"
        data-testid="calculate-exposure-index"
        iconName="barChart"
        label="Score"
        onClick={() => calculateStreamExposureIndex()}
      />
    </EuiFlexGroup>
  );
};

export default ExposureIndexButton;

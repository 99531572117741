import { FC } from 'react';
import { EuiFlexGroup, EuiFlexGroupProps, EuiFlexItem, EuiText, EuiToolTip, useEuiTheme } from 'ui';
import { Text } from './QualityScoreKey.emotion';
import { QualityScore } from './types';
import { qualityScoresForSnapshots } from './utils';

interface Props {
  items?: QualityScore[];
  direction?: EuiFlexGroupProps['direction'];
  justifyContent?: EuiFlexGroupProps['justifyContent'];
  gutterSize?: EuiFlexGroupProps['gutterSize'];
}

const QualityScoreKey: FC<Props> = ({
  items = qualityScoresForSnapshots,
  direction = 'row',
  justifyContent = 'spaceBetween',
  gutterSize = 'none',
}) => {
  const Swatch: FC<{ color: string; score: number }> = ({ color, score }) => {
    const width = 9;
    const height = score === 0 ? 1 : width;
    return <div style={{ backgroundColor: color, height, marginRight: 5, width }} />;
  };

  const { euiTheme } = useEuiTheme();
  const { colors } = euiTheme;

  return (
    <EuiFlexGroup gutterSize={gutterSize} direction={direction}>
      {items.map((item) => (
        <EuiFlexItem key={item.score} grow={false}>
          <EuiToolTip content={item.detail}>
            <EuiFlexGroup gutterSize="none" alignItems="center">
              <EuiFlexItem grow={false}>
                <Swatch color={item.color} score={item.score} />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText size="xs">
                  <Text color={colors.primary}>
                    <small>{item.label}</small>
                  </Text>
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiToolTip>
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  );
};

export default QualityScoreKey;

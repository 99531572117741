import { Controller, useFormContext } from 'react-hook-form';
import { CurrencyField, CurrencyFieldProps, EuiFormRow, EuiFormRowProps } from 'ui';

export interface IFormCurrencyInputProps {
  name: string;
  inputProps?: Omit<CurrencyFieldProps, 'onChange'>;
  formRowProps?: EuiFormRowProps;
  onChange?: CurrencyFieldProps['onChange'];
}

export const FormCurrencyInput = ({
  name,
  onChange,
  inputProps = {},
  formRowProps = {} as EuiFormRowProps,
}: IFormCurrencyInputProps) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const inputError = errors?.[name];

  return (
    <EuiFormRow
      id="form-text-input"
      isInvalid={!!inputError}
      // @ts-ignore
      error={inputError?.message}
      {...formRowProps}
      fullWidth={inputProps?.fullWidth}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          const controllerOnChange = field.onChange;
          const handleChange = (value: number) => {
            if (onChange) {
              onChange(value);
            }
            controllerOnChange(value);
          };
          return (
            <CurrencyField
              aria-labelledby="form-text-input"
              {...inputProps}
              {...field}
              onChange={handleChange}
              isInvalid={!!inputError}
            />
          );
        }}
      />
    </EuiFormRow>
  );
};

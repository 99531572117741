import { FC } from 'react';
import { OptionImg } from '@onarchipelago/cx/SmartFolder/components/RelationshipSelect/Components';
import { Checkbox, EuiFlexGroup, EuiFlexItem, EuiText, EuiToolTip } from 'ui';
import PropertyImg from '@app/components/PropertyImg/PropertyImg';
import { IGraphQLProperty } from '@app/queries/properties/types';
import styles from './PropertyItem.emotion';

interface SharedProperty extends IGraphQLProperty {
  documents?: any;
}

interface Props {
  item: IGraphQLProperty;
  selectedProperties: any;
  updateSelectedProperties: (selectedProperties: IGraphQLProperty[]) => void;
  multiple?: boolean;
  isPrimaryProperty?: boolean;
  isSecondaryList?: boolean;
  sharedPropertyInfo?: SharedProperty;
  disabled?: boolean;
  tooltipMessage?: string;
  hideCheckbox?: boolean;
  hideImage?: boolean;
}

const PropertyItem: FC<Props> = ({
  item,
  selectedProperties,
  updateSelectedProperties,
  isPrimaryProperty,
  isSecondaryList,
  sharedPropertyInfo,
  disabled,
  tooltipMessage,
  hideCheckbox = false,
  hideImage = false,
}) => {
  const { archipelagoId, locationId, locationName, pictures, streetAddress, city, state, country } =
    item;
  const displayDocs = isPrimaryProperty || isSecondaryList;

  const isSelected = selectedProperties
    ? selectedProperties.some((p: IGraphQLProperty) => p.archipelagoId === archipelagoId)
    : false;

  const toggleSelected = () => {
    if (!isSelected) {
      // eslint-disable-next-line
      selectedProperties = [...(selectedProperties || []), item];
      updateSelectedProperties(selectedProperties);
    } else {
      // eslint-disable-next-line
      selectedProperties = selectedProperties.filter(
        (p: IGraphQLProperty) => p.archipelagoId !== archipelagoId,
      );
      updateSelectedProperties(selectedProperties);
    }
  };

  const locationSubLabel = [city, state, country].filter((i) => !!i).join(', ');

  const testId = locationName
    ? `property-item-${locationName.split(' ').join('-')}`
    : 'property-item';

  return (
    <EuiFlexGroup
      alignItems="center"
      className={styles.container}
      gutterSize="s"
      justifyContent="spaceBetween"
    >
      {!hideImage && (
        <EuiFlexItem grow={false}>
          <OptionImg className={styles.imageContainer} onClick={toggleSelected}>
            <PropertyImg locationName={locationName} pictures={pictures} width={40} height={40} />
          </OptionImg>
        </EuiFlexItem>
      )}
      <EuiFlexItem className={styles.textButtonContainer} grow={false}>
        <div className={styles.textButton}>
          <EuiFlexGroup
            direction="column"
            gutterSize="none"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            <EuiFlexItem className={styles.textContainer} grow={false}>
              <EuiToolTip
                position="left"
                content={
                  <>
                    <EuiText>
                      <p>
                        {locationId && locationId + ' - '} {archipelagoId}
                      </p>
                    </EuiText>
                    <EuiText>
                      <p>
                        {locationName && locationName !== streetAddress
                          ? locationName
                          : streetAddress}
                      </p>
                    </EuiText>
                  </>
                }
              >
                <EuiText className={styles.title}>
                  {locationId && locationId + ' - '} {archipelagoId && archipelagoId + ' - '}
                  {locationName && locationName !== streetAddress ? locationName : streetAddress}
                </EuiText>
              </EuiToolTip>
            </EuiFlexItem>
            {locationName && locationName !== streetAddress && (
              <EuiFlexItem className={styles.textContainer} grow={false}>
                <EuiText className={styles.subTitle}>{streetAddress}</EuiText>
              </EuiFlexItem>
            )}
            <EuiFlexItem className={styles.textContainer} grow={false}>
              <EuiText className={styles.subTitle}>{locationSubLabel}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem className={styles.textContainer} grow={false}>
              <EuiToolTip
                position="left"
                content={
                  <>
                    {sharedPropertyInfo?.documents?.map((document) => (
                      <p key={document}>{document.filename}</p>
                    ))}
                  </>
                }
              >
                <EuiText className={styles.subTitle}>
                  {sharedPropertyInfo?.documents?.map((document, idx) => (
                    <span key={idx}>{(idx ? ', ' : '') + document.filename}</span>
                  ))}
                </EuiText>
              </EuiToolTip>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </EuiFlexItem>
      {!hideCheckbox && (
        <EuiFlexItem className={styles.checkboxContainer} grow={false}>
          <EuiToolTip position="top" content={disabled ? tooltipMessage : undefined}>
            <Checkbox
              id={`${testId}-checkbox`}
              data-testid={testId}
              checked={isSelected}
              onChange={toggleSelected}
              disabled={disabled}
            />
          </EuiToolTip>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
};

export default PropertyItem;

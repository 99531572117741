import { FC, useState } from 'react';
import { BarScore } from '@onarchipelago/cx/Reports/Charts/HorizontalBarChart/types';
import { EuiFlexGroup, EuiFlexItem, EuiToolTip } from 'ui';
import { formatCurrency, formatNumber } from '@app/utils/format';
import { ProgressBarSize } from './types';
import { colorByName } from './utils';

interface Props {
  scores: BarScore[];
  size: ProgressBarSize;
  'data-testid'?: string;
  currency?: string;
}

const QualityProgressBar: FC<Props> = ({ scores, size, 'data-testid': dataTestId, currency }) => {
  const formatProperties = (locations: number) => formatNumber(locations);
  const formatPercent = (percent: number) => Number.parseFloat((percent || 0).toFixed(2));
  const formatTIV = (TIV: number) => formatCurrency(TIV, { currency });
  const height = size === 's' ? 9 : 17;
  const [activeBar, setActiveBar] = useState('');
  const opacity = (name: string) =>
    activeBar === '' || (activeBar !== '' && activeBar === name) ? 1 : 0.25;

  const orderedFields = ['Evidenced', 'Precise', 'Ambiguous', 'Invalid', 'None'];
  const sortByQuality = (a: any, b: any) =>
    orderedFields.indexOf(a.scoreQuality) - orderedFields.indexOf(b.scoreQuality);

  const barSegments = [...scores].sort(sortByQuality);

  return (
    <EuiFlexGroup
      data-testid={dataTestId}
      gutterSize="none"
      style={{ alignItems: 'center', marginBottom: 6, marginTop: -2 }}
    >
      {barSegments.map((item) => (
        <EuiFlexItem
          data-testid={`${dataTestId}-${item.scoreQuality}`}
          key={item.scoreQuality}
          style={{ flexGrow: formatPercent(item.tivPct) }}
        >
          <EuiToolTip
            position="bottom"
            content={
              <p>
                {item.tivPct && (
                  <>
                    {`${formatPercent(item.tivPct)}% of TIV`}
                    <br />
                  </>
                )}
                {item.tiv && (
                  <>
                    {`${formatTIV(item.tiv)} TIV`}
                    <br />
                  </>
                )}
                {item.locations && <>{`${formatProperties(item.locations)} properties`}</>}
              </p>
            }
          >
            <div
              style={{ backgroundColor: 'transparent', height: 9 }}
              onMouseEnter={() => setActiveBar(item.scoreQuality || '')}
              onMouseLeave={() => setActiveBar('')}
            >
              <div
                style={{
                  backgroundColor: colorByName(item.scoreQuality || ''),
                  height: item.scoreQuality === 'None' ? 1 : height,
                  marginRight: 1,
                  opacity: opacity(item.scoreQuality || ''),
                  position: 'relative',
                  top: item.scoreQuality === 'None' ? (height - 1) / 2 : 0,
                  transition: 'all 0.6s ease-in-out' /* should match Elastic UI */,
                }}
              />
            </div>
          </EuiToolTip>
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  );
};

export default QualityProgressBar;

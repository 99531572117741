import { FC, useEffect, useState } from 'react';
import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import { EuiFlexGroup, EuiFlexItem , MultiSelect, Select, TextField } from 'ui';
import { Filter } from '../types';
import { handleFilterEnum } from '../utils';

interface Props {
  filter: Filter;
  handleOnChange?(value: any): void;
  operator?: string;
  value?: string;
}

const InputOrSelect: FC<Props> = ({ filter, handleOnChange, operator, value }) => {
  let valueArr = [];
  const { metadataMap } = useStreamContext();

  if (filter?.values && filter?.values.length > 0) {
    const options = filter.values.map((value) => ({
      label: value,
      value,
    }));

    return (
      <Select
        data-testid="query-builder-wrapper-input-or-select-filters"
        onChange={(value) => handleOnChange(value)}
        options={options}
        value={value}
      />
    );
  }

  if (operator === 'IN') {
    if (typeof value === 'string') {
      if (value.length > 0) {
        valueArr = value.replace(/(^,)|(,$)/g, '').split(',');
      }
    } else {
      valueArr = value;
    }
    if (filter?.filterEnums && filter?.filterEnums.length > 0) {
      const options = handleFilterEnum(filter, metadataMap)?.map((value) => ({
        label: value.label || value.value,
        value: value.value,
      }));
      return (
        <MultiSelect
          data-testid="query-builder-wrapper-input-or-select-enums"
          initialSelected={(valueArr || []).map((v) => ({ label: v, value: v }))}
          onChange={(value) => {
            const valueArray = value.map((format) => format.value);
            handleOnChange(valueArray);
          }}
          options={options}
        />
      );
    } 
      const createOption = (newOption) => {
        const options = [...valueArr, newOption.value];
        handleOnChange(options);
      };

      const customOptions = valueArr
        .filter((option) => option !== '')
        .map((option) => ({
          label: option,
          value: option,
        }));

      return (
        <div>
          {/* FIX ME 
          @ts-ignore */}
          <MultiSelect
            delimiter=","
            placeholder="Type your values..."
            initialSelected={customOptions}
            onChange={(value) => {
              const valueArray = value.map((format) => format.value);
              handleOnChange(valueArray);
            }}
            onCreateOption={(inputValue) => createOption({ label: inputValue, value: inputValue })}
          />
        </div>
      );
    
  }
  if (operator === 'EQUAL' && filter?.filterEnums && filter?.filterEnums.length > 0) {
    const options = handleFilterEnum(filter, metadataMap)?.map((value) => ({
      label: value.label || value.value,
      value: value.value,
    }));

    return (
      <Select
        data-testid="query-builder-wrapper-input-or-select-enums"
        onChange={(value) => handleOnChange(value)}
        options={options}
        value={value}
      />
    );
  }
  if (operator === 'BETWEEN') {
    if (typeof value === 'string') {
      if (value.length > 0) {
        valueArr = value.split(',');
      }
    } else {
      valueArr = value;
    }

    const [fromValue, setFromValue] = useState(valueArr[0]);
    const [toValue, setToValue] = useState(valueArr[1]);

    useEffect(() => {
      handleOnChange([fromValue || 0, toValue || 0].join(','));
    }, [toValue, fromValue]);
    return (
      <EuiFlexGroup gutterSize="s">
        <EuiFlexItem grow={false}>
          <TextField
            data-testid="query-builder-wrapper-input-or-select-input"
            onChange={(event) => setFromValue(event.target.value.replace(/[^0-9]/g, ''))}
            value={fromValue}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <TextField
            data-testid="query-builder-wrapper-input-or-select-input"
            onChange={(event) => setToValue(event.target.value.replace(/[^0-9]/g, ''))}
            value={toValue}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
  return (
    <TextField
      data-testid="query-builder-wrapper-input-or-select-input"
      onChange={(event) => handleOnChange(event.target.value)}
      value={value}
    />
  );
};

export default InputOrSelect;

"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonGroup = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var eui_1 = require("@elastic/eui");
var ButtonGroup_utils_1 = require("./ButtonGroup.utils");
var ButtonGroup = function (_a) {
    var dataTestId = _a["data-testid"], dataTourId = _a["data-tourid"], fullWidth = _a.fullWidth, _b = _a.iconOnly, iconOnly = _b === void 0 ? false : _b, idSelected = _a.idSelected, legend = _a.legend, onChange = _a.onChange, options = _a.options, _c = _a.size, size = _c === void 0 ? 'm' : _c, style = _a.style, className = _a.className;
    var _d = __read((0, react_1.useState)(options), 2), finalOptions = _d[0], setFinalOptions = _d[1];
    var buttonSize = {
        l: 'm',
        m: 's',
        s: 'compressed',
    };
    (0, react_1.useEffect)(function () {
        (0, ButtonGroup_utils_1.updateOptions)({ options: options, setFinalOptions: setFinalOptions });
    }, []);
    return ((0, jsx_runtime_1.jsx)(eui_1.EuiButtonGroup, { className: className, style: style, "data-testid": dataTestId || 'ui-atom-input-button-group', "data-tourid": dataTourId, buttonSize: buttonSize[size], color: "primary", idSelected: idSelected, isFullWidth: fullWidth, isIconOnly: iconOnly, legend: legend, onChange: onChange, options: finalOptions }));
};
exports.ButtonGroup = ButtonGroup;

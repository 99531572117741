import { useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import {
  Badge,
  Button,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiListGroup,
  EuiListGroupItem,
  EuiPanel,
  Spinner,
  Toggle,
  useToast,
} from 'ui';
import { useChatContext } from '@app/components/Chat/ChatProvider';
import { useGetSupportTicketsPageQuery } from '@app/graphql/queries/support/__generated__/getSupportTicketsPage.generated';
import { SortOrder } from '@app/graphql/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';

const Container = styled(EuiPanel)`
  height: 100%;
  overflow: hidden;

  .euiListGroupItem span {
    width: 100%;
  }
`;
interface Props {
  currentRoomId: string;
}
export const TicketList: React.FC<Props> = ({ currentRoomId }) => {
  const toast = useToast();
  const [includeClosedTickets, setIncludeClosedTickets] = useState<boolean>(false);

  const { data, loading } = useGetSupportTicketsPageQuery({
    onError: (e) => toast({ title: getErrorMessage(e), type: 'danger' }),
    variables: {
      input: { includeClosedTickets, sort: [{ attributeName: 'ts', order: SortOrder.Descending }] },
    },
  });

  const history = useHistory();

  const { roomSubscriptionsMap } = useChatContext();

  return (
    <Container>
      <EuiFlexGroup direction="column" style={{ height: '100%' }}>
        <EuiFlexItem grow={false}>
          <Button label="Browse all tickets" onClick={() => history.push('/support')} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Toggle
            label="Include closed tickets"
            checked={includeClosedTickets}
            onChange={(e: any) => setIncludeClosedTickets(e.target.checked)}
          />
        </EuiFlexItem>
        <EuiFlexItem style={{ overflowY: 'auto' }}>
          {loading ? (
            <Spinner />
          ) : (
            <EuiListGroup maxWidth flush>
              {data?.supportTicketsPage?.tickets?.map((t, i) => {
                const subscription = roomSubscriptionsMap?.[t.chatRoomID];
                return (
                  <EuiListGroupItem
                    style={{ width: '100%' }}
                    onClick={() => history.push(`/support/${t.chatRoomID}`)}
                    key={t.chatRoomID}
                    isActive={t.chatRoomID === currentRoomId}
                    label={
                      <EuiCard
                        style={{ width: '100%' }}
                        title={
                          <EuiFlexGroup alignItems="center" justifyContent="flexStart">
                            <EuiFlexItem>{t.title}</EuiFlexItem>
                            <EuiFlexItem grow={false}>
                              <Badge
                                color="primary"
                                label={`${subscription?.unread}`}
                                hidden={!subscription?.unread}
                              />
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        }
                        layout="horizontal"
                      >
                        <EuiFlexGroup direction="column">
                          <EuiFlexItem>
                            <EuiFlexGroup>
                              <EuiFlexItem>{t.authorEmail}</EuiFlexItem>
                            </EuiFlexGroup>
                          </EuiFlexItem>
                          {!!t?.org?.name && (
                            <EuiFlexItem>
                              <EuiFlexGroup>
                                <EuiFlexItem>Account: {t.org.name}</EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                  <Badge color="warning" label={t.status} />
                                </EuiFlexItem>
                              </EuiFlexGroup>
                            </EuiFlexItem>
                          )}
                        </EuiFlexGroup>
                      </EuiCard>
                    }
                  />
                );
              })}
            </EuiListGroup>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </Container>
  );
};

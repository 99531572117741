import * as React from 'react';
import { ExposureLevel } from '../../types';
import styles from './BarSegment.emotion';

interface Props {
  currentExposureLevel: ExposureLevel;
  exposureLevel: ExposureLevel;
  showSegmentBackgrounds: boolean;
  percent: number;
}

const BarSegment: React.FC<Props> = ({
  currentExposureLevel,
  exposureLevel,
  showSegmentBackgrounds,
  percent,
}) => (
  <div
    data-testid="exposure-bar-segment"
    className={styles.segment({
      exposureLevel,
      percent,
      showBackground: exposureLevel === currentExposureLevel || showSegmentBackgrounds,
    })}
  />
);

export default BarSegment;

import { useHistory } from 'react-router';
import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { PanelItem } from './types';

export default (): PanelItem => {
  const { slug } = useStreamContext().stream;
  const history = useHistory();

  return {
    iconName: 'integration',
    label: 'Integrations',
    onClick: () => history.push({ pathname: encodeUrl`/integrations/${slug}` }),
  };
};

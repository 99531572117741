import {
  Button,
  ButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
} from 'ui';
import { CANCEL_ADD_MESSAGE, CANCEL_EDIT_MESSAGE } from './constants';

export const CancelModal = ({ isEditMode, onCancel, onConfirm }) => {
  const message = isEditMode ? CANCEL_EDIT_MESSAGE : CANCEL_ADD_MESSAGE;

  return (
    <EuiModal style={{ maxWidth: '400px' }} onClose={onCancel}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Discard {isEditMode ? 'edits' : 'new property'}</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiText>{message}</EuiText>
      </EuiModalBody>
      <EuiModalFooter>
        <ButtonEmpty size="m" onClick={onCancel} label="Cancel" />
        <Button size="m" color="danger" fill label="Confirm" onClick={onConfirm} />
      </EuiModalFooter>
    </EuiModal>
  );
};

import { createContext, useContext } from 'react';
import * as React from 'react';
import { ApolloQueryResult, QueryLazyOptions } from '@apollo/client';
import { useSelection } from '@app/contexts/SelectionContext/SelectionContext';
import {
  StreamPropertiesPageQuery,
  useStreamPropertiesPageLazyQuery,
} from '@app/graphql/queries/streams/__generated__/StreamPropertiesPage.generated';
import {
  AttributeLocks,
  BinaryOperator,
  Exact,
  StreamPropertiesPageInput,
} from '@app/graphql/types';
import {
  IGetPropertyGroupsData,
  IGetPropertyGroupsVariables,
} from '@app/queries/streams/PropertyGroupsQuery/types';
import { refreshSelectedRows } from '../utils';

interface IPropertiesDataGridContext {
  isEditingCell: boolean;
  setIsEditingCell: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: (
    variables?: Partial<
      Exact<{
        input: StreamPropertiesPageInput;
      }>
    >,
  ) => Promise<ApolloQueryResult<StreamPropertiesPageQuery>>;
  attributeLocks: AttributeLocks[];
  refetchGroups: (
    variables?: IGetPropertyGroupsVariables,
  ) => Promise<ApolloQueryResult<IGetPropertyGroupsData>>;
  refetchSelectedRowProperties: (
    options?: QueryLazyOptions<
      Exact<{
        input: StreamPropertiesPageInput;
      }>
    >,
  ) => void;
}

export const PropertiesDataGridContext = createContext({} as IPropertiesDataGridContext);

export const PropertiesDataGridProvider = ({
  isEditingCell,
  stream,
  setIsEditingCell,
  refetch,
  attributeLocks,
  refetchGroups,
  children,
}) => {
  const { selectedRows } = useSelection();
  const [refetchSelectedRowProperties] = useStreamPropertiesPageLazyQuery({
    onCompleted: (data) => {
      refreshSelectedRows(selectedRows, data);
    },
    variables: {
      input: {
        filter: [
          {
            name: 'archipelagoId',
            operator: BinaryOperator.Equal,
            values: Object.values(selectedRows).map((row) => row?.archipelagoId),
          },
        ],
        limit: 200,
        streamSlug: stream?.slug,
      },
    },
  });

  return (
    <PropertiesDataGridContext.Provider
      value={{
        attributeLocks,
        isEditingCell,
        refetch,
        refetchGroups,
        refetchSelectedRowProperties,
        setIsEditingCell,
      }}
    >
      {children}
    </PropertiesDataGridContext.Provider>
  );
};

export const usePropertiesDataGridContext = () => {
  const context = useContext(PropertiesDataGridContext);
  return context;
};

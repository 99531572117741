import { FC } from 'react';
import styled from 'styled-components';
import MarkerDiv from '../../../cx/Stream/Reef/ReefMapV2/MarkerDiv';

const Marker = styled(MarkerDiv)`
  label: DefaultMarker;
  width: 27px;
  height: 43px;
  overflow: hidden;
  position: absolute;
  transform: translate(0, 0);
  left: -14px;
  top: -43px;
`;

export interface IProps {
  lat?: number;
  lng?: number;
}

const DefaultMarker: FC<IProps> = () => (
  <Marker>
    <img
      src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png"
      alt="Default Marker"
    />
  </Marker>
);

export default DefaultMarker;

import styled from '@emotion/styled';
import { isFloodZone, isScsZone, isZone } from '../utils';
import { getEdits, getProvenance, ICell } from './Cell';

export const Indicator = styled.div<{ show: boolean }>`
  ${({ show }) => {
    if (show) {
      return {
        backgroundColor: '#EB5757',
        borderRadius: '4px',
        color: '#FFFFFF',
        display: 'inline-block',
        fontSize: '14px',
        fontWeight: 600,
        height: '24px',
        lineHeight: '24px',
        marginRight: '3px',
        minWidth: '24px',
        textAlign: 'center',
        verticalAlign: 'middle',
      };
    }

    return {
      backgroundColor: '#F2F2F2',
      borderRadius: '4px',
      color: '#4F4F4F',
      display: 'inline-block',
      fontSize: '14px',
      fontWeight: 600,
      height: '24px',
      lineHeight: '24px',
      marginRight: '3px',
      minWidth: '24px',
      textAlign: 'center',
      verticalAlign: 'middle',
    };
  }}
`;

export const getIsFlood = (property) => {
  const floodProperty = property?.floodZone || property?.archipelagoFloodZone;
  return typeof floodProperty === 'boolean' ? floodProperty : isFloodZone(floodProperty);
};

export const getIsQuake = (property) => {
  const quakeProperty = property?.seismicZone || property?.archipelagoSeismicZone;
  return typeof quakeProperty === 'boolean' ? quakeProperty : isZone(quakeProperty);
};

export const getIsWind = (property) => {
  const windProperty = property?.windZone || property?.archipelagoWindZone;
  return typeof windProperty === 'boolean' ? windProperty : isZone(windProperty);
};

export const getIsScs = (property) => {
  const scsProperty = property?.convectiveStormZone || property?.archipelagoConvectiveStormZone;
  return isScsZone(scsProperty);
};

const HazardsIcon = (props: ICell) => {
  const id = props?.column?.id;
  const property = props.prop;
  const isQuake = getIsQuake(property);
  const isFlood = getIsFlood(property);
  const isWind = getIsWind(property);
  const provenance = props.prop.provenance ? getProvenance(props.prop, id) : null;

  const tooltip = {
    description: undefined,
    edits: getEdits(props.prop, id),
    provenance,
    title: undefined,
  };

  const body = (
    <>
      <Indicator show={isQuake}>EQ</Indicator>
      <Indicator show={isFlood}>Fl</Indicator>
      <Indicator show={isWind}>W</Indicator>
      <Indicator show={getIsScs(property)}>SCS</Indicator>
    </>
  );

  return { body, tooltip };
};

export default HazardsIcon;

import * as React from 'react';
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from 'ui';
import QueryBuilderWrapper from '@app/components/QueryBuilderWrapper/QueryBuilderWrapper';
import { getFilters } from '@app/components/QueryBuilderWrapper/utils';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { FilterQuery } from '../types';
import styles from './FilterModal.emotion';
import FilterModalActionButtons from './FilterModalActionButtons';

interface Props {
  filterable: Array<IGraphQLAttributeMetadata>;
  onApply: () => void;
  onClear: () => void;
  onClose: () => void;
  onQueryChange: (query: FilterQuery) => void;
  query?: FilterQuery;
}

const FilterModal: React.FC<Props> = ({
  filterable,
  onApply,
  onClear,
  onClose,
  onQueryChange,
  query,
}) => {
  const filters = getFilters(filterable);
  // FIX ME
  // @ts-ignore
  const defaultEmptyRule = query?.rules?.[0]?.field === 'default';
  const effectiveQuery =
    query?.rules?.length && !defaultEmptyRule
      ? query
      : {
          ...query,
          // create a empty rule for the user to pre-populate
          rules: [{ field: 'default', id: 'default', operator: 'CONTAINS', value: '' }],
        };

  return (
    <EuiModal data-testid="distiller-filter-modal" className={styles.container} onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>Filters</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFlexGroup direction="column">
          <EuiFlexItem>
            <QueryBuilderWrapper
              filters={filters}
              onQueryChange={onQueryChange}
              query={effectiveQuery}
            />
          </EuiFlexItem>
          <EuiFlexItem
            // FIX ME
            // @ts-ignore
            alignItems="flexEnd"
          >
            <FilterModalActionButtons
              onApply={onApply}
              onClear={onClear}
              onClose={onClose}
              query={effectiveQuery}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalBody>
    </EuiModal>
  );
};

export default FilterModal;

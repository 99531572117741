import * as React from 'react';
import { EuiFormRow, Select } from 'ui';
import { IForm, ModalAction } from './types';

const orgLevelOptions = [
  { label: 'Direct', value: 'Direct' },
  { label: 'Enterprise', value: 'Enterprise' },
];
interface Props {
  action: ModalAction;
  values: IForm;
  onChange: (orgLevel: string) => void;
}
const OrgLevelSelect: React.FC<Props> = ({ action, values, onChange }) => (
    <>
      <EuiFormRow label="Account Level" fullWidth>
        <Select
          data-testid="organization-reporting-type-dropdown"
          options={orgLevelOptions}
          onChange={onChange}
          value={values.orgLevel || orgLevelOptions[0].value}
          disabled={action !== 'add'}
          fullWidth
        />
      </EuiFormRow>
    </>
  );

export default OrgLevelSelect;

import { FC, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Button } from 'ui';
import { useStreamContext } from '../StreamProvider';

const useIsTourActive = (search?: string) =>
  useMemo(() => {
    const params = new URLSearchParams(search);
    return !!params.get('highlights');
  }, [search]);

const DocumentsButton: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const tourIsActive = useIsTourActive(location.search);
  const { stream } = useStreamContext();

  const newPath = !tourIsActive
    ? `/streams/${stream?.slug}/documents${location.search}`
    : `/streams/${stream?.slug}/documents?highlights=3`;

  return (
    <Button
      fill
      onClick={() => {
        history.push(`${newPath}`);
      }}
      size="s"
      data-testid="documents-button"
      data-tourid="documents-button"
      label="Documents"
    />
  );
};

export default DocumentsButton;

import styled from '@emotion/styled';
import { formatStreamCurrency } from '@app/cx/Stream/utils';
import { LossAttributes } from '@app/queries/properties/generated_types';
import { formatCurrency as _formatCurrency } from '@app/utils/format';
import { IFormatCurrencyOptions, Val } from '../../../utils/format';
import { ColumnFormattingOptions } from '../types';
import { getEdits, getProvenance, ICell } from './Cell';
import { StreamOptions } from './GridCell';

const FormattedCurency = styled.div(
  (props: ColumnFormattingOptions) => `
  label: Currency;
  text-align: ${props.textAlign || 'right'};
  font-variant-numeric: tabular-nums;
`,
);

// Special case for loss attributes
const lossAttributes: Array<keyof LossAttributes> = ['totalGrossLoss', 'largestLoss'];

const getCurrency = (props: ICell, streamOptions?: StreamOptions): string => {
  if (props?.column?.type === 'currencyLocal') {
    return props?.prop?.valuationCurrency || streamOptions?.displayCurrency || 'USD';
  }
  return streamOptions?.displayCurrency || 'USD';
};

const Currency = (props: ICell, format: boolean, streamOptions?: StreamOptions) => {
  const id = props?.column?.id;
  const isLossAttribute = lossAttributes.includes(id as keyof LossAttributes);

  const formatCurrency = (v, options?: IFormatCurrencyOptions) => {
    if (isLossAttribute) {
      return _formatCurrency(v, { ...options, currency: 'USD' });
    } 
      return formatStreamCurrency(v, getCurrency(props, streamOptions), options);
    
  };

  const formatValue = (val: Val) => (val === null ? '-' : formatCurrency?.(val));
  const formatTitle = (val: Val) => formatCurrency?.(val, { displayWithoutUnits: true });

  const provenance = getProvenance(props.prop, id);
  const edits = getEdits(props.prop, id);
  const value = props.value as Val;
  const formattedValue = formatValue(value);

  const formattedTitle = formatTitle(value);
  const title =
    !Number.isNaN(value as number) && formattedValue !== formattedTitle
      ? formattedTitle
      : undefined;
  const tooltip = {
    description: undefined,
    edits,
    provenance,
    title,
  };

  const body = (
    <FormattedCurency textAlign={props.column.formattingOptions.textAlign}>
      <span>{formattedValue}</span>
    </FormattedCurency>
  );

  return { body, tooltip };
};

export default Currency;

import { FC, Fragment } from 'react';
import { EuiFlexGroup, EuiFlexItem, Spacer } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import ChartAxisLabel from '../Components/ChartAxisLabel';
import HorizontalRule from '../Components/HorizontalRule';
import VerticalRule from '../Components/VerticalRule';
import BenchmarkKey from './BenchmarkKey';
import { round5 } from './utils';

export const getScale = (max: number) => {
  if (max < 5) {
    return [0, 2, 4, 6];
  }
  if (max < 15) {
    return [0, 5, 10, 15];
  }
  if (max < 30) {
    return [0, 10, 20, 30];
  }
  if (max < 40) {
    return [0, 15, 30, 45];
  }
  if (max < 60) {
    return [0, 20, 40, 60];
  }
  if (max < 75) {
    return [0, 25, 50, 75];
  }
  if (max < 100) {
    return [0, 35, 65, 100];
  }
  const scaleMax = round5(max);
  return [0, round5(scaleMax / 3), round5(2 * (scaleMax / 3)), scaleMax];
};

interface Bin {
  score: number;
  count: number;
}

interface Props {
  data: any;
  showKey?: boolean;
}

const maxCount = (bins: Bin[]) => bins.reduce((acc, cur) => (cur.count > acc ? cur.count : acc), 0);

const formatPercent = (percent: number) => Number.parseFloat((percent || 0).toFixed(2));

const getCombined = (data: any): Bin[] => {
  if (data?.enriched) {
    return (data?.enriched || []).map((item: Bin, index: number) => ({
      count: item.count + data?.unenriched[index]?.count,
      score: item.score,
    }));
  }

  return (data?.unenriched || []).map((item: Bin) => ({
    count: item.count,
    score: item.score,
  }));
};

const BenchmarkChart: FC<Props> = ({ data, showKey = true }) => {
  const combined = getCombined(data);

  const max = maxCount(combined);

  const scale = getScale(max);

  const scaled = (x: number) => 100 * ((x - scale[0]) / (scale[3] - scale[0]));

  const snapshotScoreBin = Math.floor((data?.snapshotScore || 0) * 10) * 10;

  return (
    <>
      {showKey && <BenchmarkKey />}
      <Spacer size="m" />
      <ChartAxisLabel hideIcon>{STREAMS_LABEL} Count</ChartAxisLabel>
      <Spacer size="m" />
      <EuiFlexGroup
        data-testid="benchmark-chart"
        style={{ marginLeft: 0, paddingRight: 12 }}
        gutterSize="s"
      >
        <EuiFlexItem grow={false}>
          <VerticalRule scale={scale} showPercent={false} />
          <Spacer size="l" />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup gutterSize="none" direction="column">
            <EuiFlexItem>
              <EuiFlexGroup direction="row" gutterSize="none">
                {combined.map((bin, index) => {
                  const scaledFormattedPercent = scaled(max * formatPercent(bin.count / max));
                  const scaledRemainingPercent = 100 - scaledFormattedPercent;

                  let scaledEnrichedPercent = null;
                  if (data?.enriched) {
                    scaledEnrichedPercent = scaled(
                      max * formatPercent(data?.enriched[index].count / bin.count),
                    );
                  }

                  const scaledUnenrichedPercent = scaled(
                    max * formatPercent(data?.unenriched[index].count / bin.count),
                  );

                  return (
                    <Fragment key={index}>
                      <EuiFlexItem style={{ position: 'relative' }}>
                        {snapshotScoreBin === bin.score && (
                          <div
                            data-testid="snapshot-score-bin"
                            style={{
                              left: -20,
                              position: 'absolute',
                              width: 80,
                            }}
                          >
                            <ChartAxisLabel hideIcon>Your Portfolio</ChartAxisLabel>
                            <div
                              data-testid={`snapshot-score-bin-${snapshotScoreBin}`}
                              style={{
                                backgroundColor: 'black',
                                height: 142,
                                left: 35,
                                position: 'absolute',
                                top: 20,
                                width: 1,
                              }}
                            />
                          </div>
                        )}
                        <EuiFlexGroup direction="column" gutterSize="none">
                          <EuiFlexItem style={{ flexGrow: scaledRemainingPercent }}>
                            <div style={{ backgroundColor: 'transparent' }} />
                          </EuiFlexItem>
                          <EuiFlexItem
                            style={{
                              flexGrow: scaledFormattedPercent,
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: 'pink' /* '#21B59A' euiColorSuccess */,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%' /* should match Elastic UI */,
                                marginRight: 1,
                                transition: 'all 0.6s ease-in-out',
                              }}
                            >
                              <div
                                data-testid="unenriched-bin"
                                style={{
                                  backgroundColor: '#E0E6EB' /* euiColorLightShade */,
                                  flexGrow: scaledUnenrichedPercent,
                                }}
                              />
                              <div
                                data-testid="enriched-bin"
                                style={{
                                  backgroundColor: '#21B59A' /* euiColorSuccess */,
                                  flexGrow: scaledEnrichedPercent || 0,
                                }}
                              />
                            </div>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFlexItem>
                    </Fragment>
                  );
                })}
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <HorizontalRule showPercent={false} omitNumbers />
              <Spacer size="xs" />
            </EuiFlexItem>
          </EuiFlexGroup>
          <ChartAxisLabel textAlign="right">Highest quality</ChartAxisLabel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export default BenchmarkChart;

import * as React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, Icon } from 'ui';
import { useExposureIndexContext } from '../../context/exposureIndexContext';
import { ExposureLevel } from '../../types';
import styles from './ExposureBar.emotion';
import ExposureBarIndicator from './ExposureBarIndicator';
import ExposureBarSegment from './ExposureBarSegment';
import { getSegmentPercents } from './utils';

const ExposureBar: React.FC = () => {
  const exposure = useExposureIndexContext();

  const segmentPercents = getSegmentPercents();
  return (
    <EuiFlexGroup data-testid="exposure-bar" direction="column" gutterSize="s">
      <EuiFlexItem grow={false}>
        <div className={styles.container}>
          <ExposureBarIndicator
            exposureLevel={exposure?.exposure?.exposureGrade}
            segmentPercents={segmentPercents}
          />
          <ExposureBarSegment
            exposureLevel={ExposureLevel.VeryLow}
            segmentPercents={segmentPercents}
          />
          <div className={styles.gap} />
          <ExposureBarSegment exposureLevel={ExposureLevel.Low} segmentPercents={segmentPercents} />
          <div className={styles.gap} />
          <ExposureBarSegment
            exposureLevel={ExposureLevel.Medium}
            segmentPercents={segmentPercents}
          />
          <div className={styles.gap} />
          <ExposureBarSegment
            exposureLevel={ExposureLevel.High}
            segmentPercents={segmentPercents}
          />
          <div className={styles.gap} />
          <ExposureBarSegment
            exposureLevel={ExposureLevel.VeryHigh}
            segmentPercents={segmentPercents}
          />
        </div>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup alignItems="center" gutterSize="xs">
          <EuiFlexItem grow={false}>
            <EuiText size="xs">
              <p>Exposure Level</p>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <Icon name="arrowRight" />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default ExposureBar;

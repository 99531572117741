import { useFormContext } from 'react-hook-form';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import { EuiFormRow, EuiFormRowProps, FloatField } from 'ui';
import { FormCurrencyInput } from '@app/components/Form/FormCurrencyInput';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { Property } from '@app/graphql/types';
import { GLOBAL_CURRENCY_INPUT_KEY, LOCAL_CURRENCY_INPUT_KEY } from '../constants';

interface IEditCurrencyPopoverContentProps {
  property: Property;
}

export const EditCurrencyPopoverContent = ({ property }: IEditCurrencyPopoverContentProps) => {
  const { stream } = useStreamContext();
  const { setValue, trigger } = useFormContext();

  const localCurrency = property?.valuationCurrency;
  const globalCurrency = stream?.displayCurrency;

  const calculateNewGlobalValue = (value: number) => {
    if (isNil(value)) {
      return;
    }
    const newGlobalValue = round(Number(value) * property.exchangeRate, 2);
    setValue(GLOBAL_CURRENCY_INPUT_KEY, newGlobalValue);
    trigger(GLOBAL_CURRENCY_INPUT_KEY);
  };

  const calculateNewLocalValue = (value: number) => {
    if (isNil(value)) {
      return;
    }
    const newGlobalValue = round(Number(value) / property.exchangeRate, 2);
    setValue(LOCAL_CURRENCY_INPUT_KEY, newGlobalValue);
    trigger(LOCAL_CURRENCY_INPUT_KEY);
  };

  return (
    <>
      <FormCurrencyInput
        name={LOCAL_CURRENCY_INPUT_KEY}
        inputProps={{ prepend: <b>{localCurrency}</b> }}
        formRowProps={{ label: 'Local Currency' } as EuiFormRowProps}
        onChange={calculateNewGlobalValue}
      />
      <FormCurrencyInput
        name={GLOBAL_CURRENCY_INPUT_KEY}
        inputProps={{ prepend: <b>{globalCurrency}</b> }}
        formRowProps={{ label: 'Global Currency' } as EuiFormRowProps}
        onChange={calculateNewLocalValue}
      />
      <EuiFormRow id="exchange-rate-field" label="Exchange Rate">
        <FloatField
          aria-labelledby="exchange-rate-field"
          disabled
          value={property?.exchangeRate?.toString()}
          onChange={undefined}
        />
      </EuiFormRow>
    </>
  );
};

import * as React from 'react';
import { Grade } from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';
import { Badge } from 'ui';
import { useUnderwritingAssessmentReportContext } from '../../../underwritingAssessmentReportContext';
import { getBadgeColor } from '../../utils';

interface Props {
  grade: Grade;
  filterable: boolean;
  isLarge?: boolean;
}
const GradeBadge: React.FC<Props> = ({ grade, filterable = true, isLarge = false }) => {
  const { gradingDetailsFilter, setGradingFilter } = useUnderwritingAssessmentReportContext();

  const handleClick = () => {
    const isTotal = grade.category === 'Total';
    const isGradingDetailsFilter = gradingDetailsFilter === grade.category;
    const newGradingFilter = isGradingDetailsFilter || isTotal ? null : grade.category;

    setGradingFilter(newGradingFilter);
  };

  return (
    <Badge
      color={getBadgeColor(grade.color)}
      iconName={filterable ? 'filter' : undefined}
      label={grade?.category}
      onClick={handleClick}
      size={isLarge ? 'l' : 's'}
    />
  );
};
export default GradeBadge;

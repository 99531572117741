import { FC, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import {
  Button,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiForm,
  EuiFormRow,
  EuiText,
  EuiTitle,
  FilePicker,
  Select,
  TextField,
  useToast,
} from 'ui';
import DateField from '@app/components/Fields/DateField';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import CREATE_RENEWAL_STREAM from '@app/graphql/mutations/stream/CreateRenewalStream';
import { CreateRenewalStreamInput } from '@app/graphql/types';
import { useTracker } from '@app/hooks/useTracker';
import { IGraphQLStream } from '@app/queries/streams/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { replaceDates } from './utils';

interface Props {
  directStream?: IGraphQLStream;
  source: 'email' | 'click';
  streamList?: IGraphQLStream[];
}

export const RenewalFlyout: FC<Props> = ({ directStream, source, streamList = [] }) => {
  const tracker = useTracker();
  useEffect(() => {
    if (source === 'email') {
      tracker.track('SOV Renewal Form Opened', { referrer: 'renewal-notification' });
    } else {
      tracker.track('SOV Renewal Form Opened', { referrer: 'sov-manager' });
    }
  }, []);

  const [selectedStream, setSelectedStream] = useState<IGraphQLStream | undefined>(streamList?.[0]);
  const stream = directStream || selectedStream;
  const formMethods = useForm({ mode: 'onSubmit' });
  const [expiry, setExpiry] = useState(moment().add(1, 'year').format('YYYY-MM-DD').toString());
  const [effective, setEffective] = useState(moment().format('YYYY-MM-DD').toString());
  const toast = useToast();

  const [files, setFiles] = useState([]);

  const { closeFlyout } = useContext(FlyoutContext);

  const [CreateRenewalStream, { loading }] = useMutation<any, { input: CreateRenewalStreamInput }>(
    CREATE_RENEWAL_STREAM,
    {
      onCompleted: () => {
        if (source === 'email') {
          tracker.track('SOV Renewal Submitted', { referrer: 'renewal-notification' });
        } else {
          tracker.track('SOV Renewal Submitted', { referrer: 'sov-manager' });
        }
        toast({
          title:
            'Successfully Submitted Renewal. Archipelago will process this submission and notify you when processing is complete.',
          type: 'success',
        });
        closeFlyout();
      },
      onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    },
  );
  const submit = () => {
    const values = formMethods.getValues();

    CreateRenewalStream({
      variables: {
        input: {
          orgName: stream.orgName,
          priorStreamSlug: stream.slug,
          sov: values.sovFile,
          stream: {
            effectiveDate: values.effective,
            expiryDate: values.expiry,
            isPublic: false,
            name: values.sovName,
          },
        },
      },
    });
  };

  const updateName = (field: string) => (e: any) => {
    formMethods.setValue(field, e.target.value);
  };

  const updateFile = (file?: File) => {
    if (file) {
      setFiles([file]);
      formMethods.setValue('sovFile', file);
    } else {
      setFiles(undefined);
    }
  };

  const updateDate = (name: string) => (value: string) => {
    formMethods.setValue(name, value);
    if (name === 'expiry') {
      setExpiry(value);
    } else {
      setEffective(value);
    }
  };

  const years = ` ${moment().format('YYYY').toString()}-${moment()
    .add(1, 'year')
    .format('YYYY')
    .toString()}`;

  const handleStreamChange = (e: any) => {
    setSelectedStream(streamList?.find((stream) => stream.name === e));
    formMethods.setValue('name', replaceDates(e, years));
  };

  const streamOptions = streamList?.map((stream) => ({
    label: stream.name,
    value: stream.name,
  }));

  const streamNameWithNewDates = replaceDates(stream.name, years);
  return (
    <FormProvider {...formMethods}>
      <EuiFlyout onClose={closeFlyout} onSubmit={formMethods.handleSubmit(submit)}>
        <EuiFlyoutHeader>
          <EuiTitle>
            <EuiText>Renew Your SOV</EuiText>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiFlexGroup direction="column">
            <EuiForm>
              {!directStream && (
                <EuiFormRow label="Stream" fullWidth>
                  <Select
                    onChange={handleStreamChange}
                    options={streamOptions}
                    value={selectedStream?.name}
                    fullWidth
                  />
                </EuiFormRow>
              )}
              <EuiFormRow label="SOV Name" fullWidth>
                <TextField
                  name="sovName"
                  onChange={updateName('name')}
                  fullWidth
                  {...formMethods.register('sovName', {
                    minLength: 1,
                    required: true,
                    value: directStream && streamNameWithNewDates,
                  })}
                />
              </EuiFormRow>
              <EuiFormRow label="Effective Date" fullWidth>
                <DateField
                  value={effective}
                  {...formMethods.register('effective', { value: effective })}
                  onChange={updateDate('effective')}
                />
              </EuiFormRow>
              <EuiFormRow label="Expiry Date" fullWidth>
                <DateField
                  value={expiry}
                  {...formMethods.register('expiry', { value: expiry })}
                  onChange={updateDate('expiry')}
                />
              </EuiFormRow>
              <EuiFormRow label="SOV File">
                <FilePicker
                  prompt="Drag and drop your SOV to renew"
                  data-testid="submission-file-picker"
                  files={files}
                  onChange={(files?: File[]) =>
                    files ? updateFile(files[0]) : updateFile(undefined)
                  }
                />
              </EuiFormRow>
            </EuiForm>
          </EuiFlexGroup>
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup direction="row" justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <Button label="Cancel" onClick={closeFlyout} fill={false} />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <Button
                fill
                label="Renew"
                disabled={!files || files.length < 1 || formMethods.getFieldState('name').invalid}
                loading={loading}
                onClick={submit}
                type="submit"
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    </FormProvider>
  );
};

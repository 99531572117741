import { FC, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import styled from '@emotion/styled';
import get from 'lodash/get';
import { Button, EuiFlexGroup, EuiFlexItem } from 'ui';
import { FilterQuery } from '@app/components/Filter/types';
import { convertRuleGroupToFilters } from '@app/components/Filter/utils';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import QueryBuilderWrapper from '@app/components/QueryBuilderWrapper/QueryBuilderWrapper';
import { getFilters, PropertyAttributeMetadata } from '@app/components/QueryBuilderWrapper/utils';
import { FormFooter, FormHeader } from './Components';

export const Label = styled.span`
  display: block;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 234px;
`;

function VirtualizedWrapper({
  items,
  hasNextPage,
  isNextPageLoading,
  loadNextPage,
  RenderItem,
  itemHeight,
  searchText,
}: any) {
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items?.length + 1 : items?.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  // Render an item or a loading indicator.
  const Item = ({ index, style }: { index: number; style: any }) => {
    let content;
    if (!isItemLoaded(index)) {
      content = <LoadingSpinnerV2 />;
    } else {
      content = (
        <RenderItem
          item={items[index]}
          key={items[index]?.id}
          // disabled={!items[index].attributeProvenance}
          // tooltipMessage="This property does not have any linked documents"
        />
      );
    }

    return <div style={style}>{content}</div>;
  };

  const itemSize = (index: number) => itemHeight(items[index]);
  const key = isNextPageLoading ? itemCount : searchText;

  return (
    <div
      className="propFlyout"
      style={{ flex: '1 1 auto', height: '100%', margin: '12px 0px', position: 'relative' }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            threshold={15}
          >
            {({ onItemsRendered, ref }) => (
              <VariableSizeList
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                ref={ref}
                height={height}
                width={width}
                itemSize={itemSize}
                key={key}
              >
                {Item}
              </VariableSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  );
}

interface IProps {
  relationship: string;
  query: any;
  item: any;
  dataPath: string;
  hasMore: boolean;
  formHeader: any;
  formFooter: any;
  itemHeight: (item: any) => number;
  selectedItems: any;
  onlyShowSelected?: boolean;
  type?: string;
  renderOutside?: boolean;
  renderLabel?: () => JSX.Element;
  filterable?: Array<PropertyAttributeMetadata>;
  onQueryRun?: (filters: any) => void;
}

const RelationshipSelectFlyout: FC<IProps> = ({
  relationship,
  query,
  item,
  dataPath,
  hasMore,
  formHeader,
  formFooter,
  itemHeight,
  selectedItems,
  onlyShowSelected,
  filterable,
  onQueryRun,
}) => {
  const isPropertySelect = relationship === 'properties';

  const [filtersEnabled, setFiltersEnabled] = useState<boolean>(false);
  const [localFilterQuery, setLocalFilterQuery] = useState<FilterQuery | undefined>();

  const toggleFiltersEnabled = () => {
    setFiltersEnabled(!filtersEnabled);
  };

  const handleOnQueryChange = (filterQuery: FilterQuery) => setLocalFilterQuery(filterQuery);

  const filters = getFilters(filterable || []);

  const handleRunQuery = () => {
    const booleanFilters = ['enriched', 'multiTenant'];
    const formattedFilters = convertRuleGroupToFilters(localFilterQuery);

    formattedFilters.forEach((filter) => {
      if (booleanFilters.includes(filter.name)) {
        switch (filter.values[0]) {
          case 'Yes': {
            filter.values[0] = 'true';
            break;
          }

          case 'No': {
            filter.values[0] = 'false';
            break;
          }
        }
      }
    });

    if (onQueryRun) {
      onQueryRun(formattedFilters);
      toggleFiltersEnabled();
    }
  };

  const propertyFilters = (
    <>
      <EuiFlexGroup justifyContent="spaceBetween" gutterSize="xl">
        <EuiFlexItem grow={false}>
          <Button
            fill
            size="s"
            onClick={toggleFiltersEnabled}
            iconName="filter"
            label="Add properties matching..."
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Button size="s" onClick={handleRunQuery} label="Run" />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup gutterSize="xl">
        <EuiFlexItem>
          <QueryBuilderWrapper
            query={localFilterQuery}
            onQueryChange={handleOnQueryChange}
            filters={filters}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );

  return (
    <>
      <FormHeader style={{ margin: '24px 24px 0px 24px', width: 'initial' }}>
        {isPropertySelect && filtersEnabled === false && (
          <Button
            size="s"
            fullWidth
            onClick={toggleFiltersEnabled}
            iconName="filter"
            label="Show only properties matching..."
          />
        )}
        {filtersEnabled && propertyFilters}
        {formHeader}
      </FormHeader>
      {query?.loading && <LoadingSpinnerV2 />}
      {!onlyShowSelected && (
        <VirtualizedWrapper
          items={get(query, dataPath, [])}
          hasNextPage={hasMore}
          isNextPageLoading={query?.loading}
          loadNextPage={query?.loadMore}
          RenderItem={item}
          itemHeight={itemHeight}
          searchText={query?.searchText}
        />
      )}
      {onlyShowSelected && (
        <VirtualizedWrapper
          items={selectedItems || []}
          hasNextPage={false}
          isNextPageLoading={false}
          loadNextPage={null}
          RenderItem={item}
          itemHeight={itemHeight}
        />
      )}
      {formFooter && <FormFooter>{formFooter}</FormFooter>}
    </>
  );
};
export default RelationshipSelectFlyout;

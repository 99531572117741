import { useContext } from 'react';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { ModalContext } from '@app/contexts/ModalContext';
import { usePropertiesPageContext } from '@app/cx/Properties/PropertiesPage.context';
import EditStreamModal from '../EditModal/EditStreamModal';
import { PanelItem } from './types';

export default (): PanelItem => {
  const { showModal } = useContext(ModalContext);
  const { stream } = usePropertiesPageContext();

  return {
    iconName: 'gear',
    label: `${STREAMS_LABEL} settings`,
    onClick: () =>
      showModal(
        <EditStreamModal
          data-testid="manage-stream"
          // FIX ME
          // @ts-ignore
          stream={stream}
        />,
      ),
  };
};

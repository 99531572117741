import { FC } from 'react';
import { Button } from 'ui';

interface GeoJobButtonArgs {
  testId?: string;
  onClick?: () => void;
}

const GeoJobButton: FC<GeoJobButtonArgs> = ({ testId, onClick }) => (
  <Button
    data-testid={`geo-job-enrich-button ${testId}`}
    size="s"
    onClick={onClick}
    label="Enrich Geo"
  />
);

export default GeoJobButton;

import { FC } from 'react';
import styled from '@emotion/styled';

interface IProps {
  hero: string | JSX.Element;
  sidekick: string | JSX.Element;
}

const Container = styled.div`
  label: Couplet;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > * {
    flex: 1;
  }
`;

const Hero = styled.div`
  label: CoupletHero;
  color: inherit;
  max-height: 22px;
  overflow: hidden;
  flex: 0 0 auto;
`;

const Sidekick = styled.div`
  label: CoupletSidekick;
  font-size: 11.85px;
  line-height: 19px;
  color: #767676;
`;

const Couplet: FC<IProps> = ({ hero, sidekick }) => (
  <Container>
    <Hero>
      <span>{hero}</span>
    </Hero>
    <Sidekick>
      <span>{sidekick}</span>
    </Sidekick>
  </Container>
);

export default Couplet;

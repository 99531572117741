import { useEffect, useMemo } from 'react';
import { EuiFlexGroup, EuiFlexItem, Icon } from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useFlyout } from '@app/contexts/FlyoutContext';
import { PaginationProvider } from '@app/contexts/PaginationContext/PaginationContext';
import { DocumentsTab } from '@app/cx/Properties/DocumentsTab/DocumentsTab';
import { usePropertiesPageContext } from '@app/cx/Properties/PropertiesPage.context';
import { RenewalFlyout } from '@app/cx/Properties/RenewalFlyout/RenewalFlyout';
import { getChart, getExplorerTitle } from '@app/cx/Stream/Aside/Explorers/chartCatalog';
import { MODE } from '@app/cx/Stream/Distillers/types';
import {
  addExpandValue,
  getActiveGroupIndex,
  getGroupByFromState,
  removeExpandValue,
} from '@app/cx/Stream/groupbyv2';
import ReefGallery from '@app/cx/Stream/Reef/ReefGallery/ReefGallery';
import ReefMapV2 from '@app/cx/Stream/Reef/ReefMapV2/ReefMapV2';
import { getPropertyCount, getStringIndices } from '@app/cx/Stream/Reef/utils';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { getDefaultDistillers, getMetadataByType, KEYS, usePageState } from '@app/cx/Stream/utils';
import { useQueryState } from '@app/hooks/useQueryState';
import useStandardQuery from '@app/hooks/useStandardQuery';
// @ts-ignore
import GET_EXPLORERS from '@app/queries/explorers/getExplorers.gql';
// TODO convert these to the generated hooks, not worth it to figure out how
// to declare these files in ts right now
// @ts-ignore
import GET_GROUPS from '@app/queries/streams/PropertyGroupsQuery/getGroups.gql';
import {
  IGetPropertyGroupsData,
  IGetPropertyGroupsVariables,
} from '@app/queries/streams/PropertyGroupsQuery/types';
import { getSnapshotLabel } from '../../cx/Stream/Aside/Explorers/utils';
import { convertRuleGroupToFilters } from '../Filter/utils';
import { getDisclosuresFromRows, modifyDisclosuresForIndices } from '../GroupedList/utils';
import LoadingSpinnerV2 from '../LoadingSpinnerV2/LoadingSpinnerV2';
import { PropertiesDataGrid } from '../PropertiesDataGrid/PropertiesDataGrid';
import PropertiesGroupedList from '../PropertiesGroupedList/PropertiesGroupedList';
import { getGroupingsFromString } from '../PropertiesGroupedList/utils';
import { SearchFilter } from '../SearchFilter/SearchFilter';
import {
  ChartContainer,
  ContentContainer,
  ExploreContainer,
  GalleryContainer,
  MapContainer,
  OptionsPanel,
  SearchBarContainer,
  ViewContainer,
  ViewModeOptions,
} from './Explore.emotion';

const toggleButtonsIcons = [
  {
    iconType: () => <Icon name="menu" size="m" />,
    id: MODE.GRID,
    label: 'SOV',
  },
  {
    iconType: () => <Icon name="map" size="m" />,
    id: MODE.MAP,
    label: 'Dashboard',
  },
  {
    iconType: () => <Icon name="image" size="m" />,
    id: MODE.GALLERY,
    label: 'Gallery',
  },
];

export const Explore = () => {
  const { account } = useAuth();
  const isAdmin = !!account?.permissions?.admin;

  const [qs, setQueryState] = useQueryState();

  const showRenewalFlyout = qs.get('renewal') === 'true';
  const view = (qs.get('view') as MODE) || MODE.GRID;
  const { showFlyout } = useFlyout();

  const { stream, propertyAttributeMetadata } = useStreamContext();
  // FIX ME
  // @ts-ignore
  const { effectiveGroupedProperties, selectedProperties, permissions } =
    usePropertiesPageContext();
  const [pageState, setPageState] = usePageState();
  const { currentSnapshot, changesSince } = pageState;
  const canEditProperties = permissions?.canEditProperties || isAdmin;

  useEffect(() => {
    if (showRenewalFlyout && canEditProperties) {
      showFlyout(<RenewalFlyout directStream={stream} source="email" />);
    }
  }, []);

  const filters =
    pageState?.filters?.rules?.map((r) => ({
      // FIX ME
      // @ts-ignore
      name: r.field,
      // FIX ME
      // @ts-ignore
      operator: r.operator,
      // FIX ME
      // @ts-ignore
      values: r.value,
    })) || undefined;

  const effectiveToggleButtonsIcons = !stream?.isMyProperties
    ? [
        ...toggleButtonsIcons,
        {
          iconType: () => <Icon name="fileText" size="m" />,
          id: MODE.DOCUMENTS,
          label: 'Documents',
        },
      ]
    : toggleButtonsIcons;

  const groupable = getMetadataByType(propertyAttributeMetadata, 'groupable');

  const distillers = useMemo(
    () =>
      getDefaultDistillers(
        {
          groupable,
        },
        pageState,
        permissions,
      ),
    [JSON.stringify(groupable), pageState.groupByV2, pageState.sizeBy],
  );

  const { loading, data, error } = useStandardQuery<
    IGetPropertyGroupsData,
    IGetPropertyGroupsVariables
  >(
    GET_GROUPS,
    {
      variables: {
        changesSince: '',
        compareChanges: false,
        currentSnapshot: '',
        filter: [],
        // NOTE: The API only supports grouping on 1 single attribute.
        groupBy: undefined,
        streamSlug: stream?.slug,
      },
    },
    {
      errorMessage: (err) => err?.message,
    },
  );

  const groupBys = getGroupByFromState(pageState.groupByV2);
  const groups = getGroupingsFromString(`${groupBys || ''}`.split(',').filter(Boolean));
  const activeGroupIndices = getActiveGroupIndex(pageState.groupByV2);

  const disclosures = useMemo(() => {
    if (effectiveGroupedProperties?.length) {
      const disclosuresForRows = getDisclosuresFromRows(effectiveGroupedProperties, 0);
      if (activeGroupIndices !== undefined) {
        return modifyDisclosuresForIndices(disclosuresForRows, [activeGroupIndices]);
      }

      return disclosuresForRows;
    }

    return [];
  }, [activeGroupIndices, effectiveGroupedProperties]);

  const { error: explorersError, data: explorerData } = useStandardQuery(
    GET_EXPLORERS,
    {
      variables: {
        filter: filters,
        fromSnapshot: changesSince,
        streamSlug: stream.slug,
        toSnapshot: currentSnapshot,
      },
    },
    {
      errorMessage: (err) => ({
        additionalLoggingInformation: [
          'Error in ExplorersContainer',
          `Query: ${GET_EXPLORERS}`,
          `Error: ${JSON.stringify(err)}`,
        ].join('\n'),
        message: err?.message,
      }),
    },
  );

  if (loading) {
    return <LoadingSpinnerV2 />;
  }

  if (error || !data || explorersError || !explorerData) {
    return null;
  }

  const {
    explorers: { groups: explorerGroups, currentSnapshot: snapshot, previousSnapshot },
  } = explorerData;

  const previousSnapshotLabel = getSnapshotLabel(stream.snapshots, previousSnapshot);
  const currentSnapshotLabel = getSnapshotLabel(stream.snapshots, snapshot);
  const maxHeight = Math.max(
    ...explorerGroups.map((chartGroup) =>
      chartGroup.charts.map((explorer) => {
        const chart = getChart(explorer);

        return chart?.height;
      }),
    ),
  );

  const setActiveGroup = (activeGroup: Array<number>, label: string) => {
    const activeGroupIndex = activeGroup.length > 0 ? activeGroup[0] : undefined;
    const previousActiveGroupIndex = getActiveGroupIndex(pageState.groupByV2);
    if (activeGroupIndex === previousActiveGroupIndex) {
      // Closing a grouping
      return setPageState({
        [KEYS.groupByV2]: removeExpandValue(pageState.groupByV2),
      });
    }
    // Opening a grouping

    // This is a bit of a hack.
    // When using "Changes since" as a grouping, pageState.groupByV2 is always empty.
    // When using "Group by" as a grouping, pageState.groupByV2 has always atleast 1 row in the array.

    const emptyChangesSince = [
      {
        attributeName: 'changesSince',
        value: '',
      },
    ];
    const groupByValue =
      pageState.groupByV2.length === 0
        ? addExpandValue(emptyChangesSince, label, activeGroupIndex)
        : addExpandValue(pageState.groupByV2, label, activeGroupIndex);

    return setPageState({
      [KEYS.groupByV2]: groupByValue,
      mapPosition: null,
    });
  };

  const showExplorers = !groupBys?.length && !groups?.length;
  const fullHeightVh = Boolean(groupBys?.length) || Boolean(groups?.length);

  const renderBody = () => {
    if (view === MODE.DOCUMENTS) {
      return <DocumentsTab />;
    }
    if (view === MODE.GRID) {
      return (
        <ContentContainer>
          {/* @ts-ignore */}
          <ViewContainer>
            <MapContainer full>
              <PropertiesGroupedList
                mode={view}
                rows={effectiveGroupedProperties}
                groups={groups}
                handleClick={setActiveGroup}
                disclosures={disclosures}
                loading={loading}
              >
                {({ group }) => (
                  <PropertiesDataGrid
                    group={group}
                    selectedProperties={selectedProperties}
                    filters={convertRuleGroupToFilters(pageState.filters)}
                  />
                )}
              </PropertiesGroupedList>
            </MapContainer>
          </ViewContainer>
        </ContentContainer>
      );
    }
    if (view === MODE.MAP) {
      return (
        <ContentContainer>
          <ViewContainer fullHeightVh={fullHeightVh}>
            <MapContainer full={!showExplorers}>
              <PropertiesGroupedList
                mode={view}
                rows={effectiveGroupedProperties}
                groups={groups}
                handleClick={setActiveGroup}
                disclosures={disclosures}
                loading={loading}
              >
                {({ indices, group }) => (
                  <ReefMapV2
                    // FIX ME
                    // @ts-ignore
                    selectedProperties={selectedProperties}
                    stream={stream}
                    totalProperties={getPropertyCount(stream, group)}
                    propertyAttributeMetadata={propertyAttributeMetadata}
                    indices={getStringIndices(indices, [], effectiveGroupedProperties)}
                    groupID={group?.id}
                    group={group}
                    distillers={distillers}
                    pageState={pageState}
                    streamSlug={stream.slug}
                    // FIX ME
                    // @ts-ignore
                    filters={convertRuleGroupToFilters(filters)}
                  />
                )}
              </PropertiesGroupedList>
            </MapContainer>
            {showExplorers &&
              explorerGroups.map((chart) =>
                chart.charts.map((explorer) => {
                  const { name } = explorer;

                  if (explorer.name === 'tiv_by_us_state' || explorer.name === 'tiv_by_country') {
                    return null;
                  }
                  const c = getChart(explorer);

                  if (!c) {
                    return null;
                  }

                  const title = getExplorerTitle(name);

                  const Explorer = c.Explorer;
                  const explorerID = title.split(' ').join('-').toLowerCase();
                  return (
                    <ChartContainer
                      height={maxHeight}
                      data-tourid={`${explorerID}-explorer`}
                      data-testid={`${explorerID}-explorer`}
                      key={name}
                    >
                      <EuiFlexItem>
                        <Explorer
                          explorer={explorer}
                          currentSnapshotLabel={currentSnapshotLabel}
                          previousSnapshotLabel={previousSnapshotLabel}
                          changeAnalysis={!!changesSince}
                          title={title}
                        />
                      </EuiFlexItem>
                    </ChartContainer>
                  );
                }),
              )}
          </ViewContainer>
        </ContentContainer>
      );
    }

    return (
      <GalleryContainer>
        <PropertiesGroupedList
          mode={view}
          rows={effectiveGroupedProperties}
          groups={groups}
          handleClick={setActiveGroup}
          disclosures={disclosures}
          loading={loading}
        >
          {({ group }) => (
            <ReefGallery
              stream={stream}
              propertyAttributeMetadata={propertyAttributeMetadata}
              groupID={group?.id}
              totalProperties={getPropertyCount(stream, group)}
            />
          )}
        </PropertiesGroupedList>
      </GalleryContainer>
    );
  };

  return (
    <PaginationProvider stream={stream}>
      <ExploreContainer>
        <SearchBarContainer grow={false}>
          <EuiFlexGroup>
            {view !== MODE.DOCUMENTS && <SearchFilter />}
            <OptionsPanel grow={view === MODE.DOCUMENTS}>
              <ViewModeOptions
                legend="Mode"
                options={effectiveToggleButtonsIcons}
                idSelected={view}
                onChange={(id) => {
                  // FIX ME
                  // @ts-ignore
                  setQueryState({ view: id });
                }}
              />
            </OptionsPanel>
          </EuiFlexGroup>
        </SearchBarContainer>
        {renderBody()}
      </ExploreContainer>
    </PaginationProvider>
  );
};

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var BuildingEnvelope = function (_a) {
    var dataTestId = _a["data-testid"], _b = _a.color, color = _b === void 0 ? 'black' : _b, size = _a.size;
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ "data-testid": dataTestId, width: size, height: size, viewBox: "0 0 24 24", fill: "none" }, { children: (0, jsx_runtime_1.jsx)("path", { d: "M21.7498 9.09247L17.647 9.06815L17.6476 5.9471C17.6476 5.6997 17.5487 5.46287 17.3732 5.28948C17.1998 5.1182 16.9662 5.02199 16.7225 5.02199C16.7193 5.02199 16.7156 5.02199 16.7124 5.02199L12.847 5.06428L12.8899 0.934586C12.8936 0.588859 12.7043 0.270093 12.3988 0.108331C12.0937 -0.0539601 11.7237 -0.032286 11.4393 0.163837L2.39965 6.40172C2.1496 6.57406 2 6.85899 2 7.16296V23.0749C2 23.586 2.41392 24 2.92511 24H21.7445C22.2557 24 22.6696 23.586 22.6696 23.0749V10.0176C22.6696 9.50904 22.2589 9.09564 21.7498 9.09247ZM20.8194 16.4209H3.85022V10.9231H18.3866L20.8194 10.9374V16.4209ZM11.0211 2.70023L10.9868 5.99045C10.9841 6.23943 11.0819 6.47837 11.258 6.65441C11.434 6.83045 11.6724 6.92666 11.9219 6.92507L15.7974 6.88225L15.7968 9.98798C15.7968 10.0001 15.7984 10.0123 15.7984 10.0245H3.85022V7.64877L11.0211 2.70023ZM3.85022 22.1497V17.3196H20.8194V22.1497H3.85022Z", fill: color }) })));
};
exports.default = BuildingEnvelope;

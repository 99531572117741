import { FC } from 'react';

interface IProps {
  alt: string;
  title: string;
  src: string;
  defaultSrc?: string;
  delay?: number;
  height?: number;
  width?: number;
}

const Img: FC<IProps> = ({ alt, title, src, defaultSrc, width, height }) => {

  if (!src && !defaultSrc) {
    return null;
  }

  const addDefaultSrc = (event: any) => {
    if (defaultSrc) {
      // eslint-disable-next-line
      event.target.src = defaultSrc;
    }
  };

  return (
    <img
      src={src}
      alt={alt}
      title={title}
      width={width}
      height={height}
      onError={addDefaultSrc}
    />
  );
};

export default Img;

import * as React from 'react';
import StreamMessagePage from '../StreamMessagePage';
import { useHistory } from 'react-router';

const RequestAccess: React.FC = () => {
  const history = useHistory();
  return (
    <StreamMessagePage
      buttonLabel="Support"
      buttonLoading={false}
      handleClick={() => {
        history.push({ pathname: '/support' });
      }}
      subTitle={`
        You need approval to access this account. Click the support button below for assistance.
      `}
      title="You don't have access to this Stream"
    />
  );
};

export default RequestAccess;

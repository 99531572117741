import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiText, EuiToolTip, Icon, Select } from 'ui';
import { SelectOption } from '../types';
import dimensions from './dimensions';
import styles from './EditAttributeModal.emotion';

interface Props {
  dimension: string | null;
  setDimension: Dispatch<SetStateAction<string | null>>;
  setSubDimension: Dispatch<SetStateAction<string | null>>;
  subDimension: string | null;
}

const EditAttributeModalPropertyModal: React.FC<Props> = ({
  dimension,
  setDimension,
  setSubDimension,
  subDimension,
}) => {
  const [subDimensionOptions, setSubDimensionOptions] = useState<SelectOption[]>([]);

  const dimensionOptions = dimensions.map((dimension) => ({
    label: dimension.value,
    value: dimension.value,
  }));

  const getSubDimensionOptions = (parentDimension: string) => {
    const subdimensionOptions: SelectOption[] = [];
    dimensions
      .filter((dimension) => dimension.value === parentDimension)
      .forEach((dimension) => {
        dimension.children?.forEach((child) => {
          subdimensionOptions.push({
            label: child.value,
            value: child.value,
          });
        });
      });

    return subdimensionOptions;
  };

  useEffect(() => {
    if (dimension) {
      const subDimensions = getSubDimensionOptions(dimension);
      setSubDimensionOptions(subDimensions);
      if (subDimensions.length > 0) {
        setSubDimension(subDimensions[0].value);
      } else {
        setSubDimension(null);
      }
    }

    if (dimension === null) {
      setSubDimension(null);
      setSubDimensionOptions([]);
    }
  }, [dimension]);

  const onDimensionChange = (value: string) => {
    setDimension(value);
  };

  const onSubDimensionChange = (value: string) => {
    setSubDimension(value);
  };

  const subDimensionProps = subDimension ? { clear: { onClick: () => setSubDimension(null) } } : {};

  return (
    <>
      <EuiFlexItem data-testid="edit-attribute-modal-property-modal-title" grow={false}>
        <EuiText className={styles.sectionHeader}>
          <h4>Property Modal</h4>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem
        data-testid="edit-attribute-modal-property-modal-description"
        className={styles.sectionDescription}
        grow={false}
      >
        <EuiText>
          <p>
            These two dropdowns help configure where in the Property Modal the attribute is
            rendered.
          </p>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem data-testid="edit-attribute-modal-property-modal-dimension" grow={false}>
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem>
            <EuiFormRow label="Dimension">
              <Select
                fullWidth
                onClear={() => setDimension(null)}
                onChange={onDimensionChange}
                options={dimensionOptions}
                value={dimension}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem className={styles.icon} grow={false}>
            <EuiToolTip content='Value in this field must be one of the property modal tabs. E.g. "Building Characteristics".'>
              <Icon name="help" size="l" />
            </EuiToolTip>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem data-testid="edit-attribute-modal-property-modal-sub-dimension" grow={false}>
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem>
            <EuiFormRow label="Sub Dimension">
              <Select
                disabled={subDimensionOptions.length === 0 && subDimension === null}
                fullWidth
                onClear={() => setSubDimension(null)}
                onChange={onSubDimensionChange}
                options={subDimensionOptions}
                value={subDimension}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem className={styles.icon} grow={false}>
            <EuiToolTip content='Value must be a valid group within the property modal tab. E.g. "Year Built"'>
              <Icon name="help" size="l" />
            </EuiToolTip>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </>
  );
};

export default EditAttributeModalPropertyModal;

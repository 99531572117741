import * as React from 'react';
import {
  Button,
  ButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlyoutHeader,
  EuiFormRow,
  EuiText,
  EuiTitle,
  Spacer,
} from 'ui';
import { EJobType } from '@app/queries/organizations/types';
import InProgressCallout from './Callouts/InProgressCallout';
import LotsOfEditsCallout from './Callouts/LotsOfEditsCallout';

interface Content {
  title: string;
  body: JSX.Element;
}
const getContent = (jobType: EJobType): Content =>
  jobType === EJobType.EditLosses
    ? {
        body: (
          <>
            <p>
              To get started, select the properties whose loss data you want to edit. Then, select
              the loss attributes you want to edit for those properties.
            </p>
            <p>
              Select only the properties and loss attributes you need to work on. Other users can't
              edit the data you select until you finish editing, or until <b>48 hours</b> have
              passed.
            </p>
            <p>
              When you click <b>Start editing</b>, an Excel spreadsheet will download. Enter your
              edits in the spreadsheet and re-upload it here.
            </p>
          </>
        ),
        title: 'Edit your loss data',
      }
    : {
        body: (
          <>
            <p>
              To get started editing your property data, select which properties need to be updated,
              then select the attributes for those properties.
            </p>
            <p>
              Only check out what you need to work on: Editing attributes will lock others from
              making edits for <b>48 hours</b>, or until you&apos;re completed
            </p>
            <p>
              When you click &apos;Start editing&apos;, an Excel worksheet will be downloaded to
              your computer. Make any edits there, then re-upload here.
            </p>
          </>
        ),
        title: 'Edit your property data',
      };
interface Props {
  handleSelectAttributes: () => void;
  handleSelectProperties: () => void;
  inProgressItems?: string;
  isLoading: boolean;
  nSelectedAttributes: number;
  nSelectedProperties: number;
  onSubmit: () => void;
  jobType: EJobType;
}

const SelectData: React.FC<Props> = ({
  handleSelectAttributes,
  handleSelectProperties,
  inProgressItems,
  isLoading,
  nSelectedAttributes,
  nSelectedProperties,
  onSubmit,
  jobType,
}) => {
  const { title, body } = getContent(jobType);
  return (
    <>
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="s">
          <h2>{title}</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutBody>
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiText>{body}</EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
        <Spacer />
        <EuiFormRow label="Select properties">
          <ButtonEmpty
            data-testid="m-m-edit-select-properties"
            flush="left"
            iconName="chevronRight"
            iconSide="right"
            onClick={handleSelectProperties}
            label={nSelectedProperties === 0 ? 'None selected' : `${nSelectedProperties} selected`}
          />
        </EuiFormRow>
        <EuiFormRow label="Select attributes">
          <ButtonEmpty
            data-testid="m-m-edit-select-attributes"
            flush="left"
            iconName="chevronRight"
            iconSide="right"
            onClick={handleSelectAttributes}
            label={nSelectedAttributes === 0 ? 'None selected' : `${nSelectedAttributes} selected`}
          />
        </EuiFormRow>
        {inProgressItems && (
          <EuiFormRow>
            <InProgressCallout items={inProgressItems} />
          </EuiFormRow>
        )}
        {nSelectedAttributes > 10 && nSelectedProperties > 10 && (
          <EuiFormRow>
            <LotsOfEditsCallout />
          </EuiFormRow>
        )}
      </EuiFlyoutBody>
      <EuiFlyoutFooter>
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>
            <Button
              data-testid="m-m-edit-start"
              onClick={onSubmit}
              fill
              disabled={nSelectedProperties === 0 || nSelectedAttributes === 0}
              iconSide="right"
              loading={isLoading}
              label="Start Editing"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlyoutFooter>
    </>
  );
};

export default SelectData;

import { useContext, useEffect } from 'react';
import omit from 'lodash/omit';
import { useToast } from 'ui';
import { IAccountState } from '@app/containers/AuthProvider/types';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { useTracker } from '@app/hooks/useTracker';
import { useStreamContext } from '../StreamProvider';
import { IPageState } from '../types';
import { IPageStateParams, usePageState } from '../utils';

interface Return {
  pageState: IPageState;
  setPageState: (params: IPageStateParams) => void;
  userCode: string;
}

export const useStreamRenderStepTwo = (account: IAccountState): Return => {
  const [pageState, setPageState] = usePageState();
  const tracker = useTracker();
  const { loading, error, stream } = useStreamContext();
  const toast = useToast();

  useEffect(() => {
    if (pageState?.userCode !== account.userCode) {
      setPageState({
        userCode: account.userCode,
      });
    }
  }, [account.userCode]);

  const userCode = pageState.userCode || account.userCode;
  const isAdmin = !!account?.permissions?.admin;

  useEffect(() => {
    if (!!userCode && !loading && (!!error || stream === undefined)) {
      toast({
        title:
          'This stream does not exist, or you do not have access to it. Contact support@onarchipelago.com if you need assistance.',
      });
    }
  }, [error, stream, loading, userCode]);

  useEffect(() => {
    if (stream?.id) {
      tracker.track('Open Stream', {
        stream_id: stream.id,
        stream_name: stream.name,
        stream_slug: stream.slug,
      });
    }
  }, []);

  const { selectedOrganization, setOrgByName } = useContext(UserSessionContext);

  useEffect(() => {
    if (
      stream?.organizationId &&
      (isAdmin || account?.managedOrgs?.some((o) => o.org.id === stream.organizationId))
    ) {
      if (selectedOrganization?.id !== stream?.organizationId) {
        setOrgByName?.(stream?.orgName);
      }
    }
  }, [stream?.organizationId]);

  return {
    pageState,
    setPageState,
    userCode,
  };
};

import { EuiFlexGroup, EuiPageHeader, EuiSpacer, EuiText, EuiToolTip, Icon } from 'ui';
import Currency from '@app/components/Currency/Currency';
import { Explore } from '@app/components/Explore/Explore';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import SovProcessingPage from '@app/components/SovProcessingPage/SovProcessingPage';
import ProtectedModalRoutes from '@app/containers/App/Routes/ProtectedModalRoutes';
import { formatDate } from '@app/utils/format';
import ExportButton from '../Stream/ExportButton/ExportButton';
import { StreamProvider, useStreamContext } from '../Stream/StreamProvider';
import { canRequestAccess } from '../Stream/StreamRender/canRequestAccess';
import RequestAccess from '../Stream/StreamRender/RequestAccess/RequestAccess';
import { AddPropertyButton } from './Header/AddPropertyButton';
import { HeaderSnapshotButton } from './Header/HeaderSnapshotButton';
import { OtherSettingsButton } from './Header/OtherSettingsButton';
import { MarketVisibleToggle } from './MarketVisibleToggle';
import { PropertiesPageProvider, usePropertiesPageContext } from './PropertiesPage.context';
import { PageSectionContainer } from './PropertiesPage.emotion';

const PropertiesPageContent = () => {
  // FIX ME
  // @ts-ignore
  const { groups } = usePropertiesPageContext();
  const { permissions, stream, loading, marketVisibleToggle, marketVisibleSnapshots } =
    useStreamContext();

  if (loading) {
    return <LoadingSpinnerV2 />;
  }

  const hasEditPropertyPanels = permissions?.canEditProperties || permissions?.canAddProperties;
  const hasEditLossPanels = permissions?.canEditLosses || permissions?.canAddLosses;
  const hasEditDataPanels = hasEditPropertyPanels || hasEditLossPanels;

  const manageRightSideButtons = [<OtherSettingsButton key="other-settings-button" />];
  // FIX ME
  // @ts-ignore
  manageRightSideButtons.push(<ExportButton key="export-button" />);

  if (hasEditDataPanels && permissions?.canAddProperties) {
    manageRightSideButtons.push(<AddPropertyButton key="add-property-button" />);
  }

  const pageTitle = (
    <EuiFlexGroup alignItems="center" gutterSize="m">
      <EuiText>
        <h1>{stream?.name}</h1>
      </EuiText>
      <HeaderSnapshotButton />
      {stream?.effectiveDate && stream?.expiryDate && (
        <EuiToolTip
          position="bottom"
          content={
            <EuiText>
              {formatDate(stream?.effectiveDate)}
              {' - '}
              {formatDate(stream?.expiryDate)}
            </EuiText>
          }
          anchorProps={{ style: { display: 'flex' } }}
        >
          {/* @ts-ignore */}
          <Icon name="info" size="m" color="primary" style={{ verticalAlign: 'middle' }} />
        </EuiToolTip>
      )}
      {!stream?.isMyProperties && <MarketVisibleToggle />}
    </EuiFlexGroup>
  );

  const hideContent = marketVisibleToggle && marketVisibleSnapshots.length === 0;

  const isProcessing = stream?.submissionStatus === 'Processing In Progress';

  if (isProcessing) {
    return <SovProcessingPage streamName={stream?.name} />;
  }

  return (
    <PageSectionContainer paddingSize="xl">
      <EuiPageHeader
        pageTitle={pageTitle}
        description={
          !hideContent && (
            <EuiText>
              <b>{groups?.filteredPropertyCount}</b> properties with{' '}
              <b>
                <Currency value={groups?.stats?.tivChart?.sum} />
              </b>{' '}
              TIV
            </EuiText>
          )
        }
        rightSideItems={manageRightSideButtons}
      />
      {hideContent ? (
        <>
          <EuiSpacer size="l" />
          <h2>There are no market visible snapshots</h2>
        </>
      ) : (
        <Explore />
      )}
    </PageSectionContainer>
  );
};

export const PropertiesPage = () => (
  <PropertiesPageProvider>
    <StreamProvider>
      <ProtectRoutesContainer />
    </StreamProvider>
  </PropertiesPageProvider>
);

const ProtectRoutesContainer = () => {
  const { loading, error, stream } = useStreamContext();

  if (loading && !error) {
    return <LoadingSpinnerV2 dataTestId="loading-spinner" />;
  }

  if (canRequestAccess(error) || stream === undefined) {
    return <RequestAccess />;
  }

  return (
    <>
      <ProtectedModalRoutes />
      <PropertiesPageContent />
    </>
  );
};

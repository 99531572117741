import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from 'ui';
import { useNavContext } from '../SideNavContext';

export const NavGroup = ({ title, children }) => {
  const { isCollapsed } = useNavContext();
  return (
    <>
      <EuiHorizontalRule margin="m" />
      <EuiFlexGroup gutterSize="l" direction="column">
        {!isCollapsed && (
          <EuiFlexItem>
            <h2 style={{ paddingLeft: '16px' }}>{title.toUpperCase()}</h2>
          </EuiFlexItem>
        )}
        {children}
      </EuiFlexGroup>
    </>
  );
};

import { FC, useEffect, useState } from 'react';
import { ButtonEmpty, ContextMenu, EuiToolTip } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { useTracker } from '@app/hooks/useTracker';
import { EJobType } from '@app/queries/organizations/types';
import { Lock } from '@app/queries/properties/PropertyQuery/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';
import ManageStreamButtonPanels from './ManageStreamButtonPanels/ManageStreamButtonPanels';
import { useStreamContext } from './StreamProvider';
import { StreamPageOptions } from './types';
import { usePageState } from './utils';

interface IProps {
  options: StreamPageOptions;
}

const ManageStreamButton: FC<IProps> = ({ options }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { lock, refetch, stream } = useStreamContext();
  const [pageState] = usePageState();
  const tracker = useTracker();

  useEffect(() => {
    if (pageState.currentSnapshot) {
      setOpen(false);
    }
  }, [pageState.currentSnapshot]);

  useEffect(() => {
    sendMixpanelEvent(tracker, `${open ? 'Open' : 'Close'} Manage ${STREAMS_LABEL} menu`, stream);
    if (open) {
      refetch();
    }
  }, [open]);

  const inProgressJobs: Partial<Record<EJobType, Lock>> = {};

  (lock?.locks || []).forEach((jobInfo) => {
    if (!!inProgressJobs[jobInfo.type] || !jobInfo.canReuse) {
      return;
    }

    inProgressJobs[jobInfo.type] = jobInfo;
  });

  const toolTipContent = pageState.currentSnapshot
    ? 'To manage properties, use the "View data as of" control to look at the "Latest" data.'
    : '';

  const button = (
    <EuiToolTip position="top" content={`${toolTipContent}`}>
      <ButtonEmpty
        data-testid="manage-stream-button"
        disabled={!!pageState.currentSnapshot}
        size="s"
        iconName="chevronDown"
        iconSide="right"
        onClick={() => setOpen(!open)}
        label={`Manage ${STREAMS_LABEL}`}
      />
    </EuiToolTip>
  );

  return (
    <ContextMenu
      data-testid="manage-stream-popover"
      button={button}
      onClose={() => setOpen(false)}
      open={open}
      panel={ManageStreamButtonPanels({ inProgressJobs, options, refetch, setOpen })}
    />
  );
};

export default ManageStreamButton;

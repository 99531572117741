import isEmpty from 'lodash/isEmpty';
import { PropertyDataInput } from '@app/queries/properties/types';

type ChangedValues = { [fieldName: string]: { initial: any; new: any } };

export const changedAttributeValues = (
  initialValues: any = {},
  newValues: any = {},
): ChangedValues =>
  Object.keys(newValues).reduce((acc, fieldName) => {
    if (initialValues[fieldName] !== newValues[fieldName]) {
      acc[fieldName] = {
        initial: initialValues[fieldName],
        new: newValues[fieldName],
      };
    }
    return acc;
  }, {} as ChangedValues);

export const getFinalNewPropertyValues = (
  newPropertyValues: any,
  customAttributesName?: string[],
) => {
  const customAttributes = {};
  const finalNewPropertyValues: PropertyDataInput = {};

  Object.keys(newPropertyValues).forEach((id) => {
    if (
      customAttributesName?.includes(id) ||
      id.startsWith('ownerText') ||
      id.startsWith('ownerDecimal')
    ) {
      const booleanValue = newPropertyValues[id] === true ? 1 : 0;
      const val = typeof newPropertyValues[id] === 'boolean' ? booleanValue : newPropertyValues[id];

      customAttributes[id] = val;
    } else {
      finalNewPropertyValues[id] = newPropertyValues[id];
    }
  });
  if (!isEmpty(customAttributes)) {
    // FIX ME
    // @ts-ignore
    finalNewPropertyValues.customAttributes = { ...customAttributes };
  }
  return finalNewPropertyValues;
};

const rx = /cannot unmarshal \w+ into Go struct field PropertyDataInput\.(\w+) of type (\w+)$/;

// formatEditError - returns api error message or, if it's an invalid field format error, return a more
// UI friendly message
export const formatEditError = (errorMsg: string, propertyDataInput: PropertyDataInput): string => {
  const res = (errorMsg.match(rx) || []).map((x) => x.trim());
  if (res.length < 3) {
    return errorMsg;
  }

  const fieldName = res[1];
  const expectedType = res[2];

  const fieldValue = JSON.stringify(propertyDataInput?.[fieldName]);

  return `Value ${fieldValue} for attribute ${fieldName} is not in expected format: ${expectedType}`;
};

import { useContext, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, EuiConfirmModal, useToast } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { tabToPath } from '@app/components/Layout/Layout';
import { ModalContext } from '@app/contexts/ModalContext';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
// FIX ME
// @ts-ignore
import DELETE_STREAM from '@app/queries/streams/deleteStream.gql';
import { DeleteStreamVariables } from '@app/queries/streams/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import useAdvancedTab from './useAdvancedTab';

const DeleteStreamButton: React.FC = () => {
  const toast = useToast();
  const {
    copyOfStream: { slug },
  } = useAdvancedTab();
  const { selectedOrganization } = useContext(UserSessionContext);
  const { closeModal } = useContext(ModalContext);
  const history = useHistory();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const [deleteStream] = useMutation<any, DeleteStreamVariables>(DELETE_STREAM, {
    onCompleted: () => {
      toast({ title: 'Succesfully deleted stream' });
      setShowConfirmModal(false);
      closeModal();
      // Redirect to stream dashboard
      history.replace({
        pathname: tabToPath('Streams', selectedOrganization?.name),
      });
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
    variables: {
      input: {
        slug,
      },
    },
  });

  return (
    <>
      <Button
        data-testid="delete-stream-button"
        label={`Delete ${STREAMS_LABEL}`}
        color="danger"
        onClick={() => setShowConfirmModal(true)}
      />
      {showConfirmModal && (
        <EuiConfirmModal
          data-testid="confirm-delete-stream-modal"
          isLoading={false}
          title="Delete this stream?"
          onCancel={() => setShowConfirmModal(false)}
          onConfirm={() => deleteStream()}
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          buttonColor="danger"
          defaultFocusedButton="confirm"
          style={{ display: 'flex', zIndex: 10000000 }}
        >
          <p>
            Only the stream will be deleted. The properties remain the Organization and you can
            create a new stream to include them.
          </p>
        </EuiConfirmModal>
      )}
    </>
  );
};

export default DeleteStreamButton;

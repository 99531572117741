import * as React from 'react';
import { Button, EuiFlexGroup, EuiFlexItem, EuiText, Icon, IconProps, Spacer } from 'ui';
import styles from './StreamMessagePage.emotion';

interface Props {
  buttonLabel?: string;
  buttonLoading?: boolean;
  handleClick?: () => void;
  hideButton?: boolean;
  iconName?: IconProps['name'];
  subTitle: string;
  title: string;
}

const StreamMessagePage: React.FC<Props> = ({
  buttonLabel,
  buttonLoading,
  handleClick,
  hideButton = false,
  iconName = 'lock',
  subTitle,
  title,
}) => (
  <EuiFlexGroup
    data-testid="stream-message-page"
    className={styles.container}
    alignItems="center"
    direction="column"
    gutterSize="m"
  >
    <EuiFlexItem grow={false}>
      <Spacer size="xxl" />
      <Spacer size="xxl" />
      <Spacer size="xxl" />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <Icon color="brandGreen" name={iconName} size="xxl" />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiText>
        <h1 data-testid="stream-message-page-title">{title}</h1>
      </EuiText>
    </EuiFlexItem>
    <EuiFlexItem className={styles.textContainer} grow={false}>
      <EuiText>
        <p data-testid="stream-message-page-subtitle">{subTitle}</p>
      </EuiText>
    </EuiFlexItem>
    {!hideButton && (
      <>
        <EuiFlexItem grow={false}>
          <Spacer size="l" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Button
            data-testid="stream-message-page-button"
            color="brandGreen"
            fill
            label={buttonLabel}
            loading={buttonLoading}
            onClick={handleClick}
          />
        </EuiFlexItem>
      </>
    )}
  </EuiFlexGroup>
);

export default StreamMessagePage;

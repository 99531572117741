import { FC, ReactElement } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, Icon } from 'ui';
import ChartTitle from '../Charts/ChartTitle/ChartTitle';
import {
  FooterContainer,
  LargeText,
  MediumText,
  SmallText,
  StatContent,
  StatsContainer,
  ValueContainer,
} from './Stat.emotion';

interface Stat {
  'data-testid'?: string;
  color?: 'default' | 'success' | 'subdued';
  footer?: string | ReactElement;
  hasIcon?: boolean;
  iconColor?: 'success' | 'subdued';
  size?: 'medium' | 'large' | 'small';
  title: string;
  titleTooltip?: string;
  value: string;
  valueAnnotation?: string;
}

const getTextElement = (size) => {
  switch (size) {
    case 'small':
      return SmallText;
    case 'medium':
      return MediumText;
    case 'large':
      return LargeText;
  }
};

const Stat: FC<Stat> = ({
  'data-testid': dataTestId,
  color = 'default',
  footer,
  hasIcon = false,
  iconColor = 'success',
  size = 'medium',
  title,
  titleTooltip,
  value,
  valueAnnotation,
}) => {
  const statTitle = <ChartTitle tooltip={titleTooltip}>{title}</ChartTitle>;
  const TextElement = getTextElement(size);
  const StatsElement = hasIcon ? StatsContainer : EuiFlexItem;
  const statValue = (
    <EuiFlexGroup direction="column" gutterSize="none">
      <ValueContainer grow={false}>
        <EuiFlexGroup alignItems="center">
          {hasIcon && (
            <EuiFlexItem data-testid="stat-icon" grow={false}>
              <Icon name="arrowUpRight" color={iconColor} size="xl" />
            </EuiFlexItem>
          )}
          <StatsElement grow={false}>
            <EuiFlexGroup alignItems="baseline" gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiText data-testid="stat-value-eui-wrapper" color={color}>
                  <TextElement data-testid="stat-value">{value}</TextElement>
                </EuiText>
              </EuiFlexItem>
              {valueAnnotation && (
                <EuiFlexItem grow={false}>
                  <EuiText data-testid="stat-value-annotation-eui-wrapper" color="subdued">
                    <SmallText data-testid="stat-value-annotation">{valueAnnotation}</SmallText>
                  </EuiText>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </StatsElement>
        </EuiFlexGroup>
      </ValueContainer>
      {footer && typeof footer === 'string' && (
        <FooterContainer data-testid="stat-footer-label" grow={false}>
          <EuiText size="s" color="subdued">
            <p>{footer}</p>
          </EuiText>
        </FooterContainer>
      )}
      {footer && typeof footer === 'object' && (
        <FooterContainer data-testid="stat-footer" grow={false}>
          {footer}
        </FooterContainer>
      )}
    </EuiFlexGroup>
  );

  return (
    <StatContent
      title={statValue}
      description={statTitle}
      titleElement="div"
      descriptionElement="div"
      data-testid={dataTestId}
    />
  );
};

export default Stat;

import * as React from 'react';
import { convertRuleGroupToFilters } from '@app/components/Filter/utils';
import { useStreamContext } from '../StreamProvider';
import { usePageState } from '../utils';
import ExplorersContainer from './Explorers/ExplorersContainer';
import { AsideContainer } from './Aside.emotion';

const Aside: React.FC = () => {
  const { stream } = useStreamContext();
  const [pageState, setPageState] = usePageState();
  const filters = pageState.filters || undefined;

  const setExplorersTab = (explorerTab: null | string) =>
    setPageState({
      explorerTab,
    });

  return (
    <AsideContainer>
      <ExplorersContainer
        stream={stream}
        filters={convertRuleGroupToFilters(filters)}
        explorerTab={pageState.explorerTab}
        position={pageState.explorerPosition}
        changeTab={setExplorersTab}
      />
    </AsideContainer>
  );
};

export default Aside;

import { useState } from 'react';
import * as React from 'react';
import { useMutation } from '@apollo/client';
import { Button, EuiFlexGroup, EuiFlexItem, useToast } from 'ui';
import Dimmer from '@app/components/Dimmer/Dimmer';
// FIX ME
// @ts-ignore
import GET_ORGANIZATION_ATTRIBUTE_METADATA from '@app/queries/propertyMetadata/getOrganizationAttributeMetadata.gql';
import { IUpdateAttributeMetadataVariable } from '@app/queries/propertyMetadata/types';
// FIX ME
// @ts-ignore
import UPDATE_ATTRIBUTE_METADATA from '@app/queries/propertyMetadata/updateAttributeMetadata.gql';
import { useAttributeMetadataEditorContext } from '../context/attributeMetadataEditorContext';
import useUpdateBoolean from '../MetadataTable/useUpdateBoolean';
import { GroupedMetadata, PropertyMetadata } from '../types';
import { toGraphqlInput } from '../utils';

interface Props {
  attributeValue: GroupedMetadata;
  dimension: string | null;
  onClose: () => void;
  parentAttribute?: PropertyMetadata;
  scope: string | null;
  subDimension: string | null;
}

const EditAttributeModalButtons: React.FC<Props> = ({
  attributeValue,
  dimension,
  onClose,
  parentAttribute,
  scope,
  subDimension,
}) => {
  const updateBoolean = useUpdateBoolean(parentAttribute);
  const { organizationId, streamId } = useAttributeMetadataEditorContext();
  const [updateAttributeMetadata] = useMutation<Boolean, IUpdateAttributeMetadataVariable>(
    UPDATE_ATTRIBUTE_METADATA,
  );

  const [pending, setPending] = useState<boolean>(false);
  const toast = useToast();

  const onSave = async () => {
    const metadata = toGraphqlInput(attributeValue);
    metadata.dimension = dimension || null;
    metadata.subdimension = subDimension || null;
    metadata.scope = scope || 'owner'; // never accidentally set to consumer.

    await updateBoolean(attributeValue, metadata.includeInStreams);

    setPending(true);
    let err = false;
    await updateAttributeMetadata({
      refetchQueries: [
        {
          query: GET_ORGANIZATION_ATTRIBUTE_METADATA,
          variables: {
            organizationId,
            streamId,
          },
        },
      ],
      variables: {
        input: {
          metadata,
          organizationId,
          streamId,
        },
      },
    }).catch(() => {
      err = true;
    });

    setPending(false);

    if (err) {
      toast({ title: 'Could not update property metadata', type: 'danger' });
    } else {
      toast({ title: `Successfully updated property ${attributeValue.name}`, type: 'success' });
      onClose();
    }
  };

  return (
    <EuiFlexGroup data-testid="edit-attribute-modal-buttons">
      {pending && <Dimmer />}
      <EuiFlexItem grow={false}>
        <Button disabled={pending} label="Cancel" onClick={onClose} />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <Button disabled={pending} fill label="Save" onClick={onSave} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default EditAttributeModalButtons;

import { useState } from 'react';
import * as React from 'react';
import { Icon } from 'ui';
import { ProvenanceDocument } from '@app/components/PropertiesGrid/PropertiesGridTooltip/types';
import { IPropertyDocument } from '@app/queries/properties/types';
import { getFileURL } from '@app/utils/download';
import { formatBytes } from '@app/utils/format';
import { RequiredDocumentAttributes } from '../types';
import { Label, SourceLinkView } from './style';

interface IProps {
  document?: Pick<IPropertyDocument, RequiredDocumentAttributes>;
  type?: string;
  handleClick: (doc: ProvenanceDocument) => void;
}

const DocumentLink: React.FC<IProps> = ({ document, type, handleClick }) => {
  if (!document) {
    return null;
  }
  const { thumbnailURLs, sizeInBytes, id } = document;
  const formattedSize = formatBytes(sizeInBytes || 0);
  const [thumbnailVisible, setThumbnailVisible] = useState<boolean>(true);
  const httpURL = getFileURL(id);
  return (
    <SourceLinkView
      data-testid="download-link"
      target="_blank"
      rel="noopener noreferrer"
      href={httpURL}
      onClick={(e) => {
        e.stopPropagation();
        handleClick({
          filename: document.filename,
          httpURL,
        });
      }}
    >
      {!!thumbnailURLs?.original && thumbnailVisible && (
        <img
          src={thumbnailURLs.original}
          alt="thumbnail"
          onError={() => setThumbnailVisible(false)}
        />
      )}
      <Label>
        <div>
          <span>{type ? `View ${type}` : 'View Document'}</span>
          {!!formattedSize && <span>({formattedSize})</span>}
        </div>
        <Icon name="externalLink" size="l" />
      </Label>
    </SourceLinkView>
  );
};

export default DocumentLink;

import * as React from 'react';
import { FieldSelectorProps } from 'react-querybuilder';
import { SearchSelect } from 'ui';
import { FieldSelectorContainer } from '../FieldSelector.emotion';

const FieldSelector: React.FC<FieldSelectorProps> = ({ handleOnChange, options, value }) => {
  const selectOptions = options.map((option) => ({
    label: option.label,
    value: option.name,
  }));

  return (
    <FieldSelectorContainer data-testid="query-builder-wrapper-field-selector">
      <SearchSelect
        options={selectOptions}
        onChange={(value) => handleOnChange(value)}
        value={value}
      />
    </FieldSelectorContainer>
  );
};

export default FieldSelector;

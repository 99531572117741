import { useEffect, useState } from 'react';
import * as React from 'react';
import { CarrierAssessment } from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';
import { EuiFlexGroup, EuiFlexItem, Select, Spacer } from 'ui';
import ErrorMessage from '@app/components/ErrorMessage/ErrorMessage';
import { useUnderwritingAssessmentReportContext } from '../../../underwritingAssessmentReportContext';
import { toTitle } from '../../utils';
import OverallGrade from './OverallGrade/OverallGrade';
import PropertiesToPrioritize from './PropertiesToPrioritize/PropertiesToPrioritize';
import { GradingDetailsContext } from './context';
import { GradingDetail, RiskAttribute, ViewType } from './types';
import { getOverallGradeData } from './utils';

const viewTypeOptions: Array<ViewType> = [ViewType.Map, ViewType.Histogram];
interface Props {
  carrierAssessment: CarrierAssessment;
}

const GradingDetails: React.FC<Props> = ({ carrierAssessment }) => {
  if (!carrierAssessment) {
    return <ErrorMessage message="Property grade details not available for this stream" />;
  }

  const { propertyAssessmentDetails, propertyGradeDetails } = carrierAssessment;

  const { gradingDetailsSection, setGradingSection, setGradingFilter } =
    useUnderwritingAssessmentReportContext();

  const CAT = propertyGradeDetails.filter((g) => g.attribute.includes('CAT'));
  const Risk = propertyGradeDetails.filter((g) => !g.attribute.includes('CAT')).reverse();
  const Management = propertyAssessmentDetails.filter((g) => g.attribute.includes('Management'));
  const OtherPropertyAssessment = propertyAssessmentDetails.filter(
    (g) => !g.attribute.includes('Management'),
  );

  const options: Array<RiskAttribute> = [
    ...Risk.map((p) => ({
      type: GradingDetail.Grade,
      value: p.attribute,
    })),
    ...CAT.map((p) => ({
      type: GradingDetail.Grade,
      value: p.attribute,
    })),
    ...OtherPropertyAssessment.map((p) => ({
      type: GradingDetail.Assessment,
      value: p.attribute,
    })),
    ...Management.map((p) => ({
      type: GradingDetail.Assessment,
      value: p.attribute,
    })),
    {
      type: GradingDetail.AverageLoss,
      value: 'CUSTOMER_AVERAGE_LOSS',
    },
    {
      type: GradingDetail.AverageLoss,
      value: 'CUSTOMER_LOSS_FREQUENCY',
    },
  ];

  useEffect(() => {
    if (!gradingDetailsSection) {
      setGradingSection(options[0]);
    }
  }, []);

  const [viewType, setViewType] = useState<ViewType>(ViewType.Histogram);

  const render = () => {
    if (!gradingDetailsSection) {
      return null;
    }

    const overallGradeData = getOverallGradeData(
      gradingDetailsSection,
      propertyGradeDetails,
      propertyAssessmentDetails,
    );

    return (
      <>
        <EuiFlexItem>
          {overallGradeData && <OverallGrade tableData={overallGradeData} />}
        </EuiFlexItem>
        <EuiFlexItem>
          <PropertiesToPrioritize riskAttribute={gradingDetailsSection} />
        </EuiFlexItem>
      </>
    );
  };

  return (
    <GradingDetailsContext.Provider
      value={{
        carrierAssessment,
        currentAttribute: gradingDetailsSection,
        viewType,
      }}
    >
      <EuiFlexGroup direction="column">
        <EuiFlexItem grow={false} data-testid="grading-details-content">
          <Spacer size="l" />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <Select
                options={options.map((p) => ({
                  label: toTitle(p.value),
                  value: p.value,
                }))}
                onChange={(value) => {
                  setGradingSection(options.find((attr) => attr.value === value));
                  setGradingFilter(null);
                }}
                value={gradingDetailsSection?.value}
              />
            </EuiFlexItem>
            {gradingDetailsSection?.value === 'CUSTOMER_LOSS_FREQUENCY' && (
              <EuiFlexItem grow={false}>
                <Select
                  options={viewTypeOptions.map((o) => ({
                    label: o,
                    value: o,
                  }))}
                  onChange={(value) => setViewType(value as ViewType)}
                  value={viewType}
                />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
        {render()}
      </EuiFlexGroup>
    </GradingDetailsContext.Provider>
  );
};

export default GradingDetails;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var PdfFileColor = function (_a) {
    var dataTestId = _a["data-testid"], size = _a.size;
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ "data-testid": dataTestId, width: size, height: size, viewBox: "0 0 24 24", fill: "none" }, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9L13 2Z", fill: "#F84A4A" }), (0, jsx_runtime_1.jsx)("path", { d: "M13 2V9H20", fill: "white", fillOpacity: "0.5" }), (0, jsx_runtime_1.jsx)("path", { d: "M6.33026 18H7.38494V16.6449H8.10085C9.10014 16.6449 9.72443 16.0526 9.72443 15.147C9.72443 14.25 9.11293 13.6364 8.13281 13.6364H6.33026V18ZM7.38494 15.8139V14.4865H7.90483C8.37784 14.4865 8.63139 14.7422 8.63139 15.147C8.63139 15.5497 8.37784 15.8139 7.90483 15.8139H7.38494ZM11.8572 18C13.1868 18 14.0114 17.1797 14.0114 15.8161C14.0114 14.4545 13.1868 13.6364 11.8551 13.6364H10.2443V18H11.8572ZM11.299 17.1009V14.5355H11.8018C12.5518 14.5355 12.9545 14.8764 12.9545 15.8161C12.9545 16.7599 12.5518 17.1009 11.8168 17.1009H11.299ZM14.6271 18H15.6818V16.2464H17.4162V15.3878H15.6818V14.4929H17.6058V13.6364H14.6271V18Z", fill: "white" })] })));
};
exports.default = PdfFileColor;

import * as React from 'react';
import { useQuery } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import {
  EngagementViewsByUserData,
  EngagementViewsByUserVariables,
  GET_ENGAGEMENT_VIEWS_BY_USER,
} from '@onarchipelago/cx/Reports/queries/getEngagementViewsByUser';
import moment from 'moment';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import ErrorState from '../../components/ErrorState';
import UserViewsComponent from './UserViewsComponent';

const UserViewsContainer: React.FC = () => {
  const { streamSlug } = useReportsContext().stream;

  const { data, error, loading } = useQuery<
    EngagementViewsByUserData,
    EngagementViewsByUserVariables
  >(GET_ENGAGEMENT_VIEWS_BY_USER, {
    variables: {
      input: {
        fromDate: moment().format('YYYY-MM-DD'),
        streamSlug,
      },
    },
  });

  if (loading && !error) {
    return <LoadingSpinnerV2 styles={{ height: '546px' }} />;
  }

  if (!loading && error) {
    return <ErrorState />;
  }

  if (data && !loading && !error) {
    return <UserViewsComponent data={data.engagementViewsByUser} />;
  }

  return null;
};

export default UserViewsContainer;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var eui_1 = require("@elastic/eui");
var Badge_1 = require("../../display/badge/Badge");
var SelectBadge = function (_a) {
    var badge = _a.badge, label = _a.label;
    return ((0, jsx_runtime_1.jsxs)(eui_1.EuiFlexGroup, __assign({ alignItems: "center", gutterSize: "none", justifyContent: "spaceBetween" }, { children: [(0, jsx_runtime_1.jsx)(eui_1.EuiFlexItem, __assign({ grow: false }, { children: (0, jsx_runtime_1.jsx)(eui_1.EuiText, __assign({ size: "s" }, { children: (0, jsx_runtime_1.jsx)("p", { children: label }) })) })), (0, jsx_runtime_1.jsx)(eui_1.EuiFlexItem, __assign({ grow: false }, { children: (0, jsx_runtime_1.jsx)(Badge_1.Badge, { label: badge }) }))] })));
};
exports.default = SelectBadge;

import { useContext, useState } from 'react';
import * as React from 'react';
import AddStreamModal from '@onarchipelago/cx/Stream/AddModal/AddStreamModal';
import { ButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText } from 'ui';
import { SOV_LABEL } from '@app/components/Layout/SideNav/constants';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { ModalContext } from '@app/contexts/ModalContext';
import { useUserSession } from '@app/contexts/UserSessionContext';
import UploadModal from '@app/cx/UploadPortal/UploadModal';
import { HeaderTitle } from '@app/dice/JobDetails/JobDetails.emotion';
import { StyledButton, StyledFlexItem, StyledProcessingPage } from './NoSovPage.emotion';

const NoSovPage: React.FC = () => {
  const { selectedOrganization } = useUserSession();
  const [isModalShown, setIsModalShown] = useState(false);
  const { account } = useAuth();
  const isAdmin = account?.permissions?.admin;
  const { showModal, closeModal } = useContext(ModalContext);
  const [hasMyProperties] = useState<boolean>(false);

  return (
    <>
      {isAdmin && (
        <EuiPanel paddingSize="none" hasBorder={false} hasShadow={false} grow={false}>
          <EuiFlexGroup direction="row" justifyContent="flexEnd" responsive={false}>
            <EuiFlexItem
              data-testid="add-stream-btn"
              grow={false}
              style={{ marginRight: '100px', marginTop: '10px' }}
            >
              <ButtonEmpty
                iconName="plusCircle"
                onClick={() =>
                  showModal(
                    <AddStreamModal
                      hasMyPropertiesStream={hasMyProperties}
                      onCloseModal={closeModal}
                    />,
                  )
                }
                size="s"
                label={`Add ${SOV_LABEL}`}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      )}
      <StyledProcessingPage>
        <EuiFlexGroup alignItems="center" direction="column">
          <EuiFlexItem grow={false}>
            <EuiText size="m" data-testid="job-details-title">
              <HeaderTitle>Upload an SOV to get started</HeaderTitle>
            </EuiText>
          </EuiFlexItem>
          <StyledFlexItem grow={false}>
            <EuiText>Get better SOV data and prioritized recommendations for improvement.</EuiText>
          </StyledFlexItem>
          <StyledFlexItem grow={false}>
            <StyledButton
              onClick={() => setIsModalShown(true)}
              label="Upload SOV"
              fill
              size="l"
              style={{ marginBottom: '6px' }}
            />
            <EuiText textAlign="center">
              <a href="/support">Questions? Send us a support ticket</a>
            </EuiText>
          </StyledFlexItem>
        </EuiFlexGroup>
        {isModalShown && (
          <UploadModal
            setIsModalVisible={setIsModalShown}
            organizationId={selectedOrganization.id}
            organizationName={selectedOrganization.name}
          />
        )}
      </StyledProcessingPage>
    </>
  );
};

export default NoSovPage;

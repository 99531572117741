"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegerField = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var eui_1 = require("@elastic/eui");
var utils_1 = require("../../../utils");
exports.IntegerField = (0, react_1.forwardRef)(function (_a, ref) {
    var dataTestId = _a["data-testid"], disabled = _a.disabled, _b = _a.fullWidth, fullWidth = _b === void 0 ? false : _b, id = _a.id, name = _a.name, onBlur = _a.onBlur, onChange = _a.onChange, placeholder = _a.placeholder, readOnly = _a.readOnly, value = _a.value, _c = _a.min, min = _c === void 0 ? null : _c;
    var handleChange = function (event) {
        return onChange((0, utils_1.toNumber)(event.target.value));
    };
    var handleKeyDown = function (event) {
        if (event.key === '.') {
            event.preventDefault();
            event.stopPropagation();
        }
    };
    return ((0, jsx_runtime_1.jsx)(eui_1.EuiFieldNumber, { "data-testid": dataTestId || 'ui-atom-input-integer-field', disabled: disabled, fullWidth: fullWidth, id: id, name: name, min: min, onBlur: onBlur, onChange: handleChange, onKeyDown: handleKeyDown, placeholder: placeholder, readOnly: readOnly, value: value, inputRef: ref }));
});

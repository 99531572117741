/** @jsxImportSource @emotion/react */

import { useQuery } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import {
  GET_CARRIER_ASSESSMENT,
  GetCarrierAssessmentData,
  GetCarrierAssessmentVariables,
} from '@onarchipelago/cx/Reports/queries/getCarrierAssessment';
import InsurerHorizontalBarChart from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/InsurerInsights/charts/InsurerHorizontalBarChart';
import { GradeBadgeColors } from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/InsurerInsights/insurerInsightsTypes';
import { normalizeTitle } from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/InsurerInsights/insurerInsightsUtils';
import GradeLabels from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/InsurerInsights/tabs/GradingOverviewTab/GradeLabels';
import { Badge, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiText, Icon, Spacer } from 'ui';

interface GradingOverviewTabProps {
  footer?: string;
}

// TODO:
// Ask Jordan to give these back within the gql query response
const descriptions = {
  CAT_FOOTPRINT: 'Active portfolio management can help balance your exposures.',
  LOSS_HISTORY: 'Loss experience with your Insurer vs peer group benchmarks.',
  MGMT_PROGRAMS: 'Information about stewardship practices helps Risk Managers stand out.',
  RISK_QUALITY: 'Improving the quality of the data reduces the uncertainty around risk.',
};

const Footer = ({ children }) => (
  <EuiFlexGroup gutterSize="xs">
    <EuiFlexItem grow={false}>
      <EuiText
        css={{
          fontSize: '11px',
          fontWeight: 'bold',
        }}
      >
        {children}
      </EuiText>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <Icon name="arrowRight" />
    </EuiFlexItem>
  </EuiFlexGroup>
);

const footers = {
  CAT_FOOTPRINT: <Footer>% of Properties</Footer>,
  LOSS_HISTORY: <Footer>% of Properties</Footer>,
  MGMT_PROGRAMS: <Footer>% of Inspections</Footer>,
  RISK_QUALITY: <Footer>% of Graded TIV</Footer>,
};

function GradingOverviewTab({ footer }: GradingOverviewTabProps) {
  const { stream } = useReportsContext();
  const { data } = useQuery<GetCarrierAssessmentData, GetCarrierAssessmentVariables>(
    GET_CARRIER_ASSESSMENT,
    {
      skip: !stream?.streamId,
      variables: {
        input: {
          name: 'ZurichUwAR',
          streamId: stream?.streamId,
        },
      },
    },
  );

  const pillars = data?.carrierAssessment?.pillars;

  return (
    <EuiFlexItem data-testid="grading-overview-content">
      <EuiFlexGrid
        columns={2}
        css={{
          gridColumnGap: '36px',
          gridRowGap: '60px',
          padding: '2rem 0 0 0',
        }}
      >
        {pillars?.map((pillar, pillarIndex) => {
          const title = normalizeTitle(pillar.attribute);
          return (
            <EuiFlexItem key={pillarIndex}>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <EuiText
                    css={{
                      fontWeight: 'bold',
                    }}
                  >
                    {title}
                  </EuiText>
                  <Spacer size="s" />
                  <div>
                    <Badge
                      color={GradeBadgeColors[pillar.grade.color.toUpperCase()]}
                      label={pillar.grade.category}
                      size="l"
                    />
                  </div>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiText>
                    {pillar.attribute === 'MGMT_PROGRAMS' ? 'Management Program' : title} Factors
                  </EuiText>
                  <EuiText color="subdued">{descriptions[pillar.attribute]}</EuiText>
                  <Spacer size="s" />
                  {pillar.components.map((component, componentIndex) => (
                    <div key={componentIndex}>
                      <Spacer size="m" />
                      <InsurerHorizontalBarChart component={component} />
                    </div>
                  ))}
                  <Spacer size="s" />
                  <EuiText>{footer || footers[pillar?.attribute]}</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          );
        })}
      </EuiFlexGrid>
      <Spacer />
      <GradeLabels />
    </EuiFlexItem>
  );
}

export default GradingOverviewTab;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilePicker = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_dropzone_1 = require("react-dropzone");
var FilePicker_emotion_1 = require("./FilePicker.emotion");
var Icon_1 = require("../../../neutrons/icon/Icon");
var eui_1 = require("@elastic/eui");
var DEFAULT_PROMPT = 'Drag & drop files here';
var FilePicker = function (_a) {
    var _b;
    var dataTestId = _a["data-testid"], _c = _a.files, files = _c === void 0 ? [] : _c, styles = _a.styles, _d = _a.multiple, multiple = _d === void 0 ? true : _d, _e = _a.isLoading, isLoading = _e === void 0 ? false : _e, _f = _a.maxFileDisplay, maxFileDisplay = _f === void 0 ? 10 : _f, _g = _a.prompt, prompt = _g === void 0 ? DEFAULT_PROMPT : _g, onChange = _a.onChange;
    var onDrop = function (acceptedFiles, fileRejections //TODO: handle errors
    ) {
        var newFiles = multiple ? __spreadArray(__spreadArray([], __read(files), false), __read(acceptedFiles), false) : acceptedFiles;
        onChange(newFiles);
    };
    var _h = (0, react_dropzone_1.useDropzone)({
        onDrop: onDrop,
        multiple: multiple,
        noClick: !!(files === null || files === void 0 ? void 0 : files.length),
    }), getRootProps = _h.getRootProps, getInputProps = _h.getInputProps, isFocused = _h.isFocused, isDragAccept = _h.isDragAccept, isDragReject = _h.isDragReject;
    var style = (0, react_1.useMemo)(function () { return (__assign(__assign(__assign(__assign(__assign({}, FilePicker_emotion_1.baseStyle), styles), (isFocused ? FilePicker_emotion_1.focusedStyle : {})), (isDragAccept ? FilePicker_emotion_1.acceptStyle : {})), (isDragReject ? FilePicker_emotion_1.rejectStyle : {}))); }, [isFocused, isDragAccept, isDragReject]);
    var handleRemoveAll = function (event) {
        onChange([]);
    };
    var handleRemoveFile = function (index) {
        var newFiles = __spreadArray([], __read(files), false);
        newFiles.splice(index, 1);
        onChange(newFiles);
    };
    var isSingleFile = (files === null || files === void 0 ? void 0 : files.length) === 1;
    var fileListHeader = ((0, jsx_runtime_1.jsx)(eui_1.EuiText, __assign({ style: FilePicker_emotion_1.fileLabelStyle }, { children: isSingleFile
            ? "".concat((_b = files[0]) === null || _b === void 0 ? void 0 : _b.name)
            : "".concat((files === null || files === void 0 ? void 0 : files.length) || 0, " files selected") })));
    var removeAllButton = ((0, jsx_runtime_1.jsx)(eui_1.EuiButtonEmpty, __assign({ color: "primary", onClick: handleRemoveAll }, { children: isSingleFile ? 'Remove' : 'Remove all' })));
    var fileList = (files === null || files === void 0 ? void 0 : files.length) > maxFileDisplay || isSingleFile ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [fileListHeader, removeAllButton] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [fileListHeader, files === null || files === void 0 ? void 0 : files.map(function (file, index) {
                return ((0, jsx_runtime_1.jsxs)(eui_1.EuiFlexGroup, __assign({ direction: "row", alignItems: "center", justifyContent: "center", style: { margin: '3px' } }, { children: [(0, jsx_runtime_1.jsx)(eui_1.EuiText, __assign({ style: FilePicker_emotion_1.fileListStyle }, { children: file.name })), (0, jsx_runtime_1.jsx)(eui_1.EuiButtonIcon, { "aria-label": "Remove file", iconType: "cross", onClick: function () {
                                handleRemoveFile(index);
                            } })] }), "".concat(file.name, "-").concat(index)));
            }), removeAllButton] }));
    return ((0, jsx_runtime_1.jsxs)("div", __assign({}, getRootProps({ style: style }), { children: [(0, jsx_runtime_1.jsx)("input", __assign({ "data-testid": dataTestId }, getInputProps())), (0, jsx_runtime_1.jsxs)("div", __assign({ style: FilePicker_emotion_1.promptContainerStyle }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: FilePicker_emotion_1.iconContainerStyle }, { children: (0, jsx_runtime_1.jsx)(Icon_1.Icon, { name: "download", size: "l" }) })), !(files === null || files === void 0 ? void 0 : files.length) ? ((0, jsx_runtime_1.jsx)(eui_1.EuiText, __assign({ color: "subdued", style: FilePicker_emotion_1.promptStyle }, { children: prompt }))) : (fileList)] })), isLoading && ((0, jsx_runtime_1.jsx)(eui_1.EuiProgress, { size: "xs", color: "primary", position: "absolute" }))] })));
};
exports.FilePicker = FilePicker;

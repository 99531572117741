import { FC, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { Attribute } from '@onarchipelago/cx/Reports/queries/getEnrichmentPropertyImprovement';
import { Criteria, EuiBasicTable } from 'ui';

interface Props {
  tableData: Attribute[];
}

const MostImprovedTable: FC<Props> = ({ tableData }) => {
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageItems, setPageItems] = useState<Attribute[]>(tableData.slice(0, 10));
  const previousTableData = usePrevious<Attribute[]>(tableData);

  // If the selected property is changed, update the table with the new table data.
  useEffect(() => {
    if (JSON.stringify(previousTableData) !== JSON.stringify(tableData)) {
      const sliceStart = pageIndex * 10;
      const sliceEnd = (pageIndex + 1) * 10;
      setPageItems(tableData.slice(sliceStart, sliceEnd));
    }
  }, [previousTableData, tableData]);

  const handleTablePagination = (page: Criteria<Attribute>) => {
    const index = page.page?.index || 0;
    const sliceStart = index * 10;
    const sliceEnd = (index + 1) * 10;

    setPageItems(tableData.slice(sliceStart, sliceEnd));
    setPageIndex(index);
  };

  return (
    <EuiBasicTable
      data-testid="most-improved-table"
      columns={[
        {
          field: 'name',
          name: 'Attribute',
        },
        {
          field: 'before',
          name: 'Before',
        },
        {
          field: 'after',
          name: 'After',
        },
      ]}
      items={pageItems}
      onChange={handleTablePagination}
      pagination={{
        pageIndex,
        pageSize: 10,
        showPerPageOptions: false,
        totalItemCount: tableData.length,
      }}
    />
  );
};

export default MostImprovedTable;

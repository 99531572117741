import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { EuiFormRowProps, SearchSelectOption } from 'ui';
import { FormGoogleAutocompleteInput } from '@app/components/Form/FormGoogleAutocompleteInput';
import { FormSearchSelectInput } from '@app/components/Form/FormSearchSelectInput';
import { FormTextInput } from '@app/components/Form/FormTextInput';
import { AddressObjectType } from '@app/components/GoogleAutocomplete/types';
import {
  ADDRESS_AND_GEO_INPUT_KEYS,
  CITY_INPUT_KEY,
  CITY_LABEL,
  COUNTRY_INPUT_KEY,
  COUNTRY_LABEL,
  COUNTY_INPUT_KEY,
  COUNTY_LABEL,
  LAT_INPUT_KEY,
  LAT_LABEL,
  LNG_INPUT_KEY,
  LNG_LABEL,
  POSTAL_CODE_INPUT_KEY,
  POSTAL_CODE_LABEL,
  STATE_INPUT_KEY,
  STATE_LABEL,
  STREET_ADDRESS_INPUT_KEY,
  STREET_ADDRESS_LABEL,
} from '@app/cx/Stream/AddPropertyFlyout/constants';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { Property } from '@app/graphql/types';

interface IEditAddressPopoverContentProps {
  property: Property;
  setHasSelectedGoogleAddress: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditAddressPopoverContent = ({
  setHasSelectedGoogleAddress,
}: IEditAddressPopoverContentProps) => {
  const { propertyAttributeMetadata } = useStreamContext();
  const { watch, setValue, trigger } = useFormContext();

  const [countryValue] = watch([COUNTRY_INPUT_KEY]);

  const countryOptions: SearchSelectOption[] = propertyAttributeMetadata
    .find((attribute) => attribute.name === COUNTRY_INPUT_KEY)
    ?.enumMetadata?.map((metadata) => ({
        label: metadata.value,
        value: metadata.value,
      }));

  const onAddressSelect = (address: AddressObjectType) => {
    setHasSelectedGoogleAddress(true);
    ADDRESS_AND_GEO_INPUT_KEYS.forEach((key) => {
      // skip state for non US countries
      if (address.country !== 'United States' && key === STATE_INPUT_KEY) {
        return;
      }
      setValue(key, address[key]);
    });
    // triggers validation on the fields we just set
    trigger(ADDRESS_AND_GEO_INPUT_KEYS);
  };
  return (
    <>
      <FormSearchSelectInput
        name={COUNTRY_INPUT_KEY}
        formRowProps={{ label: COUNTRY_LABEL } as EuiFormRowProps}
        inputProps={{ clearable: false, options: countryOptions }}
      />
      <FormGoogleAutocompleteInput
        name={STREET_ADDRESS_INPUT_KEY}
        formRowProps={{ label: STREET_ADDRESS_LABEL } as EuiFormRowProps}
        onSelect={onAddressSelect}
        inputProps={{
          predictionTypes: ['address'],
        }}
      />
      <FormTextInput
        name={CITY_INPUT_KEY}
        formRowProps={{ label: CITY_LABEL } as EuiFormRowProps}
      />
      {countryValue === 'United States' && (
        <FormTextInput
          name={STATE_INPUT_KEY}
          formRowProps={{ label: STATE_LABEL } as EuiFormRowProps}
        />
      )}
      <FormTextInput
        name={POSTAL_CODE_INPUT_KEY}
        formRowProps={{ label: POSTAL_CODE_LABEL } as EuiFormRowProps}
      />
      <FormTextInput
        name={COUNTY_INPUT_KEY}
        formRowProps={{ label: COUNTY_LABEL } as EuiFormRowProps}
      />
      <FormTextInput name={LAT_INPUT_KEY} formRowProps={{ label: LAT_LABEL } as EuiFormRowProps} />
      <FormTextInput name={LNG_INPUT_KEY} formRowProps={{ label: LNG_LABEL } as EuiFormRowProps} />
    </>
  );
};

import { ReactElement } from 'react';
import { ButtonIcon } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import { CheckboxProps, GroupedMetadata } from '../types';
import AttributeNameColumn from './AttributeNameColumn';
import MetadataTableCheckbox from './MetadataTableCheckbox';

interface Props {
  childType?: 'child' | 'default' | 'grandchild';
  handleEdit: (attribute: GroupedMetadata) => void;
  handleUpdate: (attribute: GroupedMetadata, checked: boolean) => Promise<void>;
  itemIdToExpandedRowMap: Record<string, ReactElement>;
  toggleDetails: (item: any) => void;
}

const attributeWidth = {
  child: '354px',
  default: '358px',
  grandchild: '348px',
};

export default ({
  childType = 'default',
  handleEdit,
  handleUpdate,
  itemIdToExpandedRowMap,
  toggleDetails,
}: Props) => [
  {
    isExpander: true,
    name: 'Attribute name',
    render: (item: any) => (
      <AttributeNameColumn
        isGrandchild={childType === 'grandchild'}
        item={item}
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        toggleDetails={toggleDetails}
      />
    ),
    width: attributeWidth[childType],
  },
  {
    field: 'edit',
    name: '',
    render: (attribute: GroupedMetadata) => (
      <ButtonIcon iconName="edit" onClick={() => handleEdit(attribute)} />
    ),
    width: '40px',
  },
  {
    field: 'includeInStreams',
    name: `Include in ${STREAMS_LABEL}`,
    render: ({ id, attribute }: CheckboxProps) => (
      <MetadataTableCheckbox attribute={attribute} id={id} updateBoolean={handleUpdate} />
    ),
    width: '90px',
  },
  { field: 'filterable', name: 'Filterable in grid', width: '90px' },
  { field: 'groupable', name: 'Groupable in grid', width: '90px' },
  { field: 'displayName', name: 'Display name', width: '180px' },
  { field: 'columnPosition', name: 'Column Position', width: '90px' },
  { field: 'dimension', name: 'Dimension', width: '180px' },
  { field: 'subdimension', name: 'Sub Dimension', width: '180px' },
  { field: 'source', name: 'Property source text', width: '180px' },
];

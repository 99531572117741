import { FC } from 'react';
import Highcharts, { Options } from 'highcharts';
import seriesLabel from 'highcharts/modules/series-label';
import HighchartsReact from 'highcharts-react-official';
import { formatCurrency, IFormatCurrencyOptions } from '@app/utils/format';
import { LossYAxisTypes } from './lineChartTypes';
import { formatDateISO, getFormattedValue } from './utils';

seriesLabel(Highcharts);
interface LossData {
  facets: string[];
  name: string;
  summaryStats: [
    {
      name: string;
      value: number;
    },
  ];
  values: [
    {
      end: string;
      endValue: number;
      start: string;
      startValue: number | null;
    },
  ];
}

interface Props {
  yAxisType?: LossYAxisTypes;
  yAxisLabel: string;
  lineData: LossData;
  title: string;
  subTitle: string;
  metric: string;
}

const LineChart: FC<Props> = ({
  yAxisType = LossYAxisTypes.CURRENCY,
  yAxisLabel,
  lineData,
  title,
  subTitle,
  metric,
}) => {
  const currencyOptions: IFormatCurrencyOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  };

  const values = lineData?.values || [];

  const xValues = values.map((point) => {
    const startDate = new Date(point.start);
    const endDate = new Date(point.end);
    return `${startDate.getFullYear()}-${endDate.getFullYear().toString().substring(2)}`;
  });

  const dataPoints = values.map((point) => {
    const startDate = new Date(point.start);
    const endDate = new Date(point.end);
    const formattedValue = getFormattedValue(point.endValue, yAxisType);
    return {
      endDate: formatDateISO(endDate),
      formattedValue,
      startDate: formatDateISO(startDate),
      y: point.endValue,
    };
  });

  const fiveYrAvgPoints =
    lineData?.summaryStats?.length > 0
      ? new Array(Math.min(xValues.length, 5)).fill(lineData.summaryStats[0].value)
      : [];

  const options = {
    chart: {
      height: 382,
      spacing: [0, 0, 0, 0],
      style: {
        fontFamily: 'inherit',
      },
      width: 345,
    },
    credits: {
      enabled: false,
    },

    legend: {
      enabled: false,
    },
    series: [
      {
        color: '#AEE1D8',
        data: dataPoints,
        label: {
          enabled: false,
        },
        tooltip: {
          headerFormat: '',
          pointFormat: `Start Date: {point.startDate:%e. %b}<br/>End Date: {point.endDate:%e. %b}<br/> {point.formattedValue:%e. %b} ${metric}`,
        },
      },
      {
        color: '#343741',
        dashStyle: 'dash',
        data: fiveYrAvgPoints,
        enableMouseTracking: false,
        label: {
          align: 'top',
          enabled: true,
          style: {
            color: '#343741',
            fontSize: '10.5px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '18px',
            textAlign: 'left',
          },
          text: '5 year avg',
        },
        marker: { enabled: false },
        name: '5 year avg',
        pointStart: Math.max(xValues.length - 5, 0),
        tooltip: {
          borderRadius: 0,
          borderWidth: 0,
          headerFormat: '',
          padding: 0,
          pointFormat: '',
        },
        type: 'spline',
      },
    ],
    subtitle: {
      align: 'left',
      style: {
        color: '#69707D',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '24px',
      },
      text: subTitle,
    },
    title: {
      align: 'left',
      margin: 60,
      style: {
        color: '#343741',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '24px',
      },
      text: title,
    },
    tooltip: {
      backgroundColor: '#404040',
      borderColor: '#ffffff00',
      borderRadius: 4,
      style: {
        color: '#FFFFFF',
        fontSize: '12.25px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '21px',
      },
    },
    xAxis: {
      categories: xValues,
      labels: {
        rotation: 0,
        step: 2,
        style: {
          color: '#343741',
          fontSize: '10.5px',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: '18px',
        },
      },
      lineColor: '#343741',
      tickColor: '#343741',
      tickLength: 4,
      tickWidth: 1,
      tickmarkPlacement: 'on',
      title: {
        align: 'low',
        style: {
          color: '#343741',
          fontSize: '10.5px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '18px',
        },
        text: 'Loss Year →',
        textAlign: 'left',
      },
    },
    yAxis: {
      gridLineWidth: 0,
      labels: {
        formatter() {
          // eslint-disable-next-line react/no-this-in-sfc
          if (yAxisType === LossYAxisTypes.CURRENCY) {
            return formatCurrency(this.value, currencyOptions);
          }

          return this.value;
        },
        style: {
          color: '#343741',
          fontSize: '10.5px',
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: '18px',
        },
        x: -8,
      },
      tickColor: '#343741',
      tickLength: 4,
      tickWidth: 1,
      title: {
        align: 'high',
        reserveSpace: false,
        rotation: 0,
        style: {
          color: '#343741',
          fontSize: '10.5px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '18px',
        },
        text: yAxisLabel,
        textAlign: 'left',
        width: '100%',
        y: -26,
      },
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options as Options} />
    </div>
  );
};

export default LineChart;

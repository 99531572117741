import { ReactElement, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeGrid, GridChildComponentProps } from 'react-window';
import styled from '@emotion/styled';
import { ISize, OnResize, OnScroll } from './types';

interface IProps<T> {
  items: Array<T>;
  height: number;
  columnWidth: number;
  rowHeight: number;
  children: (props: { item: T; index: number }) => ReactElement;
  onScroll?: OnScroll;
  onResize?: OnResize;
}

type GetColCount = (width: number, colWidth: number) => number;
export const getColumnCount: GetColCount = (width, colWidth) => Math.floor(width / colWidth);

type GetRowCount = (itemsLength: number, columnCount: number) => number;
export const getRowCount: GetRowCount = (items, cols) => Math.ceil(items / cols);

const Container = styled.div<{ height: number }>`
  label: GalleryContainer;
  padding: 20px;
  width: 100%;
  min-height: 100px;
  overflow-y: none;
  height: ${({ height }) => height}px;
`;

function Gallery<T>({
  height,
  items,
  columnWidth,
  rowHeight,
  children,
  onScroll,
  onResize,
}: IProps<T>): ReactElement<IProps<T>> {
  const [size, setSize] = useState<ISize>();

  if (!items.length) {
    return <p>No items found.</p>;
  }

  const handleResize = (nextSize: ISize) => {
    if (!size || size.width !== nextSize.width || size.height !== nextSize.height) {
      setSize(nextSize);
      if (onResize) {
        onResize(nextSize);
      }
    }
  };

  return (
    <Container height={height}>
      <AutoSizer>
        {({ width: parentWidth, height: parentHeight }) => {
          handleResize({
            height: parentHeight,
            width: parentWidth,
          });
          const columnCount = getColumnCount(parentWidth, columnWidth);
          const rowCount = getRowCount(items.length, columnCount);
          return (
            <FixedSizeGrid
              columnCount={columnCount}
              columnWidth={columnWidth}
              rowHeight={rowHeight}
              rowCount={rowCount}
              width={parentWidth + 17}
              height={height - 20}
              onScroll={onScroll}
            >
              {({ columnIndex, rowIndex, style }: GridChildComponentProps) => {
                const index = columnIndex + rowIndex * columnCount;
                const item = items[index];
                if (!item) {
                  return null;
                }

                return (
                  <>
                    {/* there is a conflict between react-dom's `CSSProperties` and
                     react-window's `CSSProperties`. This will likely be solved with future
                      react upgrades ... ? @ts-expect-error: see note above */}
                    <div key={index} style={style} data-testid={`gallery-cell-${index}`}>
                      {children({
                        index,
                        item,
                      })}
                    </div>
                  </>
                );
              }}
            </FixedSizeGrid>
          );
        }}
      </AutoSizer>
    </Container>
  );
}

export default Gallery;

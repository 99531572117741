import { useContext } from 'react';
import * as React from 'react';
import StreamEditContext from '@onarchipelago/cx/Stream/EditModal/StreamEditContext';
import { EuiFlexItem } from 'ui';
import JSONField from '../../Fields/JSONField';
import styles from './ExplorersConfigTab.emotion';

const ExplorersConfigTab: React.FC = () => {
  const { copyOfStream, setFieldValue, meta, touch } = useContext(StreamEditContext);

  if (!copyOfStream || !setFieldValue || !meta || !touch) {
    return null;
  }

  return (
    <>
      <EuiFlexItem data-testid="stream-edit-modal-explorers-config-tab">
        <JSONField
          label="Explorers Config"
          name="configuration.explorersConfig"
          className={styles.formRow}
          rowsMax={16}
        />
      </EuiFlexItem>
    </>
  );
};

export default ExplorersConfigTab;

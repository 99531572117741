import { FC, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import {
  EnrichmentPropertyPriorityData,
  EnrichmentPropertyPriorityVariables,
  GET_ENRICHMENT_PROPERTY_PRIORITY,
} from '@onarchipelago/cx/Reports/queries/getEnrichmentPropertyPriority';
import { formatPercentage } from '@onarchipelago/cx/Reports/ReadinessReport/utils';
import { EuiBasicTable, EuiFlexGroup, EuiFlexItem, EuiLink, EuiText, Icon } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import UploadFlyout from '@app/components/Upload/UploadFlyout';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import ErrorState from '../../components/ErrorState';

const NextProperties: FC = () => {
  const { showFlyout } = useContext(FlyoutContext);
  const { snapshots, stream } = useReportsContext();
  const { orgId, orgName, streamSlug } = stream;
  const { afterSnapshot, currentSnapshot } = snapshots;

  const { data, loading, error } = useQuery<
    EnrichmentPropertyPriorityData,
    EnrichmentPropertyPriorityVariables
  >(GET_ENRICHMENT_PROPERTY_PRIORITY, {
    variables: {
      input: {
        amountOfProperties: 10,
        snapshotNameAfter: afterSnapshot?.snapshotName || '',
        snapshotNameBefore: currentSnapshot?.snapshotName || '',
        streamSlug,
      },
    },
  });

  if (loading && !error) {
    return <LoadingSpinnerV2 styles={{ height: '943px' }} />;
  }

  if (!loading && error) {
    return <ErrorState />;
  }

  if (data && data?.enrichmentPropertyPriority?.suggestedProperties && !loading && !error) {
    const { dqsImprovementPct, propertiesCount, suggestedProperties } =
      data?.enrichmentPropertyPriority;

    return (
      <EuiFlexGroup direction="column">
        <EuiFlexItem>
          <EuiText>
            <h3>Properties to Prioritize Next</h3>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <h4 data-testid="next-properties-subtitle">
              {`Enriching these ${propertiesCount} properties could improve your data quality score by ${formatPercentage(
                dqsImprovementPct,
              )}%.`}
            </h4>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiBasicTable
            data-testid="next-properties-table"
            items={suggestedProperties}
            columns={[
              {
                field: 'clientID',
                name: 'Client ID',
              },
              {
                field: 'locationName',
                name: 'Building Name',
              },
            ]}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiLink
            data-testid="upload-documents-link"
            onClick={() =>
              showFlyout(<UploadFlyout organizationId={orgId} organizationName={orgName} />)
            }
          >
            Upload documents to enrich property data
            <Icon name="arrowRight" size="xs" />
          </EuiLink>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }

  return null;
};

export default NextProperties;

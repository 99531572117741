import { FC, ReactNode } from 'react';
import { EuiText, Icon } from 'ui';

interface Props {
  'data-testid'?: string;
  hideIcon?: boolean;
  left?: boolean;
  children: ReactNode;
  textAlign?: 'left' | 'right' | 'center' | undefined;
}

const ChartAxisLabel: FC<Props> = ({
  children,
  'data-testid': dataTestId,
  hideIcon = false,
  left = false,
  textAlign = 'left',
}) => (
  <EuiText data-testid={dataTestId} size="s" textAlign={textAlign}>
    <h6 style={{ textTransform: 'none' }}>
      {left && <Icon name="arrowLeft" size="xs" />}
      {children}
      {!hideIcon && !left && <Icon name="arrowRight" size="xs" />}
    </h6>
  </EuiText>
);

export default ChartAxisLabel;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformPanel = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Icon_1 = require("../../../neutrons/icon/Icon");
var getPanelItem = function (item, panelId) { return ({
    // @ts-expect-error: allowing prop `data-testid`
    'data-testid': item['data-testid'] || 'ui-atom-navigation-context-menu-item',
    className: item.type,
    icon: item.icon || (item.iconName ? (0, jsx_runtime_1.jsx)(Icon_1.Icon, { name: item.iconName }) : undefined),
    onClick: item.onClick,
    name: item.label,
    panel: panelId,
    disabled: item.disabled,
    style: item.style,
    key: item.key,
    toolTipContent: item.toolTipContent,
    toolTipPosition: item.toolTipPosition,
    toolTipTitle: item.toolTipTitle,
}); };
var transformPanel = function (panel) {
    var finalPanels = [];
    var nestedPanelId = 1;
    var transformItems = function (items) {
        return items === null || items === void 0 ? void 0 : items.map(function (item) {
            var itemPanelId = undefined;
            if (item.nestedPanel) {
                itemPanelId = nestedPanelId;
                nestedPanelId = nestedPanelId + 1;
                finalPanels.push({
                    id: itemPanelId,
                    items: transformItems(item.nestedPanel.items),
                    title: item.nestedPanel.title,
                    content: item.nestedPanel.content,
                    width: item.nestedPanel.width,
                });
            }
            return getPanelItem(item, itemPanelId);
        });
    };
    var finalItems = transformItems(panel.items);
    finalPanels.push({
        id: 0,
        items: finalItems,
        title: panel.title,
        content: panel.content,
        width: panel.width,
    });
    return finalPanels;
};
exports.transformPanel = transformPanel;

import { Home } from './Home';
import { Hub } from './Hub';
import { Precheck } from './Precheck';
import { Settings } from './Settings';
import { SovManager } from './SovManager';

export const MainAppNav = () => (
  <>
    <Home />
    <Hub />
    <Precheck />
    <SovManager />
    <Settings />
  </>
);

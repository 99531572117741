import * as React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import { ExposureLevel } from '../../../types';
import BenchmarkKeySegment from './BenchmarkKeySegment';
import styles from './BenchmarksKey.emotion';

export const segmentPercents = {
  High: 12.5,
  Low: 12.5,
  Medium: 50,
  VeryHigh: 12.5,
  VeryLow: 12.5,
};

const BenchmarksKey: React.FC = () => (
  <EuiFlexGroup data-testid="exposure-benchmarks-key">
    <EuiFlexItem grow={false}>
      <EuiFlexGroup alignItems="center" gutterSize="s">
        <EuiFlexItem grow={false}>
          <div className={styles.circle} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiText size="xs">
            <b>This portfolio's % TIV exposure</b>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <BenchmarkKeySegment
        exposureLevel={ExposureLevel.VeryLow}
        percent={segmentPercents.VeryLow}
      />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <BenchmarkKeySegment exposureLevel={ExposureLevel.Low} percent={segmentPercents.Low} />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <BenchmarkKeySegment exposureLevel={ExposureLevel.Medium} percent={segmentPercents.Medium} />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <BenchmarkKeySegment exposureLevel={ExposureLevel.High} percent={segmentPercents.High} />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <BenchmarkKeySegment
        exposureLevel={ExposureLevel.VeryHigh}
        percent={segmentPercents.VeryHigh}
      />
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default BenchmarksKey;

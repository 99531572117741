import { parseAndFormatUTCDate } from '../../../utils/format';
import { getEdits, getProvenance, ICell } from './Cell';

const formatValue = (val: string) => (!val ? '-' : parseAndFormatUTCDate(val));

const Date = (props: ICell) => {
  const id = props?.column?.id;
  const provenance = getProvenance(props.prop, id);
  const formattedValue = formatValue(props.value);
  const tooltip = {
    description: undefined,
    edits: getEdits(props.prop, id),
    provenance,
    title: undefined,
  };

  const body = <span>{formattedValue}</span>;

  return { body, tooltip };
};

export default Date;

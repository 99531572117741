import { Dispatch, SetStateAction, useContext } from 'react';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { PanelItem } from '@app/cx/Stream/ManageStreamButtonPanels/types';
import PermanentlyDeletePropertyFlyout from '@app/cx/Stream/PermanentlyDeletePropertyFlyout';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { getMetadataByType } from '@app/cx/Stream/utils';
import { IJob } from '@app/dice/EnrichmentProjects/types';
import { useTracker } from '@app/hooks/useTracker';
import { EJobType } from '@app/queries/organizations/types';
import { Lock } from '@app/queries/properties/PropertyQuery/types';

interface Props {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default ({ setOpen }: Props): PanelItem => {
  const tracker = useTracker();
  const { showFlyout } = useContext(FlyoutContext);
  const { stream, propertyAttributeMetadata, metadataMap } = useStreamContext();
  const filterable = getMetadataByType(propertyAttributeMetadata, 'filterable');

  const handleClick = () => {
    tracker.track('Permanently Delete Properties - Edit', {
      stream_id: stream?.id,
      stream_name: stream?.name,
      stream_slug: stream?.slug,
    });

    showFlyout(
      <PermanentlyDeletePropertyFlyout
        metadataMap={metadataMap}
        orgID={stream?.organizationId as string}
        orgName={stream?.orgName as string}
        filterable={filterable}
        streamSlug={stream?.slug || ''}
      />,
    );

    setOpen(false);
  };

  return {
    'data-testid': 'permanently-delete-properties-button',
    iconName: 'trash',
    label: 'Delete',
    onClick: handleClick,
    type: 'danger',
  };
};

import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import isNil from 'lodash/isNil';
import { EuiFlexGroup, EuiText } from 'ui';
import { AddressObjectType } from '@app/components/GoogleAutocomplete/types';
import { getEditRecords } from '@app/components/PropertiesGrid/PropertiesGridTooltip/utils';
import { getProvenanceAsObj } from '@app/components/PropertiesGrid/utils';
import { AttributeRow, AttributeRowTypeEnum } from '../AttributeRow';
import {
  ADDRESS_AND_GEO_INPUT_KEYS,
  ADDRESS_INPUT_KEYS,
  CITY_INPUT_KEY,
  CITY_LABEL,
  COUNTRY_INPUT_KEY,
  COUNTRY_LABEL,
  COUNTY_INPUT_KEY,
  COUNTY_LABEL,
  DISABLED_MESSAGE,
  LAT_INPUT_KEY,
  LAT_LABEL,
  LNG_INPUT_KEY,
  LNG_LABEL,
  POSTAL_CODE_INPUT_KEY,
  POSTAL_CODE_LABEL,
  STATE_INPUT_KEY,
  STATE_LABEL,
  STREET_ADDRESS_INPUT_KEY,
  STREET_ADDRESS_LABEL,
} from '../constants';
import { useAddPropertyFlyoutContext } from '../context/AddPropertyFlyout.context';
import { AddressError } from './AddressAttributes.emotion';

export const AddressAttributes = () => {
  const {
    countryOptions,
    countryToCodeMap,
    isLoadingOptions,
    selectedGoogleAddress,
    setSelectedGoogleAddress,
    setCustomerProvidedGeocode,
    propertyAttributeLocks,
    property,
  } = useAddPropertyFlyoutContext();
  const { watch, setValue, trigger } = useFormContext();
  const [filledByGoogle, setFilledByGoogle] = useState(false);
  const [addressError, setAddressError] = useState(false);

  const [countryValue, lngValue, latValue, streetAddressValue] = watch([
    COUNTRY_INPUT_KEY,
    LNG_INPUT_KEY,
    LAT_INPUT_KEY,
    STREET_ADDRESS_INPUT_KEY,
  ]);

  const countryCode = countryToCodeMap[countryValue];
  const countryRestrictions = countryCode ? [countryCode.toLowerCase()] : [];

  const addressAttributesRequired = isNil(lngValue) || isNil(latValue);

  const onAddressSelect = (address: AddressObjectType) => {
    setSelectedGoogleAddress(address);
    setCustomerProvidedGeocode(false);
    ADDRESS_AND_GEO_INPUT_KEYS.forEach((key) => {
      // skip state for non-US countries
      if (address.country !== 'United States' && key === STATE_INPUT_KEY) {
        return;
      }
      setValue(key, address[key]);
    });
    setAddressError(false);
    setFilledByGoogle(true);
    // triggers validation on the fields we just set
    trigger(ADDRESS_AND_GEO_INPUT_KEYS);
  };

  const handleStreetAddressBlur = () => {
    if (
      !filledByGoogle &&
      (!selectedGoogleAddress || streetAddressValue !== selectedGoogleAddress?.streetAddress)
    ) {
      setAddressError(true);
    } else {
      setAddressError(false);
    }
  };

  useEffect(() => {
    if (lngValue && latValue) {
      // if lng and lat are set, address fields are not required
      trigger(ADDRESS_INPUT_KEYS);
      if (
        lngValue !== selectedGoogleAddress?.longitude &&
        latValue !== selectedGoogleAddress?.latitude
      ) {
        setCustomerProvidedGeocode(true);
      }
    }
  }, [lngValue, latValue]);

  const addressAndGeoKeyToDisabledMap = {};
  ADDRESS_AND_GEO_INPUT_KEYS.forEach((key) => {
    const isDisabled = !!propertyAttributeLocks?.attributeNames?.includes(key);
    addressAndGeoKeyToDisabledMap[key] = isDisabled;
  });

  const provenanceObj = getProvenanceAsObj(property?.attributeProvenance || []);

  return (
    <EuiFlexGroup direction="column">
      <EuiText color="subdued">Fill out the main attributes to add a property.</EuiText>
      {/* Address Fields */}
      <AttributeRow
        id={COUNTRY_INPUT_KEY}
        label={COUNTRY_LABEL}
        type={AttributeRowTypeEnum.select}
        inputProps={{
          clearable: false,
          disabled: addressAndGeoKeyToDisabledMap[COUNTRY_INPUT_KEY],
          loading: isLoadingOptions,
          options: countryOptions,
        }}
        required={addressAttributesRequired}
        tooltipContent={
          addressAndGeoKeyToDisabledMap[COUNTRY_INPUT_KEY] ? DISABLED_MESSAGE : undefined
        }
        provenance={{
          editInfo: getEditRecords(property, COUNTRY_INPUT_KEY),
          provenance: provenanceObj?.[COUNTRY_INPUT_KEY],
        }}
      />
      <AttributeRow
        id={STREET_ADDRESS_INPUT_KEY}
        label={STREET_ADDRESS_LABEL}
        type={AttributeRowTypeEnum.address}
        inputProps={{
          countryRestrictions,
          disabled: addressAndGeoKeyToDisabledMap[STREET_ADDRESS_INPUT_KEY],
          onBlur: handleStreetAddressBlur,
          onSelect: onAddressSelect,
          predictionTypes: ['address'],
        }}
        required={addressAttributesRequired}
        tooltipContent={
          addressAndGeoKeyToDisabledMap[STREET_ADDRESS_INPUT_KEY] ? DISABLED_MESSAGE : undefined
        }
        provenance={{
          editInfo: getEditRecords(property, STREET_ADDRESS_INPUT_KEY),
          provenance: provenanceObj?.[STREET_ADDRESS_INPUT_KEY],
        }}
        warning={
          addressError && <AddressError>Please select an address from the dropdown</AddressError>
        }
      />
      <AttributeRow
        id={CITY_INPUT_KEY}
        label={CITY_LABEL}
        type={AttributeRowTypeEnum.text}
        required={addressAttributesRequired}
        inputProps={{
          disabled: addressAndGeoKeyToDisabledMap[CITY_INPUT_KEY],
        }}
        tooltipContent={
          addressAndGeoKeyToDisabledMap[CITY_INPUT_KEY] ? DISABLED_MESSAGE : undefined
        }
        provenance={{
          editInfo: getEditRecords(property, CITY_INPUT_KEY),
          provenance: provenanceObj?.[CITY_INPUT_KEY],
        }}
      />
      {countryValue === 'United States' && (
        <AttributeRow
          id={STATE_INPUT_KEY}
          label={STATE_LABEL}
          type={AttributeRowTypeEnum.text}
          required={addressAttributesRequired}
          inputProps={{
            disabled: addressAndGeoKeyToDisabledMap[STATE_INPUT_KEY],
          }}
          tooltipContent={
            addressAndGeoKeyToDisabledMap[STATE_INPUT_KEY] ? DISABLED_MESSAGE : undefined
          }
          provenance={{
            editInfo: getEditRecords(property, STATE_INPUT_KEY),
            provenance: provenanceObj?.[STATE_INPUT_KEY],
          }}
        />
      )}
      <AttributeRow
        id={POSTAL_CODE_INPUT_KEY}
        label={POSTAL_CODE_LABEL}
        type={AttributeRowTypeEnum.text}
        required={addressAttributesRequired}
        inputProps={{
          disabled: addressAndGeoKeyToDisabledMap[POSTAL_CODE_INPUT_KEY],
        }}
        tooltipContent={
          addressAndGeoKeyToDisabledMap[POSTAL_CODE_INPUT_KEY] ? DISABLED_MESSAGE : undefined
        }
        provenance={{
          editInfo: getEditRecords(property, STATE_INPUT_KEY),
          provenance: provenanceObj?.[STATE_INPUT_KEY],
        }}
      />
      <AttributeRow
        id={COUNTY_INPUT_KEY}
        label={COUNTY_LABEL}
        type={AttributeRowTypeEnum.text}
        required={addressAttributesRequired}
        inputProps={{
          disabled: addressAndGeoKeyToDisabledMap[COUNTY_INPUT_KEY],
        }}
        tooltipContent={
          addressAndGeoKeyToDisabledMap[COUNTY_INPUT_KEY] ? DISABLED_MESSAGE : undefined
        }
        provenance={{
          editInfo: getEditRecords(property, COUNTY_INPUT_KEY),
          provenance: provenanceObj?.[COUNTY_INPUT_KEY],
        }}
      />
      <EuiText color="subdued">
        <b>Optional:</b> You can provide the latitude and longitude of a property instead of a
        street address.
      </EuiText>
      {/* Lng/Lat Fields */}
      <AttributeRow
        id={LAT_INPUT_KEY}
        label={LAT_LABEL}
        type={AttributeRowTypeEnum.number}
        inputProps={{
          disabled: addressAndGeoKeyToDisabledMap[LAT_INPUT_KEY],
        }}
        tooltipContent={addressAndGeoKeyToDisabledMap[LAT_INPUT_KEY] ? DISABLED_MESSAGE : undefined}
        provenance={{
          editInfo: getEditRecords(property, LAT_INPUT_KEY),
          provenance: provenanceObj?.[LAT_INPUT_KEY],
        }}
      />
      <AttributeRow
        id={LNG_INPUT_KEY}
        label={LNG_LABEL}
        type={AttributeRowTypeEnum.number}
        inputProps={{
          disabled: addressAndGeoKeyToDisabledMap[LNG_INPUT_KEY],
        }}
        tooltipContent={addressAndGeoKeyToDisabledMap[LNG_INPUT_KEY] ? DISABLED_MESSAGE : undefined}
        provenance={{
          editInfo: getEditRecords(property, LNG_INPUT_KEY),
          provenance: provenanceObj?.[LNG_INPUT_KEY],
        }}
      />
    </EuiFlexGroup>
  );
};

import { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  ButtonEmpty,
  EuiFilePicker,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiTitle,
  useToast,
} from 'ui';
import { useJobsApolloClient } from '@app/dice/JobsApolloClient';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { GET_JOBS } from '../../queries/organizations/getJobs';
import { SUBMIT_JOB } from '../../queries/organizations/submitJob';

interface IProps {
  jobID: string;
  jobName: string;
  projectName: string;
  orgName: string;
}

const SubmitJob: FC<IProps> = ({ jobID, jobName, projectName, orgName }) => {
  const toast = useToast();
  const jobsApolloClient = useJobsApolloClient();

  const [isPopoverOpen, setPopover] = useState(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();

  const togglePopover = () => {
    setPopover(!isPopoverOpen);
  };

  const [submitJob] = useMutation(SUBMIT_JOB, {
    client: jobsApolloClient,
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setSubmitting(false);
      togglePopover();
    },
    onError: (error) => toast({ title: getErrorMessage(error), type: 'danger' }),
  });

  const handleSubmitForReview = async () => {
    setSubmitting(true);

    submitJob({
      refetchQueries: [
        {
          query: GET_JOBS,
          variables: {
            jobsFilter: {
              activeOnly: true,
              orgName,
              projectName,
            },
            pageSize: 50,
          },
        },
      ],
      variables: {
        input: {
          jobID,
          worksheet: selectedFile,
        },
      },
    });
  };

  const submitJobButton = (
    <Button data-testid="job-upload" size="s" fill onClick={togglePopover} label="Upload" />
  );

  const onFileSelect = (files: FileList | null) => {
    if (files?.length) {
      setSelectedFile(files[0]);
    }
  };

  return (
    <EuiPopover
      id="singlePanel"
      button={submitJobButton}
      isOpen={isPopoverOpen}
      closePopover={togglePopover}
      panelPaddingSize="l"
      anchorPosition="downLeft"
    >
      <EuiFlexGroup gutterSize="l">
        <EuiFlexItem>
          <EuiTitle size="xs">
            <h4>{`Upload files to ${orgName} - ${projectName} for the job "${jobName}"`}</h4>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup gutterSize="l" justifyContent="center">
        <EuiFlexItem grow={false}>
          <EuiFilePicker
            data-testid="job-upload-file-picker"
            id="asdf2"
            display="default"
            multiple={false}
            initialPromptText="Select or drag and drop a file"
            onChange={(files) => onFileSelect(files)}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup justifyContent="flexEnd">
        <EuiFlexItem grow={false}>
          <ButtonEmpty onClick={togglePopover} label="Cancel" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Button
            data-testid="job-upload-submit"
            onClick={handleSubmitForReview}
            disabled={!selectedFile || isSubmitting}
            fill
            label={isSubmitting ? 'Submitting...' : 'Submit for review'}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>
  );
};

export default SubmitJob;

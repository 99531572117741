import { useContext } from 'react';
import * as React from 'react';
import StreamEditContext from '@onarchipelago/cx/Stream/EditModal/StreamEditContext';
import { EuiFlexItem } from 'ui';
import JSONField from '../../Fields/JSONField';
import styles from './HighlightsTab.emotion';

const HighlightsTab: React.FC = () => {
  const { copyOfStream, setFieldValue, meta, touch } = useContext(StreamEditContext);

  if (!copyOfStream || !setFieldValue || !meta || !touch) {
    return null;
  }

  return (
    <>
      <EuiFlexItem data-testid="stream-edit-modal-highlights-tab">
        <JSONField className={styles.formRow} label="Highlights" name="highlights" rowsMax={16} />
      </EuiFlexItem>
    </>
  );
};

export default HighlightsTab;

import { ReactElement } from 'react';
import styled from '@emotion/styled';
import { ChildrenProps, IRowColor } from '@app/components/GroupedList/types';
import GroupedRow from '@app/components/GroupedRow/GroupedRow';
import PropertiesGroupedListProperties from './PropertiesGroupedListProperties';
import { Children, IGroupedProps, IPropertiesProps, IProps } from './types';

export const PROPERTY_GROUPED_LIST_GROUP_HEIGHT = 88;

interface IPropertiesGroupedListRowProps extends ChildrenProps<IProps> {
  duration: number;
  children: Children;
  loading: boolean;
}

interface IRowInnerProps {
  depth?: number;
  group?: boolean;
  rowColor?: IRowColor;
}

const RowInner = styled.div<IRowInnerProps>`
  label: PropertiesGroupedListRowInner;
  flex: 1;
  height: 100%;
  display: flex;
  min-width: 0;

  background: ${({ depth, rowColor }) => {
    if (depth === 2) {
      return '#F8F8F8';
    }
    if (depth && depth > 2) {
      return '#F0F0F0';
    }
    return rowColor?.background;
  }};

  > div > label {
    color: ${({ rowColor }) => rowColor?.font};
  }
  ${({ group }) =>
    group
      ? `
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
  `
      : ''};
`;

function isGroupedProps(props: any): props is IGroupedProps {
  return props.type === 'group';
}

function PropertiesGroupedListRow<T>({
  props,
  options,
  indices,
  visible,
  duration,
  children,
  loading,
}: IPropertiesGroupedListRowProps): ReactElement<ChildrenProps<T>> {
  if (isGroupedProps(props)) {
    const { label, explorers, provenance } = props;
    const depth = indices.length;
    const style = {
      paddingLeft: 22 * (depth + 1) + (options.showDisclosureButton ? 20 : 0),
    };
    return (
      <RowInner group depth={depth} style={style} rowColor={options.color}>
        <GroupedRow label={label} columns={explorers} provenance={provenance} />
      </RowInner>
    );
  }

  return (
    <RowInner>
      <PropertiesGroupedListProperties
        visible={visible}
        indices={indices}
        duration={duration}
        props={props as IPropertiesProps}
        loading={loading}
      >
        {children}
      </PropertiesGroupedListProperties>
    </RowInner>
  );
}

export default PropertiesGroupedListRow;

import { LossAttributes } from '@app/queries/properties/generated_types';
import { IPropertiesGridColumn, IPropertyWithProvenance } from '../types';
import BooleanCell from './Boolean';
import { Cell, ICell } from './Cell';
import Currency from './Currency';
import DateCell from './Date';
import Default from './Default';
import Description from './Description';
import { StreamOptions } from './GridCell';
import HazardsIcon from './HazardsIcon';
import HazardText from './HazardText';
import InspectionsIcon from './InspectionsIcon';
import Location from './Location';
import {
  MeasurementAreaCell,
  MeasurementDistanceLarge,
  MeasurementDistanceSmall,
} from './Measurement';
import NumberCell, { RawNumber } from './Number';
import Percentage from './Percentage';
import PropertiesGridImage from './PropertiesGridImage';
import { SchemaConstruction, SchemaOccupancy } from './Schema';
import SecondaryModifier from './SecondaryModifier';
import Text from './Text';
import TwoLines from './TwoLines';

type CellTypeFn = (props: ICell, format: boolean, streamOptions?: StreamOptions) => Cell;

const cellTypes: {
  [index: string]: CellTypeFn;
} = {
  boolean: BooleanCell,
  construction: SchemaConstruction,
  currency: Currency,
  currencyLocal: Currency,
  date: DateCell,
  default: Default,
  description: Description,
  hazardIcon: HazardsIcon,
  hazardText: HazardText,
  inspectionsIcon: InspectionsIcon,
  integer: NumberCell,
  location: Location,
  measurementArea: MeasurementAreaCell,
  measurementDistanceLarge: MeasurementDistanceLarge,
  measurementDistanceSmall: MeasurementDistanceSmall,
  number: NumberCell,
  occupancy: SchemaOccupancy,
  percentage: Percentage,
  propertyImg: PropertiesGridImage,
  secondaryModifier: SecondaryModifier,
  text: Text,
  twoLines: TwoLines,
  webLink: Default,
  year: RawNumber,
};

export function getCell(
  prop?: IPropertyWithProvenance,
  column?: IPropertiesGridColumn,
  streamOptions?: StreamOptions,
) {
  if (prop === undefined || column === undefined) {
    return undefined;
  }
  const type = column.type === 'description' ? 'text' : column.type || 'default';

  if (cellTypes[type] === undefined) {
    throw new Error(`No cell type defined for ${type}.`);
  }

  let value = '';

  // If loss attribute
  const lossAttributes: Array<keyof LossAttributes> = [
    'totalGrossLoss',
    'largestLoss',
    'lossCountTotal',
    'lossCountSinceLastBound',
  ];
  const isLossAttribute = lossAttributes.includes(column.id as keyof LossAttributes);
  if (isLossAttribute) {
    value = prop?.lossAttributes?.[column.id];
  } else if (column.id !== 'ownerAttributes' && column.id !== 'consumerAttributes') {
    if (
      column.id.startsWith('ownerText') ||
      column.id.startsWith('ownerDecimal') ||
      column.id.startsWith('consumerText') ||
      column.id.startsWith('consumerDecimal')
    ) {
      value =
        column.id && prop?.ownerAttributes?.[column.id] === 0
          ? 0
          : (column.id && prop?.ownerAttributes?.[column.id]) ||
            (column.id && prop?.consumerAttributes?.[column.id]);
    } else {
      value = prop?.[column.id];
    }
  }

  return cellTypes[type]({ column, prop, value }, true, streamOptions);
}

import { FC, ReactNode } from 'react';
import { Location } from 'history';
import { EuiFlexGroup, EuiFlexItem, EuiPageBody, EuiThemeProvider } from 'ui';
import { SUBMISSIONS_PATH_BASE } from '@app/containers/App/Routes/constants';
import { encodeUrl } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { useWhiteLabel } from '@app/hooks/useWhiteLabel/useWhiteLabel';
// @ts-ignore
import { SUPPORT_PATH } from './SideNav/constants';
import { SideNavV2 } from './SideNav/SideNavV2';
import { TopNav } from './TopNav/TopNav';
import {
  CASUALTY_TAB,
  HOME_TAB,
  INBOX_TAB,
  LIBRARY_TAB,
  PRECHECK_TAB,
  PROJECTS_TAB,
  STREAMS_TAB,
  SUBMISSIONS_TAB,
  SUPPORT_TAB,
  USERS_TAB,
} from './constants';
import { MainContainer, StyledPage } from './Layout.emotion';
import { useAlert } from './useAlert';

export const tabToPath = (tab: string, selectedOrgName?: string) => {
  if (tab === SUPPORT_PATH) {
    return `/${tab}`;
  }

  // Admins/people with multiple orgs
  if (selectedOrgName) {
    const toPath = (items: string[]) => `/${items.map((s: string) => encodeUrl`${s}`).join('/')}`;
    return toPath(['organizations', selectedOrgName || '', tab.toLowerCase()]);
  }
  return `/${tab.toLowerCase()}`;
};

export const pathToTab = (location: Location) => {
  if (location.pathname.includes('/library')) {
    return LIBRARY_TAB;
  }
  if (location.pathname.includes('/projects')) {
    return PROJECTS_TAB;
  }
  if (location.pathname.includes('/users')) {
    return USERS_TAB;
  }
  if (location.pathname.includes('/inbox')) {
    return INBOX_TAB;
  }
  if (location.pathname.includes(SUBMISSIONS_PATH_BASE)) {
    return SUBMISSIONS_TAB;
  }
  if (location.pathname.includes('precheck')) {
    return PRECHECK_TAB;
  }
  if (location.pathname.includes('home')) {
    return HOME_TAB;
  }

  if (location.pathname.includes('/support')) {
    return SUPPORT_TAB;
  }

  if (location.pathname.includes('/casualty')) {
    return CASUALTY_TAB;
  }
  return STREAMS_TAB;
};

const LayoutElastic: FC<{ children: ReactNode }> = ({ children }) => {
  const { account } = useAuth();

  // FIX ME
  // @ts-ignore
  const { themeOverride } = useWhiteLabel(account);

  // Temporary styles until we remove Material UI
  const bodyStyle = document?.querySelector('body')?.style as any;
  if (bodyStyle?.display) {
    bodyStyle.backgroundColor = '#fafbfd';
    bodyStyle.fontFamily = 'inherit';
    bodyStyle.fontWeight = 'inherit';
    bodyStyle.fontSize = 'inherit';
  }

  const { shouldShowAlert, hideAlert, message, enterpriseOrgName } = useAlert();

  return (
    <EuiThemeProvider modify={themeOverride}>
      <MainContainer hasAlert={shouldShowAlert} data-testid="app-layout">
        <TopNav
          enterpriseOrgName={enterpriseOrgName}
          hideAlert={hideAlert}
          message={message}
          shouldShowAlert={shouldShowAlert}
        />
        <EuiFlexGroup gutterSize="none" style={{ height: '100%' }}>
          <EuiFlexItem grow={false}>
            <SideNavV2 hasAlert={shouldShowAlert} />
          </EuiFlexItem>
          <StyledPage>
            <EuiPageBody component="div">{children}</EuiPageBody>
          </StyledPage>
        </EuiFlexGroup>
      </MainContainer>
    </EuiThemeProvider>
  );
};

export default LayoutElastic;

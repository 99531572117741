import { useContext } from 'react';
import * as React from 'react';
import get from 'lodash/get';
import { Button, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiTextColor, Spacer, TextField } from 'ui';
import { ModalContext } from '@app/contexts/ModalContext';
import StreamEditContext from '../StreamEditContext';
import { hasErrors } from '../validator';
import styles from './TabActionButtons.emotion';

interface Props {
  onClose?: () => void;
}

const TabActionButtons: React.FC<Props> = ({ onClose }) => {
  const { message, setMessage, submit, submitting, meta, touchedFields, copyOfStream } =
    useContext(StreamEditContext);
  const { closeModal } = useContext(ModalContext);
  const hasChanges = touchedFields && Object.keys(touchedFields).length;

  const isSaveButtonDisabled = get(copyOfStream, 'name') === '';

  return (
    <EuiFlexGroup direction="column" data-testid="stream-edit-modal-tab-action-buttons">
      {!!hasChanges && (
        <EuiFlexItem grow={false}>
          <EuiFormRow
            className={styles.reasonTextInput(!hasChanges)}
            fullWidth
            label={
              <>
                Reason for changes
                <EuiTextColor color="danger">
                  {' ('}
                  <i>required</i>
                  {')'}
                </EuiTextColor>
              </>
            }
          >
            <TextField
              data-testid="stream-change-reason"
              fullWidth
              onChange={(event) => {
                setMessage?.(event.target?.value);
              }}
              value={message || ''}
            />
          </EuiFormRow>
        </EuiFlexItem>
      )}
      <EuiFlexItem grow={false}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <Button label="Cancel" onClick={onClose ? onClose : closeModal} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <Button
              data-testid="save-stream-change"
              disabled={
                !hasChanges || !message || submitting || isSaveButtonDisabled || hasErrors(meta)
              }
              fill
              label="Save"
              onClick={submit}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default TabActionButtons;

import * as React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import { ExposureLevel } from '../../types';
import exposureLabels from '../../utils/exposureLabels';
import styles from './IndexSummaryKeyItem.emotion';

interface Props {
  exposureLevel: ExposureLevel;
}

const IndexSummaryKeyItem: React.FC<Props> = ({ exposureLevel }) => (
  <EuiFlexGroup data-testid="index-summary-key-item" alignItems="center" gutterSize="xs">
    <EuiFlexItem grow={false}>
      <div className={styles.square(exposureLevel)} />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiText size="xs">
        <p>{exposureLabels[exposureLevel]}</p>
      </EuiText>
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default IndexSummaryKeyItem;

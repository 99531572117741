import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { DocumentNode } from 'graphql';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { IFetchMoreResults } from '@app/components/Query/types';
import useStandardQuery from '@app/hooks/useStandardQuery';
import { IGraphQLProperty } from '@app/queries/properties/types';
import styles from './PropertiesPageQuery.emotion';
import {
  IChildrenProps,
  IGetPropertyPageData,
  IGetPropertyPageResponse,
  IGetPropertyPageVariables,
  IGraphQLSort,
  RequestType,
} from './types';
import { getOptions, getRequestType, translateProperties } from './utils';

interface Props<T> {
  query: DocumentNode;
  groupID?: string;
  children: (props: IChildrenProps<T>) => ReactElement;
  pageSize?: number;
  sort?: IGraphQLSort[];
}

type Attributes = 'id' | 'archipelagoId';

function PropertiesPageQuery<T extends Pick<IGraphQLProperty, Attributes>>({
  query,
  groupID,
  pageSize = 999999,
  sort,
  children,
}: Props<T>) {
  type D = IGetPropertyPageData<T>;
  type V = IGetPropertyPageVariables;
  type R = IGetPropertyPageResponse<T>;
  type NextResult = IFetchMoreResults<R, V>;

  const fetching = useRef<boolean>(false);
  const [previousRequestType, setPreviousRequestType] = useState<RequestType | undefined>();
  const [initialized, setInitialized] = useState(false);
  const sortBy = sort ? sort : [];
  const [currentCursor, setCurrentCursor] = useState<string>('');
  const [localGroupID, setLocalGroupID] = useState<string>('');
  const options = useMemo(
    () =>
      getOptions(groupID, {
        pageSize,
        sortBy,
      }),
    [sort, groupID, pageSize],
  );

  const [prevOptions, setPrevOptions] = useState<V>(options?.variables);

  useEffect(() => {
    if (prevOptions) {
      setPreviousRequestType(getRequestType(prevOptions, options.variables));
    }
    setPrevOptions(options?.variables);
  }, [JSON.stringify(options?.variables)]);

  const { loading, networkStatus, error, data, fetchMore } = useStandardQuery<D, V>(
    query,
    options,
    {
      errorMessage: 'There was an error loading properties',
      numberOfAllowableErrors: 3,
    },
  );

  // const loadingInitial = loading && initialized === false;
  const loadingGlobal = loading && networkStatus === 1;
  const loadingMore = loading && networkStatus === 3;
  const loadingSort = loading && previousRequestType === 'sort';

  useEffect(() => {
    if (loading === false) {
      setPreviousRequestType(undefined);
    }
  }, [loading]);

  useEffect(() => {
    if (initialized === false && data && !loading && !error) {
      setInitialized(true);
    }
  }, [loading, error, !!data]);

  useEffect(() => {
    if (localGroupID) {
      fetchMore({
        query,
        updateQuery: (previousResult: R, nextResult: NextResult) => {
          fetching.current = false;
          if (!nextResult.fetchMoreResult) {
            throw new Error('No result for query with latest cursor');
          }
          if (!previousResult) {
            throw new Error('A blank previousResult was returned from updateQuery');
          }
          if (!nextResult) {
            throw new Error('A blank nextResult was returned from updateQuery');
          }

          const mergedProperties = [
            ...previousResult.propertiesPage.properties,
            ...(nextResult.fetchMoreResult.propertiesPage.properties || []),
          ];

          const merged: R = {
            ...previousResult,
            propertiesPage: {
              ...previousResult.propertiesPage,
              pageInfo: nextResult.fetchMoreResult.propertiesPage.pageInfo,
              properties: mergedProperties,
            },
          };

          return merged;
        },

        variables: {
          cursor: currentCursor,
          groupID: localGroupID,
          pageSize,
          sortBy,
        },
      });
    }
  }, [currentCursor, localGroupID, JSON.stringify(sortBy), pageSize]);

  if (loadingGlobal) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingSpinnerV2 />
      </div>
    );
  }

  if (!data) {
    return children({
      fetchMore: undefined,
      loadingGlobal,
      loadingMore,
      loadingSort,
      properties: [],
    });
  }

  const translatedData = translateProperties(data, options.variables);

  if (!translatedData) {
    return children({
      fetchMore: undefined,
      loadingGlobal,
      loadingMore,
      loadingSort,
      properties: [],
    });
  }

  const {
    propertiesPage: {
      properties,
      pageInfo: { cursor },
    },
  } = translatedData;

  return children({
    fetchMore:
      cursor === null
        ? undefined
        : ({ groupID: newGroupID }) => {
            if (fetching.current === false) {
              fetching.current = true;
              setCurrentCursor(cursor);
              setLocalGroupID(newGroupID);
            }
          },
    loadingGlobal,
    loadingMore,
    loadingSort,
    properties,
  });
}

export default PropertiesPageQuery;

import { FC } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, Select } from 'ui';
import { useExposureIndexContext } from '../context/exposureIndexContext';

const SnapshotSelect: FC = () => {
  const { snapshots } = useExposureIndexContext();
  const { currentSnapshot, loading, setCurrentSnapshot, snapshotOptions } = snapshots;

  if (snapshotOptions.length === 0) {
    return null;
  }

  return (
    <EuiFlexGroup responsive={false} gutterSize="s">
      <EuiFlexItem grow={false}>
        <EuiText style={{ lineHeight: '40px' }}>View report for</EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false} style={{ width: '160px' }}>
        <Select
          options={snapshotOptions}
          value={currentSnapshot}
          onChange={(value) => {
            setCurrentSnapshot(value);
          }}
          loading={loading}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default SnapshotSelect;

import styled from '@emotion/styled';
import { getEdits, getProvenance, ICell } from './Cell';

// https://stackoverflow.com/questions/9716468/pure-javascript-a-function-like-jquerys-isnumeric
const isNumeric = (n: any) => !Number.isNaN(parseFloat(n)) && Number.isFinite(n);

const FormattedPercentage = styled.div`
  label: Percentage;
  text-align: right;
`;

const Percentage = (props: ICell) => {
  const id = props.column && (props.column.id as string);
  const value = props.value;

  let formatted = '-';
  if (isNumeric(value)) {
    // If number then format as percentage
    const n = parseFloat(value);
    formatted = `${n.toFixed(0)}%`;
  } else {
    // If somehow the value is some kind of string then just render it
    formatted = value || '-';
  }

  const provenance = getProvenance(props.prop, id);

  const tooltip = {
    description: undefined,
    edits: getEdits(props.prop, id),
    provenance,
    title: undefined,
  };

  const body = (
    <FormattedPercentage>
      <span>{formatted}</span>
    </FormattedPercentage>
  );

  return { body, tooltip };
};

export default Percentage;

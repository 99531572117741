import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import isFinite from 'lodash/isFinite';
import isNil from 'lodash/isNil';
import { EuiFlexGroup } from 'ui';
import { getEditRecords } from '@app/components/PropertiesGrid/PropertiesGridTooltip/utils';
import { getProvenanceAsObj } from '@app/components/PropertiesGrid/utils';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { useStreamContext } from '../../StreamProvider';
import { AttributeRow, AttributeRowTypeEnum } from '../AttributeRow';
import {
  BIC_INPUT_KEY,
  BIC_LABEL,
  COUNTRY_INPUT_KEY,
  CRC_INPUT_KEY,
  CRC_LABEL,
  CURRENCY_INPUT_KEY,
  CURRENCY_KEYS,
  CURRENCY_LABEL,
  DERIVED_MESSAGE,
  DISABLED_MESSAGE,
  RCV_INPUT_KEY,
  RCV_LABEL,
  TIV_INPUT_KEY,
  TIV_LABEL,
} from '../constants';
import { useAddPropertyFlyoutContext } from '../context/AddPropertyFlyout.context';
import { getSubValueCurrencyFieldsToParentMap } from '../utils';

export const CurrencyAttributes = () => {
  const { stream, propertyAttributeMetadata } = useStreamContext();
  const [subValueToParentMap, setSubValueToParentMap] = useState<{
    [key: string]: IGraphQLAttributeMetadata[];
  }>({});
  const { setValue, watch, trigger, formState } = useFormContext();
  const {
    currencyOptions,
    countryToCurrencyMap,
    isLoadingOptions,
    subValueAttributes,
    setSubValueAttributes,
    propertyAttributeLocks,
    property,
  } = useAddPropertyFlyoutContext();

  const [rcvValue, crcValue, bicValue, currencyValue, countryValue, ...subValues] = watch([
    RCV_INPUT_KEY,
    CRC_INPUT_KEY,
    BIC_INPUT_KEY,
    CURRENCY_INPUT_KEY,
    COUNTRY_INPUT_KEY,
    ...subValueAttributes,
  ]);

  // this is required because the individual currency field is not in a dirty state when a user
  // clicks on an option but doesn't click outside of the input, so you have to check the global
  // dirty state of the form
  const effectiveCurrencyValue = formState.isDirty ? currencyValue : stream?.displayCurrency;

  useEffect(() => {
    if (propertyAttributeMetadata?.length) {
      const newSubValueToParentMap =
        getSubValueCurrencyFieldsToParentMap(propertyAttributeMetadata);
      setSubValueToParentMap(newSubValueToParentMap);
      const newSubValueAttributes = Object.values(newSubValueToParentMap)
        .flat()
        .map((attribute) => attribute.name);
      setSubValueAttributes(newSubValueAttributes);
    }
  }, [propertyAttributeMetadata]);

  useEffect(() => {
    if (countryValue && countryToCurrencyMap[countryValue]) {
      setValue(CURRENCY_INPUT_KEY, countryToCurrencyMap[countryValue]);
      trigger(CURRENCY_INPUT_KEY);
    }
  }, [countryValue]);

  useEffect(() => {
    if (!isNil(rcvValue) && !isNil(crcValue) && !isNil(bicValue)) {
      const tivValue = rcvValue + crcValue + bicValue;
      setValue(TIV_INPUT_KEY, tivValue);
      trigger(TIV_INPUT_KEY);
    }
  }, [rcvValue, crcValue, bicValue]);

  const currencyKeyLockedMap = {};
  const currencyKeyDerivedMap = {};

  CURRENCY_KEYS.forEach((key) => {
    const attributeMeta = propertyAttributeMetadata?.find((attribute) => attribute.name === key);
    const isDerived = !!attributeMeta?.derived;
    const isLocked = !!propertyAttributeLocks?.attributeNames?.includes(key);
    currencyKeyLockedMap[key] = isLocked;
    currencyKeyDerivedMap[key] = isDerived;
  });

  useEffect(() => {
    if (!subValues?.length) {
      return;
    }
    const getNewDerivedValue = (key: string) => {
      const subValuesMeta = subValueToParentMap[key];
      return subValuesMeta?.reduce((acc, value) => {
        const subValueIndex = subValueAttributes.findIndex((attr) => attr === value?.name);
        const subValueValue = subValues[subValueIndex];
        if (isFinite(subValueValue)) {
          return (acc || 0) + subValueValue;
        }
        return acc;
      }, undefined);
    };

    if (currencyKeyDerivedMap[RCV_INPUT_KEY]) {
      const newRcvValue = getNewDerivedValue(RCV_INPUT_KEY);
      if (newRcvValue !== rcvValue) {
        setValue(RCV_INPUT_KEY, newRcvValue);
        trigger(RCV_INPUT_KEY);
      }
    }

    if (currencyKeyDerivedMap[CRC_INPUT_KEY]) {
      const newCrcValue = getNewDerivedValue(CRC_INPUT_KEY);
      if (newCrcValue !== crcValue) {
        setValue(CRC_INPUT_KEY, newCrcValue);
        trigger(CRC_INPUT_KEY);
      }
    }

    if (currencyKeyDerivedMap[BIC_INPUT_KEY]) {
      const newBicValue = getNewDerivedValue(BIC_INPUT_KEY);
      if (newBicValue !== bicValue) {
        setValue(BIC_INPUT_KEY, newBicValue);
        trigger(BIC_INPUT_KEY);
      }
    }
  }, [subValues]);

  const getTooltipMessage = (isDerived: boolean, isLocked: boolean) => {
    if (isDerived) {
      return DERIVED_MESSAGE;
    }
    if (isLocked) {
      return DISABLED_MESSAGE;
    }
    return undefined;
  };

  const provenanceObj = getProvenanceAsObj(property?.attributeProvenance || []);

  return (
    <EuiFlexGroup direction="column">
      <AttributeRow
        id={CURRENCY_INPUT_KEY}
        label={CURRENCY_LABEL}
        type={AttributeRowTypeEnum.select}
        inputProps={{
          clearable: false,
          disabled: currencyKeyLockedMap[CURRENCY_INPUT_KEY],
          loading: isLoadingOptions,
          options: currencyOptions,
          value: effectiveCurrencyValue,
        }}
        tooltipContent={getTooltipMessage(
          currencyKeyDerivedMap[CURRENCY_INPUT_KEY],
          currencyKeyLockedMap[CURRENCY_INPUT_KEY],
        )}
        provenance={{
          editInfo: getEditRecords(property, CURRENCY_INPUT_KEY),
          provenance: provenanceObj?.[CURRENCY_INPUT_KEY],
        }}
        required
      />
      <AttributeRow
        id={TIV_INPUT_KEY}
        label={TIV_LABEL}
        type={AttributeRowTypeEnum.currency}
        inputProps={{ disabled: true, prepend: <b>{effectiveCurrencyValue}</b> }}
        tooltipContent={DERIVED_MESSAGE}
        provenance={{
          editInfo: getEditRecords(property, TIV_INPUT_KEY),
          provenance: provenanceObj?.[TIV_INPUT_KEY],
        }}
        required
      />
      <AttributeRow
        id={RCV_INPUT_KEY}
        label={RCV_LABEL}
        type={AttributeRowTypeEnum.currency}
        inputProps={{
          disabled: currencyKeyLockedMap[RCV_INPUT_KEY] || currencyKeyDerivedMap[RCV_INPUT_KEY],
          prepend: <b>{effectiveCurrencyValue}</b>,
        }}
        tooltipContent={getTooltipMessage(
          currencyKeyDerivedMap[RCV_INPUT_KEY],
          currencyKeyLockedMap[RCV_INPUT_KEY],
        )}
        provenance={{
          editInfo: getEditRecords(property, CURRENCY_INPUT_KEY),
          provenance: provenanceObj?.[CURRENCY_INPUT_KEY],
        }}
        required
      />
      {currencyKeyDerivedMap[RCV_INPUT_KEY] &&
        subValueToParentMap[RCV_INPUT_KEY]?.map((subValue) => {
          const isLocked = !!propertyAttributeLocks?.attributeNames?.includes(subValue.name);
          return (
            <AttributeRow
              key={subValue.name}
              id={subValue.name}
              label={subValue.displayName}
              type={AttributeRowTypeEnum.currency}
              inputProps={{
                disabled: isLocked,
                prepend: <b>{effectiveCurrencyValue}</b>,
              }}
              provenance={{
                editInfo: getEditRecords(property, subValue.name),
                provenance: provenanceObj?.[subValue.name],
              }}
              tooltipContent={getTooltipMessage(false, isLocked)}
            />
          );
        })}
      <AttributeRow
        id={CRC_INPUT_KEY}
        label={CRC_LABEL}
        type={AttributeRowTypeEnum.currency}
        inputProps={{
          disabled: currencyKeyLockedMap[CRC_INPUT_KEY] || currencyKeyDerivedMap[CRC_INPUT_KEY],
          prepend: <b>{effectiveCurrencyValue}</b>,
        }}
        tooltipContent={getTooltipMessage(
          currencyKeyDerivedMap[CRC_INPUT_KEY],
          currencyKeyLockedMap[CRC_INPUT_KEY],
        )}
        provenance={{
          editInfo: getEditRecords(property, CRC_INPUT_KEY),
          provenance: provenanceObj?.[CRC_INPUT_KEY],
        }}
        required
      />
      {currencyKeyDerivedMap[CRC_INPUT_KEY] &&
        subValueToParentMap[CRC_INPUT_KEY]?.map((subValue) => {
          const isLocked = !!propertyAttributeLocks?.attributeNames?.includes(subValue.name);
          return (
            <AttributeRow
              key={subValue.name}
              id={subValue.name}
              label={subValue.displayName}
              type={AttributeRowTypeEnum.currency}
              inputProps={{
                disabled: isLocked,
                prepend: <b>{effectiveCurrencyValue}</b>,
              }}
              tooltipContent={getTooltipMessage(false, isLocked)}
              provenance={{
                editInfo: getEditRecords(property, subValue.name),
                provenance: provenanceObj?.[subValue.name],
              }}
            />
          );
        })}
      <AttributeRow
        id={BIC_INPUT_KEY}
        label={BIC_LABEL}
        type={AttributeRowTypeEnum.currency}
        inputProps={{
          disabled: currencyKeyLockedMap[BIC_INPUT_KEY] || currencyKeyDerivedMap[BIC_INPUT_KEY],
          prepend: <b>{effectiveCurrencyValue}</b>,
        }}
        tooltipContent={getTooltipMessage(
          currencyKeyDerivedMap[BIC_INPUT_KEY],
          currencyKeyLockedMap[BIC_INPUT_KEY],
        )}
        provenance={{
          editInfo: getEditRecords(property, BIC_INPUT_KEY),
          provenance: provenanceObj?.[BIC_INPUT_KEY],
        }}
        required
      />
      {currencyKeyDerivedMap[BIC_INPUT_KEY] &&
        subValueToParentMap[BIC_INPUT_KEY]?.map((subValue) => {
          const isLocked = !!propertyAttributeLocks?.attributeNames?.includes(subValue.name);
          return (
            <AttributeRow
              key={subValue.name}
              id={subValue.name}
              label={subValue.displayName}
              type={AttributeRowTypeEnum.currency}
              inputProps={{
                disabled: isLocked,
                prepend: <b>{effectiveCurrencyValue}</b>,
              }}
              tooltipContent={getTooltipMessage(false, isLocked)}
              provenance={{
                editInfo: getEditRecords(property, subValue.name),
                provenance: provenanceObj?.[subValue.name],
              }}
            />
          );
        })}
    </EuiFlexGroup>
  );
};

import { createContext, ReactElement, useContext } from 'react';
import { useDecodedParams } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import useGetStreamData from '@app/cx/Reports/contexts/reportsContext/useGetStreamData';
import { ExposureLevel } from '../types';
import { ExposureIndexContext as IExposureIndexContext } from './types';
import useGetExposureIndex from './useGetExposureIndex';
import useGetExposureSnapshots from './useGetExposureSnapshots';

// ----- CONTEXT INITIALIZATION ----- //

export const initialExposureIndexState: IExposureIndexContext = {
  exposure: {
    allExposureRows: [],
    exposureGrade: ExposureLevel.Low,
    hasError: false,
    loading: false,
    mainExposureRows: [],
    noData: true,
    portfolioN: 0,
    rowGroups: {},
  },
  snapshots: {
    currentSnapshot: undefined,
    loading: false,
    setCurrentSnapshot: () => {},
    snapshotOptions: [],
    snapshots: [],
  },

  stream: {
    displayCurrency: undefined,
    hasError: false,
    loading: false,
    orgId: undefined,
    orgName: undefined,
    streamSlug: '',
  },
};

export const ExposureIndexContext = createContext<IExposureIndexContext>(initialExposureIndexState);

// ----- PROVIDER ----- //

export const ExposureIndexContextProvider = (props: object): ReactElement => {
  const { streamSlug } = useDecodedParams<{ streamSlug: string }>();
  const { account } = useAuth();
  const isAdmin = !!account?.permissions?.admin;
  const userCode = account?.userCode || '';

  const snapshotsData = useGetExposureSnapshots(streamSlug);
  const exposureData = useGetExposureIndex(
    streamSlug,
    isAdmin,
    userCode,
    snapshotsData?.currentSnapshotName,
  );
  const streamData = useGetStreamData(streamSlug, isAdmin);

  return (
    <ExposureIndexContext.Provider
      value={{
        exposure: { ...exposureData },
        snapshots: { ...snapshotsData },
        stream: { ...streamData },
      }}
      {...props}
    />
  );
};

// ----- USE CONTEXT HOOK ----- //

export const useExposureIndexContext = (): IExposureIndexContext =>
  useContext(ExposureIndexContext);

import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  EuiBasicTable,
  EuiPageHeader,
  EuiPanel,
  EuiText,
  EuiToolTip,
  Icon,
  Spacer,
  Toggle,
  useToast,
} from 'ui';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { useNotificationsApolloClient } from '@app/hooks/useNotificationsClient';
// FIX ME
// @ts-ignore
import GET_NOTIFICATIONS_METADATA from '@app/queries/organizations/getNotificationsMetadata.gql';
// FIX ME
// @ts-ignore
import UPDATE_NOTIFICATIONS_METADATA from '@app/queries/organizations/updateNotificationsMetadata.gql';
import { Helmet } from '../Helmet';
import LoadingSpinnerV2 from '../LoadingSpinnerV2/LoadingSpinnerV2';
import { makeNotificationItems } from './utils';

interface SettingsItem {
  displayName: string;
  notificationType: string;
  toggleFields: {
    enabled: boolean;
    enabledEditable: boolean;
  };
}

export const AccountSettings = () => {
  const toast = useToast();
  const { selectedOrganization } = useContext(UserSessionContext);

  const notificationsApolloClient = useNotificationsApolloClient();

  const [items, setItems] = useState<SettingsItem[]>([]);

  const { loading, data } = useQuery(GET_NOTIFICATIONS_METADATA, {
    client: notificationsApolloClient,
    variables: { input: { organizationName: selectedOrganization.name } },
  });

  const [updateNotificationsMetadata] = useMutation(UPDATE_NOTIFICATIONS_METADATA, {
    client: notificationsApolloClient,
  });

  useEffect(() => {
    const notificationItems = makeNotificationItems(
      data?.notificationsMetadata?.notifications || [],
    );

    if (notificationItems) {
      setItems(notificationItems);
    }
  }, [data]);

  const handleToggle = async (item) => {
    const notificationsArray = items.map((i) =>
      i === item
        ? {
            displayName: i.displayName,
            notificationType: i.notificationType,
            toggleFields: {
              enableEditable: i.toggleFields.enabledEditable,
              enabled: !i.toggleFields.enabled,
            },
          }
        : i,
    );

    const notificationsArrayAsInput = notificationsArray.map((i) => ({
      enabled: i.toggleFields.enabled,
      notificationType: i.notificationType,
    }));

    await updateNotificationsMetadata({
      variables: {
        input: {
          notifications: notificationsArrayAsInput,
          organizationName: selectedOrganization.name,
        },
      },
    }).then((r) => {
      const notificationItems = makeNotificationItems(
        r.data?.updateNotificationsMetadata?.notifications,
      );
      setItems(notificationItems);
      toast({ title: 'Successfully updated notification status', type: 'success' });
    });
  };

  const columns = [
    {
      field: 'displayName',
      name: 'Name',
    },
    {
      field: 'toggleFields',
      name: 'Active',
      render: (toggleFields, parentItem) => (
        <EuiText>
          <Toggle
            checked={toggleFields?.enabled}
            disabled={!toggleFields?.enabledEditable}
            onChange={() => handleToggle(parentItem)}
          />
          {!toggleFields?.enabledEditable && (
            <EuiToolTip
              position="top"
              content={<p>This alert is enabled by default, and cannot be changed</p>}
            >
              <Icon name="info" />
            </EuiToolTip>
          )}
        </EuiText>
      ),
    },
  ];

  if (loading) {
    return <LoadingSpinnerV2 />;
  }

  return (
    <EuiPanel paddingSize="l" hasBorder={false} hasShadow={false}>
      <Helmet title={'Alerts'} />
      <EuiPageHeader pageTitle={'Alerts'} />
      <Spacer />
      <Spacer />
      <EuiBasicTable columns={columns} items={items} />
    </EuiPanel>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Spacer = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var eui_1 = require("@elastic/eui");
var Spacer = function (_a) {
    var dataTestId = _a["data-testid"], _b = _a.size, size = _b === void 0 ? "l" : _b;
    return ((0, jsx_runtime_1.jsx)(eui_1.EuiSpacer, { "data-testid": dataTestId || "ui-atom-display-avatar", size: size }));
};
exports.Spacer = Spacer;

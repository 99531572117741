import { FC, useEffect, useState } from 'react';
import { ButtonGroup, Icon } from 'ui';
import { HandleChange, MODE } from './types';

const toggleButtonsIcons = [
  {
    iconType: () => <Icon data-testid="mode-button-grid" name="menu" size="l" />,
    id: MODE.GRID,
    label: 'Grid',
  },
  {
    iconType: () => <Icon data-testid="mode-button-gallery" name="image" size="l" />,
    id: MODE.GALLERY,
    label: 'Gallery',
  },
  {
    iconType: () => <Icon data-testid="mode-button-map" name="map" size="l" />,
    id: MODE.MAP,
    label: 'Map',
  },
];

interface Props {
  currentMode: MODE;
  handleModeChange: HandleChange;
}

const Modes: FC<Props> = ({ currentMode, handleModeChange }) => {
  const [mode, setMode] = useState<MODE>(currentMode);

  useEffect(() => {
    if (currentMode !== mode) {
      setMode(currentMode);
    }
  }, [currentMode]);

  const handleChange = (id: MODE) => {
    setMode(id);
    handleModeChange(id);
  };

  return (
    <ButtonGroup
      data-testid="mode-button"
      data-tourid="mode-button"
      size="m"
      idSelected={mode}
      iconOnly
      legend="Mode"
      onChange={handleChange}
      options={toggleButtonsIcons}
    />
  );
};

export default Modes;

import { useState } from 'react';
import * as React from 'react';
import { useQuery } from '@apollo/client';
import { IStream } from '@onarchipelago/cx/Stream/types';
import { getSnapshotsWithLabels } from '@onarchipelago/cx/Stream/utils';
import {
  IGetSnapshotConfigData,
  IGetSnapshotConfigVariables,
} from 'queries/streams/snapshots/types';
import { Button, EuiFlexGroup,EuiFlexItem, EuiSpacer, EuiText, Spacer } from 'ui';
import ErrorMessage from '@app/components/ErrorMessage/ErrorMessage';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import TakeSnapshotModal from '@app/dice/EnrichmentProjects/TakeSnapshotModal';
// FIX ME
// @ts-ignore
import GET_STREAM_SNAPSHOT_CONFIG from '@app/queries/streams/snapshots/getSnapshotConfig.gql';
import { MarketToolTip } from './MarketToolTip';
import SnapshotTable from './SnapshotTable';

interface IProps {
  stream: IStream;
  simplifiedStreamSetting: boolean;
}

const Swatch = ({ color }) => (
  <div
    style={{
      backgroundColor: color,
      border: '1px solid lightgrey',
      borderRadius: '4px',
      height: 12,
      marginRight: 2,
      width: 12,
    }}
  />
);

const SnapshotsTab: React.FC<IProps> = ({ simplifiedStreamSetting, stream }) => {
  const { loading, data, error, refetch } = useQuery<
    IGetSnapshotConfigData,
    IGetSnapshotConfigVariables
  >(GET_STREAM_SNAPSHOT_CONFIG, { variables: { streamSlug: stream.slug } });

  const [isOpen, setIsOpen] = useState(false);

  if (error) {
    return <ErrorMessage title="Could not get snapshot configuration" message={error.message} />;
  }
  if (loading || !data) {
    return <LoadingSpinnerV2 />;
  }

  const snapshots = getSnapshotsWithLabels(
    data?.snapshotConfig?.snapshots,
    stream.propertyStatusLabel,
  );

  const publishedSnapshotId = data?.snapshotConfig?.publishedSnapshotId;

  return (
    <>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup gutterSize="l" alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiText>
              <h3>Snapshots</h3>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup gutterSize="xs" alignItems="center">
              <EuiFlexItem grow={false}>
                <Swatch color={'rgb(197, 229, 300)'} />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText size="s">
                  <small>Market visible</small>
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <MarketToolTip />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          {isOpen && (
            <TakeSnapshotModal
              orgName={stream.orgName}
              refetchJobs={refetch}
              // FIX ME
              // @ts-ignore
              setModal={() => setIsOpen(false)}
            />
          )}
          <EuiFlexItem grow={false}>
            <Button
              data-testid="take-snapshot-button"
              size="s"
              color="primary"
              onClick={() => {
                setIsOpen(true);
              }}
              label="Create New Snapshot"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <SnapshotTable
          stream={stream}
          simplifiedStreamSetting={simplifiedStreamSetting}
          snapshots={snapshots}
          snapshotInfo={data?.snapshotConfig?.snapshotsInfo || []}
          publishedSnapshotId={publishedSnapshotId}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <Spacer />
      </EuiFlexItem>
    </>
  );
};

export default SnapshotsTab;

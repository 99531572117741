// @ts-ignore
import { FC, useContext, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import get from 'lodash/get';
import { Button, Checkbox, EuiFlexGroup, EuiFlexItem, TextField } from 'ui';
import { FilterQuery } from '@app/components/Filter/types';
import { convertRuleGroupToFilters } from '@app/components/Filter/utils';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import QueryBuilderWrapper from '@app/components/QueryBuilderWrapper/QueryBuilderWrapper';
import { getFilters, PropertyAttributeMetadata } from '@app/components/QueryBuilderWrapper/utils';
import { UserSessionContext } from '@app/contexts/UserSessionContext';
import { GET_SHARED_DOC_PROPERTIES } from '@app/queries/provenance/getSharedDocProperties';
import { FormFooter, FormHeader } from './Components';

export const Label = styled.span`
  display: block;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 234px;
`;

export const SectionLabel = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
  white-space: nowrap;
  padding-left: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 700;
  max-width: 100%;
  min-height: 30px;
  margin-bottom: -8px;
  background-color: #fafbfd;
  border-top: 1px solid #e0e6eb;
  border-bottom: 1px solid #e0e6eb;
  color: #343741;
  text-transform: uppercase;
`;

function VirtualizedWrapper({
  items,
  hasNextPage,
  isNextPageLoading,
  loadNextPage,
  RenderItem,
  itemHeight,
  searchText,
  isPrimaryProperty,
  isSecondaryList,
  sharedProperties,
}: any) {
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items?.length + 1 : items?.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  // Render an item or a loading indicator.
  const Item = ({ index, style }: { index: number; style: any }) => {
    let content;
    if (!isItemLoaded(index)) {
      content = <LoadingSpinnerV2 />;
    } else {
      content = (
        <RenderItem
          item={items[index]}
          key={items[index]?.id}
          isPrimaryProperty={isPrimaryProperty}
          isSecondaryList={isSecondaryList}
          sharedPropertyInfo={sharedProperties?.[index]}
        />
      );
    }

    return <div style={style}>{content}</div>;
  };

  const itemSize = (index: number) => itemHeight(items[index]);
  const key = isNextPageLoading ? itemCount : searchText;
  return (
    <div
      className="propFlyout"
      style={{
        flex: '1 1 auto',
        height: isPrimaryProperty ? '120px' : '100%',
        margin: '12px 0px',
        position: 'relative',
      }}
    >
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            threshold={15}
          >
            {({ onItemsRendered, ref }) => (
              <VariableSizeList
                itemCount={itemCount}
                onItemsRendered={onItemsRendered}
                ref={ref}
                height={height}
                width={width}
                itemSize={itemSize}
                key={key}
              >
                {Item}
              </VariableSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  );
}

interface IProps {
  relationship: string;
  query: any;
  item: any;
  dataPath: string;
  hasMore: boolean;
  formHeader: any;
  formFooter: any;
  itemHeight: (item: any) => number;
  selectedItems: any;
  onlyShowSelected?: boolean;
  type?: string;
  renderOutside?: boolean;
  renderLabel?: () => JSX.Element;
  filterable?: Array<PropertyAttributeMetadata>;
  onQueryRun?: (filters: any) => void;
  updateSelectedProperties: any;
}

const ProvenancePropertySelect: FC<IProps> = ({
  relationship,
  query,
  item,
  dataPath,
  hasMore,
  formHeader,
  formFooter,
  itemHeight,
  selectedItems,
  onlyShowSelected,
  filterable,
  onQueryRun,
  updateSelectedProperties,
}) => {
  const { selectedOrganization } = useContext(UserSessionContext);
  const [sharedDocumentSearch, setSharedDocumentSearch] = useState('');

  const orgName = selectedOrganization?.name;
  const pageSize = 10000;
  const fullPage = get(query, dataPath, []);
  const isPropertySelect = relationship === 'properties';

  const [filtersEnabled, setFiltersEnabled] = useState<boolean>(false);
  const [localFilterQuery, setLocalFilterQuery] = useState<FilterQuery | undefined>();

  const selectAll: boolean = selectedItems.length <= 1;
  const mainPropertyId = selectedItems[0]?.archipelagoId;

  // @ts-ignore
  const { data: sharedDocPropertyData } = useQuery<any, ISharedDocumentPropertyVariables>(
    GET_SHARED_DOC_PROPERTIES,
    {
      skip: !mainPropertyId,
      variables: {
        input: {
          maxResults: pageSize,
          orgName,
          propertyID: mainPropertyId,
          resultsStartIndex: 0,
        },
      },
    },
  );

  const toggleFiltersEnabled = () => {
    setFiltersEnabled(!filtersEnabled);
  };

  const handleOnQueryChange = (filterQuery: FilterQuery) => setLocalFilterQuery(filterQuery);

  const filters = getFilters(filterable || []);

  const handleRunQuery = () => {
    const booleanFilters = ['enriched', 'multiTenant'];
    const formattedFilters = convertRuleGroupToFilters(localFilterQuery);

    formattedFilters.forEach((filter) => {
      if (booleanFilters.includes(filter.name)) {
        switch (filter.values[0]) {
          case 'Yes': {
            filter.values[0] = 'true';
            break;
          }

          case 'No': {
            filter.values[0] = 'false';
            break;
          }
        }
      }
    });

    if (onQueryRun) {
      onQueryRun(formattedFilters);
      toggleFiltersEnabled();
    }
  };

  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      updateSelectedProperties([...selectedItems, ...filteredSharedProperties]);
    } else {
      updateSelectedProperties(selectedItems.filter((prop) => prop.__typename === 'Property'));
    }
  };

  const propertyFilters = (
    <>
      <EuiFlexGroup justifyContent="spaceBetween" gutterSize="xl">
        <EuiFlexItem grow={false}>
          <Button
            fill
            size="s"
            onClick={toggleFiltersEnabled}
            iconName="filter"
            label="Add properties matching..."
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <Button size="s" onClick={handleRunQuery} label="Run" />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup gutterSize="xl">
        <EuiFlexItem>
          <QueryBuilderWrapper
            query={localFilterQuery}
            onQueryChange={handleOnQueryChange}
            filters={filters}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );

  const sharedProperties = sharedDocPropertyData?.propertiesWithSharedDocuments?.properties || [];
  const filteredSharedProperties = sharedProperties.filter((prop) =>
    sharedDocumentSearch?.length > 0
      ? prop.locationName?.match(new RegExp(`${sharedDocumentSearch}`, 'i'), 'i')
      : prop,
  );

  return (
    <>
      <FormHeader
        data-testid={'provenance-property-select'}
        style={{ margin: '24px 24px 0px 24px', width: 'initial' }}
      >
        {isPropertySelect && filtersEnabled === false && (
          <Button
            size="s"
            fullWidth
            onClick={toggleFiltersEnabled}
            iconName="filter"
            label="Show only properties matching..."
          />
        )}
        {filtersEnabled && propertyFilters}
        {formHeader}
      </FormHeader>
      {query?.loading && <LoadingSpinnerV2 />}
      <div>
        <SectionLabel>
          {selectedItems.length === 0 ? 'Select Property' : 'Primary Property'}
        </SectionLabel>
      </div>
      <VirtualizedWrapper
        // eslint-disable-next-line no-constant-binary-expression
        items={selectedItems.length === 0 ? fullPage : [selectedItems[0]] || []}
        hasNextPage={false}
        isNextPageLoading={false}
        loadNextPage={null}
        RenderItem={item}
        itemHeight={itemHeight}
        isPrimaryProperty={selectedItems.length !== 0}
      />
      {sharedProperties.length > 0 && (
        <>
          <SectionLabel>Shared Document Properties</SectionLabel>
          <div style={{ margin: '24px 24px 0px' }}>
            <TextField
              fullWidth
              disabled={onlyShowSelected}
              iconName="search"
              value={sharedDocumentSearch}
              onChange={(e) => {
                setSharedDocumentSearch(e.target.value);
              }}
            />
          </div>
          <div style={{ margin: '16px' }}>
            <Checkbox
              data-testid={'select-all-shared-properties-checkbox'}
              label={selectAll ? 'Select all properties' : 'Deselect all properties'}
              checked={!selectAll}
              id={'select-all-shared-properties'}
              onChange={handleSelectAllClick}
            />
          </div>
          <VirtualizedWrapper
            items={filteredSharedProperties}
            hasNextPage={hasMore}
            isNextPageLoading={query?.loading}
            loadNextPage={query?.loadMore}
            RenderItem={item}
            itemHeight={itemHeight}
            searchText={query?.searchText}
            isSecondaryList={true}
            sharedProperties={filteredSharedProperties}
          />
        </>
      )}
      {formFooter && <FormFooter>{formFooter}</FormFooter>}
    </>
  );
};
export default ProvenancePropertySelect;

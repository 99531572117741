import { FC, useState } from 'react';
import { RuleType } from 'react-querybuilder';
import { Button } from 'ui';
import { usePagination } from '@app/contexts/PaginationContext/PaginationContext';
import { usePropertiesPageContext } from '@app/cx/Properties/PropertiesPage.context';
import { TrackStreamPage, useTracker } from '@app/hooks/useTracker';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import FilterModal from './FilterModal/FilterModal';
import { FilterQuery } from './types';

interface Props {
  filterQuery: FilterQuery | undefined;
  handleChange: (ruleGroup: FilterQuery | undefined) => void;
  filterable: Array<IGraphQLAttributeMetadata>;
}

const FilterButton: FC<Props> = ({ filterQuery, handleChange, filterable }) => {
  const [localFilterQuery, setLocalFilterQuery] = useState<FilterQuery | undefined>(filterQuery);
  const [showModal, setShowModal] = useState<boolean>(false);
  const tracker = useTracker();
  const { setPagination } = usePagination();

  const { stream } = usePropertiesPageContext();

  const handleClearClick = () => {
    setShowModal(false);
    handleChange(undefined);
    setLocalFilterQuery(undefined);
  };

  const handleApplyClick = () => {
    handleChange(localFilterQuery);
    tracker.track(`${TrackStreamPage.prefix}: Filter applied`, {
      event_surface: TrackStreamPage.event_surface,
      filter_attributes: localFilterQuery.rules.map((rule: RuleType) => rule.field),
      filter_length: localFilterQuery.rules.length,
      filter_operators: localFilterQuery.rules.map((rule: RuleType) => rule.operator),
      filter_values: localFilterQuery.rules.map((rule: RuleType) => rule.value),
      organization_name: stream.orgName,
      stream_name: stream.name,
      stream_slug: stream.slug,
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setPagination && setPagination((pagination) => ({ ...pagination, pageIndex: 0 }));
    setShowModal(false);
  };

  const handleClose = () => {
    // If a user closes the modal without applying any new rule changes, then reset the filter
    // rules to what is in the query string.
    setLocalFilterQuery(filterQuery);
    setShowModal(false);
  };
  return (
    <>
      <Button
        data-testid="distiller-filter-button"
        label={filterQuery ? `Filters (${filterQuery?.rules?.length || ''})` : 'Filters'}
        onClick={() => setShowModal(true)}
        size={'m'}
      />
      {showModal && (
        <FilterModal
          filterable={filterable}
          onApply={handleApplyClick}
          onClear={handleClearClick}
          onClose={handleClose}
          onQueryChange={setLocalFilterQuery}
          query={localFilterQuery}
        />
      )}
    </>
  );
};

export default FilterButton;

import { useEffect } from 'react';
import * as React from 'react';
import { useQuery } from '@apollo/client';
import AttributeGroupItem from '@onarchipelago/dice/EnrichmentProjects/AttributeGroupItem';
import AttributeItem from '@onarchipelago/dice/EnrichmentProjects/AttributeItem';
import SelectAttributesFlyout from '@onarchipelago/dice/EnrichmentProjects/SelectAttributesFlyout';
import { AnimatedPanel } from '@app/components/Flyout/AnimatedPanel';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import { sendMixpanelEvent } from '@app/components/PropertiesDataGrid/utils';
import { useTracker } from '@app/hooks/useTracker';
// FIX ME
// @ts-ignore
import GET_LOSS_ATTRIBUTE_METADATA from '@app/queries/lossMetadata/getLossAttributeMetadata.gql';
import { EJobType } from '@app/queries/organizations/types';
// FIX ME
// @ts-ignore
import GET_PROPERTY_ATTRIBUTE_METADATA from '@app/queries/propertyMetadata/getPropertyAttributeMetadata.gql';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import { isFeatureEnabled } from '@app/utils/FeatureFlags/FeatureFlags';

interface Props {
  jobType: EJobType;
  handleClose: () => void;
  handleUpdateSelectedAttributes: (updatedAttributes: IGraphQLAttributeMetadata[]) => void;
  streamSlug: string;
  stream?: IGraphQLStream;
  selectedAttributes: IGraphQLAttributeMetadata[];
}

const AttributePicker: React.FC<Props> = ({
  jobType,
  handleClose,
  handleUpdateSelectedAttributes,
  selectedAttributes,
  streamSlug,
  stream,
}) => {
  const [propertyAttributeMetadata, setPropertyAttributeMetadata] = React.useState<any[]>([]);
  const tracker = useTracker();
  const isEditLosses = jobType === EJobType.EditLosses;
  const query = isEditLosses ? GET_LOSS_ATTRIBUTE_METADATA : GET_PROPERTY_ATTRIBUTE_METADATA;

  const { data, loading } = useQuery(query, {
    variables: {
      streamSlug,
    },
  });

  useEffect(() => {
    setPropertyAttributeMetadata(
      isEditLosses ? data?.lossAttributeMetadata || [] : data?.propertyAttributeMetadata || [],
    );
  }, [data]);

  // Only attributes that are not derived can be edited. (for now)

  const attributeItem = (renderedRow: any) => (
    <AttributeItem
      item={renderedRow.item}
      selectedAttributes={selectedAttributes}
      updateSelectedAttributes={handleUpdateSelectedAttributes}
    />
  );

  const attributeGroupItem = (renderedRow: any) => (
    <AttributeGroupItem
      item={renderedRow.item}
      selectedAttributes={selectedAttributes}
      updateSelectedAttributes={handleUpdateSelectedAttributes}
    />
  );

  const selectableAttributes = propertyAttributeMetadata?.filter((m) => !m.derived) || [];
  const handleSelectAll = (): void => {
    sendMixpanelEvent(
      tracker,
      `Edit properties with an Excel File: ${
        selectedAttributes.length > 0 ? 'deselect' : 'select'
      } all`,
      stream,
    );

    if (selectedAttributes.length > 0) {
      handleUpdateSelectedAttributes([]);
    } else {
      handleUpdateSelectedAttributes(selectableAttributes);
    }
  };

  return (
    <AnimatedPanel type="secondary">
      {loading ? (
        <LoadingSpinnerV2 dataTestId="attribute-picker-loading" />
      ) : (
        <SelectAttributesFlyout
          handleSelectAll={handleSelectAll}
          hide={handleClose}
          list={propertyAttributeMetadata}
          selectedItems={selectedAttributes}
          rowComponent={attributeItem}
          groupRowComponent={attributeGroupItem}
        />
      )}
    </AnimatedPanel>
  );
};

export default AttributePicker;

import { useContext } from 'react';
import * as React from 'react';
import { useStreamContext } from '@onarchipelago/cx/Stream/StreamProvider';
import { ButtonEmpty,EuiFlexItem } from 'ui';
import { ModalContext } from '@app/contexts/ModalContext';
import InviteStreamModal from './InviteModal/InviteStreamModal';

interface Props {
  show?: boolean;
}

const ShareStreamButton: React.FC<Props> = ({ show }) => {
  const { stream } = useStreamContext();
  const { showModal } = useContext(ModalContext);

  if (show) {
    return (
      <EuiFlexItem data-testid="share-stream-button" grow={false}>
        <ButtonEmpty
          onClick={() => showModal(<InviteStreamModal stream={stream} />)}
          size="s"
          label="Share"
        />
      </EuiFlexItem>
    );
  }

  return null;
};

export default ShareStreamButton;

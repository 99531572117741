import * as React from 'react';
import { DataError, DataErrorType, JobErrors } from '@onarchipelago/dice/EnrichmentProjects/types';
import { CallOut, EuiDescriptionList, EuiText, Spacer } from 'ui';
import { EJobStatus } from '@app/queries/organizations/types';

interface Props {
  jobErrors: JobErrors;
}

const getErrorDetails = (e: DataError) => ({
  description: e.message,
  title:
    e.type === DataErrorType.DuplicateClientID || e.type === DataErrorType.InvalidPropertyIDs
      ? e.location
      : e.columnHeader,
});

const JobErrorsCallout: React.FC<Props> = ({ jobErrors: { dataErrors, systemErrors } }) => (
    <>
      {dataErrors?.length > 0 && (
        <CallOut
          data-testid="edit-data-error-message-validation-issues"
          type="danger"
          title={`We found ${dataErrors.length} validation error${
            dataErrors.length > 1 ? 's' : ''
          }.`}
        >
          <EuiText>
            Some of the values in your worksheet aren&#39;t valid. Please fix the following issues
            &amp; re-upload your edits:
          </EuiText>
          <>
            <Spacer />
            <EuiDescriptionList type="column" listItems={dataErrors.map(getErrorDetails)} />
          </>
        </CallOut>
      )}
      {(systemErrors?.length > 0 || !dataErrors) && (
        <CallOut data-testid="edit-data-error-message-internal-error" type="danger" title={'Error'}>
          <EuiText>
            We&#39;re not able to process your worksheet — it may be corrupted or in a format we
            can&#39;t read. Please download a new copy &amp; try again.
          </EuiText>
        </CallOut>
      )}
    </>
  );
export default JobErrorsCallout;

import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { ApolloError, ApolloQueryResult, useQuery } from '@apollo/client';
import keyBy from 'lodash/keyBy';
import { useDecodedParams } from '@app/containers/App/Routes/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { StreamPropertiesPageQuery } from '@app/graphql/queries/streams/__generated__/StreamPropertiesPage.generated';
import { Exact, StreamPropertiesPageInput } from '@app/graphql/types';
import useStandardQuery from '@app/hooks/useStandardQuery';
import { IGraphQLPropertyLock } from '@app/queries/properties/PropertyQuery/types';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
// FIX ME
// @ts-ignore
import GET_STREAM from '@app/queries/streams/getStream.gql';
// FIX ME
// @ts-ignore
import GET_STREAMS from '@app/queries/streams/getStreams.gql';
import {
  IGetPropertyGroupsData,
  IGetPropertyGroupsVariables,
} from '@app/queries/streams/PropertyGroupsQuery/types';
import {
  GetStreamData,
  GetStreamsResponse,
  GetStreamV2Variables,
  IGraphQLStream,
  StreamPermissions,
} from '@app/queries/streams/types';
import { FormatCurrency as _FormatCurrency } from '@app/utils/format';
import { STREAM_VIEWER_PERMISSIONS } from '../Properties/constants';
import { formatStreamCurrency as _formatStreamCurrency, usePageState } from './utils';

export type FormatCurrency = _FormatCurrency;
export interface MetadataMap {
  [key: string]: IGraphQLAttributeMetadata;
}
export interface IStreamContext {
  error: ApolloError | undefined;
  loading?: any;
  stream?: IGraphQLStream;
  refetch?: (latest?: boolean) => Promise<ApolloQueryResult<GetStreamData>>;
  refetchDiscussions?: () => void;
  propertyAttributeMetadata: Array<IGraphQLAttributeMetadata>;
  metadataMap: MetadataMap;
  formatStreamCurrency?: FormatCurrency;
  streamMeasurementAreaUnit: 'ft²' | 'm²';
  selectedProperties?: any[];
  setSelectedProperties?: Dispatch<SetStateAction<any[]>>;
  permissions?: StreamPermissions;
  lock?: IGraphQLPropertyLock;
  isLatestSnapshot?: boolean;
  refetchPropertiesDataGrid?: (
    variables?: Partial<
      Exact<{
        input: StreamPropertiesPageInput;
      }>
    >,
  ) => Promise<ApolloQueryResult<StreamPropertiesPageQuery>>;
  setRefetchPropertiesDataGrid?: React.Dispatch<
    React.SetStateAction<
      (
        variables?: Partial<
          Exact<{
            input: StreamPropertiesPageInput;
          }>
        >,
      ) => Promise<ApolloQueryResult<StreamPropertiesPageQuery>>
    >
  >;
  refetchPropertyGroups?: (
    variables?: IGetPropertyGroupsVariables,
  ) => Promise<ApolloQueryResult<IGetPropertyGroupsData>>;
  setRefetchPropertyGroups?: React.Dispatch<
    React.SetStateAction<
      (
        variables?: IGetPropertyGroupsVariables,
      ) => Promise<ApolloQueryResult<IGetPropertyGroupsData>>
    >
  >;
  showmarketVisibleToggle: boolean;
  handleSetMarketVisible: (boolean) => void;
  marketVisibleToggle: boolean;
  marketVisibleSnapshots: IGraphQLStream['snapshots'];
  isNoSOV: boolean;
}

export const StreamContext = React.createContext<IStreamContext>({
  error: undefined,
  metadataMap: {},
  propertyAttributeMetadata: [],

  streamMeasurementAreaUnit: 'ft²',
} as IStreamContext);
export const useStreamContext = () => useContext(StreamContext);

interface IProps {
  children: JSX.Element | string | null | undefined | boolean;
}

interface IRouteProps {
  streamSlug: string;
  organizationName: string;
}

export const StreamProvider: React.FC<IProps> = ({ children }) => {
  const { account, refresh: refreshAccount } = useAuth();
  const { selectedOrganization, setOrgByName } = useUserSession();
  const [customLoading, setCustomLoading] = useState<boolean>(true);
  const [streamV2, setStreamV2] = useState<GetStreamData['streamV2']>();
  const [selectedProperties, setSelectedProperties] = useState([]);
  // TODO https://app.shortcut.com/onarchipelago/story/169739/look-into-underscore-variable-on-the-usepagestate
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPageState] = usePageState();

  const orgPermissions = getOrgPermissions(account, selectedOrganization?.id);
  const canManageProperties = orgPermissions?.includes('canManageProperties');

  const showmarketVisibleToggle =
    canManageProperties || (!canManageProperties && account.permissions?.admin);

  const [marketVisibleToggle, setMarketVisibleToggle] = useState(false);

  const [propertyAttributeMetadata, setPropertyAttributeMetadata] = useState<
    Array<IGraphQLAttributeMetadata>
  >([]);
  const [refetchPropertiesDataGrid, setRefetchPropertiesDataGrid] = useState<
    (
      variables?: Partial<
        Exact<{
          input: StreamPropertiesPageInput;
        }>
      >,
    ) => Promise<ApolloQueryResult<StreamPropertiesPageQuery>>
  >();
  const [refetchPropertyGroups, setRefetchPropertyGroups] =
    useState<
      (
        variables?: IGetPropertyGroupsVariables,
      ) => Promise<ApolloQueryResult<IGetPropertyGroupsData>>
    >();
  const { streamSlug, organizationName } = useDecodedParams<IRouteProps>();
  const isAdmin = account?.permissions?.admin || false;

  const [pageState] = usePageState();

  const getMyPropertiesStream = (streams: Array<IGraphQLStream>): IGraphQLStream | undefined =>
    (streams || []).find((v) => v.isMyProperties && v.orgName === organizationName);
  const getSlug = (streams: Array<IGraphQLStream>): string | undefined => {
    if (streamSlug) {
      return streamSlug;
    }
    return getMyPropertiesStream(streams || [])?.slug;
  };

  // FIXME: Don't query for all the streams. Query only for the requested stream.

  // added some logic that it does not query for all streams when there's a slug present.
  const streamsQueryResult = useStandardQuery<
    GetStreamsResponse,
    {
      orgName: string | null;
    }
  >(
    GET_STREAMS,
    {
      fetchPolicy: 'cache-first',
      skip: !!streamSlug || organizationName === undefined,
      variables: {
        orgName: organizationName,
      },
    },
    {
      errorMessage: (err) => err?.message,
    },
  );

  const loadingStreams = streamsQueryResult.loading;
  const errorStreams = streamsQueryResult.error;
  const dataStreams = streamsQueryResult.data;

  const { data, loading, error, refetch } = useQuery<GetStreamData, GetStreamV2Variables>(
    GET_STREAM,
    {
      onCompleted: () => {
        refreshAccount();
      },
      onError: () => {
        setCustomLoading(false);
      },

      skip: !streamSlug && !dataStreams,
      variables: {
        isAdmin,
        slug: getSlug(dataStreams?.streamsV2?.streams || []) || '',
        snapshot: pageState.currentSnapshot,
        userCode: account?.userCode || '',
      },
    },
  );

  useEffect(() => {
    const orgName = data?.streamV2?.stream?.orgName;
    if (!orgName || !account?.managedOrgs?.find((o) => o.org.name === orgName)) {
      return;
    }

    setOrgByName(orgName);
  }, [JSON.stringify(data?.streamV2), JSON.stringify(account?.managedOrgs)]);

  const dataOneStream = data;
  const loadingOneStream = loading;
  const errorStream = error;

  const re = (latest: boolean = false) =>
    refetch({
      isAdmin,
      slug: getSlug(dataStreams?.streamsV2?.streams || []) || '',
      snapshot: latest ? null : pageState.currentSnapshot,
      userCode: account?.userCode || '',
    });

  useEffect(() => {
    if (!errorStream && dataOneStream) {
      if (dataOneStream?.streamV2?.stream) {
        setPropertyAttributeMetadata(dataOneStream.propertyAttributeMetadataV2);
        setStreamV2(dataOneStream?.streamV2);

        if (streamSlug && !loadingOneStream) {
          setCustomLoading(false);
        } else if (!loadingStreams && !loadingOneStream) {
          setCustomLoading(false);
        }
      }
    }
  }, [loadingOneStream, errorStream, dataOneStream]);

  const formatStreamCurrency: FormatCurrency = (val, options) =>
    _formatStreamCurrency(val, streamV2?.stream?.displayCurrency || 'USD', options);

  const getStreamMeasurementAreaUnit = () => {
    if (streamV2?.stream?.streamSettings?.unitsOfMeasurement === 'imperial') {
      return 'ft²';
    }
    return 'm²';
  };

  const marketVisibleSnapshots =
    // FIX ME
    // @ts-ignore
    streamV2?.stream?.snapshots?.filter((snap) => snap.marketVisible) || [];

  useEffect(() => {
    if (marketVisibleToggle) {
      setPageState({ currentSnapshot: marketVisibleSnapshots[0]?.name });
    } else {
      setPageState({ currentSnapshot: null });
    }
  }, [marketVisibleToggle]);

  const handleSetMarketVisible = (value: boolean) => {
    if (value) {
      setPageState({ currentSnapshot: marketVisibleSnapshots[0]?.name });
    } else {
      setPageState({ currentSnapshot: null });
    }

    setMarketVisibleToggle(value);
  };

  const reportsEnabled = !!streamV2?.stream?.allowedReports?.length;

  let permissions = {
    ...streamV2?.permissions,
    canViewReports: reportsEnabled,
  };

  if (showmarketVisibleToggle && marketVisibleToggle === true) {
    // FIX ME
    // @ts-ignore
    permissions = STREAM_VIEWER_PERMISSIONS;
  }

  return (
    <StreamContext.Provider
      value={{
        error: errorStreams || errorStream,
        formatStreamCurrency: formatStreamCurrency,
        handleSetMarketVisible,
        isLatestSnapshot: !pageState.currentSnapshot,
        isNoSOV:
          !customLoading &&
          (!streamV2?.stream ||
            (streamV2?.stream &&
              streamV2?.stream.isMyProperties &&
              !streamV2?.stream.propertiesCount)),
        loading: customLoading,
        lock: streamV2?.lock,
        marketVisibleSnapshots,

        marketVisibleToggle,
        metadataMap: keyBy(propertyAttributeMetadata, 'name'),
        permissions,
        propertyAttributeMetadata,
        refetch: re,
        refetchPropertiesDataGrid,
        refetchPropertyGroups,
        selectedProperties,
        setRefetchPropertiesDataGrid,
        setRefetchPropertyGroups,
        setSelectedProperties,
        showmarketVisibleToggle,
        stream: streamV2?.stream
          ? {
              ...streamV2?.stream,
              displayCurrency: streamV2?.stream?.displayCurrency || 'USD',
            }
          : undefined,
        streamMeasurementAreaUnit: getStreamMeasurementAreaUnit(),
      }}
    >
      {children}
    </StreamContext.Provider>
  );
};

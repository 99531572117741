import { FC, useEffect, useState } from 'react';
import ReactTour from 'reactour';
import { ClassNames } from '@emotion/react';
import { IGraphQLStream } from '@app/queries/streams/types';
import { Container } from './Tour.emotion';
import { IStep } from './types';
import { getSteps } from './utils';

interface Window {
  MSInputMethodContext?: any;
}

interface Document {
  documentMode?: any;
}

declare const window: Window;
declare const document: Document;

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

const maskClassName = `
  label: MaskClassName;
  color: #4f4f4f;
  opacity: 0.2;

  > defs {
    ${isIE11 ? 'display: inline !important' : ''}
  }
`;

const ACCENT_COLOR = '#2D9CDB';

interface IProps {
  stream?: IGraphQLStream;
  step?: number;
  steps: Array<IStep>;
  open: boolean;
  handleOpen?: (step: number) => void;
  handleClose: () => void;
  handleStepChange?: (step: number) => void;
}

const parseStep = (step: undefined | number, steps: Array<IStep>): number => {
  if (step === undefined || step < 0 || step > steps.length) {
    return 0;
  }

  return step;
};

const Tour: FC<IProps> = ({
  stream,
  step,
  steps,
  open,
  handleOpen,
  handleClose,
  handleStepChange,
}) => {
  const [mounted, setMounted] = useState(false);
  const [currentStep, setCurrentStep] = useState(parseStep(step, steps));

  const goToNext = (next: number) => {
    setCurrentStep(() => next + 1);
  };

  useEffect(() => {
    if (handleStepChange) {
      handleStepChange(Math.floor(currentStep / 2));
    }
  }, [currentStep]);

  useEffect(() => {
    const parsedStep = parseStep(step, steps);
    if (step && parsedStep !== Math.floor(currentStep / 2)) {
      setCurrentStep(parsedStep * 2);
    }
  }, [step]);

  const disabled = currentStep % 2 === 0 || steps.length === 1;

  useEffect(() => {
    const timer = setTimeout(() => {
      setMounted(true);
    }, 10);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (mounted === false) {
    return null;
  }

  return (
    <Container>
      <ClassNames>
        {({ css }: any) => (
          <ReactTour
            className={css`
              max-width: 648px;
              min-width: 488px;
              color: white;
              background-color: black;
              border-radius: 10px;
              opacity: 1;

              // Override the core reactour transition
              transition: all 0s;

              .reactour__dot:nth-child(2n) {
                display: none;
              }

              .reactour__dot:nth-child(${currentStep}) {
                color: ${ACCENT_COLOR};
                background: ${ACCENT_COLOR};
              }

              ${steps.length <= 1
                ? `
              div[data-tour-elem="controls"] {
                display: none;
              }
              `
                : ''}
            `}
            badgeContent={(curr) => Math.ceil(curr / 2)}
            getCurrentStep={setCurrentStep}
            onAfterOpen={() => {
              if (handleOpen) {
                handleOpen(Math.floor(currentStep / 2));
              }
            }}
            startAt={currentStep}
            goToStep={currentStep}
            prevStep={() => {
              setCurrentStep((previousStep) => previousStep - 3);
            }}
            onRequestClose={() => {
              setCurrentStep((previousStep) => {
                const nextStep = Math.floor(previousStep / 2);
                if (nextStep === steps.length - 1) {
                  return 0;
                }
                return nextStep * 2;
              });
              handleClose();
            }}
            disableDotsNavigation={disabled}
            disableKeyboardNavigation={disabled}
            showButtons={!disabled}
            accentColor={ACCENT_COLOR}
            maskClassName={css`
              ${maskClassName}
            `}
            steps={getSteps(steps, goToNext)}
            isOpen={open}
          />
        )}
      </ClassNames>
    </Container>
  );
};

export default Tour;

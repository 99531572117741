import { FC, useMemo } from 'react';
import { IDistiller, IDistillers } from '@onarchipelago/cx/Stream/types';
import moment from 'moment';
import { EuiFlexGroup, EuiFlexItem, EuiFormRow } from 'ui';
import FilterButton from '@app/components/Filter/FilterButton';
import { FilterQuery } from '@app/components/Filter/types';
import SnapshotButton from '@app/components/Snapshot/SnapshotButton';
import { groupSnapshotsByQuarter } from '@app/components/Snapshot/utils';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { IGraphQLAttributeMetadata } from '@app/queries/propertyMetadata/types';
import { IGraphQLStream, SnapshotTypeEnum } from '@app/queries/streams/types';
import ExportButton from '../ExportButton/ExportButton';
import { IPageState } from '../types';
import Distiller from './Distiller';
import styles from './Distillers.emotion';
import Modes from './Modes';
import { HandleChange, MODE } from './types';

interface Props {
  distillers: IDistillers;
  filterQuery: FilterQuery | undefined;
  filterable: Array<IGraphQLAttributeMetadata>;
  handleChange: (name: string, val?: string[]) => void;
  handleModeChange: HandleChange;
  onFilterQueryChange?: (filterQuery: FilterQuery | undefined) => void;
  pageState: IPageState;
  stream: IGraphQLStream;
}

interface IDistillerWithName extends IDistiller {
  name: string;
}

export const getVisibleDistillers = (distillers: IDistillers, currentMode: MODE) => {
  const distillersWithNames = Object.entries(distillers).map(([name, distiller]) => {
    const distillerWithName: IDistillerWithName = {
      ...distiller,
      name,
    };

    return distillerWithName;
  });

  return distillersWithNames
    .filter((distiller) => {
      if (distiller.visible) {
        return distiller.visible(currentMode);
      }

      return true;
    })
    .sort((a, b) => a.order - b.order);
};

const Distillers: FC<Props> = ({
  handleModeChange,
  handleChange,
  distillers,
  filterQuery,
  onFilterQueryChange,
  pageState,
  stream,
  filterable,
}) => {
  const { account } = useAuth();

  const handleFilterChange = (changedFilters: FilterQuery) => {
    if (onFilterQueryChange) {
      onFilterQueryChange(changedFilters);
    }
  };

  const groupedSnapshotsWithoutValuations = useMemo(
    () =>
      groupSnapshotsByQuarter(
        stream?.snapshots?.filter((snapshot) => snapshot.type !== SnapshotTypeEnum.VALUATION) || [],
        moment.unix(stream?.effectiveDate || 0),
        stream.propertyStatusLabel,
      ),
    [JSON.stringify(stream?.snapshots), stream?.effectiveDate, stream?.propertyStatusLabel],
  );

  const groupedSnapshotsWithoutSOVs = useMemo(
    () =>
      groupSnapshotsByQuarter(
        stream?.snapshots?.filter((snapshot) => snapshot.type === SnapshotTypeEnum.STANDARD) || [],
        moment.unix(stream?.effectiveDate || 0),
        stream.propertyStatusLabel,
      ),
    [JSON.stringify(stream?.snapshots), stream?.effectiveDate, stream?.propertyStatusLabel],
  );

  const getDefaultSnapshotId = () => {
    const defaultSnapshot = stream?.snapshots?.filter(
      (snapshot) => snapshot.name === stream.defaultSnapshot,
    );
    if (defaultSnapshot.length > 0) {
      return defaultSnapshot[0].id;
    }

    return null;
  };

  return (
    <EuiFlexGroup
      data-testid="distillers"
      justifyContent="spaceBetween"
      data-tourid="distillers"
      alignItems="flexEnd"
    >
      <EuiFlexItem>
        <EuiFlexGroup className={styles.container}>
          {groupedSnapshotsWithoutSOVs.length > 0 && (
            <EuiFlexItem>
              <EuiFormRow label="View data as of">
                <SnapshotButton
                  isAdmin={account?.permissions?.admin}
                  defaultSnapshot={getDefaultSnapshotId()}
                  snapshotType="current"
                  snapshots={groupedSnapshotsWithoutSOVs}
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
          {getVisibleDistillers(distillers, pageState.mode).map((distiller) => (
            <EuiFlexItem key={distiller.name}>
              <EuiFormRow label={distiller.name === 'groupBy' ? 'Pivot by' : 'Size by'}>
                <Distiller
                  distiller={distiller}
                  handleChange={(val) => handleChange(distiller.name, val)}
                  pageState={pageState}
                />
              </EuiFormRow>
            </EuiFlexItem>
          ))}
          {groupedSnapshotsWithoutValuations.length > 0 && (
            <EuiFlexItem>
              <EuiFormRow label="Changes since">
                <SnapshotButton
                  defaultSnapshot={stream.defaultSnapshot}
                  snapshotType="changesSince"
                  snapshots={groupedSnapshotsWithoutValuations}
                />
              </EuiFormRow>
            </EuiFlexItem>
          )}
          <EuiFlexItem grow={false}>
            <EuiFormRow hasEmptyLabelSpace>
              <FilterButton
                filterQuery={filterQuery}
                handleChange={handleFilterChange}
                filterable={filterable}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFormRow hasEmptyLabelSpace>
              <Modes currentMode={pageState.mode} handleModeChange={handleModeChange} />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <ExportButton distillers={distillers} />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default Distillers;

import { cloneElement, ReactElement, useState } from 'react';
import * as React from 'react';
import styled from '@emotion/styled';
import { EuiPopover } from 'ui';

const StyledPopover = styled(EuiPopover)`
  width: 100%;

  > div {
    width: 100%;
  }
`;
interface Props {
  dataTestId?: string;
  children: ReactElement;
  content: ReactElement;
}

const PropertiesGridPopover: React.FC<Props> = ({ dataTestId, children, content }) => {
  const [isContentHovered, setIsContentHovered] = useState<boolean>(false);
  const [isChildHovered, setIsChildHovered] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const handleMouseEnter = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const id = setTimeout(() => setIsChildHovered(true), 500);
    setTimeoutId(id);
  };

  const handleMouseLeave = () => {
    if (timeoutId) {
      // cancels any mouse enter events to prevent flickering of popover
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    // This timeout is needed to allow time to hover over the content
    setTimeout(() => setIsChildHovered(false), 500);
  };

  return (
    <StyledPopover
      data-testid={['properties-grid-popover', dataTestId].join(' ')}
      button={cloneElement(children, {
        onMouseLeave: handleMouseLeave,
        onMouseOver: handleMouseEnter,
        onScroll: handleMouseLeave,
      })}
      closePopover={() => setIsContentHovered(false)}
      isOpen={isContentHovered || isChildHovered}
    >
      <div
        onMouseOver={() => setIsContentHovered(true)}
        onMouseLeave={() => setIsContentHovered(false)}
      >
        {content}
      </div>
    </StyledPopover>
  );
};

export default PropertiesGridPopover;

import { EuiText } from 'ui';
import { TabContentGroup } from '../AddPropertyFlyout.emotion';
import { DocumentsUpload } from './DocumentsUpload';

export const DocumentsTab = ({ id, archipelagoId }) => (
    <TabContentGroup direction="column">
      <EuiText size="s" color="subdued">
        You can upload documents related to this property below. Please contact your Archipelago
        Client Service member to coordinate document enrichment.
      </EuiText>
      <DocumentsUpload id={id} archipelagoId={archipelagoId} />
    </TabContentGroup>
  );

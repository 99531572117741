import * as React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText, EuiTextColor } from 'ui';
import LoadingSpinnerV2 from '@app/components/LoadingSpinnerV2/LoadingSpinnerV2';
import ErrorState from '@app/cx/Reports/components/ErrorState';
import { useExposureIndexContext } from '../../context/exposureIndexContext';
import BenchmarksKey from './BenchmarksKey/BenchmarksKey';
import BenchmarksTable from './BenchmarksTable/BenchmarksTable';
import styles from './IndexBenchmarksCard.emotion';

const IndexBenchmarksCard: React.FC = () => {
  const { exposure, snapshots } = useExposureIndexContext();

  const rows = exposure.allExposureRows;
  const loading = exposure.loading;
  const error = exposure.hasError;

  if (!loading && error) {
    return <ErrorState />;
  }

  if (
    (rows.length === 0 ||
      (!snapshots.loading && snapshots.snapshotOptions.length === 0) ||
      exposure.noData) &&
    !loading
  ) {
    return <></>;
  }

  return (
    <EuiPanel data-testid="exposure-index-benchmarks-card" className={styles.panel}>
      {loading && !error ? (
        <LoadingSpinnerV2
          dataTestId="index-benchmarks-loading-spinner"
          styles={{ height: '386px' }}
        />
      ) : (
        <EuiFlexGroup direction="column">
          <EuiFlexItem grow={false}>
            <EuiText>
              <h4>Exposure benchmarks by peril</h4>
              <EuiText>
                <EuiTextColor color="subdued">
                  Risk awareness is important when formulating a risk management strategy. This
                  chart groups exposures by peril and identifies key drivers of risk.
                </EuiTextColor>
              </EuiText>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <BenchmarksKey />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <BenchmarksTable />
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </EuiPanel>
  );
};

export default IndexBenchmarksCard;

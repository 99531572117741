import * as React from 'react';
import { EuiFlexGroup, EuiFlexItem } from 'ui';
import { ExposureLevel } from '../../types';
import IndexSummaryKeyItem from './IndexSummaryKeyItem';

const IndexSummaryKey: React.FC = () => (
  <EuiFlexGroup data-testid="index-summary-key">
    <EuiFlexItem grow={false}>
      <IndexSummaryKeyItem exposureLevel={ExposureLevel.VeryLow} />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <IndexSummaryKeyItem exposureLevel={ExposureLevel.Low} />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <IndexSummaryKeyItem exposureLevel={ExposureLevel.Medium} />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <IndexSummaryKeyItem exposureLevel={ExposureLevel.High} />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <IndexSummaryKeyItem exposureLevel={ExposureLevel.VeryHigh} />
    </EuiFlexItem>
  </EuiFlexGroup>
);

export default IndexSummaryKey;

import { useContext } from 'react';
import * as React from 'react';
import { useMutation } from '@apollo/client';
import { EuiConfirmModal, useToast } from 'ui';
import { ModalContext } from '@app/contexts/ModalContext';
import { IGraphQLOrganization } from '@app/queries/organizations/types';
import { getErrorMessage } from '@app/utils/getErrorMessage';
import { RoleAssignmentsData } from '../RoleChecker/types';
import { IForm, REGISTER_USER } from './PreRegisterOrgModal';

interface Props {
  org?: IGraphQLOrganization;
  values: IForm;
  roleAssignmentData: RoleAssignmentsData;
}

const ConfirmUserOrgUpdateModal: React.FC<Props> = ({ org, values, roleAssignmentData }) => {
  const { closeModal } = useContext(ModalContext);
  const toast = useToast();

  const [registerUser] = useMutation(REGISTER_USER, {
    onCompleted: () => {
      toast({ title: 'Successfully updated user org', type: 'success' });
      closeModal();
    },
    onError: (err) => toast({ title: getErrorMessage(err), type: 'danger' }),
  });

  return (
    <EuiConfirmModal
      data-testid="update-role-confirm-modal"
      title="Update Primary Account"
      onCancel={closeModal}
      onConfirm={() => {
        registerUser({
          variables: {
            input: {
              connection: 'PASSWORDLESS',
              password: null,
              ...values,
              orgName: org?.name || null,
              sendEmail: false,
              streamSlugs: [],
            },
          },
        });
      }}
      cancelButtonText="Cancel"
      confirmButtonText="Update Primary Account"
      buttonColor="success"
      defaultFocusedButton="confirm"
    >
      <b>{values.email}</b> is already registered as a user with{' '}
      {roleAssignmentData?.roleAssignmentsV2.primaryOrgName}. Do you wish to update this user's
      primary account to {org?.name}?
    </EuiConfirmModal>
  );
};

export default ConfirmUserOrgUpdateModal;

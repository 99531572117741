import { useEffect, useState } from 'react';
import * as React from 'react';
import { useQuery } from '@apollo/client';
import { useReportsContext } from '@onarchipelago/cx/Reports/contexts/reportsContext/reportsContext';
import { GET_CARRIER_PROPERTY_ASSESSMENTS } from '@onarchipelago/cx/Reports/queries/getCarrierPropertyAssessments';
import {
  CarrierPropertyGradesVariables,
  GET_CARRIER_PROPERTY_GRADE,
} from '@onarchipelago/cx/Reports/queries/getCarrierPropertyGrades';
import { propertyGradeMetricCategories } from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/InsurerInsights/InsurerInsights';
import { CriteriaWithPagination, EuiBasicTable, Pagination } from 'ui';
import { useUnderwritingAssessmentReportContext } from '../../../../underwritingAssessmentReportContext';
import { GradingDetail, RiskAttribute } from '../types';
import { getColumns, getItems, Item } from './tableData';

const PAGE_SIZE = 6;

interface Props {
  riskAttribute: RiskAttribute;
}
const PropertiesToPrioritize: React.FC<Props> = ({ riskAttribute }) => {
  const { stream } = useReportsContext();

  const { gradingDetailsFilter } = useUnderwritingAssessmentReportContext();
  const [pageIndex, setPageIndex] = useState<number>(0);

  const isGrade = riskAttribute.type === GradingDetail.Grade;

  const query = isGrade ? GET_CARRIER_PROPERTY_GRADE : GET_CARRIER_PROPERTY_ASSESSMENTS;

  // Grades requires an array of strings. When filtering out categories we
  // still need to send an array, so we check if there are filtered categories
  // and format properly
  const hasGradedFilteredCategories = !!gradingDetailsFilter?.length;
  const gradeCategories = hasGradedFilteredCategories
    ? [gradingDetailsFilter]
    : propertyGradeMetricCategories;

  // Grades use `categories`, Property assessments `category`
  const categoryOps = isGrade
    ? {
        categories: gradeCategories,
      }
    : {
        category: gradingDetailsFilter,
      };

  const { data, loading } = useQuery<any, CarrierPropertyGradesVariables>(query, {
    variables: {
      input: {
        filter: {
          attribute: riskAttribute.value,
          limit: PAGE_SIZE,
          offset: pageIndex * PAGE_SIZE,
          ...categoryOps,
        },
        reportName: 'ZurichUwAR',
        streamId: stream?.streamId,
      },
    },
  });

  useEffect(() => {
    setPageIndex(0);
  }, [gradingDetailsFilter, riskAttribute?.value]);

  const tableData = getItems(riskAttribute, data);

  const pagination: Pagination = {
    // FIX ME
    // @ts-ignore
    hidePerPageOptions: true,
    pageIndex,
    pageSize: PAGE_SIZE,
    totalItemCount: tableData?.totalCount,
  };

  const onTableChange = (criteria: CriteriaWithPagination<Item>) => {
    setPageIndex(criteria.page.index);
  };

  return (
    <EuiBasicTable
      loading={loading}
      columns={getColumns(riskAttribute)}
      items={tableData?.items || []}
      pagination={pagination}
      onChange={onTableChange}
      data-testid="property-breakdown-table"
    />
  );
};

export default PropertiesToPrioritize;

import { memo, useMemo } from 'react';
import * as React from 'react';
import Highcharts from 'highcharts';
import HCMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { IGraphQLPercentageChart, IGraphQLPercentageCharts } from '@app/queries/explorers/types';
import { ExplorerRadial } from './DataQualityV2.emotion';
import { IExplorerProps } from './types';
import { buildPercentageChartData, chartDataIsEqual } from './utils';

HCMore(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});

const getDataPoint = (queryData: any, index: string, key: string) => queryData[index]?.[key] || 0;

export const formatDataQualityArrayByKey = (
  explorer: IGraphQLPercentageCharts,
): { [index: string]: IGraphQLPercentageChart } =>
  explorer.charts.reduce((accumulator, current) => {
    accumulator[current.name] = buildPercentageChartData(current);

    return accumulator;
  }, {});

const getOptions = (
  queryData = {},
  currentSnapshotLabel?: string,
  previousSnapshotLabel?: string,
  title?: string,
): any => ({
  //eslint-disable-line
  chart: {
    height: '100%',
    ignoreHiddenSeries: false,
    polar: true,
    style: {
      margin: '0 auto',
    },
    type: 'line',
    width: 290,
  },
  colorAxis: {
    maxColor: '#2F80ED',
    min: 0,
    minColor: '#E9F4FB',
  },
  credits: {
    enabled: false,
  },
  exporting: {
    chartOptions: {
      legend: {
        enabled: true,
      },
    },
  },
  legend: {
    enabled: true,
    itemStyle: {
      fontSize: '10px',
      fontWeight: 'normal',
    },
    symbolWidth: 6,
    width: '100%',
  },
  pane: {
    size: '61%',
  },
  plotOptions: {
    series: {
      marker: {
        enabled: true,
        radius: 3,
      },
    },
  },
  series: [
    {
      color: '#219653',
      data: [
        getDataPoint(queryData, 'this_year', 'geo_code_address_or_better'),
        getDataPoint(queryData, 'this_year', 'construction_class'),
        getDataPoint(queryData, 'this_year', 'specific_occupancy'),
        getDataPoint(queryData, 'this_year', 'year_built'),
        getDataPoint(queryData, 'this_year', 'roofing_material_installation_year'),
        getDataPoint(queryData, 'this_year', 'stories_above_ground'),
        getDataPoint(queryData, 'this_year', 'square_footage'),
        getDataPoint(queryData, 'this_year', 'building_sprinklered'),
        getDataPoint(queryData, 'this_year', 'building_sprinkler_type'),
      ],
      name: `Known as of ${currentSnapshotLabel || 'this year'}`,
      pointPlacement: 'on',
      zIndex: 10,
    },
    {
      color: '#F2994A',
      data: [
        getDataPoint(queryData, 'last_year', 'geo_code_address_or_better'),
        getDataPoint(queryData, 'last_year', 'construction_class'),
        getDataPoint(queryData, 'last_year', 'specific_occupancy'),
        getDataPoint(queryData, 'last_year', 'year_built'),
        getDataPoint(queryData, 'last_year', 'roofing_material_installation_year'),
        getDataPoint(queryData, 'last_year', 'stories_above_ground'),
        getDataPoint(queryData, 'last_year', 'square_footage'),
        getDataPoint(queryData, 'last_year', 'building_sprinklered'),
        getDataPoint(queryData, 'last_year', 'building_sprinkler_type'),
      ],
      name: previousSnapshotLabel,
      pointPlacement: 'on',
      zIndex: 5,
    },
  ],
  subtitle: {
    text: null,
  },
  title: {
    text: title ? title : null,
  },
  tooltip: {
    pointFormat:
      '<span style="color:{series.color}; font-weight: bold">{series.name}: <b>{point.y}% of TIV</b><br/>',
    shared: true, //eslint-disable-line
  },
  xAxis: {
    categories: [
      'Geo-code level',
      'Construction class',
      'Specific occupancy',
      'Year built',
      'Roof age',
      'Stories above ground',
      'Floor area',
      'Building sprinklered',
      'Sprinkler type',
    ],
    labels: {
      distance: 17,
      step: 1,
      style: {
        color: '#828282',
        fontSize: '10px',
        fontWeight: 'bold',
        margin: '0px',
        opacity: '1 !important',
        padding: '0px',
        width: '70px',
      },
    },
    lineWidth: 0,
    tickmarkPlacement: 'on',
  },
  yAxis: {
    gridLineInterpolation: 'polygon',
    max: 100,
    min: 0,
    tickPositions: [0, 20, 40, 60, 80, 100],
    title: {
      enabled: false,
    },
  },
});

const DataQualityV2: React.FC<IExplorerProps> = ({
  explorer,
  currentSnapshotLabel,
  previousSnapshotLabel,
  changeAnalysis,
  title,
}) => {
  const previousSnapshotTitle = useMemo(() => {
    if (!previousSnapshotLabel) {
      return 'Prev. bind N/A';
    }

    if (changeAnalysis) {
      return `Known as of ${previousSnapshotLabel}`;
    }

    return `Known as of previous binding - ${previousSnapshotLabel}`;
  }, [previousSnapshotLabel, !!changeAnalysis]);

  const queryData = formatDataQualityArrayByKey(explorer as IGraphQLPercentageCharts);
  const options = getOptions(queryData, currentSnapshotLabel, previousSnapshotTitle, title);

  return (
    <ExplorerRadial>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </ExplorerRadial>
  );
};

export default memo(DataQualityV2, chartDataIsEqual);

import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router';
import moment from 'moment';
import {
  Button,
  ButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiPanel,
  EuiText,
  EuiTitle,
  FilePicker,
} from 'ui';
import { useAuth } from '@app/containers/AuthProvider/AuthProvider';
import { getOrgPermissions } from '@app/containers/AuthProvider/helper';
import { useUserSession } from '@app/contexts/UserSessionContext';
import { useStreamContext } from '@app/cx/Stream/StreamProvider';
import { useGetStreamsQuery } from '@app/graphql/queries/streams/__generated__/GetStreams.generated';
import { IGraphQLStream } from '@app/queries/streams/types';
import { formatDate } from '@app/utils/format';
import Currency from '../Currency/Currency';
import { ProcessingBadge, StyledBadge } from '../Portfolios/Portfolios.emotion';
import { makeEffectiveStreams } from '../Portfolios/utils';
import { StyledTaskTable } from './SOVSummary.emotion';

export const SOVSummary = () => {
  const { stream } = useStreamContext();

  const { account } = useAuth();
  const history = useHistory();

  const { selectedOrganization } = useUserSession();
  const organizationName = selectedOrganization?.name;

  const isAdmin = account?.permissions?.admin;

  const permissions = getOrgPermissions(account, selectedOrganization?.id);

  const canViewLibrary = permissions.includes('canManageDocuments');

  const hasNonViewManagedOrgs = account?.managedOrgs?.some(
    (o) => o.editDocuments || o.editProjects || o.editProperties || o.editSubmissions,
  );

  const useOrgInQuery = !!(isAdmin || hasNonViewManagedOrgs);

  const { loading, data } = useGetStreamsQuery({
    skip: useOrgInQuery && !organizationName,
    variables: {
      orgName: useOrgInQuery ? organizationName : null,
    },
  });

  const hasNoSOVs = data?.streamsV2?.streams?.length < 1 || !data?.streamsV2?.streams;

  const libraryLink = selectedOrganization?.name
    ? generatePath('/organizations/:organizationName/library', {
        organizationName: selectedOrganization?.name,
      })
    : '';

  const streamsLink = selectedOrganization?.name
    ? generatePath('/organizations/:organizationName/streams', {
        organizationName: selectedOrganization?.name,
      })
    : '';

  if (hasNoSOVs && !loading && selectedOrganization?.name) {
    history.push(streamsLink);
  }

  const isProcessing = stream?.submissionStatus === 'Processing In Progress';
  const renderBadge = (stream: IGraphQLStream) => {
    if (isProcessing) {
      return <ProcessingBadge>Processing</ProcessingBadge>;
    }
    if (stream.expiryDate && moment.unix(stream.expiryDate).isBefore(moment())) {
      return <StyledBadge>Expired</StyledBadge>;
    }
    return null;
  };

  const formMethods = useForm({ mode: 'onSubmit' });
  const [files, setFiles] = useState([]);

  const updateFile = (file?: File) => {
    if (file) {
      setFiles([file]);
      formMethods.setValue('sovFile', file);
    } else {
      setFiles(undefined);
    }
  };

  // 1. isMyProperties: true` at the top
  // 2. Sort by expiryDate (soonest first)
  // 3. keep only the first 3
  const effectiveStreams = makeEffectiveStreams(data, 3);

  const header = (
    <EuiFlexGroup alignItems="flexStart" justifyContent="spaceBetween">
      <EuiTitle size="xs">
        <p>SOVs</p>
      </EuiTitle>
      {canViewLibrary && (
        <ButtonEmpty
          size="s"
          color="primary"
          label="Library"
          onClick={() => {
            history.push(libraryLink);
          }}
        ></ButtonEmpty>
      )}
    </EuiFlexGroup>
  );

  const noData = data?.streamsV2?.streams?.length === 0 || !data;

  if (noData && !loading) {
    return (
      <EuiPanel paddingSize="l">
        {header}
        <FormProvider {...formMethods}>
          <FilePicker
            prompt="Upload an SOV"
            data-testid="upload-sov"
            files={files}
            onChange={(files?: File[]) => (files ? updateFile(files[0]) : updateFile(undefined))}
          />
        </FormProvider>
      </EuiPanel>
    );
  }

  const columns = [
    {
      field: 'name',
      name: 'Name',
      render: (name, stream) => (
        <EuiFlexGroup>
          <EuiFlexItem grow={4}>{name}</EuiFlexItem>
          {renderBadge(stream)}
        </EuiFlexGroup>
      ),
      sortable: true,
      width: '35%',
    },
    {
      field: 'brokerEmail',
      name: 'Primary Contact',
      render: (brokerEmail) => (
        <EuiFlexGroup>
          {brokerEmail ? <EuiLink href={`mailto:${brokerEmail}`}>{brokerEmail}</EuiLink> : '-'}
        </EuiFlexGroup>
      ),
      sortable: true,
      width: '20%',
    },
    {
      field: 'totalInsuredValue',
      name: `TIV ${stream?.displayCurrency ? `(${stream?.displayCurrency})` : ''}`,
      render: (tiv, stream) => (
        <EuiText size="s">
          {isProcessing ? null : <Currency value={tiv} currency={stream.displayCurrency} />}
        </EuiText>
      ),
      sortable: true,
      width: '15%',
    },
    {
      field: 'propertiesCount',
      name: '# properties',
      render: (propertiesCount) =>
        isProcessing ? null : <EuiText size="s">{propertiesCount}</EuiText>,
      sortable: true,
      width: '15%',
    },
    {
      field: 'effectiveDate',
      name: 'Effective Date',
      render: (effectiveDate) => <EuiText size="s">{formatDate(effectiveDate)}</EuiText>,
      sortable: true,
      width: '15%',
    },
    {
      field: 'expiryDate',
      name: 'Expiry Date',
      render: (expiryDate) => <EuiText size="s">{formatDate(expiryDate)}</EuiText>,
      sortable: true,
      width: '15%',
    },
  ];

  return (
    <EuiPanel paddingSize="l">
      {header}
      <StyledTaskTable
        loading={loading}
        items={effectiveStreams || []}
        rowProps={(stream) => ({
          onClick: () => {
            history.push(generatePath(`/streams/${stream?.slug}`));
          },
        })}
        columns={columns}
      ></StyledTaskTable>
      {effectiveStreams.length > 0 && (
        <EuiFlexItem data-testid="sov-summary-view-all" grow={false}>
          <Button
            onClick={() => {
              history.push(streamsLink);
            }}
            size="s"
            label={'View All'}
          />
        </EuiFlexItem>
      )}
    </EuiPanel>
  );
};

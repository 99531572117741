import { FC } from 'react';
import { EuiText } from 'ui';
import OverviewItem, { OverviewItemProps } from '../../../Overview/OverviewItem';

const TivMetric: FC<OverviewItemProps> = (props) => {
  const { ...rest } = props;
  const content = (
    <EuiText size="s">
      <h3>{rest.content}</h3>
    </EuiText>
  );
  return <OverviewItem {...props} content={content} />;
};

export default TivMetric;

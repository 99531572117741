import { ReactElement, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import DisclosureButton from './DisclosureButton';
import { IFlattenedRow } from './types';

interface IProps<T> {
  row: IFlattenedRow<T>;
  height?: number;
  children: JSX.Element | string | null | undefined | boolean;
  handleClick: () => void;
  duration: number;
}

interface ContainerProps {
  active: boolean;
  height?: number;
  duration: number;
  indices: Array<number>;
}

const Container = styled.div<ContainerProps>`
  label: GroupedListRow;
  position: relative;
  display: flex;
  overflow: hidden;
  min-width: 0;
  transition-duration: ${({ duration }) => duration}ms;
  height: ${({ height, indices }) => {
    if (height !== undefined) {
      return `${height}px`;
    }

    if (indices.length === 1) {
      // Only one index implies there is no grouping,
      // therefore the row should take the full height
      return '100%';
    }

    // More than one index implies there is an active grouping,
    // therefore the row should make room for its parent row
    return 'calc(100% - 88px)';
  }};
`;

const GroupedListRow: <T>(p: IProps<T>) => ReactElement<IProps<T>> = ({
  height,
  children,
  handleClick,
  row,
  duration,
}) => {
  const hasDisclosureButton = row.options.showDisclosureButton !== false;
  const [clickable, setClickable] = useState<Boolean>(height !== 0);

  const [timer, setTimer] = useState<ReturnType<typeof setTimeout>>();

  // Disallow exposures while rows are animating
  // open. This is to prevent flashes of content as subsequent
  // rows are added / disappear from the tree.
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    if (height !== 0) {
      setTimer(
        setTimeout(() => {
          setClickable(true);
        }, duration),
      );
    } else {
      setClickable(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [height]);

  const active = clickable && hasDisclosureButton;

  return (
    <Container
      indices={row.indices}
      duration={duration}
      active={active}
      height={height}
      onClick={() => {
        if (active) {
          handleClick();
        }
      }}
    >
      {hasDisclosureButton && <DisclosureButton open={row.open} depth={row.indices.length - 1} />}
      {children}
    </Container>
  );
};

export default GroupedListRow;

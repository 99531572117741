/** @jsxImportSource @emotion/react */

import { FC, useEffect, useState } from 'react';
import { TivMapProps } from '@onarchipelago/cx/Reports/UnderwritingAssessmentReport/RiskSummary/GeographyAndLosses/TIVMap/tivMapTypes';
import { EuiFlexGroup, EuiFlexItem, EuiText, Select } from 'ui';
import Currency from '@app/components/Currency/Currency';
import { TivColumnChart } from './TivColumn';
import TivMetric from './TivMetric';

enum Hazards {
  HAZARD_EXPOSURE_QUAKEZONE = 'Earthquake exposed properties',
  HAZARD_EXPOSURE_WINDZONE = 'Wind exposed properties',
  HAZARD_EXPOSURE_FLOODZONE = 'Flood exposed properties',
}

enum Regions {
  REGION_UNITED_STATES = 'USA',
  REGION_WORLD = 'World',
}

const TivMap: FC<TivMapProps> = ({ mapData }) => {
  const [activeMapMode, setActiveMapMode] = useState<string>('REGION_UNITED_STATES');
  const [activeHazardMode, setActiveHazardMode] = useState<string>('HAZARD_EXPOSURE_QUAKEZONE');
  const hazardData = mapData.find(
    (data) => data.facets.includes(activeMapMode) && data.facets.includes(activeHazardMode),
  );

  const MapComponent: FC = () => (
    <TivColumnChart
      mapData={hazardData}
      activeHazardMode={activeHazardMode}
      activeMapMod={activeMapMode}
    />
  );

  // This function and the next below are very similar, but not enough for
  // abstracting into a single function.
  // Also these functions are imperative, even tho they don't have side effects,
  // they depend of the `mapData` object from the parent scope.
  // I'd like to figure out how to make them 100% declarative, then we could
  // refactor them into utility functions.
  const hazardOptions = Object.keys(Hazards).map((hazardKey) => {
    const hazard = Hazards[hazardKey];

    const data = mapData.filter((dataItem) => dataItem?.facets?.includes(hazardKey));
    const hasData = data.some((dataItem) => !!dataItem?.values?.length);

    return {
      disabled: !hasData,
      label: hazard,
      value: hazardKey,
    };
  });

  const regionOptions = Object.keys(Regions).map((regionKey) => {
    const region = Regions[regionKey];

    const dataOption = mapData.find(
      (dataItem) =>
        dataItem.facets.includes(regionKey) && dataItem.facets.includes(activeHazardMode),
    );

    const hasData = dataOption?.values?.length;

    return {
      disabled: !hasData,
      label: region,
      value: regionKey,
    };
  });

  // By default, EUI let people change from one option to another one even if the next
  // selection is disabled. For example, if the user lands on the page with "Wind"/"USA"
  // and changes to "Flood"/"USA" but that combination has zero data, EUI won't complain
  // and the UI will show an empty chart.
  // This hook prevents that. Checks if the new selection doesn't have data, if so then
  // it switches to the selection that has it.
  // We doesn't allow Hazards where the two regions don't have data. So we don't have
  // to worry about having both regions empty.
  useEffect(() => {
    const regionsWithEmptyData = regionOptions.filter((region) => region.disabled);

    if (regionsWithEmptyData.length) {
      const regionWithData = regionOptions.find((region) => !region.disabled);
      setActiveMapMode(regionWithData?.value);
    }
  }, [activeHazardMode, activeMapMode]);

  return (
    <EuiFlexGroup direction="column" justifyContent="spaceBetween" style={{ marginTop: '.5rem' }}>
      <EuiFlexGroup direction="row" justifyContent="spaceBetween">
        <EuiFlexItem style={{ maxHeight: '100px' }}>
          <Select options={hazardOptions} value={activeHazardMode} onChange={setActiveHazardMode} />
        </EuiFlexItem>
        <EuiFlexItem style={{ maxHeight: '100px' }}>
          <Select options={regionOptions} value={activeMapMode} onChange={setActiveMapMode} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexItem>
        <EuiFlexGroup>
          <EuiFlexItem grow={null}>
            <EuiFlexGroup direction="column">
              <EuiFlexItem grow={null}>
                <TivMetric
                  title={
                    <EuiText size="m">
                      <h4>Exposed TIV</h4>
                    </EuiText>
                  }
                  content={
                    <EuiText size="m">
                      <h2>
                        <Currency
                          value={
                            hazardData?.summaryStats.find((data) =>
                              data.name.includes('exposed_tiv'),
                            ).value
                          }
                        />
                      </h2>
                    </EuiText>
                  }
                  percentChange={
                    hazardData?.summaryStats.find((data) =>
                      data.name.includes('exposed_tiv_pct_change'),
                    ).value
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem grow={null}>
                <TivMetric
                  title={
                    <EuiText size="m">
                      <h4>% of Total TIV</h4>
                    </EuiText>
                  }
                  content={
                    hazardData?.summaryStats.find((data) =>
                      data.name.includes('exposed_tiv_pct_of_total'),
                    ).value
                  }
                  percentChange={
                    hazardData?.summaryStats.find((data) =>
                      data.name.includes('exposed_tiv_pct_of_total_pct_change'),
                    ).value
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem grow={null}>
                <TivMetric
                  title={
                    <EuiText size="m">
                      <h4>Exposed Properties</h4>
                    </EuiText>
                  }
                  content={
                    hazardData?.summaryStats.find((data) =>
                      data.name.includes('exposed_properties'),
                    ).value
                  }
                  percentChange={
                    hazardData?.summaryStats.find((data) =>
                      data.name.includes('exposed_properties_pct_change'),
                    ).value
                  }
                />
              </EuiFlexItem>
              {activeHazardMode === 'HAZARD_EXPOSURE_FLOODZONE' && (
                <EuiFlexItem css={{ fontSize: '14px', lineHeight: '24px', maxWidth: '9rem' }}>
                  <p>
                    The above totals are for high-risk flood zones (FEMA-A and FEMA-V), but exclude
                    FEMA-X.
                  </p>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <MapComponent />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default TivMap;

import { ReactElement } from 'react';
import * as React from 'react';
import Supercluster from 'supercluster';
import { MapBounds } from '@app/components/GoogleMap/types';
import PropertyMarker from '@app/components/PropertiesMap/PropertyMarker';
import { SizeBy } from '@app/components/PropertiesMap/types';
import {
  BBox,
  ClusterRange,
  GraphqlPropertyCluster,
  GraphqlPropertyMarker,
  GraphqlPropertyPoint,
} from '@app/queries/properties/types';
import { useStreamContext } from '../../StreamProvider';
import PropertyCluster from './PropertyCluster';

export const DEFAULT_ZOOM = 4;
export const DEFAULT_CENTER = { lat: 0, lng: 0 };
const sizeByClusterFields: {
  [key in SizeBy]: string;
} = {
  [SizeBy.TIV]: 'tiv',
  [SizeBy.PropertyCount]: 'propertyCount',
  [SizeBy.LossCountTotal]: 'lossCountTotal',
  [SizeBy.TotalGrossLoss]: 'totalGrossLoss',
};

const clusterize = (
  markers: Array<GraphqlPropertyMarker>,
  zoom: number,
): Array<GraphqlPropertyMarker> => {
  let id = 0;
  const supercluster = new Supercluster({
    extent: 512,
    map: (c: GraphqlPropertyMarker): GraphqlPropertyCluster => {
      if (c.__typename === 'PropertyCluster') {
        return c as GraphqlPropertyCluster;
      }

      const point = c as GraphqlPropertyPoint;
      return {
        __typename: 'PropertyCluster',
        coordinates: point.coordinates,
        expansionZoom: -1,
        id: id++,
        lossCountTotal: point.property.lossAttributes?.lossCountTotal,
        propertyCount: 1,
        totalGrossLoss: point.property.lossAttributes?.totalGrossLoss,
        totalInsuredValue: point.property.totalInsuredValue,
        totalInsuredValueDisplay: point.property.totalInsuredValueDisplay,
      };
    },
    maxZoom: 16,
    minZoom: 0,
    nodeSize: 64,

    radius: 128,

    reduce: (accumulated: GraphqlPropertyCluster, props: GraphqlPropertyCluster) => {
      accumulated.totalInsuredValue += props.totalInsuredValue;
      accumulated.totalInsuredValueDisplay += props.totalInsuredValueDisplay;
      accumulated.totalGrossLoss += props.totalGrossLoss;
      accumulated.lossCountTotal += props.lossCountTotal;
      accumulated.propertyCount += props.propertyCount;
    },
  });

  supercluster.load(
    markers.map((m) => ({
      geometry: {
        coordinates: [m.coordinates.latitude, m.coordinates.longitude],
        type: 'Point',
      },
      properties: m,
      type: 'Feature',
    })),
  );

  const clusters = supercluster.getClusters([-180, -90, 180, 90], zoom);

  return clusters.map((c) => {
    if (c.properties?.__typename === 'PropertyPoint') {
      return c.properties as GraphqlPropertyPoint;
    }

    const cluster = c.properties as GraphqlPropertyCluster;
    if (cluster.expansionZoom === -1) {
      cluster.expansionZoom = supercluster.getClusterExpansionZoom(c.id as number);
    }
    return cluster;
  });
};

export const getClusterValue = (cluster: GraphqlPropertyCluster, attribute: string): number => {
  // NOTE: To be fixed in the next iteration
  if (attribute === 'grossTotalLoss') {
    return cluster?.totalGrossLoss;
  }

  return cluster?.[attribute];
};

const MarkerWrapper: React.FC<{
  children: ReactElement;
  lat: number;
  lng: number;
  marker: GraphqlPropertyMarker;
}> = ({ children }) => <div>{children}</div>;

const normalizeDegrees = (v) => (v < 0 ? 360 + (v % 360) : v % 360);

const isMarkerInBounds = (gqlMarker: GraphqlPropertyMarker, mapBounds: MapBounds): boolean => {
  const {
    coordinates: { latitude, longitude },
  } = gqlMarker;
  const ne = mapBounds.getNorthEast();
  const sw = mapBounds.getSouthWest();

  return (
    latitude >= sw.lat() &&
    latitude <= ne.lat() &&
    normalizeDegrees(longitude - sw.lng()) <= normalizeDegrees(ne.lng() - sw.lng())
  );
};

const isMarkerInBBox = (
  gqlMarker: GraphqlPropertyMarker,
  { northEast, southWest }: BBox,
): boolean => {
  const {
    coordinates: { latitude, longitude },
  } = gqlMarker;

  return (
    latitude >= southWest.latitude &&
    latitude <= northEast.latitude &&
    longitude <= northEast.longitude &&
    longitude >= southWest.longitude
  );
};

// TODO - Fix this. This was meant to merge clusters from tile edges together, but something is wrong with the
// clustering logic here. Clusters keep changing when dragging the map, and the zoom level seems to high and inconsistent
// with api request
export const clusterizeVisibleMarkers = (
  allMarkers: Array<GraphqlPropertyMarker>,
  mapBounds: MapBounds,
  zoom: number,
): Array<GraphqlPropertyMarker> =>
  clusterize(
    allMarkers.filter((gqlMarker) => isMarkerInBounds(gqlMarker, mapBounds)),
    zoom,
  );

export const getMarkers = (
  gqlMarkers: Array<GraphqlPropertyMarker>,
  clusterRanges: Array<ClusterRange>,
  sizeBy: SizeBy,
  streamSlug: string,
) => {
  const markers = [];
  const { stream, propertyAttributeMetadata } = useStreamContext();

  const clusterRange = clusterRanges.find(({ attribute }) => {
    // NOTE: To be fix in the next iteration
    if (attribute === 'grossTotalLoss') {
      return sizeByClusterFields.totalGrossLoss;
    }

    return attribute === sizeByClusterFields[sizeBy];
  });

  gqlMarkers.forEach((gqlMarker, key) => {
    const {
      __typename,
      coordinates: { latitude, longitude },
    } = gqlMarker;
    if (__typename === 'PropertyPoint') {
      const { property } = gqlMarker as GraphqlPropertyPoint;
      markers.push(
        <MarkerWrapper key={key} lat={latitude} lng={longitude} marker={gqlMarker}>
          <PropertyMarker
            streamSlug={streamSlug || stream?.slug}
            sizeBy={sizeBy}
            propertyAttributeMetadata={propertyAttributeMetadata}
            marker={{
              attribute: { stream, ...property },
              lat: latitude,
              lng: longitude,
            }}
          />
        </MarkerWrapper>,
      );
    } else if (__typename === 'PropertyCluster') {
      markers.push(
        <MarkerWrapper key={key} lat={latitude} lng={longitude} marker={gqlMarker}>
          <PropertyCluster
            cluster={gqlMarker as GraphqlPropertyCluster}
            range={clusterRange}
            id={key}
          />
        </MarkerWrapper>,
      );
    }
  });

  return markers;
};

// getClusterZoom - Based on current map position, snap cluster zoom to the previous even integer.
// This is meant to reduce the number of api calls per zoom levels and improve performance on large
// datasets
export const getClusterZoom = (mapZoom: number): number => {
  // don't snap past a certain zoom level. We want to keep marker clustering as accurate as possible in this case
  if (mapZoom >= 16) {
    return mapZoom;
  }
  return mapZoom % 2 === 0 ? mapZoom : mapZoom - 1;
};

export const getUniqueMarkers = (
  allMarkers: Array<GraphqlPropertyMarker>,
): Array<GraphqlPropertyMarker> => {
  const idMap = {};
  const markers = [];

  allMarkers.forEach((m) => {
    const id =
      m.__typename === 'PropertyCluster'
        ? (m as GraphqlPropertyCluster).id
        : (m as GraphqlPropertyPoint)?.property?.id;

    if (!idMap[id]) {
      markers.push(m);
      idMap[id] = true;
    }
  });

  return markers;
};

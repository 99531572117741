"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloatField = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var eui_1 = require("@elastic/eui");
var utils_1 = require("../../../utils");
exports.FloatField = (0, react_1.forwardRef)(function (_a, ref) {
    var dataTestId = _a["data-testid"], append = _a.append, disabled = _a.disabled, _b = _a.fullWidth, fullWidth = _b === void 0 ? false : _b, id = _a.id, name = _a.name, onBlur = _a.onBlur, onChange = _a.onChange, placeholder = _a.placeholder, readOnly = _a.readOnly, value = _a.value, _c = _a.isInvalid, isInvalid = _c === void 0 ? false : _c, _d = _a.min, min = _d === void 0 ? null : _d;
    var handleChange = function (event) {
        return onChange((0, utils_1.toNumber)(event.target.value));
    };
    return ((0, jsx_runtime_1.jsx)(eui_1.EuiFieldNumber, { "data-testid": dataTestId || 'ui-atom-input-float-field', append: append && (0, jsx_runtime_1.jsx)(eui_1.EuiText, { children: append }), disabled: disabled, fullWidth: fullWidth, id: id, name: name, onBlur: onBlur, onChange: handleChange, placeholder: placeholder, readOnly: readOnly, step: "any", value: value, inputRef: ref, isInvalid: isInvalid, min: min }));
});

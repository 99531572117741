import { ReactElement, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAPS_API_KEY } from '@app/config';
import { getQueryAsObj, makeQueryString } from '@app/hooks/useQueryState';
import DefaultMarker from './markers/DefaultMarker';
import getMapPosition from './getMapPosition';
import MarkerWrapper from './MarkerWrapper';
import { Children, Coords, IMapPosition, IMarker,MapOptions, MapPosition, Maps } from './types';

export const getMapPositionAsString = (mapPosition?: IMapPosition | null) => {
  if (mapPosition === null || mapPosition === undefined) {
    return mapPosition;
  }
  return [mapPosition.zoom, mapPosition.center.lat, mapPosition.center.lng].join(',');
};

const getMapOptions =
  (options?: MapOptions) =>
  (maps: Maps): MapOptions => ({
    ...options,
    mapTypeControl: options?.mapTypeControl || false,
    mapTypeId: options?.mapTypeId || maps.MapTypeId.HYBRID,
    minZoom: 1,
    scaleControl: options?.scaleControl || true,
    streetViewControl: options?.streetViewControl || true,
  });

export const setMapQueryString = (location: any, e: IMapPosition) => {
  // Previously we used setPageState like this:
  // setPageState({
  //  mapPosition: {
  //    zoom: e.zoom,
  //    center: e.center,
  //  },
  // });
  //
  // The new solution is to directly set the querystring
  // this prevents the components from re-rendering and
  // greatly improves map performance.
  //
  // This is a temporary solution until a more systemic
  // approach is available.

  const qs = new URLSearchParams(location.search);
  if (e === null) {
    qs.delete('mapPosition');
  } else {
    qs.set('mapPosition', getMapPositionAsString({ center: e.center, zoom: e.zoom }) as string);
  }
  const parsed = makeQueryString(getQueryAsObj(qs));
  const url = `${location.pathname}?${parsed}`;
  window.history.replaceState(null, '', url);
};

interface IProps<T = {}> {
  zoom?: number;
  center?: Coords;
  height?: number | string;
  options?: MapOptions;
  handleMapTypeIdChange?: (mapTypeId?: string) => void;
  handleChange?: (value: IMapPosition) => void;
  handleOnDrag?: (value: any) => void;
  children?: Children<T>;
  markers?: Array<IMarker<T>>;
  skipQueryString?: boolean;
  width?: number;
}

const Container = styled.div<{ height?: number | string; width?: number }>`
  label: GoogleMap;
  height: ${({ height }) => {
    if (typeof height === 'string') {
      return height;
    }
    if (typeof height === 'number') {
      return `${height}px`;
    }
    return '100%';
  }};
  width: ${({ width }) => {
    if (typeof width === 'number') {
      return `${width}px`;
    }
    return '100%';
  }};
  flex: 1;
`;

function GoogleMap<T>({
  height,
  zoom,
  center,
  options,
  handleChange,
  handleOnDrag,
  handleMapTypeIdChange,
  markers,
  children = () => <DefaultMarker />,
  skipQueryString = false,
  width,
}: IProps<T>): ReactElement<IProps<T>> {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [mapPosition, setMapPosition] = useState<MapPosition | undefined>();

  useEffect(() => {
    if (zoom && center) {
      setMapPosition({
        center,
        zoom,
      });
    }
  }, [zoom, center]);

  return (
    <Container data-testid="properties-map-container" height={height} ref={ref} width={width}>
      {mapPosition && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: GOOGLE_MAPS_API_KEY,
            libraries: ['places'],
          }}
          resetBoundsOnResize
          yesIWantToUseGoogleMapApiInternals
          onMapTypeIdChange={handleMapTypeIdChange}
          zoom={mapPosition.zoom}
          center={mapPosition.center}
          onChange={(props) => {
            handleChange?.({
              center: props.center,
              zoom: props.zoom,
            });
            if (!skipQueryString) {
              setMapQueryString(location, {
                center: props.center,
                zoom: props.zoom,
              });
            }

            setMapPosition(
              getMapPosition({
                center: props.center,
                zoom: props.zoom,
              }),
            );
          }}
          onDrag={(value: any) => {
            if (handleOnDrag) {
              handleOnDrag(value);
            }
          }}
          options={getMapOptions(options)}
        >
          {markers?.map((marker, key) => (
              <MarkerWrapper
                key={key}
                lat={marker.lat}
                lng={marker.lng}
                marker={marker}
                testId={`map-${key}`}
              >
                {children({ marker })}
              </MarkerWrapper>
            ))}
        </GoogleMapReact>
      )}
    </Container>
  );
}

export default GoogleMap;

import * as React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText } from 'ui';
import { STREAMS_LABEL } from '@app/components/Layout/constants';
import exposureLabels from '@app/cx/ExposureIndex/utils/exposureLabels';
import { ExposureLevel } from '../../../types';
import styles from './BenchmarksKey.emotion';

interface Props {
  exposureLevel: ExposureLevel;
  percent: number;
}

const BenchmarkKeySegment: React.FC<Props> = ({ exposureLevel, percent }) => {
  const keyPercentsLabel = {
    [ExposureLevel.VeryLow]: `(<12.5% of ${STREAMS_LABEL}s)`,
    [ExposureLevel.Low]: '(12.5-25%)',
    [ExposureLevel.Medium]: '(25-75%)',
    [ExposureLevel.High]: '(75-87.5%)',
    [ExposureLevel.VeryHigh]: '(>87.5%)',
  };

  const endLabel = exposureLevel === ExposureLevel.VeryLow ? `% of ${STREAMS_LABEL}s` : '%';

  return (
    <EuiFlexGroup data-testid="exposure-benchmark-key-segment" alignItems="center" gutterSize="s">
      <EuiFlexItem grow={false}>
        <div className={styles.square(exposureLevel)} />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiText size="xs">
          {exposureLabels[exposureLevel]} {keyPercentsLabel[exposureLevel]}
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default BenchmarkKeySegment;

import { FC, useContext } from 'react';
import styled from '@emotion/styled';
import ExpanderIcon from './ExpanderIcon';
import GridContext from './GridContext';
import { Column } from './types';

type Side = 'left' | 'right';

const EXPANDER_SIZE = 22;

const Container = styled.div<{ side: Side }>`
  label: ExpanderContainer;
  position: absolute;
  z-index: 100;
  width: ${EXPANDER_SIZE / 2 + 1}px;
  overflow: hidden;
  top: 4px;

  ${({ side }) =>
    side === 'right'
      ? `
    right: 0;
    margin-right: -${EXPANDER_SIZE / 2 + 1}px;
    border-left: 1px solid #E0E0E0;
  `
      : `
    left: 0;
    margin-left: -${EXPANDER_SIZE / 2}px;
    border-right: 1px solid #E0E0E0;
  `}
`;

const Button = styled.button<{ side: Side }>`
  && {
    background: white;
    height: ${EXPANDER_SIZE}px;
    width: ${EXPANDER_SIZE}px;
    border-radius: ${EXPANDER_SIZE}px;
    border: 1px solid #e0e0e0;
    z-index: 100;
    cursor: pointer;
    outline: none;
    vertical-align: middle;

    ${({ side }) =>
      side === 'right'
        ? `
      margin-left: -${EXPANDER_SIZE / 2}px;
    `
        : ''}
  }
`;

interface IProps {
  open?: boolean;
  side: Side;
  column: Column;
}

const Expander: FC<IProps> = ({ open, side, column }) => {
  const { handleExpand } = useContext(GridContext);

  return (
    <Container data-testid="grid-expander" side={side}>
      <Button
        data-testid={`${column.id}-expander-button`}
        type="button"
        side={side}
        onClick={() => handleExpand(column)}
      >
        <ExpanderIcon open={open} side={side} />
      </Button>
    </Container>
  );
};

export default Expander;

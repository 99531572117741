import { useContext } from 'react';
import * as React from 'react';
import moment from 'moment';
import { EuiFocusTrap } from 'ui';
import { FlyoutContext } from '@app/contexts/FlyoutContext';
import { Lock } from '@app/queries/properties/PropertyQuery/types';
import { IGraphQLStream } from '@app/queries/streams/types';
import { Flyout } from './Flyout.emotion';
import UploadData from './UploadData';

interface Props {
  inProgressJob: Lock;
  stream: IGraphQLStream;
  refetch: () => void;
}

const InProgress: React.FC<Props> = ({ inProgressJob, stream, refetch }) => {
  const { closeFlyout } = useContext(FlyoutContext);

  return (
    <EuiFocusTrap>
      <Flyout ownFocus onClose={closeFlyout} aria-labelledby="flyoutTitle" size="m">
        <UploadData
          hoursRemaining={moment(inProgressJob?.expiryTime).diff(moment(), 'hours')}
          jobID={inProgressJob?.id || ''}
          nSelectedAttributes={inProgressJob?.attributeNames.length || 0}
          nSelectedProperties={inProgressJob?.propertyIds.length || 0}
          stream={stream}
          worksheetLink={inProgressJob?.worksheetURL}
          jobType={inProgressJob.type}
          refetch={refetch}
        />
      </Flyout>
    </EuiFocusTrap>
  );
};

export default InProgress;

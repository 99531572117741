/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { EuiFlexItem, EuiText } from 'ui';

const grades = [
  {
    color: '#4870C6',
    grade: 'Very Low Risk/Excellent Quality',
  },
  {
    color: '#19A5B6',
    grade: 'Low Risk/Good Quality',
  },
  {
    color: '#F7AD00',
    grade: 'Medium Risk/Fair Quality',
  },
  {
    color: '#FF7569',
    grade: 'High Risk/Poor Quality',
  },
  {
    color: '#839BAF',
    grade: 'Ungraded',
  },
];

type GradeSquareProps = {
  color: string;
};

const GradeSquare: React.FC<GradeSquareProps> = ({ color }) => {
  const size = '8px';
  return (
    <div
      aria-hidden
      css={{
        backgroundColor: color,
        display: 'inline-block',
        height: size,
        marginRight: '5px',
        width: size,
      }}
    />
  );
};

const GradeLabels: React.FC = () => (
  <EuiFlexItem css={{ alignItems: 'center', flexDirection: 'row' }}>
    <EuiText css={{ fontSize: '11px' }}>Grades:</EuiText>
    {grades.map(({ color, grade }, index) => (
      <EuiText key={index} css={{ fontSize: '11px', marginLeft: '1rem' }}>
        <GradeSquare color={color} />
        {grade}
      </EuiText>
    ))}
  </EuiFlexItem>
);

export default GradeLabels;

import { FC, ReactElement, ReactNode, useEffect } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useTracker } from '@app/hooks/useTracker';
import { useAuth } from '../AuthProvider/AuthProvider';
import { GainsightWidget } from './__mocks__/types';

interface Window {
  aptrinsic?: GainsightWidget;
}
declare const window: Window;

export const Widgets: FC<{ children: ReactNode }> = ({ children }) => {
  const mixpanel = useTracker('mixpanel');
  const { loading, account } = useAuth();
  const fullName = `${account?.givenName} ${account?.familyName}`;

  useEffect(() => {
    if (loading || !account?.email) {
      return;
    }

    // Identify a user with a unique ID to track user activity across devices, tie a user to their
    // events, and create a user profile. If you never call this method, unique visitors are tracked
    // using a UUID generated the first time they visit the site.
    // https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpanelidentify
    mixpanel.identify(account.email);

    // Register a set of super properties, which are included with all events. This will overwrite
    // previous super property values.
    // https://github.com/mixpanel/mixpanel-js/blob/master/doc/readme.io/javascript-full-api-reference.md#mixpanelregister
    mixpanel.register({ email: account.email });

    datadogLogs.addLoggerGlobalContext('account', {
      email: account.email,
      fullName,
      organization: account?.organizationName,
    });

    datadogRum.setUser({
      email: account.email,
      id: account.userId,
      name: fullName,
    });
  }, [loading, account?.email]);

  if (window.aptrinsic) {
    window.aptrinsic('identify', {
      email: account.email,
      firstName: account.givenName,
      id: account.userId,
      lastName: account.familyName,
      signUpDate: account.createdAt,
    });
  }

  return (children as ReactElement) || <div />;
};

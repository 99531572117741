import { createContext, ReactElement, useContext } from 'react';
import { DocumentsContext as IDocumentsContext } from '../types/contextTypes';
import useGetData from './data/useGetData';
import useGetDocumentProperties from './data/useGetDocumentProperties';
import useGetDownloads from './downloads/useGetDownloads';
import useGetFilters from './filters/useGetFilters';
import useGetMeta from './meta/useGetMeta';
import useGetNavigation from './navigation/useGetNavigation';
import useGetPreview from './preview/useGetPreview';
import useQueryString from './queryString/useQueryString';
import initialDocumentsState from './initialDocumentsState';

export const DocumentsContext = createContext<IDocumentsContext>(initialDocumentsState);
export const useDocumentsContext = (): IDocumentsContext => useContext(DocumentsContext);

export const DocumentsContextProvider = (props: object): ReactElement => {
  const meta = useGetMeta(); // Get meta
  const data = useGetData(meta, null); // Get data
  const downloads = useGetDownloads(); // Get downloads
  const navigation = useGetNavigation(); // Get navigation
  const filters = useGetFilters(data.classifications, navigation); // Get Filters
  const preview = useGetPreview(); // get preview
  data['properties'] = useGetDocumentProperties(meta, filters);

  useQueryString(filters, navigation); // Manage query string

  return (
    <DocumentsContext.Provider
      value={{
        data,
        downloads,
        filters,
        meta,
        navigation,
        preview,
      }}
      {...props}
    />
  );
};

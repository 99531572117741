import { FC } from 'react';
import styled from '@emotion/styled';
import { Icon } from 'ui';

interface IProps {
  open: boolean;
  depth: number;
}

const Container = styled.div<IProps>`
  label: DisclosureButton;
  position: absolute;
  z-index: 4;
  top: 50%;
  margin-top: -12px;
  margin-right: 20px;
  opacity: 0.54;
  cursor: pointer;
  transform: rotate(0deg);
  font-size: 24px;
  transition: all 0.5s;

  left: ${({ depth }) => 22 * (depth + 1)}px;

  ${({ open }) =>
    open
      ? `
    transform: rotate(180deg) translateY(6px);
  `
      : ''}
`;

const DisclosureButton: FC<IProps> = ({ open, depth }) => (
  <Container data-testid="disclosure-button" open={open} depth={depth}>
    <Icon name="chevronDown" size="l" />
  </Container>
);

export default DisclosureButton;
